import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from 'react';
import { FullscreenOutlined, FullscreenExitOutlined, PauseCircleFilled, PlayCircleFilled, BorderBottomOutlined, } from '@ant-design/icons';
import clsx from 'clsx';
import CircleButton from 'components/CircleButton';
import { txt } from 'libs/i18n';
import useResizeObserver from './use-resize-observer';
import ShakaPlayer from './shaka-player';
import HTMLPlayer from './html-player';
import VideoCaptions from './video-captions';
import { usePlaybackContext } from '../WithMediaPlayback';
import './style.less';
const MediaPlayer = ({ position, className, connection, project, forceVideoCanvas, language, }) => {
    var _a;
    const [videoWidth, setVideoWidth] = useState(0);
    const [playbackIconState, setPlaybackIconState] = useState('initial');
    const [newCaption, setNewCaption] = useState(null);
    const { captions, mediaSource, playback, playerState, setReadyToPlay, setShowVideo, } = usePlaybackContext();
    const player = useRef(null);
    const videoElement = useRef(null);
    const videoCanvas = useRef(null);
    const handleReadyToPlay = (hasVideo) => {
        setReadyToPlay(hasVideo || forceVideoCanvas);
    };
    const reloadPlayer = () => {
        if (player.current !== null) {
            player.current.destroy();
        }
        if (videoElement.current === null)
            return;
        if (mediaSource.isDash()) {
            player.current = new ShakaPlayer(videoElement.current, playback, handleReadyToPlay, mediaSource.url, connection, project);
        }
        else {
            player.current = new HTMLPlayer(videoElement.current, playback, handleReadyToPlay);
        }
    };
    const handleVideoWidthChange = () => {
        if (videoCanvas.current === null)
            return;
        setVideoWidth(videoCanvas.current.offsetWidth);
    };
    useResizeObserver({ callback: handleVideoWidthChange, elementRef: videoCanvas });
    useEffect(() => {
        return () => { var _a; return (_a = player.current) === null || _a === void 0 ? void 0 : _a.destroy(); };
    }, []);
    useEffect(() => {
        if (mediaSource.isAvailable()) {
            reloadPlayer();
        }
    }, [mediaSource.type, forceVideoCanvas]);
    const toggleVideoMaximized = () => {
        if (playerState.video === 'no-video') {
            return;
        }
        setShowVideo(playerState.video.isVisible, !playerState.video.isMaximized);
    };
    const resetCaptionPosition = () => {
        if (newCaption === null)
            return;
        captions.resetCaptionFormat(newCaption);
    };
    const hasBeenCaptionPositionChanged = (isVideoMinimized) => {
        if (newCaption === null || isVideoMinimized) {
            return false;
        }
        return captions.hasCaptionFormat(newCaption);
    };
    const handleClick = () => {
        if (playback.playing === true) {
            setPlaybackIconState('pause');
            playback.pause();
        }
        else {
            setPlaybackIconState('play');
            playback.play();
        }
    };
    return (_jsxs("div", { style: position, className: `${className}${playerState.status === 'ready' ? '' : ' display-none'}`, children: [playerState.video !== 'no-video' ? (_jsxs("div", { className: "control-elements", children: [_jsx("div", { className: "control-elements--video", children: playerState.video.isVisible ? (_jsx(CircleButton, { content: playerState.video.isMaximized
                                ? _jsx(FullscreenExitOutlined, {})
                                : _jsx(FullscreenOutlined, {}), tooltip: playerState.video.isMaximized ? txt('minVid') : txt('maxVid'), tooltipPlacement: "left", className: "video-button", onClick: toggleVideoMaximized })) : null }), playerState.video.isVisible
                        && hasBeenCaptionPositionChanged(!playerState.video.isMaximized)
                        ? (_jsx(CircleButton, { tooltip: txt('resetCaptionPosition'), tooltipPlacement: "left", className: "video-button", content: _jsx(BorderBottomOutlined, {}), onClick: resetCaptionPosition })) : null] })) : null, _jsx("div", { className: "video-player", children: _jsxs("div", { className: "video-player__canvas", ref: videoCanvas, role: "presentation", onClick: handleClick, children: [_jsx("div", { className: clsx([
                                'video-player__playback-icon',
                                {
                                    'video-player__playback-icon--minimized': playerState.video !== 'no-video' && !playerState.video.isMaximized,
                                },
                            ]), children: playbackIconState === 'initial'
                                ? null
                                : (playbackIconState === 'play'
                                    ? _jsx(PlayCircleFilled, {})
                                    : _jsx(PauseCircleFilled, {})) }), _jsx(VideoCaptions, { videoWidth: videoWidth, readOnly: project.isReadOnly, handleNewCaption: setNewCaption, language: language }), _jsxs("video", { playsInline: true, ref: videoElement, children: [mediaSource.isPackaged() ? _jsx("source", { src: ((_a = mediaSource.url) !== null && _a !== void 0 ? _a : undefined) }) : undefined, _jsx("track", { kind: "captions" })] })] }) })] }));
};
export default MediaPlayer;
