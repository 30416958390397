import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Tooltip, } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import clsx from 'clsx';
import TableView from './table-view';
import PaginationStorage from './pagination-storage';
import styles from './style.module.less';
export const empiricalScrollHeightAdjust = (scrollHeight) => {
    // The scroll height is manually calculated by trial and error
    // for all tables that need to stretch with the height of the
    // screen and not overflow beyond its bottom edge.
    return scrollHeight;
};
const tableViewDefaultParams = {};
export const useTableView = (tableId, defaultViewParams = {}) => {
    const history = useHistory();
    let viewParams = tableViewDefaultParams[tableId];
    if (viewParams === undefined) {
        viewParams = Object.assign({ version: global.VERSION, currentPage: 1, pageSize: PaginationStorage.getPageSize(tableId, 25), sieve: null }, defaultViewParams);
        tableViewDefaultParams[tableId] = viewParams;
    }
    return new TableView(tableId, history, viewParams);
};
const ResponsiveTable = ({ tableView, bordered = false, onViewChange = () => undefined, className = '', columns, components, dataSource, expandable, footer, title, loading, stripeRows, onRow, showSizeChanger = true, pageSizeOptions = ['5', '10', '25', '50', '100', '150', '200', '1000'], scroll, paginationDisplaySize = 'small', recordCount, rowClassName, rowSelection, locale, onSortChange = () => undefined, rowKey, pagination, }) => {
    useEffect(() => {
        onViewChange(tableView.getViewParams());
    }, [tableView.getViewParams()]);
    const paginationRender = (page, type, element) => {
        if (type === 'prev') {
            return (_jsx(Tooltip, { title: `${txt('prevPage')}`, children: _jsx(LeftOutlined, {}) }));
        }
        if (type === 'next') {
            return (_jsx(Tooltip, { title: `${txt('nextPage')}`, children: _jsx(RightOutlined, {}) }));
        }
        return element;
    };
    const getSortingOrder = (sorter) => {
        var _a;
        const oldSieve = tableView.getViewParams().sieve;
        // sorter.order is false when table is being changed, but sorter is not active
        // e.g. when pagination changed
        if (sorter.order === null || sorter.order === undefined) {
            return { sortBy: oldSieve === null || oldSieve === void 0 ? void 0 : oldSieve.sortBy, sortOrder: (_a = oldSieve === null || oldSieve === void 0 ? void 0 : oldSieve.sortOrder) !== null && _a !== void 0 ? _a : 'descend' };
        }
        return ({ sortBy: sorter.columnKey, sortOrder: sorter.order });
    };
    const handleTableChange = (paginationConfig, filters, sorter) => {
        tableView.setPagination(paginationConfig.current, paginationConfig.pageSize);
        const oldSieve = tableView.getViewParams().sieve;
        // Some tables may not do sorting by using sorter,
        // then sorter === {} and table is sieved without sortingOrder
        if (Object.keys(sorter).length === 0) {
            tableView.setSieve(Object.assign(Object.assign({}, oldSieve), { filters }));
        }
        else {
            if (Array.isArray(sorter)) {
                throw TypeError('array of sorter results is not supported');
            }
            const sortingOrder = getSortingOrder(sorter);
            onSortChange(sorter);
            tableView.setSieve(Object.assign(Object.assign({}, oldSieve), { filters, sortBy: sortingOrder.sortBy, sortOrder: sortingOrder.sortOrder }));
        }
    };
    return (_jsx(Table, { bordered: bordered, className: clsx(className, stripeRows === true ? styles.stripeRows : undefined), columns: columns, components: components, dataSource: dataSource, expandable: expandable, footer: footer, title: title, loading: loading, onChange: handleTableChange, onRow: onRow, pagination: pagination !== false ? {
            showSizeChanger,
            pageSizeOptions,
            total: recordCount,
            pageSize: tableView.getViewParams().pageSize,
            current: tableView.getViewParams().currentPage,
            itemRender: paginationRender,
            showTitle: false,
            size: paginationDisplaySize,
        } : false, rowClassName: rowClassName, rowSelection: rowSelection, scroll: scroll, locale: locale, rowKey: rowKey }));
};
export default ResponsiveTable;
