import { calculateTranscriptionCredit } from 'api/project-api';
const calculateCreditForOrigins = async (connection, origins) => {
    const secondsDurations = origins.map((origin) => (origin.duration.status === 'loading' && origin.duration.seconds !== null
        ? origin.duration.seconds
        : 0));
    const creditDurations = await calculateTranscriptionCredit(connection, ...secondsDurations);
    return origins.map((origin, index) => (origin.duration.status === 'loading' && origin.duration.seconds !== null
        ? origin.withCreditsDuration(creditDurations[index])
        : origin));
};
export class MediaOriginsList {
    constructor(files = [], urls = []) {
        this.files = files;
        this.urls = urls;
    }
    getAllOrigins() {
        return [...this.files, ...this.urls];
    }
    isEmpty() {
        return this.getAllOrigins().length === 0;
    }
    computeRequiredCredit() {
        return this.getAllOrigins().reduce((acc, mediaOrigin) => {
            if (this.isEmpty()
                || this.containsError()
                || this.containsLongMedia()
                || acc === 'cannot-compute') {
                return 'cannot-compute';
            }
            if (mediaOrigin.duration.status === 'loading'
                || acc === 'loading') {
                return 'loading';
            }
            if (mediaOrigin.duration.status === 'unknown'
                || acc === 'unknown') {
                return 'unknown';
            }
            return acc + mediaOrigin.duration.credits;
        }, 0);
    }
    areOriginsResolved() {
        return this.getAllOrigins().every((mediaOrigin) => (mediaOrigin.duration.status !== 'loading'));
    }
    async resolveOrigins(connection) {
        const filesWithMetadata = await Promise.all(this.files.map((mediaFile) => mediaFile.resolveDetail()));
        const urlsWithMetadata = await Promise.all(this.urls.map((mediaUrl) => mediaUrl.resolveDetail(connection)));
        const resolvedFiles = await calculateCreditForOrigins(connection, filesWithMetadata);
        const resolvedUrls = await calculateCreditForOrigins(connection, urlsWithMetadata);
        return new MediaOriginsList(resolvedFiles, resolvedUrls);
    }
    hasEnoughCredit(availableCredit) {
        const requiredCredit = this.computeRequiredCredit();
        if (requiredCredit === 'loading' || requiredCredit === 'unknown' || requiredCredit === 'cannot-compute') {
            return true;
        }
        return availableCredit >= requiredCredit;
    }
    hasEnoughStorage(availableStorage) {
        const requiredStorage = this.computeRequiredStorage();
        if (requiredStorage === 'loading' || requiredStorage === 'unknown' || requiredStorage === 'cannot-compute') {
            return true;
        }
        return requiredStorage < availableStorage;
    }
    containsMediumMedia() {
        return this.getAllOrigins().some((mediaOrigin) => mediaOrigin.hasMediumDuration());
    }
    containsLongMedia() {
        return this.getAllOrigins().some((mediaOrigin) => mediaOrigin.hasLongDuration());
    }
    containsInvalidDuration() {
        return this.getAllOrigins().some((mediaOrigin) => !mediaOrigin.hasValidDuration());
    }
    containsError(error) {
        if (error === undefined) {
            return this.getAllOrigins().some((mediaOrigin) => mediaOrigin.getError() !== null);
        }
        return this.getAllOrigins().some((mediaOrigin) => mediaOrigin.getError() === error);
    }
    canSubmit(availableCredit, availableStorage, hasUnlimitedStorage) {
        if (this.containsError() || this.containsInvalidDuration()) {
            return false;
        }
        return this.hasEnoughCredit(availableCredit)
            && (hasUnlimitedStorage || this.hasEnoughStorage(availableStorage));
    }
    removeFile(mediaFile) {
        const index = this.files.indexOf(mediaFile);
        const newFiles = [...this.files];
        newFiles.splice(index, 1);
        return new MediaOriginsList(newFiles, this.urls);
    }
    removeUrl(urlFile) {
        const index = this.urls.indexOf(urlFile);
        const newUrls = [...this.urls];
        newUrls.splice(index, 1);
        return new MediaOriginsList(this.files, newUrls);
    }
    addUrl(url) {
        const newUrls = [...this.urls, url];
        return new MediaOriginsList(this.files, newUrls);
    }
    computeRequiredStorage() {
        return this.getAllOrigins().reduce((acc, mediaOrigin) => {
            if (this.isEmpty()
                || this.containsError()
                || this.containsLongMedia()
                || acc === 'cannot-compute') {
                return 'cannot-compute';
            }
            if (mediaOrigin.size.status === 'loading'
                || acc === 'loading') {
                return 'loading';
            }
            if (mediaOrigin.size.status === 'unknown'
                || acc === 'unknown') {
                return 'unknown';
            }
            return acc + mediaOrigin.size.size;
        }, 0);
    }
}
