import { jsx as _jsx } from "react/jsx-runtime";
import { Collapse } from 'antd';
import { localeTxt } from 'libs/i18n';
import ClaimsTable from '../ClaimsTable';
const LanguageProfiles = ({ profiles, locale, userId, claimSet, onRefetchClaims, }) => {
    const claimList = profiles.map((profile) => ({
        title: `${locale.code} ${String(profile.tags[0])}`,
        apiName: profile.claim,
        tags: [profile.tags[0]],
    }));
    return (_jsx(Collapse, { bordered: false, items: [
            {
                key: locale.code,
                label: localeTxt(locale),
                children: (_jsx(ClaimsTable, { claimsList: claimList, claimSet: claimSet, userId: userId, onRefetchClaims: onRefetchClaims })),
            },
        ] }));
};
export default LanguageProfiles;
