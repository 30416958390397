import { Subject, concatAll, scan, distinctUntilChanged, } from 'rxjs';
import { bind } from '@react-rxjs/core';
import { initiateUpload, } from 'libs/media-processing/media-upload';
import { enqueue } from 'api/transcription-api';
import { UploadQueue } from './upload-queue';
export class UploadQueueController {
    constructor(connection) {
        this.activeUploads$ = new Subject();
        this.actions$ = new Subject();
        this.uploadQueue$ = this.actions$.pipe(scan((prevQueue, action) => action(prevQueue), UploadQueue.createEmpty()), distinctUntilChanged());
        this.uploadProgress$ = this.activeUploads$.pipe(concatAll());
        this.subscription = this.uploadProgress$.subscribe((uploadedFile) => {
            this.updateUpload(uploadedFile);
        });
        this.connection = connection;
        const [useUploadQueue] = bind(this.uploadQueue$, UploadQueue.createEmpty());
        this.useUploadQueue = useUploadQueue;
    }
    enqueue(items) {
        this.actions$.next((queue) => {
            const newQueue = queue.enqueue(items);
            const { itemToActivate } = newQueue;
            if (itemToActivate === null) {
                return newQueue;
            }
            this.activeUploads$.next(initiateUpload(this.connection, itemToActivate.uploadTarget, itemToActivate.mediaFile.getRawFile()));
            return newQueue.activateNext();
        });
    }
    updateUpload(uploadedFile) {
        this.actions$.next((queue) => {
            const newQueue = queue.progress(uploadedFile);
            if (queue === newQueue) {
                return queue;
            }
            if (newQueue.shouldStartTranscription && newQueue.active !== null) {
                void enqueue(this.connection, uploadedFile.projectId, newQueue.active.uploadTarget.transcribeAttributes.language, newQueue.active.uploadTarget.transcribeAttributes.profile, newQueue.active.uploadTarget.transcribeAttributes.usePPC, newQueue.active.uploadTarget.transcribeAttributes.useVAD, newQueue.active.uploadTarget.transcribeAttributes.useDiarization);
                return newQueue;
            }
            const { itemToActivate } = newQueue;
            if (itemToActivate === null) {
                return newQueue;
            }
            this.activeUploads$.next(initiateUpload(this.connection, itemToActivate.uploadTarget, itemToActivate.mediaFile.getRawFile()));
            return newQueue.activateNext();
        });
    }
    clear() {
        this.actions$.next((queue) => queue.clear());
    }
    dispose() {
        this.subscription.unsubscribe();
    }
}
