import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { getDialogPosition } from 'libs/dialog-position';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import clsx from 'clsx';
import DraggableModal from 'components/DraggableModal';
import { getKeywordsWithTimestamps, getContrastColor } from '../keywords';
import styles from './style.module.less';
const KeywordPreview = ({ keywordsHighlight, keywords, onClose, }) => {
    const [resultsPositions, setResultsPositions] = useState({});
    const [activePosition, setActivePosition] = useState(null);
    const [uniquesCategories, setUniqueCategories] = useState([]);
    const highlightedKeywords = keywordsHighlight !== null
        ? getKeywordsWithTimestamps(keywordsHighlight.textList) : null;
    useEffect(() => {
        const createUniqueKeywordCategories = () => {
            if (keywordsHighlight === null || highlightedKeywords === null) {
                return;
            }
            const result = {};
            highlightedKeywords.forEach((keyword) => {
                var _a;
                if (keyword.category !== null) {
                    // NOTE: If a unique category is not defined yet, create it.
                    if (result[keyword.category] === undefined) {
                        result[keyword.category] = {
                            currentIndex: 0,
                            keywordInfo: [],
                        };
                    }
                    (_a = result[keyword.category]) === null || _a === void 0 ? void 0 : _a.keywordInfo.push(keyword);
                }
            });
            const firstKeyword = highlightedKeywords.length > 0 ? highlightedKeywords[0] : null;
            if (firstKeyword !== null && activePosition === null && highlightedKeywords.length > 0) {
                setActivePosition(highlightedKeywords[0]);
            }
            setResultsPositions(result);
            setUniqueCategories(Object.keys(result));
        };
        createUniqueKeywordCategories();
    }, [keywordsHighlight]);
    useEffect(() => {
        if (activePosition === null
            || activePosition.highlight === null
            || activePosition.timestamp === null) {
            return;
        }
        void keywords.highlightActiveKeyword({
            timestamp: activePosition.timestamp,
            text: activePosition.text,
            highlight: activePosition.highlight,
        });
    }, [activePosition]);
    const getCorrectedCurrentIndex = (direction, result) => {
        if (direction === 'previous' && result.currentIndex <= 0) {
            return result.keywordInfo.length - 1;
        }
        if (direction === 'next' && result.currentIndex >= result.keywordInfo.length - 1) {
            return 0;
        }
        return direction === 'previous' ? result.currentIndex - 1 : result.currentIndex + 1;
    };
    const handleChangePosition = (keyword, direction) => {
        if (highlightedKeywords === null) {
            return;
        }
        // NOTE: Decolor the previous active keyword. Usecase: the user is switching between
        // active keyword by changing the result position.
        if (activePosition !== null && keyword.category !== activePosition.category) {
            if (activePosition.timestamp !== null) {
                void keywords.toneDownKeyword(activePosition.timestamp, activePosition);
            }
        }
        highlightedKeywords.forEach((item) => {
            if (item.timestamp === keyword.timestamp && keyword.category !== null) {
                const result = resultsPositions[keyword.category];
                if (result === undefined) {
                    return;
                }
                const newCurrentIndex = getCorrectedCurrentIndex(direction, result);
                setResultsPositions(Object.assign(Object.assign({}, resultsPositions), { [keyword.category]: Object.assign(Object.assign({}, result), { currentIndex: newCurrentIndex }) }));
                setActivePosition(result.keywordInfo[newCurrentIndex]);
            }
        });
    };
    const handleKeywordClick = (keyword) => {
        if (activePosition !== null && activePosition.category !== null) {
            const previousPosition = resultsPositions[activePosition.category];
            if (previousPosition === undefined) {
                return;
            }
        }
        setActivePosition(keyword);
    };
    const closeKeywordModal = () => {
        onClose();
        if (activePosition !== null && activePosition.timestamp !== null) {
            void keywords.toneDownKeyword(activePosition.timestamp, activePosition);
        }
    };
    const getCategories = uniquesCategories.map((category) => {
        const result = resultsPositions[category];
        if (result === undefined) {
            return [];
        }
        const keyword = result.keywordInfo[result.currentIndex];
        const getKeywordBgColor = () => {
            if (keyword.highlight === null) {
                return '';
            }
            if (keyword.highlight === 'default') {
                return 'var(--keyword-bgcolor)';
            }
            return keyword.highlight;
        };
        return (_jsxs("div", { className: styles.keyword, children: [_jsx("button", { type: "button", style: {
                        backgroundColor: getKeywordBgColor(),
                        color: getContrastColor(getKeywordBgColor()),
                    }, className: clsx(styles.keywordText, {
                        [styles.keywordTextHighlighted]: keyword.category === (activePosition === null || activePosition === void 0 ? void 0 : activePosition.category),
                    }), onClick: () => handleKeywordClick(keyword), children: category === '' ? txt('noCategory') : category }), _jsxs("div", { className: styles.keywordPosition, children: [_jsx("span", { className: keyword.category !== (activePosition === null || activePosition === void 0 ? void 0 : activePosition.category)
                                ? styles.inactiveKeyword : styles.activeKeyword, children: `${result.currentIndex + 1} / ${result.keywordInfo.length}` }), _jsx("button", { type: "button", onClick: () => handleChangePosition(keyword, 'previous'), className: keyword.category !== (activePosition === null || activePosition === void 0 ? void 0 : activePosition.category)
                                ? styles.inactiveKeyword : styles.activeKeyword, children: _jsx(ArrowUpOutlined, {}) }), _jsx("button", { type: "button", onClick: () => handleChangePosition(keyword, 'next'), className: keyword.category !== (activePosition === null || activePosition === void 0 ? void 0 : activePosition.category)
                                ? styles.inactiveKeyword : styles.activeKeyword, children: _jsx(ArrowDownOutlined, {}) })] })] }, nanoid(5)));
    });
    return (_jsx(DraggableModal, { bounds: "body", title: txt('keywordPreview'), onClose: closeKeywordModal, defaultPosition: getDialogPosition({ width: 500, height: 300 }), children: _jsx("div", { className: styles.dragModal, children: _jsx("div", { className: styles.content, children: highlightedKeywords === null ? txt('noKeywords') : getCategories }) }) }));
};
export default KeywordPreview;
