// Returns in cases where selection is non-existant
export const isSelectionRightToLeft = () => {
    const sel = window.getSelection();
    if (sel === null)
        return false;
    const { anchorNode, focusNode } = sel;
    // Selection does not exist
    if (!anchorNode || !focusNode) {
        return false;
    }
    // anchorNode nad focusNodes are text nodes,
    // need to access their parents which are spans
    const anchorElement = anchorNode.parentElement;
    const focusElement = focusNode.parentElement;
    if (focusElement === null || anchorElement === null)
        return false;
    // Selection is within one span
    if (anchorElement === focusElement) {
        const { anchorOffset, focusOffset } = sel;
        return anchorOffset > focusOffset;
    }
    // Check if anchor element follows, see:
    // https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
    return (Boolean(
    // eslint-disable-next-line no-bitwise
    focusElement.compareDocumentPosition(anchorElement) & Node.DOCUMENT_POSITION_FOLLOWING));
};
export const revertSelection = () => {
    const sel = window.getSelection();
    if (sel === null)
        return;
    const { anchorNode, anchorOffset, focusNode, focusOffset, } = sel;
    if (focusNode === null || anchorNode === null)
        return;
    sel.setBaseAndExtent(focusNode, focusOffset, anchorNode, anchorOffset);
};
/*
scroll so that the bottom of the element is in view
*/
export const scrollElementIntoView = (element, containerElement, margin = 60) => {
    const selectedRect = element.getBoundingClientRect();
    const containerRect = containerElement.getBoundingClientRect();
    let fixedMargin = margin;
    if (containerRect.height < 2 * margin) {
        fixedMargin = 0;
    }
    const upperLimit = containerRect.top + fixedMargin;
    const lowerLimit = containerRect.bottom - fixedMargin;
    if (selectedRect.bottom <= lowerLimit
        && selectedRect.bottom > upperLimit) {
        // no need to scroll, because bottom of the element is already in view
        return;
    }
    if (selectedRect.bottom > lowerLimit) {
        // scroll so that bottom of the element aligns with bottom of the container
        containerElement.scrollBy(0, selectedRect.bottom - lowerLimit);
    }
    else if (selectedRect.top < upperLimit) {
        // scroll so that top of the element aligns with top of the container
        containerElement.scrollBy(0, selectedRect.bottom - upperLimit);
    }
};
