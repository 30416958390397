import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Button, Divider, Form, Input, Spin, } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Alerts } from 'components/Alerts';
import { txt } from 'libs/i18n';
import AuthProviders from 'screens/LoginScreen/AuthProviders';
import { fetchProviders } from 'api/providers-api';
import formStyles from '../../LoginScreen/LoginForm/style.module.less';
import styles from './style.module.less';
const SignUpFormGeneral = ({ isAlertVisible, onContinueWithEmail, initialEmail, }) => {
    const [providers, setProviders] = useState('loading');
    const [form] = Form.useForm();
    const externalProviders = providers !== 'loading'
        ? providers.filter((provider) => provider.key !== 'Beey' && provider.key !== 'Apa')
        : [];
    useEffect(() => {
        void (async () => {
            setProviders(await fetchProviders());
        })();
    }, []);
    return (_jsxs(_Fragment, { children: [isAlertVisible && Alerts.warning(txt('alreadyExists')), _jsxs(Form, { form: form, onFinish: (values) => onContinueWithEmail(values.email), className: formStyles.form, initialValues: {
                    email: initialEmail,
                }, children: [_jsx("p", { children: txt('enterEmailPassword') }), _jsx(Form.Item, { name: "email", className: formStyles.formInput, rules: [
                            {
                                required: true,
                                transform: (value) => value.trim(),
                                type: 'email',
                                message: txt('pleaseEmail'),
                            },
                            {
                                max: 254,
                                message: txt('maxCharacters'),
                            },
                        ], children: _jsx(Input, { placeholder: txt('email'), autoFocus: true, size: "large" }) }), _jsx(Button, { className: formStyles.formButton, type: "primary", htmlType: "submit", size: "large", children: txt('continueWithEmail') }), (externalProviders.length > 0)
                        && _jsx(Divider, { className: styles.orDivider, children: txt('orLabel') }), providers !== 'loading' ? (_jsx(AuthProviders, { providers: externalProviders })) : _jsx("div", { className: styles.spinner, children: _jsx(Spin, {}) })] })] }));
};
export default SignUpFormGeneral;
