import { useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
const useResizeObserver = ({ callback, elementRef }) => {
    const { current } = elementRef;
    const observer = useRef(null);
    useEffect(() => {
        // NOTE: if we are already observing old element
        if (observer.current !== null && current !== null) {
            observer.current.unobserve(current);
        }
        const observe = () => {
            if (elementRef.current !== null && observer.current !== null) {
                observer.current.observe(elementRef.current);
            }
        };
        const ResizeObserverOrPolyfill = ResizeObserver;
        observer.current = new ResizeObserverOrPolyfill(callback);
        observe();
        return () => {
            if (observer.current !== null && elementRef.current !== null) {
                observer.current.unobserve(elementRef.current);
            }
        };
    }, [current]);
};
export default useResizeObserver;
