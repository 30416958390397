// NOTE: The sanitization of any type works as follows:
//
// Every possible runtime type schema has to produce a value of the desired
// type when sanitizing against any input value. This is achieved by two
// step process:
//
// 1.  Coertion: If the input value can somehow be used as the desired result,
//     we try to coerce it. For example, objects can be coarced by filling the
//     missing properties with default values and sanitizing the existing ones.
// 1.  Sanitization: If the input value cannot be coerced, it is thrown away and
//     replaced by a fallback value. Fallback values are of two types:
//     -   Default values: These are values that are explicitly set when creating
//         the schema.
//     -   Constructed values: These are values that are constructed by the
//         schema itself if there is no default value specified. For example,
//         an object schema without a default value will construct a full object
//         with all the sanitized values of its properties.
// NOTE: General schema to define all the possible types of data.
class Schema {
    constructor(defaultValue, transformFn) {
        this.defaultValue = defaultValue;
        this.transformFn = transformFn;
    }
    sanitize(data) {
        const coerced = this.coerce(data);
        return this.runTransform(coerced.success ? coerced.value : this.fallback());
    }
    runTransform(value) {
        return this.transformFn ? this.transformFn(value) : value;
    }
    nullable() {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return new Nullable(this);
    }
    fallback() {
        return this.defaultValue === undefined ? this.constructFallback() : this.defaultValue;
    }
}
export class Nullable extends Schema {
    constructor(schema, defaultValue, transformFn) {
        super(defaultValue, transformFn !== null && transformFn !== void 0 ? transformFn : ((value) => (value === null ? null : schema.runTransform(value))));
        this.schema = schema;
    }
    default(defaultValue) {
        return new Nullable(this.schema, defaultValue, this.transformFn);
    }
    coerce(data) {
        if (data === null) {
            return { success: true, value: data };
        }
        return this.schema.coerce(data);
    }
    nullable() {
        return this;
    }
    transform(transformFn) {
        return new Nullable(this.schema, this.defaultValue, transformFn);
    }
    constructFallback() {
        return this.schema.sanitize(undefined);
    }
}
export class StringSchema extends Schema {
    constructor(defaultValue, transformFn) {
        super(defaultValue, transformFn);
    }
    default(defaultValue) {
        return new StringSchema(defaultValue);
    }
    coerce(data) {
        if (typeof data === 'string') {
            return { success: true, value: data };
        }
        return { success: false };
    }
    transform(transformFn) {
        return new StringSchema(this.defaultValue, transformFn);
    }
    constructFallback() {
        return '';
    }
}
export class NumberSchema extends Schema {
    constructor(defaultValue, transformFn) {
        super(defaultValue, transformFn);
    }
    default(defaultValue) {
        return new NumberSchema(defaultValue);
    }
    coerce(data) {
        if (typeof data === 'number' && !Number.isNaN(data)) {
            return { success: true, value: data };
        }
        return { success: false };
    }
    transform(transformFn) {
        return new NumberSchema(this.defaultValue, transformFn);
    }
    constructFallback() {
        return 0;
    }
}
export class BooleanSchema extends Schema {
    constructor(defaultValue, transformFn) {
        super(defaultValue, transformFn);
    }
    default(defaultValue) {
        return new BooleanSchema(defaultValue);
    }
    coerce(data) {
        if (typeof data === 'boolean') {
            return { success: true, value: data };
        }
        return { success: false };
    }
    transform(transformFn) {
        return new BooleanSchema(this.defaultValue, transformFn);
    }
    constructFallback() {
        return false;
    }
}
export class LiteralSchema extends Schema {
    constructor(value) {
        super(value);
    }
    get value() {
        // NOTE: In the constructor, we ensure the defaultValue is not undefined.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.defaultValue;
    }
    default(value) {
        return new LiteralSchema(value);
    }
    coerce(data) {
        if (data === this.defaultValue) {
            return { success: true, value: data };
        }
        return { success: false };
    }
    transform() {
        return this;
    }
    constructFallback() {
        return this.value;
    }
}
export class EnumSchema extends Schema {
    constructor(values, defaultValue, transformFn) {
        super(defaultValue, transformFn);
        this.values = values;
    }
    default(defaultValue) {
        return new EnumSchema(this.values, defaultValue);
    }
    coerce(data) {
        if (this.values.includes(data)) {
            return { success: true, value: data };
        }
        return { success: false };
    }
    transform(transformFn) {
        return new EnumSchema(this.values, this.defaultValue, transformFn);
    }
    constructFallback() {
        return this.values[0];
    }
}
export class UnionSchema extends Schema {
    constructor(schemas, defaultValue, transformFn) {
        super(defaultValue, transformFn);
        this.schemas = schemas;
    }
    default(defaultValue) {
        return new UnionSchema(this.schemas, defaultValue);
    }
    coerce(data) {
        for (let i = 0; i < this.schemas.length; i += 1) {
            const coerced = this.schemas[i].coerce(data);
            if (coerced.success) {
                return coerced;
            }
        }
        return { success: false };
    }
    transform(transformFn) {
        return new UnionSchema(this.schemas, this.defaultValue, transformFn);
    }
    constructFallback() {
        return this.schemas[0].sanitize(undefined);
    }
}
export class ObjectSchema extends Schema {
    constructor(shape, defaultValue, transformFn) {
        super(defaultValue, transformFn);
        this.shape = shape;
    }
    default(defaultValue) {
        return new ObjectSchema(this.shape, defaultValue);
    }
    coerce(data) {
        if (data === null || Array.isArray(data)) {
            return { success: false };
        }
        if (typeof data === 'object') {
            return { success: true, value: this.sanitizeObject(data) };
        }
        return { success: false };
    }
    keys() {
        return new UnionSchema(Object.keys(this.shape).map((key) => new LiteralSchema(key)), Object.keys(this.shape)[0]);
    }
    values() {
        const values = Object.values(this.shape);
        return new UnionSchema(values, values[0].sanitize(undefined));
    }
    entries() {
        return Object.entries(this.shape);
    }
    transform(transformFn) {
        return new ObjectSchema(this.shape, this.defaultValue, transformFn);
    }
    constructFallback() {
        return this.sanitizeObject({});
    }
    sanitizeObject(data) {
        const result = {};
        Object.keys(this.shape).forEach((key) => {
            result[key] = this.shape[key].sanitize(data[key]);
        });
        return result;
    }
}
export class ArraySchema extends Schema {
    constructor(schema, defaultValue, trnasformFn) {
        super(defaultValue, trnasformFn);
        this.schema = schema;
    }
    default(defaultValue) {
        return new ArraySchema(this.schema, defaultValue);
    }
    coerce(data) {
        if (!Array.isArray(data)) {
            return { success: false };
        }
        const value = data.map((item) => {
            const coerced = this.schema.coerce(item);
            return coerced.success ? coerced.value : undefined;
        }).filter((item) => item !== undefined);
        return { success: true, value };
    }
    transform(transformFn) {
        return new ArraySchema(this.schema, this.defaultValue, transformFn);
    }
    constructFallback() {
        return [];
    }
}
export const s = {
    null: () => new LiteralSchema(null),
    string: (defaultValue) => new StringSchema(defaultValue),
    number: (defaultValue) => new NumberSchema(defaultValue),
    boolean: (defaultValue) => new BooleanSchema(defaultValue),
    literal: (value) => new LiteralSchema(value),
    enum: (values, defaulValue) => new EnumSchema(values, defaulValue),
    union: (schemas, defaulValue) => new UnionSchema(schemas, defaulValue),
    object: (shape) => new ObjectSchema(shape),
    array: (schema) => new ArraySchema(schema),
};
