import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import useLocalStorageState from 'use-local-storage-state';
import { ColorPicker } from 'antd';
import { SpeakerColorSchema } from 'api/settings/user-settings';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const speakerColorFormats = ['hex', 'rgb', 'hsb'];
const parseSpeakerColorFormat = (value) => {
    const format = speakerColorFormats.find((fmt) => fmt === value);
    return format !== null && format !== void 0 ? format : 'hex';
};
const SpeakerColorPicker = ({ value, onChange, onChangeComplete, onOpenChange, disabled, buttonType = 'full', children, }) => {
    const [colorFormat, setColorFormat] = useLocalStorageState('speakerColorFormat', {
        defaultValue: 'hex',
        serializer: {
            stringify: (val) => String(val),
            parse: parseSpeakerColorFormat,
        },
    });
    return (_jsx(ColorPicker, { className: clsx(styles.speakerColorPicker, buttonType === 'circle' && styles.circleButton), size: "small", disabled: disabled, arrow: false, value: value, showText: buttonType === 'full', format: colorFormat, onFormatChange: setColorFormat, presets: [
            { label: txt('primaryColors'), colors: [...SpeakerColorSchema.values] },
        ], onChange: (color) => onChange === null || onChange === void 0 ? void 0 : onChange(color.toHexString()), onChangeComplete: (color) => onChangeComplete === null || onChangeComplete === void 0 ? void 0 : onChangeComplete(color.toHexString()), onOpenChange: onOpenChange, destroyTooltipOnHide: true, panelRender: children === undefined
            ? undefined
            // eslint-disable-next-line react/no-unstable-nested-components
            : (_, { components: { Picker, Presets } }) => (_jsxs(_Fragment, { children: [_jsx(Picker, {}), children, _jsx(Presets, {})] })) }));
};
export default SpeakerColorPicker;
