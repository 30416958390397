import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { useState, useEffect, useRef } from 'react';
import { Tooltip, Button, Spin, Flex, Divider, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import TagsPanel from 'components/TagsPanel';
import ProjectMetadataModal from 'components/ProjectMetadataModal';
import { fetchMediaSize, fetchProjectLanguage, fetchProjectMetadata, listProjectSharing, } from 'api/project-api';
import { useSession } from 'components/Authenticated';
import { localeTxt, txt } from 'libs/i18n';
import { Mutex } from 'async-mutex';
import { BYTES_IN_GIGABYTE, BYTES_IN_MEGABYTE } from 'libs/utils';
import buttonStyles from 'components/DownloadButton/style.module.less';
import styles from './style.module.less';
export const renderDuration = (mediaDuration) => {
    const formattedDuration = dayjs(mediaDuration, 'HH:mm:ss').format('HH:mm:ss');
    return (_jsx("span", { children: formattedDuration }));
};
export const renderDate = (date) => {
    const formattedDate = dayjs(date);
    let short;
    if (dayjs().isSame(formattedDate, 'day')) {
        short = formattedDate.format('LT');
    }
    else {
        short = formattedDate.format('l');
    }
    return (_jsx(Tooltip, { title: formattedDate.format('l, LT'), placement: "right", children: _jsx("span", { children: short }) }));
};
const ProjectPanel = ({ project, onUpdateProjects, onUpdateTags }) => {
    var _a;
    const { session } = useSession();
    const projectRef = useRef(project);
    const mutexRef = useRef(new Mutex());
    const [expandedProjectInfo, setExpandedProjectInfo] = useState(null);
    const [metadataModalVisible, setMetadataModalVisible] = useState(false);
    const [mediaSize, setMediaSize] = useState(0);
    useEffect(() => {
        const getMediaSize = async () => {
            const size = await fetchMediaSize(session.connection, project.id);
            setMediaSize(size.bytes);
        };
        projectRef.current = project;
        void (async () => {
            const notes = await fetchProjectMetadata(session.connection, project.id, 'notes');
            const projectLanguage = await fetchProjectLanguage(session.connection, project);
            const sharedWith = await listProjectSharing(session.connection, project.id, session.login.user.email);
            setExpandedProjectInfo({ notes, projectLanguage, sharedWith });
        })();
        void getMediaSize();
    }, [project]);
    const mediaSizeFormatted = mediaSize / BYTES_IN_MEGABYTE > 1024
        ? `${(mediaSize / BYTES_IN_GIGABYTE).toFixed(2)} GB`
        : `${(mediaSize / BYTES_IN_MEGABYTE).toFixed(2)} MB`;
    const enqueueProjectUpdate = async (action) => {
        const release = await mutexRef.current.acquire();
        try {
            // NOTE: During await for mutex acquire, the project can be updated
            // (multiple times even) from the server. That's why we need to duplicate the project in ref.
            await action(projectRef.current);
            await onUpdateProjects();
        }
        catch (error) {
            global.logger.error('failed to update project', { project }, error);
        }
        release();
    };
    return (_jsxs(_Fragment, { children: [_jsx("table", { className: styles.projectPanel, children: _jsxs("tbody", { children: [_jsxs("tr", { children: [_jsxs("th", { className: styles.tableHeadings, children: [txt('name'), ":"] }), _jsxs("td", { children: [project.name, ' ', expandedProjectInfo !== null && expandedProjectInfo.projectLanguage !== null ? (_jsx("span", { children: localeTxt(expandedProjectInfo.projectLanguage) })) : null] })] }), _jsxs("tr", { children: [_jsxs("th", { className: styles.tableHeadings, children: [txt('storageStatistics'), ":"] }), _jsx("td", { children: mediaSizeFormatted })] }), _jsxs("tr", { children: [_jsxs("th", { className: styles.tableHeadings, children: [txt('created'), ":"] }), _jsx("td", { children: renderDate(project.created) })] }), _jsxs("tr", { children: [_jsxs("th", { className: styles.tableHeadings, children: [txt('updated'), ":"] }), _jsx("td", { children: renderDate(project.updated) })] }), _jsxs("tr", { children: [_jsxs("th", { className: styles.tableHeadings, children: [txt('length'), ":"] }), _jsx("td", { children: renderDuration(project.length) })] }), expandedProjectInfo === null || project.shareCount === 1 ? null : (_jsxs("tr", { children: [_jsx("th", { className: styles.tableHeadings, children: txt('sharedWith') }), _jsx("td", { children: expandedProjectInfo.sharedWith.join(', ') })] })), _jsxs("tr", { children: [_jsxs("th", { className: styles.tableHeadings, children: [txt('tags'), ":"] }), _jsx("td", { children: _jsx(TagsPanel, { project: project, enqueueProjectUpdate: enqueueProjectUpdate, onTagsChange: onUpdateTags }) })] })] }) }), _jsx(Divider, {}), _jsxs(Flex, { className: styles.aboutProject, align: "center", gap: "large", children: [_jsx("div", { children: expandedProjectInfo === null
                            ? _jsx(Spin, { indicator: _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true }) })
                            : (_jsxs(Flex, { vertical: true, children: [_jsxs(Flex, { children: [_jsxs("div", { className: styles.tableHeadings, children: [txt('notes'), ":"] }), _jsx("div", { children: expandedProjectInfo.notes })] }), _jsxs(Flex, { children: [_jsxs("div", { className: styles.tableHeadings, children: [txt('author'), ":"] }), _jsx("div", { children: project.description.author })] })] })) }), _jsx(Tooltip, { title: project.isReadOnly ? txt('readOnlyProject') : txt('editVerb'), placement: "right", children: _jsx(Button, { className: buttonStyles.button, type: "primary", size: "small", onClick: () => setMetadataModalVisible(true), id: `action-project-notes-${project.id}`, disabled: project.isReadOnly, children: txt('editVerb') }) })] }), expandedProjectInfo === null ? null : (_jsx(ProjectMetadataModal, { visible: metadataModalVisible, onCancel: () => setMetadataModalVisible(false), enqueueProjectUpdate: enqueueProjectUpdate, onMetadataChange: (metadata) => {
                    setExpandedProjectInfo(Object.assign(Object.assign({}, expandedProjectInfo), { notes: metadata.notes }));
                    void onUpdateProjects();
                }, notes: (_a = expandedProjectInfo.notes) !== null && _a !== void 0 ? _a : '', project: project }))] }));
};
export default ProjectPanel;
