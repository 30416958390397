import { useEffect } from 'react';
const globalShortcuts = [];
const parseCombo = (combo) => {
    const parts = combo.split('+').map((part) => part.trim().toLowerCase());
    return {
        ctrlKey: parts.includes('ctrl'),
        altKey: parts.includes('alt'),
        shiftKey: parts.includes('shift'),
        metaKey: parts.includes('meta'),
        key: parts[parts.length - 1],
    };
};
const comboEquals = (a, b) => {
    return (a.ctrlKey === b.ctrlKey
        && a.altKey === b.altKey
        && a.shiftKey === b.shiftKey
        && a.metaKey === b.metaKey
        && a.key.toLowerCase() === b.key.toLowerCase());
};
const findShortcutIndex = (combo) => globalShortcuts
    .findIndex((shortcut) => comboEquals(shortcut.combo, combo));
window.addEventListener('keydown', (event) => {
    const index = findShortcutIndex(event);
    if (index > -1 && globalShortcuts[index].enabled) {
        event.preventDefault();
        globalShortcuts[index].callback();
    }
});
export const registerGlobalShortcut = (combo, callback) => {
    const parsedCombo = parseCombo(combo);
    const index = findShortcutIndex(parsedCombo);
    if (index > -1) {
        // NOTE: We don't yet allow registering the same shortcut twice
        global.logger.error(`Shortcut ${combo} is already registered`);
        throw new Error(`Shortcut ${combo} is already registered`);
    }
    globalShortcuts.push({
        combo: parsedCombo,
        enabled: true,
        callback,
    });
};
export const unregisterGlobalShortcut = (combo) => {
    const index = findShortcutIndex(parseCombo(combo));
    if (index > -1) {
        globalShortcuts.splice(index, 1);
    }
};
export const enableGlobalShortcut = (combo) => {
    const index = findShortcutIndex(parseCombo(combo));
    if (index > -1) {
        globalShortcuts[index].enabled = true;
    }
};
export const disableGlobalShortcut = (combo) => {
    const index = findShortcutIndex(parseCombo(combo));
    if (index > -1) {
        globalShortcuts[index].enabled = false;
    }
};
export const useShortcut = (combo, callback) => {
    useEffect(() => {
        registerGlobalShortcut(combo, callback);
        return () => {
            unregisterGlobalShortcut(combo);
        };
    }, []);
};
export const useMuteShortcuts = (mute, ...combos) => {
    useEffect(() => {
        if (mute === true) {
            combos.forEach((combo) => disableGlobalShortcut(combo));
            return () => {
                combos.forEach((combo) => enableGlobalShortcut(combo));
            };
        }
        combos.forEach((combo) => enableGlobalShortcut(combo));
        return () => { };
    }, [mute]);
};
