import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Form, Select, } from 'antd';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import { fetchExport } from 'api/project-api';
import FormModal from 'components/FormModal';
import { useState } from 'react';
import { downloadBlobAsFile } from '../../ProjectMenuPanel/exports';
const { Option } = Select;
const DownloadModal = ({ downloadFormat, visible, setDownloadModalVisible, exportTemplates, enqueueProjectUpdate, isRightToLeft, }) => {
    const [form] = Form.useForm();
    const { session, updateUserSettings } = useSession();
    const [loading, setLoading] = useState(false);
    const savedSettings = session.login.user.settings.documentExportSettings;
    const handleSubmit = async (value) => {
        setLoading(true);
        await enqueueProjectUpdate(async (initialProject) => {
            const blob = await fetchExport(session.connection, initialProject, downloadFormat, session.login.user.settings.enableTimestampLabels, isRightToLeft, value.template);
            downloadBlobAsFile(`${initialProject.name}.${downloadFormat}`, blob);
            return initialProject;
        });
        setLoading(false);
        setDownloadModalVisible(false);
        updateUserSettings({
            documentExportSettings: {
                template: value.template,
            },
        });
    };
    return (_jsx(FormModal, { open: visible, onCancel: () => setDownloadModalVisible(false), title: downloadFormat === 'pdf' ? txt('downloadPdf') : txt('downloadDoc'), footer: (_jsxs(_Fragment, { children: [_jsx(Button, { type: "default", onClick: () => setDownloadModalVisible(false), children: txt('cancel') }, "back"), _jsx(Button, { loading: loading, type: "primary", htmlType: "submit", onClick: () => form.submit(), children: txt('download') }, "submit")] })), children: _jsx(Form, { initialValues: savedSettings, form: form, onFinish: handleSubmit, children: _jsx(Form.Item, { name: "template", label: txt('exportTemplate'), children: _jsxs(Select, { children: [_jsx(Option, { value: "default", children: txt('speakerColorDefault') }), exportTemplates.map((template) => (_jsx(Option, { value: template.name, children: template.name }, template.name)))] }) }) }) }));
};
export default DownloadModal;
