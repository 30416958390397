import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip, Progress } from 'antd';
import { CustomerServiceOutlined, WarningOutlined, StopOutlined, ClockCircleOutlined, SyncOutlined, EllipsisOutlined, LoadingOutlined, UploadOutlined, DeleteOutlined, } from '@ant-design/icons';
import dayjs from 'dayjs';
import { MovieStripIcon } from 'components/CustomIcons';
import { durationInSeconds } from 'libs/duration';
import { txt } from 'libs/i18n';
import IconWithTooltip from '../IconWithTooltip';
const computePercentProgress = (projectLength, transcribedTime) => {
    const secondsDuration = durationInSeconds(projectLength);
    const secondsTranscribed = durationInSeconds(transcribedTime);
    return Math.min(Math.round((secondsTranscribed / secondsDuration) * 100), 100);
};
const ProjectIcon = ({ listedProject }) => {
    const stateDetail = listedProject.processingStateDetail;
    const today = dayjs(new Date());
    const deleteDate = listedProject.deleteAt !== undefined
        ? dayjs(new Date(listedProject.deleteAt))
        : today;
    const diffDay = deleteDate.diff(today, 'day');
    const diffTime = diffDay === 0 ? deleteDate.diff(today, 'hour') : diffDay;
    const tooltipContent = ` ${diffDay === 0 ? txt('trashedProjectHours') : txt('trashedProjectDays')} 
  ${' '}
  ${diffTime} 
  ${diffDay === 0 ? txt('hours') : txt('days')}`;
    if (listedProject.queued) {
        // note: For queued projects the chain runs twice: once for transcoding and once
        // for transcription. While the project waits for transcription, processing state
        // can be anything.
        return _jsx(IconWithTooltip, { tooltip: txt('inQueue'), IconComponent: ClockCircleOutlined });
    }
    if (listedProject.deleteAt !== undefined) {
        return (_jsx(IconWithTooltip, { tooltip: tooltipContent, IconComponent: DeleteOutlined }));
    }
    if (stateDetail !== null) {
        if (stateDetail.transcribed === 'none') {
            if (stateDetail.uploading) {
                return _jsx(IconWithTooltip, { tooltip: txt('uploading'), IconComponent: UploadOutlined });
            }
            return (_jsx(IconWithTooltip, { tooltip: txt('processingMedia'), IconComponent: SyncOutlined, iconSpin: true }));
        }
        if (stateDetail.transcribed === 'unknown') {
            return _jsx(IconWithTooltip, { tooltip: `${txt('transcribing')}...`, IconComponent: LoadingOutlined });
        }
        if (durationInSeconds(listedProject.length) <= durationInSeconds(stateDetail.transcribed.time)) {
            // NOTE: This happens for live streams. Duration in these projects is wrong and usually around
            // 5 seconds. Therefore transcription progress would be over 100 % for all of these projects.
            // It is better not to display percent in this case at all.
            return _jsx(IconWithTooltip, { tooltip: `${txt('transcribing')}...`, IconComponent: LoadingOutlined });
        }
        return (_jsx(Tooltip, { title: `${txt('transcribing')}...`, placement: "top", children: _jsx(Progress, { type: "circle", className: "progress-icon", percent: computePercentProgress(listedProject.length, stateDetail.transcribed.time), format: (percent) => `${percent !== null && percent !== void 0 ? percent : '?? '}%`, size: 33, strokeLinecap: "square" }) }));
    }
    const { processingState } = listedProject;
    if (processingState === 'None') {
        return _jsx(IconWithTooltip, { tooltip: txt('missingRecording'), IconComponent: EllipsisOutlined });
    }
    if (processingState === 'Failed') {
        return _jsx(IconWithTooltip, { tooltip: txt('failedProject'), IconComponent: WarningOutlined });
    }
    if (processingState === 'Canceled') {
        return _jsx(IconWithTooltip, { tooltip: txt('canceledProject'), IconComponent: StopOutlined });
    }
    if (processingState === 'Completed') {
        const { mediaInfo } = listedProject;
        if (mediaInfo === null) {
            return _jsx(IconWithTooltip, { tooltip: txt('failedProject'), IconComponent: WarningOutlined });
        }
        return mediaInfo.hasVideo === true ? (_jsx(IconWithTooltip, { tooltip: txt('videoProject'), IconComponent: MovieStripIcon })) : (_jsx(IconWithTooltip, { tooltip: txt('audioProject'), IconComponent: CustomerServiceOutlined }));
    }
    return _jsx(IconWithTooltip, { tooltip: txt('processingMedia'), IconComponent: LoadingOutlined });
};
export default ProjectIcon;
