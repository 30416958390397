import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Alert, Skeleton } from 'antd';
import { useSession } from 'components/Authenticated';
import { fetchCurrentUserTranscriptionOptions } from 'api/user-api';
import { txt } from 'libs/i18n';
import { fetchCurrentTeam, fetchTeamPlan, fetchTeamStorageStatistics } from 'api/team-api';
import { selectInitialTranscriptionOption } from 'libs/locales/recognition';
import { BeeyLocale } from 'libs/locales/locale';
import { useUploadQueue } from 'screens/DashboardScreen/dashboard-context';
import FormModal from 'components/FormModal';
import { useHistory } from 'react-router';
import { BYTES_IN_GIGABYTE } from 'libs/utils';
import LoadedContent from './LoadedContent';
import styles from './style.module.less';
const TranscribeModal = ({ visible, onClose, project, }) => {
    var _a;
    const { session, refetchLogin } = useSession();
    const uploadQueue = useUploadQueue();
    const history = useHistory();
    const [shouldRedirectToProject, setShouldRedirectToProject] = useState(false);
    const [transcriptionSetup, setTranscriptionSetup] = (useState('loading'));
    useEffect(() => {
        if (visible) {
            const fetchTranscriptionSetup = async () => {
                const options = await fetchCurrentUserTranscriptionOptions(session.connection);
                const teamPlan = await fetchTeamPlan(session.connection);
                const storageStatistics = await fetchTeamStorageStatistics(session.connection);
                const usedStorage = storageStatistics.bytesPerShareCount.reduce((acc, stat) => acc + stat.bytes, 0);
                const availableStorage = Number(teamPlan.productMetadata.storageLimit) * BYTES_IN_GIGABYTE;
                const storedLocale = session.login.user.settings.transcriptionSettings.recognitionLocale;
                const initialOption = selectInitialTranscriptionOption(options, storedLocale === null ? null : BeeyLocale.fromCode(storedLocale));
                if (initialOption === null) {
                    setTranscriptionSetup('empty');
                    return;
                }
                const team = await fetchCurrentTeam(session.connection);
                setTranscriptionSetup({
                    transcriptionOptions: options,
                    initialLocale: initialOption.locale,
                    credit: team.remainingTotalCredit,
                    remainingStorage: availableStorage - usedStorage,
                });
            };
            void fetchTranscriptionSetup();
            void refetchLogin();
        }
    }, [visible]);
    const redirectUrlProject = (projectId) => {
        history.push(`/edit/${projectId}`);
    };
    useEffect(() => {
        // NOTE: Redirect to file project is dependent on the queue status and thus handled in useEffect
        const redirectFileProject = () => {
            var _a;
            if (shouldRedirectToProject && ((_a = uploadQueue.active) === null || _a === void 0 ? void 0 : _a.status) === 'running') {
                if (project !== undefined) {
                    history.push(`/edit/${uploadQueue.active.projectId}/interimEditorRedirect`);
                }
                else {
                    history.push(`/edit/${uploadQueue.active.projectId}`, { mediaFile: uploadQueue.active.mediaFile.getRawFile() });
                }
            }
        };
        redirectFileProject();
    }, [(_a = uploadQueue.active) === null || _a === void 0 ? void 0 : _a.status]);
    const handleSubmit = (mediaOriginsList, urlProjects) => {
        if ((mediaOriginsList.getAllOrigins().length !== 1)
            || (uploadQueue.isRunning && !uploadQueue.isSolitary)) {
            setShouldRedirectToProject(false);
        }
        else {
            setShouldRedirectToProject(true);
            // NOTE: Redirect to url project can happen instantly.
            if (urlProjects.length > 0) {
                redirectUrlProject(urlProjects[0].id);
            }
        }
    };
    if (!visible) {
        return null;
    }
    return (_jsx(FormModal, { className: styles.transcribeModal, footer: false, open: visible, onCancel: onClose, maskClosable: false, centered: true, children: transcriptionSetup === 'loading'
            ? (_jsxs(_Fragment, { children: [_jsx(Skeleton, { active: true, paragraph: { rows: 2 } }), _jsx(Skeleton, { active: true, paragraph: { rows: 4 } }), _jsx(Skeleton, { active: true, paragraph: { rows: 4 } }), _jsx(Skeleton, { active: true, paragraph: { rows: 3 } })] }))
            : transcriptionSetup === 'empty'
                ? _jsx(Alert, { type: "warning", showIcon: true, message: txt('noTranscriptionOptions') })
                : (_jsx(LoadedContent, { onClose: onClose, onSubmit: handleSubmit, transcriptionOptions: transcriptionSetup.transcriptionOptions, initialLocale: transcriptionSetup.initialLocale, credit: transcriptionSetup.credit, remainingStorage: transcriptionSetup.remainingStorage, project: project })) }));
};
export default TranscribeModal;
