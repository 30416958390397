import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Progress, Space } from 'antd';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
import { separateThousands, separateThousandsWithDecimals } from '../LastPayment';
const PlanUsage = ({ icon, total, remaining, unit, decimal, }) => {
    const percent = total !== undefined && remaining !== undefined ? (remaining / total) * 100 : 0;
    return (_jsxs("div", { children: [_jsxs(Flex, { justify: "space-between", children: [_jsxs(Space, { children: [_jsx("strong", { children: decimal
                                    ? separateThousandsWithDecimals(remaining !== null && remaining !== void 0 ? remaining : 0)
                                    : separateThousands(remaining !== null && remaining !== void 0 ? remaining : 0) }), _jsx("span", { children: txt('left') })] }), _jsxs(Space, { children: [txt('fromAmount'), separateThousands(total !== null && total !== void 0 ? total : 0), unit] })] }), _jsxs(Flex, { align: "center", gap: "middle", children: [icon, _jsx(Progress, { percent: percent, showInfo: false, className: styles.progress })] })] }));
};
export default PlanUsage;
