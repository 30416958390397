export class CaptionsCache {
    constructor() {
        this.cachedCaptions = new Map();
    }
    set(index, caption) {
        this.cachedCaptions.set(index, caption);
    }
    reset() {
        this.cachedCaptions = new Map();
    }
    get(index) {
        return this.cachedCaptions.get(index);
    }
}
