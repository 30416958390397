import { escapeXMLString } from 'libs/xml-utils';
export default class TrsxChapter {
    constructor(name) {
        this.name = name;
        this.sections = [];
    }
    addSection(section) {
        this.sections.push(section);
    }
    serialize() {
        const escapedName = escapeXMLString(this.name);
        const header = `  <ch name="${escapedName}">\n`;
        const inner = this.sections.map((se) => se.serialize()).join('');
        const footer = '  </ch>\n';
        return header + inner + footer;
    }
}
