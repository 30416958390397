import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DatePicker, ConfigProvider, Button, Form, } from 'antd';
import { getCurrentAntdLocale } from 'libs/dayjs-locale';
import { txt } from 'libs/i18n';
import dayjs from 'dayjs';
import styles from './style.module.less';
const { RangePicker } = DatePicker;
const DateRangeFilterDropdown = ({ onDropdownVisibility, filterDates, onFilterDatesChanged, onClearSieve, }) => {
    const [form] = Form.useForm();
    const handleFilterDatesChanged = (selectedDates) => {
        const dateRange = selectedDates.rangePicker;
        const today = dayjs();
        const isCurrentMonth = !dateRange[1].isBefore(today, 'month');
        const selectedFrom = dateRange[0];
        const selectedTo = isCurrentMonth ? dateRange[1] : dateRange[1].endOf('month');
        const monthsRange = [selectedFrom.format('YYYY-MM-01 HH:mm:ss'), selectedTo.format('YYYY-MM-DD HH:mm:ss')];
        onFilterDatesChanged(monthsRange);
        onDropdownVisibility(false);
    };
    const handleClearFilter = () => {
        form.setFieldsValue({ rangePicker: ['', ''] });
        onClearSieve();
        onDropdownVisibility(false);
    };
    return (_jsx("div", { className: styles.dialog, children: _jsx(ConfigProvider, { locale: getCurrentAntdLocale(), children: _jsxs(Form, { form: form, initialValues: {
                    rangePicker: filterDates.length === 0
                        ? [null, null]
                        : [dayjs(filterDates[0]), dayjs(filterDates[1])],
                }, onFinish: handleFilterDatesChanged, children: [_jsx(Form.Item, { name: "rangePicker", rules: [
                            {
                                validator: async (_, date) => {
                                    if (date === undefined
                                        || date === null
                                        || date[0] === ''
                                        || date[1] === '') {
                                        return Promise.reject(txt('enterDate'));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ], children: _jsx(RangePicker, { picker: "month" }) }), _jsxs("div", { className: styles.buttons, children: [_jsx(Button, { size: "small", type: "link", onClick: handleClearFilter, children: txt('clearFilter') }), _jsx(Button, { type: "primary", size: "small", htmlType: "submit", children: txt('filter') })] })] }) }) }));
};
export default DateRangeFilterDropdown;
