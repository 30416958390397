import * as clientEnv from 'libs/client-env';
import jsLogger from 'js-logger';
import { buildLogItem, sendStoredLogs } from 'api/logging';
// workaround to prevent ResizeObserver loop limit exceeded errors
// https://trello.com/c/zSP94PMr/913-resizeobserver-loop-limit-exceeded-errors
// https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
// @ts-ignore
window.ResizeObserver = undefined;
jsLogger.useDefaults();
const LOG_STORAGE_LIMIT = 99;
const isLogFields = (value) => {
    if (typeof value !== 'object') {
        return false;
    }
    if (value === null) {
        return false;
    }
    if (Array.isArray(value)) {
        return false;
    }
    return true;
};
const validateLogMessages = (messages) => {
    if (messages.length < 1) {
        return 'Log must have at last a text message';
    }
    if (messages.length > 3) {
        return 'Log cannot have more then 3 parts';
    }
    const [template, fields, error] = messages;
    if (typeof template !== 'string') {
        return 'Log message must be a string';
    }
    if (fields !== undefined && !isLogFields(fields)) {
        return 'Log fields must be in LogFields format';
    }
    if (error !== undefined && !(error instanceof Error)) {
        return 'Log error must be an instance of Error';
    }
    return 'valid';
};
const isLogMessages = (messages) => validateLogMessages(messages) === 'valid';
const getProjectId = () => {
    var _a, _b;
    return (_b = (_a = /\/edit\/([0-9]+)/
        .exec(window.location.pathname)) === null || _a === void 0 ? void 0 : _a[1]) !== null && _b !== void 0 ? _b : null;
};
const consoleHandler = jsLogger.createDefaultHandler();
const getLogLevelFromContext = (context) => {
    switch (context.level.name) {
        case 'DEBUG': {
            return 'Debug';
        }
        case 'WARN': {
            return 'Warning';
        }
        case 'ERROR': {
            return 'Error';
        }
        case 'INFO':
        default: {
            return 'Information';
        }
    }
};
const EMPTY_LOG = '0|';
const postHandler = async (messages, context) => {
    var _a, _b, _c;
    const [messageTemplate, fields, error] = messages;
    const user = (_a = clientEnv.getUserEmail()) !== null && _a !== void 0 ? _a : undefined;
    const projectId = (_b = getProjectId()) !== null && _b !== void 0 ? _b : undefined;
    const level = getLogLevelFromContext(context);
    const logItem = await buildLogItem(level, messageTemplate, Object.assign({ user, projectId }, fields), error);
    const prevLog = (_c = localStorage.getItem('log')) !== null && _c !== void 0 ? _c : EMPTY_LOG;
    const sizeSeparatorIndex = prevLog.indexOf('|');
    const prevLogSize = Number(prevLog.substring(0, sizeSeparatorIndex));
    if (prevLogSize > LOG_STORAGE_LIMIT) {
        return;
    }
    const jsonItem = JSON.stringify(logItem);
    if (prevLog === EMPTY_LOG) {
        localStorage.setItem('log', `1|${jsonItem}`);
    }
    else {
        const prevLogData = prevLog.substring(sizeSeparatorIndex + 1);
        localStorage.setItem('log', `${prevLogSize + 1}|${prevLogData},${jsonItem}`);
        if (prevLogSize === (LOG_STORAGE_LIMIT - 1)) {
            jsLogger.warn('Reached the maximum number or log messages per period.');
        }
    }
};
jsLogger.setHandler((messages, context) => {
    try {
        consoleHandler(messages, context);
        if (isLogMessages(messages)) {
            void postHandler(messages, context);
        }
        else {
            const validation = validateLogMessages(messages);
            jsLogger.error(`Log format error: ${validation}`);
        }
    }
    catch (error) {
        try {
            // This happens for example when Firefox throws network error.
            jsLogger.error('error while loggin error.', { message: String(error) });
        }
        catch (error2) {
            // eslint-disable-next-line no-console
            console.error('failed to log error that logging error failed.', error2);
        }
    }
});
setInterval(sendStoredLogs, 180000); // Every three minutes
window.addEventListener('load', sendStoredLogs);
window.addEventListener('error', (e) => {
    jsLogger.error(e.message, {
        filename: e.filename,
        lineno: e.lineno,
        colno: e.colno,
    }, e.error);
});
export default jsLogger;
