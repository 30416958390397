import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col, InputNumber, Switch, Tooltip, } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const AbandonProjects = ({ value, onChange, children, hasInfoCircle = false, isTeamOwner, }) => {
    const handleSwitch = (val) => {
        if (val === false && value.inDays !== null) {
            onChange({ inDays: null, enable: val }, true);
        }
        else {
            onChange({ inDays: value.inDays, enable: val }, false);
        }
    };
    const handleInputChange = (val) => {
        onChange(Object.assign(Object.assign({}, value), { inDays: val }), false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Row, { children: [_jsxs(Col, { sm: 12, className: `${styles.header} ${styles.label}`, children: [txt('automaticProjectDeletion'), hasInfoCircle
                                ? (_jsx(Tooltip, { title: txt('queueProjectsDeletion'), children: _jsx(InfoCircleOutlined, { className: styles.infoCircle }) })) : null] }), _jsx(Col, { children: _jsx(Switch, { defaultChecked: value.inDays !== null, onChange: handleSwitch, size: "small", disabled: !isTeamOwner }) })] }), !hasInfoCircle
                ? (_jsx(Col, { children: txt('queueProjectsDeletion') })) : null, _jsx("div", { className: styles.italics, children: txt('permanentlydeleteProjects') }), _jsxs(Row, { className: styles.row, children: [_jsxs(Col, { className: styles.label, sm: 12, children: [txt('deleteProjectsAfter'), ":"] }), _jsxs(Col, { children: [_jsx(InputNumber, { className: styles.daysInput, type: "number", min: 1, precision: 0, disabled: value.enable !== true, value: value.inDays, onChange: handleInputChange }), _jsx("span", { className: styles.afterDays, children: txt('afterDays') }), children] })] })] }));
};
export default AbandonProjects;
