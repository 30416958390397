import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, } from 'react';
import { useAppContext } from 'App/app-context';
import { txt } from 'libs/i18n';
import { Button, App } from 'antd';
import IntroScreen from 'components/IntroScreen';
const VersionMismatchOverlay = ({ children }) => {
    const { versionMatch } = useAppContext();
    const { notification } = App.useApp();
    const [notificationTriggered, setNotificationTriggered] = useState(false);
    useEffect(() => {
        if (versionMatch === 'compatible') {
            // wait to see if there is a hard mismatch together with this soft mismatch.
            setTimeout(() => {
                setNotificationTriggered(true);
            }, 2000);
        }
    }, [versionMatch]);
    useEffect(() => {
        if (notificationTriggered && versionMatch !== 'incompatible') {
            notification.info({
                message: txt('newVersionAvailable'),
                description: (_jsx(Button, { onClick: () => { window.location.reload(); }, children: txt('reload') })),
                placement: 'topRight',
                duration: 0,
            });
        }
    }, [notificationTriggered]);
    // NOTE: Logging version mismatch caused by uknown version for monitoring reasons.
    // If the reason when this occurs is found, the logging can be deleted.
    if (versionMatch === 'unknown') {
        global.logger.info('Version mismatch - unknown version.');
    }
    return (versionMatch === 'incompatible' || versionMatch === 'unknown' ? (_jsxs(IntroScreen, { children: [_jsxs("p", { children: [txt('updateNeeded'), ' ', txt('pageRefresh')] }), _jsx(Button, { onClick: () => { window.location.reload(); }, children: txt('reload') })] })
    // eslint-disable-next-line react/jsx-no-useless-fragment
    ) : _jsx(_Fragment, { children: children }));
};
export default VersionMismatchOverlay;
