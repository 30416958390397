import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import clsx from 'clsx';
import { LoadingOutlined } from '@ant-design/icons';
import { App, Spin, Tooltip } from 'antd';
import { Authorized } from 'components/Authorized';
import './style.less';
import { txt } from 'libs/i18n';
const ControlsButton = ({ icon, label, busyMessage, isBeta, accessClaim, accessRole, disabledMessage, extra, action, asyncAction, className, }) => {
    const [loading, setLoading] = useState(false);
    const { message } = App.useApp();
    const handleClick = async () => {
        if (action === undefined) {
            if (asyncAction === undefined) {
                return;
            }
            setLoading(true);
            try {
                await asyncAction();
            }
            catch (error) {
                void message.error(txt('unexpectedError'));
                global.logger.error('action failed', {}, error);
            }
            setLoading(false);
            return;
        }
        action();
    };
    return (_jsx(Authorized, { claim: accessClaim, userRole: accessRole, children: _jsx(Tooltip, { title: disabledMessage, placement: "top", trigger: ['focus', 'hover'], children: _jsxs("div", { className: "controls-button", children: [_jsx("button", { type: "button", className: clsx('controls-button__btn', className), onClick: handleClick, disabled: disabledMessage !== undefined || loading, children: loading ? (_jsxs("div", { className: "controls-button__loading", children: [_jsx(Spin, { indicator: _jsx(LoadingOutlined, {}) }), _jsx("span", { className: "busy-msg", children: busyMessage })] })) : (_jsxs(_Fragment, { children: [icon, _jsx("span", { className: "controls-button__label", children: label }), isBeta === true ? _jsx("span", { className: "beta-tag", children: "BETA" }) : null] })) }), extra === undefined ? null : (_jsx("div", { className: "extra", children: extra }))] }) }) }));
};
export default ControlsButton;
