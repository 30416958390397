import Quill from 'quill';
import { getNodeProperty } from 'libs/quill-utils';
import { inlineblotOrder } from './inline-blot-order';
const WARNING = 'warning';
const Inline = Quill.import('blots/inline');
const getClasses = (value) => {
    if (!value.includes(' ')) {
        return `${WARNING}-${value}`;
    }
    const [type, details] = value.split(' ');
    return `${WARNING}-${type} ${WARNING}-details-${details}`;
};
class WarningBlot extends Inline {
    static create(value) {
        const node = super.create(value);
        if (!inlineblotOrder.includes(WARNING)) {
            // NOTE: if the format name is missing in blot order, it causes bugs in quill,
            // see https://github.com/quilljs/quill/issues/2312
            throw new Error('Blot is not listed in inline blot order');
        }
        if (typeof value === 'string') {
            node.className = getClasses(value);
        }
        return node;
    }
    static formats(domNode) {
        return WarningBlot.valueFromDomNode(domNode);
    }
    static valueFromDomNode(domNode) {
        const typePrefix = `${WARNING}-`;
        const detailsPrefix = `${WARNING}-details-`;
        const type = getNodeProperty(domNode, typePrefix);
        const details = getNodeProperty(domNode, detailsPrefix);
        if (type === null) {
            throw Error('warning type is missing');
        }
        if (details === null) {
            return type;
        }
        return `${type} ${details}`;
    }
    format(name, value) {
        if (name === WARNING && value) {
            this.domNode.className = getClasses(value);
        }
        else {
            super.format(name, value);
        }
    }
    formats() {
        const formats = super.formats();
        formats.warning = WarningBlot.formats(this.domNode);
        return formats;
    }
    attach() {
        if (this.parent != null) {
            this.scroll = this.parent.scroll;
        }
    }
}
WarningBlot.blotName = WARNING;
export default WarningBlot;
WarningBlot.blotName = WARNING;
// setting a custom tag name prevents some really weird bugs in quill.
WarningBlot.tagName = WARNING;
