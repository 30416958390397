const NBSP_WORDS_PER_LANGUAGE = {
    'cs-CZ': new Set([
        'během', 'bez', 'beze', 'dle', 'proč', 'kdy', 'jestli',
        'kolem', 'krom', 'kromě', 'kvůli', 'mezi', 'nad', 'nade', 'ode', 'ohledně', 'okolo',
        'oproti', 'poblíž', 'pod', 'pode', 'podél', 'podle', 'před', 'přede', 'přes', 'přese',
        'při', 'pro', 'proti', 'skrze', 'včetně', 'vůči', 'zpod', 'zpoza', 'než',
        'to', 'ač', 'aj', 'ať', 'až', 'či', 'co', 'že', 'od', 'do', 'za', 'na', 'po', 'tak',
        'ten', 'tento', 'tenhle', 'onen', 'takový', 'týž', 'tentýž', 'tohoto', 'toho',
        'slečno', 'slečnu', 'pan', 'pane', 'pana', 'paní',
        'no', 'my', 'vy', 'ty', 'ti', 'ale', 'který', 'která', 'které', 'kterou',
        'našim', 'našemu', 'naší', 'naši',
        'nějaké', 'nějaký', 'nějakou', 'jak',
        'ke', 'ze', 've',
    ]),
    'pl-PL': new Set([
        'nad', 'po', 'dookoła', 'na', 'przed', 'pomiędzy', 'ale', 'blisko', 'podczas', 'dla', 'środku', 'zamiast', 'podobnie',
        'obok', 'poza', 'zewnętrzny', 'od', 'niż', 'pod', 'do', 'bez', 'za', 'u', 'znad', 'poprzez',
        'ten', 'ta', 'to', 'tamten', 'tamta', 'tamto', 'ci', 'te', 'tamci', 'tamte',
        'oraz', 'tudzież', 'albo', 'bądź', 'czy', 'lub', 'ani', 'ni', 'aczkolwiek', 'ale', 'jednak', 'lecz', 'natomiast', 'zaś',
        'czyli', 'mianowicie', 'ponieważ', 'dlatego', 'przeto', 'tedy', 'więc', 'zatem', 'toteż', 'aby', 'bowiem', 'choć', 'czy',
        'jeżeli', 'że', 'by', 'żeby', 'ażeby', 'bo', 'gdyż', 'albowiem', 'jak', 'jeśli', 'który', 'która', 'które', 'którzy',
        'pan', 'pana', 'panu', 'panem', 'panie', 'pani', 'panią',
    ]),
    'sk-SK': new Set([
        'od', 'pre', 'spoza', 'niže', 'hore', 'začiatkom', 'počínajúc', 'bez', 'cez', 'do', 'medzi', 'na', 'nad', 'od', 'okrem',
        'po', 'pod', 'pre', 'pred', 'pri', 'proti', 'naproti', 'oproti', 'skrz', 'za', 'ponad', 'popod', 'popred', 'poza', 'popri',
        'pomedzi', 'znad', 'spred', 'zmedzi', 'vopred', 'odpred', 'spopred', 'sponad', 'spopod', 'spoza', 'spopri', 'spomedzi',
        'kvôli', 'namiesto', 'naprieč', 'napriek', 'navzdor', 'navzdory', 'neďaleko', 'obďaleč', 'okolo', 'počas', 'podľa', 'vedľa',
        'povedľa', 'pozdĺž', 'poniže', 'povyše', 'pozdola', 'pozhora', 'pokraj', 'pomimo', 'naprostred', 'vprostred', 'uprostred',
        'doprostred', 'sprostred', 'strán', 'vnútri', 'voči', 'vôkol', 'zdola', 'zhora', 'skraja', 'skraj', 'znútra', 'sponiže', 'vrátane',
        'včítane', 'ohľadom', 'ohľadne', 'ku', 'zo', 'so', 'vo', 'odoů',
        'aj', 'ani', 'ani', 'ako', 'ba', 'ale', 'no', 'lež', 'lenže', 'leda', 'že', 'iba', 'avšak', 'alebo', 'buď', 'buďto', 'či',
        'abo', 'lebo', 'veď', 'však', 'teda', 'totiž', 'či', 'čiže', 'žeby', 'aby', 'keď', 'keby', 'ako', 'kým', 'čo', 'než',
        'zatiaľ', 'pokiaľ', 'pokým', 'dokým', 'len', 'pretože', 'keďže', 'bo', 'nech', 'ak', 'ač', 'až', 'hoci', 'hoc', 'bár',
        'bárs', 'trebárs', 'čopriam', 'akokoľvek', 'ačkoľvek', 'by', 'pričom', 'ktorý', 'ktorá', 'ktoré', 'ktorého', 'ktorému',
        'ktorom', 'ktorým', 'ktorej', 'ktorů', 'ktorou', 'ktorí', 'ktorých', 'ktorími', 'pán', 'paní', 'slečna', 'pána',
        'ten', 'to', 'tam', 'tomu', 'tu', 'tá', 'tí', 'tie', 'onen', 'oná', 'tamten', 'tento',
    ]),
    'en-US': new Set([
        'is', 'am', 'are', 'was', 'were', 'seem', 'seems', 'seemed', 'I', 'you', 'she', 'he', 'it',
        'we', 'they', 'my', 'your', 'her', 'his', 'its', 'our', 'their', 'a', 'an', 'the', 'this',
        'these', 'those', 'some', 'any', 'every', 'much', 'many', 'no', 'several', 'enough', 'so',
        'too', 'had', 'has', 'have', 'not', 'do', 'did', 'can', 'could', 'may', 'might', 'will',
        'would', 'should', 'shall', 'must', 'need', 'needs', 'been', 'I’m', 'I’ve', 'I’d',
        'you’re', 'you’ve', 'you’d', 'she’s', 'she’d', 'he’s ', 'he’d', 'we’re', 'we’ve', 'we’d',
        'they’re', 'they’ve', 'they’d', 'hadn’t', 'hasn’t', 'haven’t', 'don’t', 'didn’t', 'can’t',
        'couldn’t', 'won’t', 'wouldn’t', 'shouldn’t', 'mustn’t', 'needn’t', 'could’ve', 'should’ve',
        'would’ve', 'it’s', 'that’s', 'what’s', 'who’s', 'when’s', 'where’s', 'why’s', 'and', 'but',
        'nor', 'or', 'unless', 'if', 'because', 'although', 'lest', 'since', 'owing', 'regardless',
        'that', 'which', 'what', 'who', 'whom', 'whose', 'when', 'where', 'why', 'against', 'amid',
        'amidst', 'mid', 'midst', 'among', 'amongst', 'anti', 'as', 'at', 'beside', 'but', 'circa',
        'despite', 'during', 'except', 'for', 'from', 'including', 'into', 'less', 'minus', 'next',
        'of', 'onto', 'opposite', 'out', 'per', 'plus', 'post', 'pre', 'pro', 'than', 'till', 'times',
        'to', 'toward', 'towards', 'unlike', 'until', 'upon', 'versus', 'via', 'vice', 'with', 'aboard',
        'about', 'above', 'across', 'after', 'alongside', 'before', 'behind', 'below', 'beneath', 'besides',
        'between', 'beyond', 'by', 'down', 'in', 'inside', 'like', 'near', 'on', 'outside',
        'past', 'since', 'through', 'throughout', 'under', 'underneath', 'within', 'without',
    ]),
    'de-DE': new Set([
        'Herr', 'Frau', 'der', 'die', 'das', 'dem', 'den', 'des', 'ein', 'eine', 'einer', 'einem', 'einen', 'eines',
        'ich', 'du', 'er', 'sie', 'es', 'wir', 'ihr', 'Sie', 'mein', 'dein', 'sein', 'unser', 'euer', 'Ihr',
        'meine', 'deine', 'seine', 'ihre', 'unsere', 'eure', 'Ihre', 'meines', 'deines', 'seines', 'ihres',
        'unseres', 'eures', 'Ihres', 'meiner', 'deiner', 'seiner', 'ihrer', 'unserer', 'eurer', 'Ihrer',
        'meinem', 'deinem', 'seinem', 'ihrem', 'unserem', 'eurem', 'Ihrem', 'meinen', 'deinen', 'seinen', 'ihren',
        'unseren', 'euren', 'Ihren', 'mich', 'dich', 'sich', 'uns', 'euch', 'mir', 'dir', 'dessen', 'deren',
        'dieser', 'diese', 'dieses', 'derjenige', 'diejenige', 'dasjenige', 'diejenigen', 'derselbe', 'dieselbe',
        'dasselbe', 'dieselben', 'desjenigen', 'derjenigen', 'desselben', 'derselben', 'demjenigen', 'denjenigen',
        'demselben', 'denselben', 'denselben', 'dieselben', 'jeder', 'jede', 'jedes', 'man', 'jedem', 'jeder',
        'einem', 'jeden', 'einen', 'habe', 'hast', 'hat', 'haben', 'habt', 'hatte', 'hattest', 'hatte', 'hatten',
        'hattet', 'bin', 'bist', 'ist', 'sind', 'seid', 'war', 'warst', 'waren', 'wart', 'zu', 'an', 'am', 'auf',
        'hinter', 'in', 'im', 'ins', 'bei', 'beim', 'neben', 'vor', 'vom', 'zu', 'zum', 'zur', 'mit', 'ohne',
        'anlässlich', 'aufgrund', 'bezüglich', 'dank', 'gemäss', 'infolge', 'laut', 'mangels', 'ungeachtet',
        'zwecks', 'als', 'ab', 'außer', 'aus', 'gegenüber', 'nach', 'seit', 'von', 'bis', 'durch', 'um', 'gegen',
        'entlang', 'über', 'unter', 'zwischen', 'wegen', 'während', 'infolge', 'statt', 'trotz', 'aber', 'denn',
        'doch', 'oder', 'sondern', 'und', 'als', 'bevor', 'bis', 'da', 'damit', 'dass', 'ehe', 'falls', 'indem',
        'nachdem', 'obwohl', 'seit', 'seitdem', 'sodass', 'solange', 'sooft', 'während', 'weil', 'wenn',
        'wohingegen', 'allerdings', 'also', 'anschließend', 'außerdem', 'dabei', 'dadurch', 'dafür', 'dagegen',
        'damit', 'danach', 'dann', 'darauf', 'darum', 'davor', 'dazu', 'deshalb', 'deswegen', 'ferner', 'folglich',
        'genauso', 'immerhin', 'inzwischen', 'jedoch', 'schließlich', 'seitdem', 'später', 'trotzdem', 'vorher',
        'weder', 'noch', 'zuvor', 'zwar',
    ]),
    'nl-NL': new Set([
        'mijn', 'onze', 'dit', 'een', 'had', 'de', 'het',
        'maar', 'na', 'zo', 'noch', 'jij', 'jouw', 'elk', 'am', 'in', 'als',
        'of', 'hij', 'zijn', 'ene', 'van', 'onder', 'sinds', 'ook', 'dan',
        'voor', 'zij', 'hun', 'haar', 'ik', 'dat', 'is', 'ins', 'bedankt',
        'en', 'aan', 'at', 'dus', 'wij', 'jezelf', 'die', 'heeft', 'ons',
        'was', 'naast', 'luid', 'bovendien', 'daarom', 'toch', 'ondanks', 'met',
        'omdat', 'tegen', 'wait', 'naar', 'verder', 'zu', 'door', 'an', 'rond',
    ]),
};
const NBSP_WORDS_UNIVERSAL = new Set([
    'Bc.', 'BcA.', 'Ing.', 'MUDr.', 'MVDr.', 'Mgr.', 'MgA.', 'JUDr', 'PhDr.',
    'RNDr.', 'PharmDr.', 'ThLic.', 'ThDr.', 'Ph.D.', 'PhD.', 'ArtD.', 'Th.D.',
    'CSc.', 'DrSc.', 'prof.', 'doc.', 'profesor', 'docent', 'inż.', 'dr.',
]);
const NUMBER_REGEX = /^[0-9]*\.?$/;
const shouldJoinNext = (word, language) => {
    if (word.length === 1)
        return true;
    if (language !== null
        && Object.keys(NBSP_WORDS_PER_LANGUAGE).includes(language.code)
        && NBSP_WORDS_PER_LANGUAGE[language.code].has(word)) {
        return true;
    }
    if (NBSP_WORDS_UNIVERSAL.has(word))
        return true;
    if (NUMBER_REGEX.test(word))
        return true;
    return false;
};
export default shouldJoinNext;
