import { escapeXMLString, unescapeXMLString } from 'libs/xml-utils';
import { getNodeProperty } from 'libs/quill-utils';
import { nanoid } from 'nanoid';
import { normalizeString } from 'libs/normalize-string';
const RAW_DIARIZATION_REGEX = /^S[0-9]{4,5}$/;
export const isRawDiarizationCode = (label) => {
    return RAW_DIARIZATION_REGEX.test(label);
};
export class Speaker {
    constructor(firstName, lastName, role, id, dbid, diarizationCode, isUnknown, language, unmaintainedMetadata, captionColor = null, isDefaultColor = true) {
        if (id !== null) {
            this.id = id;
        }
        else {
            this.id = Speaker.generateId();
        }
        this.firstName = firstName;
        this.lastName = lastName;
        this.role = role;
        this.dbid = dbid;
        this.diarizationCode = diarizationCode;
        this.isUnknown = Boolean(isUnknown);
        this.language = language;
        this.captionColor = captionColor;
        this.isDefaultColor = isDefaultColor;
        this.unmaintainedMetadata = unmaintainedMetadata;
    }
    static getId(domNode) {
        const id = getNodeProperty(domNode, 'speakerid-');
        if (id === null) {
            throw new Error('speaker id missing');
        }
        return id;
    }
    static getQuillFormatValueFromNode(domNode) {
        const captionColor = getNodeProperty(domNode, 'caption-color-');
        if (captionColor === null) {
            return Speaker.getId(domNode);
        }
        return `${Speaker.getId(domNode)} ${captionColor}`;
    }
    static fromXMLString(speakerXmlString) {
        const xmlParser = new DOMParser();
        const xmlDoc = xmlParser.parseFromString(speakerXmlString, 'text/xml');
        const speakers = xmlDoc.getElementsByTagName('s');
        const s = speakers[0];
        return this.fromXMLElement(s);
    }
    static fromXMLElement(xmlSpeaker) {
        var _a;
        let speaker = null;
        const lastName = xmlSpeaker.getAttribute('surname');
        const id = this.generateId();
        const attributes = xmlSpeaker.getElementsByTagName('a');
        const language = xmlSpeaker.getAttribute('lang');
        let role = '';
        let isUnknown = false;
        let captionColor = null;
        let isDefaultColor = false;
        const unmaintainedMetadata = new Map();
        for (let j = 0; j < attributes.length; j += 1) {
            const attribute = attributes[j];
            const name = attribute.getAttribute('name');
            if (name === null)
                throw Error('a speaker attribute is missing name');
            const value = unescapeXMLString((_a = attribute.textContent) !== null && _a !== void 0 ? _a : '');
            if (name === 'role') {
                role = value;
            }
            else if (name === 'unknown') {
                isUnknown = true;
            }
            else if (name === 'captionColor') {
                captionColor = value;
            }
            else if (name === 'isDefaultColor') {
                isDefaultColor = true;
            }
            else {
                unmaintainedMetadata.set(name, value);
            }
        }
        let dbid = xmlSpeaker.getAttribute('dbid');
        if (dbid === 'null') {
            dbid = null;
        }
        speaker = new Speaker(xmlSpeaker.getAttribute('firstname'), lastName !== null && lastName !== void 0 ? lastName : '', role, id, dbid, xmlSpeaker.getAttribute('diarization'), isUnknown, language, unmaintainedMetadata, captionColor, isDefaultColor);
        return speaker;
    }
    static fromSpeaker(speaker) {
        return new Speaker(speaker.firstName, speaker.lastName, speaker.role, this.generateId(), speaker.dbid, speaker.diarizationCode, speaker.isUnknown, speaker.language, speaker.unmaintainedMetadata, speaker.captionColor, speaker.isDefaultColor);
    }
    static generateId() {
        return nanoid(30);
    }
    matches(prefix) {
        var _a, _b;
        const firstName = ((_a = this.firstName) !== null && _a !== void 0 ? _a : '').split(' ');
        const lastName = this.lastName.split(' ');
        const role = ((_b = this.role) !== null && _b !== void 0 ? _b : '');
        const matchTexts = [
            this.composeLabel(),
            ...firstName,
            ...lastName,
            role,
            [...lastName, role].join(' '),
            [...firstName, ...lastName, role].join(' '),
        ];
        const normalizedTexts = matchTexts.map(normalizeString);
        const normalizedPrefix = normalizeString(prefix);
        for (let i = 0; i < normalizedTexts.length; i += 1) {
            if (normalizedTexts[i].startsWith(normalizedPrefix))
                return true;
        }
        return false;
    }
    composeLabel() {
        const { role } = this;
        let label = this.fullName();
        if (role !== null && role !== '') {
            label = `${label} (${role})`;
        }
        return label;
    }
    fullName() {
        const { firstName, lastName } = this;
        if (firstName !== null && firstName !== '') {
            if (lastName === '')
                return firstName;
            return `${firstName} ${lastName}`;
        }
        return lastName;
    }
    getQuillFormatValue() {
        if (this.captionColor === null)
            return this.id;
        return `${this.id} ${this.captionColor}`;
    }
    exportXml(documentSpeakerId = 0) {
        var _a;
        let attributes = '';
        const { firstName, lastName, role, captionColor, isDefaultColor, } = this;
        const escapedFirstName = firstName === null
            ? '' : escapeXMLString(firstName);
        const escapedLastName = escapeXMLString(lastName);
        if (role !== null && role !== '') {
            const escapedRole = escapeXMLString(role);
            attributes += `      <a name="role" date="0001-01-01T00:00:00Z">${escapedRole}</a>\n`;
        }
        if (this.isUnknown) {
            // date is necessary because some libraries handling trsx require it
            attributes += `      <a name="unknown" date="0001-01-01T00:00:00Z">${escapedLastName}</a>\n`;
        }
        if (captionColor !== null) {
            attributes += `      <a name="captionColor" date="0001-01-01T00:00:00Z">${captionColor}</a>\n`;
        }
        if (isDefaultColor) {
            attributes += '      <a name="isDefaultColor" date="0001-01-01T00:00:00Z"></a>\n';
        }
        this.unmaintainedMetadata.forEach((value, name) => {
            const escapedAttributeName = escapeXMLString(name);
            const escapedAttributeValue = escapeXMLString(value);
            attributes += `      <a name="${escapedAttributeName}" date="0001-01-01T00:00:00Z">${escapedAttributeValue}</a>\n`;
        });
        const diarizationAttribute = this.diarizationCode !== null ? `diarization="${this.diarizationCode}" ` : '';
        const dbtypeAttribute = this.dbid !== null ? 'dbtype="api" ' : 'dbtype="file" ';
        const dbidAttribute = this.dbid !== null ? `dbid="${this.dbid}" ` : '';
        const xml = `    <s id="${documentSpeakerId}" surname="${escapedLastName}" firstname="${escapedFirstName}" ${dbidAttribute}`
            + `${diarizationAttribute}${dbtypeAttribute}sex="x" lang="${(_a = this.language) !== null && _a !== void 0 ? _a : 'null'}">\n${attributes}    </s>\n`;
        return xml;
    }
}
