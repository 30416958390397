import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { json } from '@newtontechnologies/beey-api-js-client/receivers';
export const createOrder = async (connection, credit, currency) => connection.authFetch()
    .url(apiV2.orders.url({ credit, currency }))
    .post()
    .receive(json({ camelcaseResponse: false }))
    .fetch();
export const createStripeOrder = async (connection, lookupKey, quantity, currency) => connection.authFetch()
    .url(apiV2.orders.checkout.url({ lookupKey, quantity, currency }))
    .post()
    .receive(json({ camelcaseResponse: false })
    .catchHttpError((response) => (response.status === 422 ? 'invalid-user-data' : 'unexpected')))
    .fetchStrict();
export const listOrders = async (connection, params = { skip: 0, count: 0 }) => connection.authFetch()
    .url(apiV2.orders.url(params))
    .receive(json())
    .fetch();
export const fetchPriceSettings = async (connection, currency) => {
    const priceSettings = await connection.authFetch()
        .url(apiV2.orders.stripePricingSettings.url())
        .receive(json({ camelcaseResponse: false }))
        .fetch();
    const currencyPrice = priceSettings.Currencies[currency];
    return {
        creditPrice: currencyPrice.CreditPriceWithoutTax,
        minCreditToPurchase: priceSettings.MinimumCreditOrder,
        tax: priceSettings.Tax,
        currency: currencyPrice.Name,
        fractionalUnitsPerUnit: currencyPrice.FractionalUnitsPerUnit,
    };
};
export const setTestClock = async (connection, TestClockId) => connection.authFetch()
    .url(apiV2.currentUser.setTestClock.url())
    .postJson({ TestClockId })
    .send();
