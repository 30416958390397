import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const { Dragger } = Upload;
const SingleFileUpload = ({ onChange = () => { }, label, accept, uploadFile, }) => {
    const triggerChange = ({ file }) => {
        if (file.status === 'removed') {
            onChange(null);
        }
        else {
            onChange(file);
        }
    };
    return (_jsxs(Dragger, { className: styles.fileDragger, beforeUpload: () => false, fileList: uploadFile ? [uploadFile] : [], onChange: triggerChange, accept: accept, children: [_jsxs("p", { className: styles.fileDraggerHeader, children: [_jsx(UploadOutlined, { className: styles.fileDraggerIcon }), label] }), _jsx("p", { className: styles.fileDraggerHint, children: txt('dragAndDropSingle') })] }));
};
export default SingleFileUpload;
