import { addNonbreakingSpaces, getAllMatchIndices, cleanUpText } from './text-utils';
const POSSIBLE_LINE_BREAK_REGEX = /[\n\u0020]/g;
const PUNCTUATION_REGEX = /[:?!,.-]/g;
/*
  returns score evaluating, how nice the caption line is.
*/
const scoreLine = (rawLine, maxLineLength) => {
    const line = cleanUpText(rawLine);
    if (line.trim() === '') {
        return -1000; // empty line is bad
    }
    const lastCharacter = rawLine.charAt(rawLine.length - 1);
    const lastButOneCharacter = rawLine.charAt(rawLine.length - 2);
    const breakAtParagraphEndBonus = lastCharacter === '\n' ? 1 : 0;
    const breakAtPunctuationBonus = PUNCTUATION_REGEX.test(lastButOneCharacter) ? 1 : 0;
    // shortLinePenalty is between 0 and 1 and favors evenly distributed lines
    const shortLinePenalty = line.length > maxLineLength ? 0
        : ((maxLineLength - line.length) / maxLineLength) ** 2;
    // longLinePenalty is penalty for lines exceeding maximum line length.
    // It slightly favors evenly distributed lines
    const longLinePenalty = line.length <= maxLineLength ? 0
        : (line.length - maxLineLength) ** 1.01;
    const linePenalty = 1; // constant penalty - prefer not to split line
    return breakAtParagraphEndBonus * 10 + breakAtPunctuationBonus * 0.3 - shortLinePenalty * 1
        - longLinePenalty * 100 - linePenalty * 5;
};
/*
  Decides whether the caption text should be split to two lines and where.
  Returns null if it is best not to split it. Otherwise returns index of the nicest
  split.
*/
export const findBestLineBreakForText = (rawText, language, maxLineLength) => {
    const text = addNonbreakingSpaces(rawText, language);
    const possibleLineBreaks = getAllMatchIndices(text, POSSIBLE_LINE_BREAK_REGEX);
    if (possibleLineBreaks.length === 0)
        return null;
    const scores = possibleLineBreaks.map((lineBreakIndex) => {
        const line1 = text.substring(0, lineBreakIndex + 1);
        const line2 = text.substring(lineBreakIndex + 1, text.length);
        return scoreLine(line1, maxLineLength) + scoreLine(line2, maxLineLength);
    });
    const bestBreakScore = Math.max(...scores);
    const noBreakScore = scoreLine(text, maxLineLength);
    if (noBreakScore > bestBreakScore)
        return null;
    const bestBreak = possibleLineBreaks[scores.indexOf(bestBreakScore)];
    return bestBreak;
};
export default findBestLineBreakForText;
