import Quill from 'quill';
import { getNodeProperty } from 'libs/quill-utils';
import { inlineblotOrder } from './inline-blot-order';
const ANCHOR = 'timeAnchor';
const Inline = Quill.import('blots/inline');
class TimeAnchorBlot extends Inline {
    static create(value) {
        const node = super.create(value);
        if (inlineblotOrder.includes(ANCHOR)) {
            node.className = `${ANCHOR} ${ANCHOR}-${value}`;
        }
        else {
            throw new Error('Blot is not listed in inline blot order'); // if the format name is missing in blot order, it causes bugs in quill, see https://github.com/quilljs/quill/issues/2312
        }
        return node;
    }
    static formats(domNode) {
        return TimeAnchorBlot.valueFromDomNode(domNode);
    }
    static valueFromDomNode(domNode) {
        const prefix = `${ANCHOR}-`;
        return getNodeProperty(domNode, prefix);
    }
    format(name, value) {
        if (name === ANCHOR && value) {
            this.domNode.className = `${ANCHOR} ${ANCHOR}-${value}`;
        }
        else {
            super.format(name, value);
        }
    }
    formats() {
        const formats = super.formats();
        formats.timeAnchor = TimeAnchorBlot.formats(this.domNode);
        return formats;
    }
    attach() {
        if (this.parent != null) {
            this.scroll = this.parent.scroll;
        }
    }
}
TimeAnchorBlot.blotName = ANCHOR;
TimeAnchorBlot.tagName = 'span';
export default TimeAnchorBlot;
TimeAnchorBlot.blotName = ANCHOR;
// setting a custom tag name prevents some really weird bugs in quill.
TimeAnchorBlot.tagName = ANCHOR;
