import { jsx as _jsx } from "react/jsx-runtime";
import { SnippetsOutlined, EditOutlined, ProfileOutlined, ReadOutlined, TeamOutlined, AppstoreOutlined, TagsOutlined, } from '@ant-design/icons';
import { TeamIcon, AbbreviationIcon } from 'components/CustomIcons';
class MenuContent {
    constructor(items) {
        this.items = items;
    }
    filter(fn) {
        return new MenuContent(this.filterItems(this.items, fn));
    }
    filterByStatus(claims, role) {
        return this.filter((item) => {
            const claimOk = item.claim === undefined || claims.includes(item.claim);
            const roleOk = item.excludedRoles === undefined || !item.excludedRoles.includes(role);
            return claimOk && roleOk;
        });
    }
    findItemByKey(key) {
        return this.items.find((item) => item.key === key);
    }
    filterItems(items, fn) {
        return items.map((item) => {
            if (item.subItems !== undefined) {
                return Object.assign(Object.assign({}, item), { subItems: this.filterItems(item.subItems, fn) });
            }
            return item;
        }).filter((item) => fn(item) && (item.subItems === undefined || item.subItems.length > 0));
    }
}
export const fullMenuContent = new MenuContent([
    {
        key: 'projects',
        icon: _jsx(SnippetsOutlined, {}),
        claim: 'projects:list',
    },
    {
        key: 'edit',
        icon: _jsx(EditOutlined, {}),
        claim: 'projects:edit',
    },
    {
        key: 'speakers',
        icon: _jsx(TeamOutlined, {}),
        claim: 'speakers:list',
    },
    {
        key: 'userlex',
        icon: _jsx(ReadOutlined, {}),
        claim: 'lexicons:get',
    },
    {
        key: 'tags',
        icon: _jsx(TagsOutlined, {}),
    },
    {
        key: 'abbreviations',
        icon: _jsx(AbbreviationIcon, {}),
        claim: 'editor:abbreviations',
    },
    {
        key: 'admin',
        icon: _jsx(ProfileOutlined, {}),
        claim: 'AdminUser:post',
        subItems: [
            { key: 'users' },
            { key: 'announcements' },
            { key: 'groups', claim: 'admin:superadmin' },
        ],
    },
    {
        key: 'apps',
        icon: _jsx(AppstoreOutlined, {}),
        claim: 'apps:list',
    },
    // NOTE: This creates a divider above last item
    {
        key: null,
    },
    {
        key: 'account',
        icon: _jsx(TeamIcon, {}),
    },
]);
