import ResizeObserver from 'resize-observer-polyfill';
import dayjs from 'dayjs';
import * as ProjectAPI from 'api/project-api';
import { ApiError } from '@newtontechnologies/beey-api-js-client/receivers';
import deepEqual from 'fast-deep-equal';
import { durationInSeconds } from 'libs/duration';
import { debounce } from 'throttle-debounce';
import { txt } from 'libs/i18n';
import { sleep } from 'libs/utils';
import { formatNumber, formatTime } from 'libs/format-number';
import { createWaveformFromUrl } from './waveform-generator';
import { CAPTION_EVENTS } from '../DocumentEditor/captions';
import { getTextLines } from '../DocumentEditor/caption-utils';
import { PlaybackEvents } from '../MediaPlayer/playback';
import { TIME_ANCHOR_SYMBOL } from '../DocumentEditor/text-utils';
import styles from './style.module.less';
const TIME_SPAN_COLOR = '#444';
const TEXT_COLOR = '#000000';
const FONT_SIZE = 14;
const CAPTION_FONT = `${FONT_SIZE}px beey-editor`;
const INFO_FONT = '12px sans-serif';
const FONT_BOX_HEIGHT = FONT_SIZE + 2;
const TIME_ANCHOR_COLOR = '#444';
const SCENE_CHANGE_LINE_COLOR = '#bababa';
const CAPTION_END_WIDTH = 12;
const WAVE_RANGE = 256;
const WAVE_OFFSET = 128;
const MINIMUM_ALLOWED_CAPTION_DURATION = 0.2;
export class WaveCanvas {
    constructor(editorController, container, playback, captions, project, session, onZoomChange) {
        this.zoom = {
            actual: 1,
            count: 0,
        };
        this.mouseState = { down: false, move: false };
        this.mousePosition = null;
        this.mousePositionStart = { x: 0, y: 0 };
        this.linesOffset = {
            line1: { x: 0, y: 40 },
            line2: { x: 0, y: 60 },
            anchor: { x: 0, y: 90 },
            duration: { x: 0, y: 120 },
            speed: { x: 0, y: 150 },
        };
        this.defaultZoom = 1; // equals to 100 %, i.e. with time span 10
        this.span = 10;
        this.captionsInTimeRange = [];
        this.waveForm = null;
        this.minArray = [];
        this.maxArray = [];
        this.lastCaptionUnderMouse = null;
        this.resumePlaybackAfterScroll = false;
        this.draggedCaption = null;
        this.dragUpdateInterval = null;
        this.isCanvasOutdated = false;
        this.isDestroyed = false;
        this.finalizeSeekDebounce = debounce(500, () => {
            this.playback.seekTo(this.playback.time);
            if (this.resumePlaybackAfterScroll === true) {
                this.playback.play();
            }
            this.resumePlaybackAfterScroll = null;
        });
        this.updateWaveCanvasVisibility = (isVisible) => {
            this.isCanvasVisible = isVisible;
        };
        this.zoomCanvasHorizontally = (zoomDirection, timeAtMousePosition) => {
            if (zoomDirection === 'zoomOut') {
                // Minimum zoom: All caption are displayed upto the recording end
                if (this.playback.duration <= this.timeRange.end)
                    return;
                this.zoom.count += 1;
            }
            else {
                if (this.zoom.actual >= 2.5)
                    return; // maximum zoom
                this.zoom.count -= 1;
            }
            const zoomStep = 10; // in percent
            this.zoom.actual = this.defaultZoom * (100 / (100 + zoomStep * this.zoom.count));
            // Update of ranges
            const prevTimeSpan = this.timeSpan;
            this.timeSpan = this.span / this.zoom.actual;
            if (timeAtMousePosition !== null) {
                // The ratio mouse position from the begin is after zooming same
                const begin = timeAtMousePosition
                    - (timeAtMousePosition - this.timeRange.begin) * (this.timeSpan / prevTimeSpan);
                const end = begin + this.timeSpan;
                if (begin <= 0) {
                    this.timeRange = {
                        begin: 0,
                        end: this.timeSpan,
                    };
                }
                else if (end >= this.playback.duration) {
                    this.timeRange = {
                        begin: this.playback.duration - this.timeSpan,
                        end: this.playback.duration,
                    };
                }
                else {
                    this.timeRange = {
                        begin,
                        end,
                    };
                }
            }
            else {
                // NOTE: Zooming by button click.
                // TODO: when reaching max/min then go to oposite direction.
                this.timeRange = {
                    begin: this.playback.time,
                    end: this.playback.time + this.timeSpan,
                };
            }
            this.timeRangeEdgeCaseCorrections();
            this.refresh();
            this.onZoomChange({
                horizontal: this.zoom.actual,
                vertical: this.verticalScale,
            });
        };
        this.zoomCanvasVertically = (zoomDirection) => {
            const minVerticalScale = this.verticalScale;
            const maxVerticalScale = this.verticalScale;
            if (zoomDirection === 'zoomIn') {
                this.verticalScale *= 1.5;
            }
            else {
                this.verticalScale /= 1.5;
            }
            // Edge cases - min and max vertical scale
            if (this.verticalScale <= 0.01) {
                this.verticalScale = minVerticalScale;
                return;
            }
            if (this.verticalScale >= 50) {
                this.verticalScale = maxVerticalScale;
                return;
            }
            this.refresh(false);
            this.onZoomChange({
                horizontal: this.zoom.actual,
                vertical: this.verticalScale,
            });
        };
        /* retrying the fetch is useful for cases when we need to wait for backend to generate low
        / quality audio such as when uploading media with trsx and immediataly switching on caption
        / review.
        */
        this.fetchWaveFormWithRetry = async (retries, interval) => {
            for (let i = 0; i < retries; i += 1) {
                if (this.isDestroyed) {
                    return false;
                }
                // eslint-disable-next-line no-await-in-loop
                const isWaveformFetched = await this.fetchWaveForm();
                if (isWaveformFetched) {
                    return true;
                }
                // eslint-disable-next-line no-await-in-loop
                await sleep(interval);
            }
            return false;
        };
        this.fetchWaveForm = async () => {
            if (this.hasWaveFormFetchStarted)
                return true; // fetch only once
            this.hasWaveFormFetchStarted = true;
            try {
                const lowQualityAudioUrl = await ProjectAPI.buildLowQualityAudioUrl(this.project, this.session.connection);
                this.waveForm = await createWaveformFromUrl(lowQualityAudioUrl, this.session.connection);
                this.setDefaultVerticalScale();
                this.onZoomChange({
                    horizontal: this.zoom.actual,
                    vertical: this.verticalScale,
                });
            }
            catch (error) {
                global.logger.error('failed to fetch waveform', {}, error);
                this.hasWaveFormFetchStarted = false;
                return false;
            }
            this.maxArray = this.waveForm.channel(0).max_array();
            this.minArray = this.waveForm.channel(0).min_array();
            this.refresh();
            return true;
        };
        this.fetchSceneChange = async () => {
            try {
                const scenes = await ProjectAPI.fetchVideoSceneChange(this.project, this.session.connection);
                this.sceneChange = scenes.map((scene) => durationInSeconds(scene));
            }
            catch (error) {
                if (error instanceof ApiError && error.response.status === 404) {
                    throw error;
                }
            }
        };
        this.handleTimeUpdate = () => {
            this.isCanvasOutdated = true;
        };
        this.refreshOnTimeUpdate = () => {
            if (!this.isCanvasVisible)
                return;
            if (!this.isCanvasOutdated)
                return;
            this.isCanvasOutdated = false;
            const upperThreshold = 0.9;
            const upperTimeRangeThreshold = this.timeSpan * 0.9;
            const lowerTimeRangeThreshold = this.timeSpan * (1 - upperThreshold);
            // Updating time range if the playback time is out of range
            if (this.timeRange.end - lowerTimeRangeThreshold <= this.playback.time
                || this.timeRange.begin + lowerTimeRangeThreshold >= this.playback.time) {
                // Different behaviour for playback and seeking
                if (this.playback.playing) {
                    this.updateTimeRangeDuringPlaying(upperTimeRangeThreshold, lowerTimeRangeThreshold);
                }
                else {
                    this.updateTimeRangeDuringSeeking(upperTimeRangeThreshold, lowerTimeRangeThreshold);
                }
                this.timeRangeEdgeCaseCorrections();
                this.refresh();
            }
            else {
                // Captions are not updated - this enables to drag&drop caption if the playback is playing
                // Caption are updated only if they are changed by handler for caption update after change
                this.refresh(false);
            }
        };
        this.updateTimeRangeDuringPlaying = (upperThreshold, lowerThreshold) => {
            this.timeRange = {
                begin: this.playback.time - lowerThreshold,
                end: this.playback.time + upperThreshold,
            };
        };
        this.updateTimeRangeDuringSeeking = (upperThreshold, lowerThreshold) => {
            // Seeking forward
            if (this.timeRange.end - lowerThreshold <= this.playback.time) {
                this.timeRange = {
                    begin: this.playback.time - upperThreshold,
                    end: this.playback.time + lowerThreshold,
                };
            }
            // Seeking backwards
            if (this.timeRange.begin + lowerThreshold >= this.playback.time) {
                this.timeRange = {
                    begin: this.playback.time - lowerThreshold,
                    end: this.playback.time + upperThreshold,
                };
            }
        };
        this.timeRangeEdgeCaseCorrections = () => {
            // Time span can be zoomed and thus can be in edge cases greater then playback duration.
            // Therefore it has to be corrected.
            if (this.timeRange.begin <= 0) {
                this.timeRange = {
                    begin: 0,
                    end: this.timeSpan > this.playback.duration ? this.playback.duration : this.timeSpan,
                };
            }
            else if (this.timeRange.end >= this.playback.duration) {
                this.timeRange = {
                    begin: this.timeSpan > this.playback.duration ? 0 : this.playback.duration - this.timeSpan,
                    end: this.playback.duration,
                };
            }
        };
        this.getTimeAtX = (x) => {
            const relativePosition = x / this.canvasWidthInDom;
            return this.timeRange.begin + relativePosition * (this.timeRange.end - this.timeRange.begin);
        };
        this.getXAtTime = (seconds) => {
            const relativePosition = (seconds - this.timeRange.begin)
                / (this.timeRange.end - this.timeRange.begin);
            return this.canvasWidthInDom * relativePosition;
        };
        this.isMouseInCaption = (caption) => {
            if (this.mousePosition === null)
                return false;
            return caption.startX <= this.mousePosition.x
                && caption.startX + caption.width >= this.mousePosition.x;
        };
        this.getCaptionUnderMouse = () => {
            if (this.draggedCaption !== null) {
                return this.draggedCaption.caption;
            }
            const captionsUnderMouse = this.captionsInTimeRange.filter((caption) => {
                return this.isMouseInCaption(caption);
            });
            if (captionsUnderMouse.length === 0)
                return null;
            return captionsUnderMouse[0];
        };
        this.pickAmplitudeBetweenTimes = (array, begin, end, criterium) => {
            if (this.waveForm === null) {
                return 0;
            }
            const indexFrom = Math.max(0, Math.floor(begin * this.waveForm.pixels_per_second));
            const indexTo = Math.min(this.waveForm.length, Math.floor(end * this.waveForm.pixels_per_second) + 1);
            if (indexFrom === indexTo) {
                return 0;
            }
            if (criterium === 'max') {
                return Math.max(...array.slice(indexFrom, indexTo));
            }
            return Math.min(...array.slice(indexFrom, indexTo));
        };
        this.setDefaultVerticalScale = () => {
            if (this.waveForm === null) {
                return;
            }
            const waveformData = [...this.waveForm.toJSON().data];
            const ascendingSortedData = waveformData.sort((a, b) => (a - b));
            const indexOfPercentil99 = Math.floor(waveformData.length * 0.99) - 1;
            const percentil99 = ascendingSortedData[indexOfPercentil99] === 0
                ? 1 : ascendingSortedData[indexOfPercentil99];
            // 70 % of the height of percentil 99 in vertical scale
            this.verticalScale = (WAVE_OFFSET / percentil99) * 0.7;
        };
        this.drawWave = (xFrom = 0, xTo = this.canvasWidthInDom) => {
            if (this.waveForm === null) {
                return;
            }
            const height = (amplitude) => {
                return this.canvasHeightInDom - ((amplitude * this.verticalScale + WAVE_OFFSET) * this.canvasHeightInDom) / WAVE_RANGE;
            };
            this.ctx.beginPath();
            this.ctx.strokeStyle = this.theme.waveColor;
            this.ctx.fillStyle = this.theme.waveColor;
            this.ctx.lineWidth = 1;
            // Loop forwards, drawing the upper half of the waveform
            for (let x = xFrom; x < xTo; x += 1) {
                const amplitude = this.pickAmplitudeBetweenTimes(this.maxArray, this.getTimeAtX(x), this.getTimeAtX(x + 1), 'max');
                this.ctx.lineTo(x + 0.5, height(amplitude) + 0.5);
            }
            // Loop backwards, drawing the lower half of the waveform
            for (let x = xTo - 1; x >= xFrom; x -= 1) {
                const amplitude = this.pickAmplitudeBetweenTimes(this.minArray, this.getTimeAtX(x), this.getTimeAtX(x + 1), 'min');
                this.ctx.lineTo(x + 0.5, height(amplitude) + 0.5);
            }
            this.ctx.stroke();
            this.ctx.fill();
            this.ctx.closePath();
        };
        // TODO: handle very short text
        this.handleTextOverflow = (text, maxWidth) => {
            let textWidth = this.ctx.measureText(text).width;
            const ellipsis = '...';
            const ellipsisWidth = this.ctx.measureText(ellipsis).width;
            if (textWidth <= maxWidth || textWidth <= ellipsisWidth) {
                return text;
            }
            let textLength = text.length;
            let correctedText = text;
            while (textWidth >= maxWidth - ellipsisWidth && textLength > 1) {
                correctedText = correctedText.substring(0, textLength);
                textWidth = this.ctx.measureText(correctedText).width;
                textLength -= 1;
            }
            return correctedText + ellipsis;
        };
        this.drawSpeedWarningBackground = (caption, offset) => {
            if (caption.editorCaption.warningOnSpeed !== null) {
                const { type } = caption.editorCaption.warningOnSpeed;
                this.ctx.filter = 'opacity(0.7)';
                this.ctx.fillStyle = this.theme[`warning-${type}`];
                this.ctx.fillRect(offset.x + caption.startX, offset.y, caption.width, -FONT_BOX_HEIGHT);
                this.ctx.filter = 'opacity(1)'; // reset opacity
            }
        };
        this.drawDurationWarningBackground = (caption, offset) => {
            if (caption.editorCaption.warningOnDuration !== null) {
                const { type } = caption.editorCaption.warningOnDuration;
                this.ctx.filter = 'opacity(0.7)';
                this.ctx.fillStyle = this.theme[`warning-${type}`];
                this.ctx.fillRect(offset.x + caption.startX, offset.y, caption.width, -FONT_BOX_HEIGHT);
                this.ctx.filter = 'opacity(1)'; // reset opacity
            }
        };
        this.drawLengthWarningBackground = (caption, offset, textWidth) => {
            if (caption.editorCaption.warningOnLength.length > 0) {
                this.ctx.filter = 'opacity(0.7)';
                this.ctx.fillStyle = this.theme['warning-too-long'];
                this.ctx.fillRect(offset.x + caption.startX, offset.y, textWidth, -FONT_BOX_HEIGHT);
                this.ctx.filter = 'opacity(1)'; // reset opacity
            }
        };
        this.drawText = (caption, text, offset) => {
            this.ctx.fillStyle = TEXT_COLOR;
            this.ctx.lineWidth = 1;
            this.ctx.textBaseline = 'bottom';
            this.ctx.fillText(text, offset.x + caption.startX, offset.y);
        };
        this.drawSpeedAndDurationInfo = (caption) => {
            const captionParam = this.captions.getCaptionDurationAndSpeed(caption.editorCaption.lines, caption.editorCaption.begin, caption.editorCaption.end);
            const charsPerSecond = `${formatNumber(captionParam.charsPerSecond, 1, 'ceil')} ${txt('charactersPerSec')}`;
            const speed = this.handleTextOverflow(charsPerSecond, caption.width);
            const duration = this.handleTextOverflow(`${formatNumber(captionParam.captionDuration, 2, 'floor')} s`, caption.width);
            this.ctx.beginPath();
            this.ctx.font = INFO_FONT;
            // Caption speed
            this.drawSpeedWarningBackground(caption, this.linesOffset.speed);
            this.drawText(caption, speed, this.linesOffset.speed);
            // Caption duration
            this.drawDurationWarningBackground(caption, this.linesOffset.duration);
            this.drawText(caption, duration, this.linesOffset.duration);
            this.ctx.closePath();
        };
        this.isMouseAtCaptionEnd = (caption) => {
            if (this.mousePosition === null)
                return false;
            return caption.startX + caption.width - CAPTION_END_WIDTH <= this.mousePosition.x
                && (caption.startX + caption.width + CAPTION_END_WIDTH) >= this.mousePosition.x;
        };
        this.setCaptionMovementType = () => {
            const isMouseInCaption = (caption) => {
                return caption.startX <= this.mousePositionStart.x
                    && (caption.startX + caption.width - CAPTION_END_WIDTH) >= this.mousePositionStart.x;
            };
            this.captionsInTimeRange.forEach((caption) => {
                if (isMouseInCaption(caption)) {
                    this.draggedCaption = {
                        caption,
                        movementType: 'dragging',
                        originalStartX: caption.startX,
                        originalWidth: caption.width,
                    };
                }
            });
            this.captionsInTimeRange.forEach((caption) => {
                if (this.isMouseAtCaptionEnd(caption)) {
                    this.draggedCaption = {
                        caption,
                        movementType: 'adjustingEnd',
                        originalStartX: caption.startX,
                        originalWidth: caption.width,
                    };
                }
            });
        };
        this.handlePointerDown = (event) => {
            event.preventDefault(); // keeps focus on playback button or editor
            this.mouseState = {
                down: true,
                move: false,
            };
            this.timeOnMouseDown = dayjs().valueOf();
            this.mousePositionStart = {
                x: event.clientX - this.canvas.getBoundingClientRect().left,
                y: event.clientY - this.canvas.getBoundingClientRect().top,
            };
            this.mousePosition = Object.assign({}, this.mousePositionStart);
            this.adjustCursorType(this.mousePosition);
            this.setCaptionMovementType();
        };
        this.handlePointerUp = (event) => {
            if (!this.mouseState.down) {
                // mouse dragging started outside of canvas. Do not handle.
                return;
            }
            event.preventDefault();
            this.mousePosition = {
                x: event.clientX - this.canvas.getBoundingClientRect().left,
                y: event.clientY - this.canvas.getBoundingClientRect().top,
            };
            if (!this.isValidDrag()) {
                // click without move is seek
                const timeAtMouseClick = this.getTimeAtX(this.mousePosition.x);
                this.playback.seekTo(timeAtMouseClick);
                this.editorController.moveCaretToTime(timeAtMouseClick);
            }
            this.mouseState = {
                down: false,
                move: false,
            };
            this.draggedCaption = null;
            if (this.dragUpdateInterval !== null) {
                window.clearInterval(this.dragUpdateInterval);
                this.dragUpdateInterval = null;
            }
            this.refresh();
            this.adjustCursorType(this.mousePosition);
        };
        // Drag text in X-axis
        this.handlePointerMove = (event) => {
            this.mousePosition = {
                x: event.clientX - this.canvas.getBoundingClientRect().left,
                y: event.clientY - this.canvas.getBoundingClientRect().top,
            };
            void this.updateCaptionsUnderline();
            if (!this.mouseState.down) {
                this.adjustCursorType(this.mousePosition);
                return;
            }
            this.mouseState.move = true;
            // TODO: clicking on Escape cancels drag&drop
            const dragDistance = this.mousePosition.x - this.mousePositionStart.x;
            if (this.draggedCaption !== null) {
                for (let i = 0; i < this.captionsInTimeRange.length; i += 1) {
                    const caption = this.captionsInTimeRange[i];
                    if (this.getCaptionMovementType(caption) === 'adjustingEnd') {
                        const oldEndX = this.draggedCaption.originalStartX + this.draggedCaption.originalWidth;
                        const newEndX = oldEndX + dragDistance;
                        // startX may have changed since drag started becuase of aligner.
                        caption.width = newEndX - caption.startX;
                    }
                    else if (this.getCaptionMovementType(caption) === 'dragging') {
                        caption.startX = this.draggedCaption.originalStartX
                            + dragDistance;
                    }
                }
            }
            if (this.isValidDrag()) {
                if (this.dragUpdateInterval === null) {
                    // Function updateTimeAnchorForDraggedCaption is expensive for processor time.
                    // If the events are fired faster than we can handle them, we need to skip handling
                    // for some events. This is exactly what setInterval does. It skips execution
                    // if the last execution is still running. Note: throttle does not work like this
                    // if the execution time is larger than interval.
                    this.dragUpdateInterval = window.setInterval(this.updateTimeAnchorForDraggedCaption, 20);
                }
            }
            else {
                this.refresh(false);
            }
        };
        this.buildTheme = () => {
            const warningColors = {
                'warning-faster-than-optimum': 'rgb(255, 255, 220)',
                'warning-too-fast-critical': 'rgb(250, 242, 91)',
                'warning-duration-short': 'rgb(250, 242, 91)',
                'warning-duration-long': '#ffbb62',
                'warning-too-long': '#FFD5D6',
                'warning-unaligned': '#c8dffa',
            };
            if (this.session.login.hasClaim('dataTheme:apa')) {
                return Object.assign(Object.assign({}, warningColors), { captionBgColor: '#97daff26', waveColor: '#86CFF0', currentCaptionBgColor: '#fff3e0', borderLineColor: '#2c82be', playbackTimePointerColor: '#f06900' });
            }
            return Object.assign(Object.assign({}, warningColors), { captionBgColor: '#97daff26', waveColor: '#b9e6ff', currentCaptionBgColor: '#FFFFFF', borderLineColor: '#90d7ff', playbackTimePointerColor: '#ffae43' });
        };
        this.updateTimeAnchorForDraggedCaption = () => {
            var _a, _b;
            const requiredPause = (_b = (_a = this.editorController.captions.parameters) === null || _a === void 0 ? void 0 : _a.pauseBetweenCaptions) !== null && _b !== void 0 ? _b : 0;
            this.captionsInTimeRange.forEach((caption) => {
                // time anchors must be rounded to 3 decimals
                const startTime = Number(this.getTimeAtX(caption.startX).toFixed(3));
                const endTime = Number(this.getTimeAtX(caption.startX + caption.width).toFixed(3));
                const movementType = this.getCaptionMovementType(caption);
                // TODO: What should happen if time anchor is too close?
                if (movementType === 'adjustingEnd') {
                    this.editorController.timeAnchors.insertTimeAnchor(endTime, caption.editorCaption.to - 1, true, Math.max(requiredPause, MINIMUM_ALLOWED_CAPTION_DURATION), requiredPause, true);
                }
                else if (movementType === 'dragging') {
                    this.editorController.timeAnchors.insertTimeAnchor(startTime, caption.editorCaption.firstWordIndex, true, requiredPause, Math.max(requiredPause, MINIMUM_ALLOWED_CAPTION_DURATION), true);
                }
            });
        };
        this.handlePointerLeave = () => {
            this.mousePosition = null;
            void this.updateCaptionsUnderline();
        };
        this.handleMouseWheel = (event) => {
            event.preventDefault();
            if (this.mousePosition === null)
                return;
            if (event.ctrlKey && !event.shiftKey) {
                // Zoom in/out in canvas
                const time = this.getTimeAtX(this.mousePosition.x);
                if (event.deltaY < 0) {
                    this.zoomCanvasHorizontally('zoomIn', time);
                }
                else {
                    this.zoomCanvasHorizontally('zoomOut', time);
                }
            }
            else if (event.ctrlKey && event.shiftKey) {
                // Zoom vertically in/out in canvas
                if (event.deltaY < 0) {
                    this.zoomCanvasVertically('zoomIn');
                }
                else {
                    this.zoomCanvasVertically('zoomOut');
                }
            }
            else if (event.shiftKey) {
                // Shifting only captions without playback and its pointer
                const timeIncrement = event.deltaY < 0 ? 1 : -1;
                this.timeRange = {
                    begin: this.timeRange.begin + timeIncrement,
                    end: this.timeRange.end + timeIncrement,
                };
                this.timeRangeEdgeCaseCorrections();
                this.refresh();
            }
            else {
                // Shifting playback and its pointer
                const timeIncrement = event.deltaY < 0 ? 1 : -1; // Normalized mouse wheel movement
                if (this.resumePlaybackAfterScroll === null) {
                    this.resumePlaybackAfterScroll = this.playback.playing;
                }
                this.playback.pause();
                this.playback.scrubTo(this.playback.time + timeIncrement);
                this.finalizeSeekDebounce();
            }
        };
        this.getCaptionsInTimeRange = () => {
            this.captionsInTimeRange = [];
            let caption = this.captions.getCaptionAtTimeOrPrevious(this.timeRange.begin);
            if (caption === null) { // time is before the first caption
                caption = this.captions.getCaptionAtIndex(0);
            }
            while (caption !== null && caption.begin < this.timeRange.end) {
                const editorCaption = caption;
                const startX = this.getXAtTime(caption.begin);
                const duration = Math.round((caption.end - caption.begin) * 1000) / 1000;
                const width = this.getXAtTime(caption.end) - startX;
                this.captionsInTimeRange.push({
                    editorCaption,
                    startX,
                    duration,
                    width,
                });
                caption = this.captions.getCaptionAtIndex(caption.to + 1);
            }
            return this.captionsInTimeRange;
        };
        this.getCaptionsRangeWithoutSpeakers = (from, to) => {
            const nonSpeakerCaptions = [];
            for (let i = from; i < to; i += 1) {
                if (this.editorController.getLineFormat(i).speaker === undefined) {
                    const newLine = this.editorController.getNextNewLineIndex(i);
                    if (newLine !== null) {
                        nonSpeakerCaptions.push({ begin: i, end: newLine < to ? newLine : to });
                        i = newLine;
                    }
                }
            }
            return nonSpeakerCaptions;
        };
        this.updateCaptionsUnderline = () => {
            this.editorController.execTextChange({ runAligner: false, requestSave: false }, () => {
                const captionUnderMouse = this.getCaptionUnderMouse();
                const { lastCaptionUnderMouse } = this;
                if (deepEqual(captionUnderMouse, lastCaptionUnderMouse)) {
                    return;
                }
                if (lastCaptionUnderMouse !== null) {
                    const from = lastCaptionUnderMouse.editorCaption.firstWordIndex;
                    const length = lastCaptionUnderMouse.editorCaption.to - from;
                    this.editorController.formatText(from, length, {
                        underline: false,
                    }, 'api');
                }
                this.lastCaptionUnderMouse = captionUnderMouse;
                if (captionUnderMouse !== null) {
                    const from = captionUnderMouse.editorCaption.firstWordIndex;
                    const { to } = captionUnderMouse.editorCaption;
                    const captionsToUnderline = this.getCaptionsRangeWithoutSpeakers(from, to);
                    captionsToUnderline.forEach((caption) => {
                        this.editorController.formatText(caption.begin, caption.end - caption.begin, {
                            underline: true,
                        }, 'api');
                    });
                }
            });
        };
        this.adjustCursorType = (position) => {
            const time = this.getTimeAtX(position.x);
            if (this.captionsInTimeRange.some((caption) => this.isMouseAtCaptionEnd(caption))) {
                this.canvas.style.cursor = 'col-resize';
                return;
            }
            if (this.captions.getCaptionAtTime(time) === null) {
                this.canvas.style.cursor = 'default';
                return;
            }
            if (this.mouseState.down) {
                this.canvas.style.cursor = 'grabbing';
            }
            else {
                this.canvas.style.cursor = 'grab';
            }
        };
        this.handleCaptionUpdate = (event) => {
            const { from, to } = event;
            if (from <= this.timeRange.end && to >= this.timeRange.begin) {
                this.refresh();
            }
        };
        // Scaling canvas by the devicePixelRatio
        this.adjustCanvasByScale = () => {
            // DevicePixelRatio has to be readjusted if the browser window is zoomed
            this.devicePixelRatio = window.devicePixelRatio || 1;
            const rect = this.canvas.getBoundingClientRect();
            this.canvasWidthInDom = rect.width;
            this.canvasHeightInDom = rect.height;
            this.canvas.width = rect.width * this.devicePixelRatio;
            this.canvas.height = rect.height * this.devicePixelRatio;
            // Update offset of caption lines and duration based on canvas height
            const padding = 3;
            this.linesOffset.speed.y = this.canvasHeightInDom;
            this.linesOffset.duration.y = this.linesOffset.speed.y - FONT_BOX_HEIGHT - padding;
            this.linesOffset.anchor.y = this.linesOffset.duration.y - FONT_BOX_HEIGHT - padding;
            this.ctx.scale(this.devicePixelRatio, this.devicePixelRatio);
        };
        // If browser is resized, then canvas sizes incl. captions
        this.handleResponsivity = () => {
            if (this.isCanvasVisible) {
                this.adjustCanvasByScale();
            }
            this.refresh();
        };
        this.editorController = editorController;
        this.container = container;
        this.playback = playback;
        this.captions = captions;
        this.project = project;
        this.session = session;
        this.canvas = document.createElement('canvas');
        this.onZoomChange = onZoomChange;
        this.canvas.className = styles.waveCanvas;
        this.container.appendChild(this.canvas);
        const ctx = this.canvas.getContext('2d');
        if (ctx === null) {
            throw Error('failed to get context for canvas');
        }
        this.ctx = ctx;
        this.isCanvasVisible = false;
        this.devicePixelRatio = window.devicePixelRatio || 1;
        this.canvasHeightInDom = this.canvas.height;
        this.canvasWidthInDom = this.canvas.width;
        this.zoom.actual = this.defaultZoom;
        this.timeSpan = this.span / this.zoom.actual;
        this.verticalScale = 1; // minimal scale
        this.timeRange = {
            begin: this.playback.time,
            end: this.playback.time + this.timeSpan,
        };
        this.timeOnMouseDown = dayjs().valueOf();
        this.hasWaveFormFetchStarted = false;
        this.canvas.addEventListener('pointerdown', this.handlePointerDown);
        this.canvas.addEventListener('pointerup', this.handlePointerUp);
        this.canvas.addEventListener('pointermove', this.handlePointerMove);
        this.canvas.addEventListener('wheel', this.handleMouseWheel);
        this.canvas.addEventListener('pointerleave', this.handlePointerLeave);
        this.playback.addEventListener(PlaybackEvents.TimeUpdate, this.handleTimeUpdate);
        this.captions.addEventListener(CAPTION_EVENTS.CHANGED, this.handleCaptionUpdate);
        // NOTE: Function refreshOnTimeUpdate is expensive for processor time.
        // If the events are fired faster than we can handle them, we need to skip handling
        // for some events. This is exactly what setInterval does. It skips execution
        // if the last execution is still running. Throttle does not work like this
        // if the execution time is larger than interval.
        this.playbackUpdateInterval = window.setInterval(this.refreshOnTimeUpdate, 40);
        this.sceneChange = [];
        this.resizeObserver = new ResizeObserver(this.handleResponsivity);
        this.resizeObserver.observe(this.canvas);
        this.theme = this.buildTheme();
    }
    get getIsDestroyed() {
        return this.isDestroyed;
    }
    refresh(updateCaptions = true) {
        if (!this.isCanvasVisible)
            return;
        if (updateCaptions) {
            this.captionsInTimeRange = this.getCaptionsInTimeRange();
        }
        this.ctx.clearRect(0, 0, this.canvasWidthInDom, this.canvasHeightInDom);
        this.drawWave();
        this.drawCaptions();
        this.drawTimeSpan();
        this.drawPlaybackTime();
        void this.updateCaptionsUnderline();
        this.drawSceneChange();
    }
    destroy() {
        this.isDestroyed = true;
        this.canvas.removeEventListener('pointerdown', this.handlePointerDown);
        this.canvas.removeEventListener('pointerup', this.handlePointerUp);
        this.canvas.removeEventListener('pointermove', this.handlePointerMove);
        this.canvas.removeEventListener('pointerleave', this.handlePointerLeave);
        this.canvas.removeEventListener('wheel', this.handleMouseWheel);
        this.playback.removeEventListener(PlaybackEvents.TimeUpdate, this.handleTimeUpdate);
        this.captions.removeEventListener(CAPTION_EVENTS.CHANGED, this.handleCaptionUpdate);
        this.resizeObserver.disconnect();
        if (this.dragUpdateInterval !== null) {
            window.clearInterval(this.dragUpdateInterval);
            this.dragUpdateInterval = null;
        }
        window.clearInterval(this.playbackUpdateInterval);
    }
    drawTimeSpan() {
        const duration = this.timeRange.end - this.timeRange.begin;
        let step = 1;
        if (duration < 1) {
            step = 0.1;
        }
        else if (duration < 10) {
            step = 1;
        }
        else {
            step = 10;
        }
        const substep = step / 10;
        const startTime = Math.floor(this.timeRange.begin / step) * step;
        const timeFormat = step < 1 ? 'HH:mm:ss.SSS' : 'HH:mm:ss';
        this.ctx.beginPath();
        this.ctx.font = INFO_FONT;
        this.ctx.fillStyle = TIME_SPAN_COLOR;
        this.ctx.strokeStyle = TIME_SPAN_COLOR;
        for (let time = startTime; time <= this.timeRange.end; time += step) {
            const x = this.getXAtTime(time);
            this.ctx.moveTo(x, 0);
            this.ctx.lineTo(x, 10);
            const prettyTime = formatTime(time, timeFormat);
            this.ctx.fillText(prettyTime, x, 20);
        }
        for (let time = startTime; time < this.timeRange.end; time += substep) {
            const x = this.getXAtTime(time);
            this.ctx.moveTo(x, 0);
            this.ctx.lineTo(x, 4);
        }
        this.ctx.stroke();
        this.ctx.closePath();
    }
    drawPlaybackTime() {
        const playbackTimePosition = this.getXAtTime(this.playback.time);
        this.ctx.beginPath();
        this.ctx.strokeStyle = this.theme.playbackTimePointerColor;
        this.ctx.lineWidth = 3;
        this.ctx.moveTo(playbackTimePosition, 0);
        this.ctx.lineTo(playbackTimePosition, this.canvasHeightInDom);
        this.ctx.stroke();
        this.ctx.closePath();
    }
    drawSceneChange() {
        this.ctx.beginPath();
        this.ctx.strokeStyle = SCENE_CHANGE_LINE_COLOR;
        this.ctx.lineWidth = 1;
        this.ctx.setLineDash([15, 15]); // pattern for dashed line
        this.sceneChange.forEach((scene) => {
            if (scene >= this.timeRange.begin && scene <= this.timeRange.end) {
                const scenePosition = this.getXAtTime(scene);
                this.ctx.moveTo(scenePosition, 0);
                this.ctx.lineTo(scenePosition, this.canvasHeightInDom);
            }
        });
        this.ctx.stroke();
        this.ctx.setLineDash([]); // clears dashed line stroke
        this.ctx.closePath();
    }
    drawCaptionBackground(caption) {
        const isCaptionUnderPlaybackPointer = this.playback.time >= caption.editorCaption.begin
            && this.playback.time <= caption.editorCaption.end;
        this.ctx.beginPath();
        if (isCaptionUnderPlaybackPointer) {
            this.ctx.fillStyle = this.theme.currentCaptionBgColor;
        }
        else {
            this.ctx.fillStyle = this.theme.captionBgColor;
        }
        this.ctx.fillRect(caption.startX + this.linesOffset.line1.x, 0, caption.width, this.canvasHeightInDom);
        if (isCaptionUnderPlaybackPointer) {
            // caption under playback pointer hides the wave behind it - redraw it over the background
            this.drawWave(caption.startX, caption.startX + caption.width);
        }
        this.ctx.closePath();
    }
    drawCaptionBorderLines(caption) {
        this.ctx.beginPath();
        this.ctx.moveTo(caption.startX + this.linesOffset.line1.x, 0);
        this.ctx.lineTo(caption.startX + this.linesOffset.line1.x, this.canvasHeightInDom);
        this.ctx.moveTo(caption.startX + this.linesOffset.line1.x + caption.width, 0);
        this.ctx.lineTo(caption.startX + this.linesOffset.line1.x + caption.width, this.canvasHeightInDom);
        this.ctx.strokeStyle = this.theme.borderLineColor;
        this.ctx.stroke();
        this.ctx.closePath();
    }
    getCaptionMovementType(caption) {
        if (this.draggedCaption === null) {
            return null;
        }
        if (caption.editorCaption.from === this.draggedCaption.caption.editorCaption.from) {
            return this.draggedCaption.movementType;
        }
        return null;
    }
    drawCaptionTimeAnchors(caption) {
        this.ctx.beginPath();
        this.ctx.strokeStyle = TIME_ANCHOR_COLOR;
        this.ctx.font = CAPTION_FONT;
        caption.editorCaption.timeAnchors.forEach((timeAnchor) => {
            const x = this.getXAtTime(timeAnchor.value);
            this.ctx.moveTo(x, 0);
            this.ctx.lineTo(x, this.canvasHeightInDom);
            const isEnd = timeAnchor.value.toFixed(3) === caption.editorCaption.end.toFixed(3);
            const xOffset = isEnd ? x + this.linesOffset.anchor.x - 14 : x + this.linesOffset.anchor.x;
            this.ctx.fillText(TIME_ANCHOR_SYMBOL, xOffset, this.linesOffset.anchor.y);
        });
        this.ctx.stroke();
        this.ctx.closePath();
    }
    drawCaptionText(caption) {
        const { lines, warningOnLength } = caption.editorCaption;
        const linesOffset = [this.linesOffset.line1, this.linesOffset.line2];
        const textLines = [];
        this.ctx.beginPath();
        this.ctx.font = CAPTION_FONT;
        getTextLines(lines).forEach((line) => {
            const textLine = this.handleTextOverflow(line, caption.width);
            textLines.push({
                text: this.handleTextOverflow(line, caption.width),
                width: this.ctx.measureText(textLine).width,
            });
        });
        // Draw warnings on length
        warningOnLength.forEach((line) => {
            this.drawLengthWarningBackground(caption, linesOffset[line.lineNumber], textLines[line.lineNumber].width);
        });
        // Draw text lines
        textLines.forEach((line, index) => {
            this.drawText(caption, line.text, linesOffset[index]);
        });
        this.ctx.closePath();
    }
    drawCaptions() {
        this.ctx.beginPath();
        this.captionsInTimeRange.forEach((caption) => {
            this.drawCaptionBackground(caption);
            this.drawCaptionText(caption);
            this.drawSpeedAndDurationInfo(caption);
            this.drawCaptionBorderLines(caption);
            this.drawCaptionTimeAnchors(caption);
        });
        this.ctx.closePath();
    }
    isValidDrag() {
        const currentTime = dayjs().valueOf();
        const isMouseDownLonger = (currentTime - this.timeOnMouseDown) > 110; // in millisecs
        return this.mouseState.down && isMouseDownLonger && this.mouseState.move;
    }
}
