export const createIntervalGraph = (sortedIntervals) => {
    const intervalGraph = [];
    for (let i = 0; i < sortedIntervals.length; i += 1) {
        intervalGraph[i] = [];
    }
    for (let currentIndex = 0; currentIndex < sortedIntervals.length; currentIndex += 1) {
        const currentSpeaker = sortedIntervals[currentIndex];
        for (let prevIndex = 0; prevIndex < currentIndex; prevIndex += 1) {
            const previousSpeaker = sortedIntervals[prevIndex];
            if (currentSpeaker.firstBegin <= previousSpeaker.lastEnd) {
                // NOTE: Intervals are sorted by their beginning and colored from the first
                // so marking overlaps with preceding intervals are sufficient.
                intervalGraph[currentIndex].push(prevIndex);
            }
        }
    }
    return intervalGraph;
};
export const colorIntervals = (graph, intervals, colors) => {
    const coloredIntervals = intervals.map((interval) => (Object.assign(Object.assign({}, interval), { color: colors[0] })));
    for (let i = 0; i < coloredIntervals.length; i += 1) {
        const neighborsColorIndices = graph[i].map((overlap) => colors.indexOf(coloredIntervals[overlap].color));
        let pickedColor = 0;
        let pickedColorConflictsCount = Infinity;
        for (let j = 0; j < colors.length; j += 1) {
            const conflictsCount = neighborsColorIndices.filter((neighbor) => neighbor === j).length;
            if (conflictsCount < pickedColorConflictsCount) {
                pickedColor = j;
                pickedColorConflictsCount = conflictsCount;
            }
        }
        coloredIntervals[i].color = colors[pickedColor];
    }
    return coloredIntervals;
};
