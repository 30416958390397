import dayjs from 'dayjs';
import 'dayjs/locale/cs';
import 'dayjs/locale/de';
import 'dayjs/locale/hr';
import 'dayjs/locale/pl';
import 'dayjs/locale/sl';
import 'dayjs/locale/sk';
import 'dayjs/locale/es';
import 'dayjs/locale/ru';
import 'dayjs/locale/sr';
import 'dayjs/locale/nb';
import 'dayjs/locale/it';
import 'dayjs/locale/sv';
import enUS from 'antd/es/locale/en_US';
import csCZ from 'antd/es/locale/cs_CZ';
import plPL from 'antd/es/locale/pl_PL';
import deDE from 'antd/es/locale/de_DE';
import hrHR from 'antd/es/locale/hr_HR';
import slSI from 'antd/es/locale/sl_SI';
import skSK from 'antd/es/locale/sk_SK';
import esES from 'antd/es/locale/es_ES';
import ruRU from 'antd/es/locale/ru_RU';
import srRS from 'antd/es/locale/sr_RS';
import ukUA from 'antd/es/locale/uk_UA';
import nbNo from 'antd/es/locale/nb_NO';
import itIT from 'antd/es/locale/it_IT';
import svSE from 'antd/es/locale/sv_SE';
import { getCurrentUiLocale } from './locales/ui';
export const beeyCurrencies = [
    'CZK',
    'PLN',
    'EUR',
    'USD',
];
dayjs.locale(getCurrentUiLocale().lang);
export const getCurrentAntdLocale = () => {
    switch (getCurrentUiLocale().lang) {
        case 'cs':
            return csCZ;
        case 'pl':
            return plPL;
        case 'en':
            return enUS;
        case 'de':
            return deDE;
        case 'hr':
            return hrHR;
        case 'sl':
            return slSI;
        case 'sk':
            return skSK;
        case 'es':
            return esES;
        case 'ru':
            return ruRU;
        case 'sr':
            return srRS;
        case 'uk':
            return ukUA;
        case 'nb':
            return nbNo;
        case 'it':
            return itIT;
        case 'sv':
            return svSE;
        default:
            return enUS;
    }
};
export const currencyBasedOnUI = () => {
    switch (getCurrentUiLocale().country) {
        case 'CZ':
            return 'CZK';
        case 'PL':
            return 'PLN';
        default:
            return 'EUR';
    }
};
