import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { txt } from 'libs/i18n';
import { HexagonIcon } from 'components/CustomIcons';
import { Flex, Space } from 'antd';
import styles from './style.module.less';
const ProductFeatures = ({ lookupKey, price, currency }) => {
    if (lookupKey === 'beey_start') {
        return (_jsxs(Flex, { vertical: true, children: [_jsxs(Space, { className: styles.payAsYouGoFeature, children: [_jsx(HexagonIcon, {}), _jsxs("span", { children: [txt('buyCreditFor'), ' ', _jsx("p", { children: _jsxs("strong", { children: [price.toFixed(2), ' ', currency.toUpperCase(), "/min"] }) })] })] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('allStandard')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('singleUser')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('tenGiga')] })] }));
    }
    if (lookupKey === 'beey_plus_monthly') {
        return (_jsxs(Flex, { vertical: true, children: [_jsxs("span", { children: [_jsxs(Space, { children: [_jsx(HexagonIcon, {}), txt('fiveHours')] }), _jsxs("p", { children: [_jsx("span", { className: styles.price, children: txt('transcribeFor') }), ' ', _jsxs("strong", { children: [(price / 300).toFixed(2), ' ', currency.toUpperCase(), "/min"] })] })] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('allStandard')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('fiveUsers')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('fiftyGiga')] })] }));
    }
    if (lookupKey === 'beey_plus_yearly') {
        return (_jsxs(Flex, { vertical: true, children: [_jsxs("span", { children: [_jsxs(Space, { children: [_jsx(HexagonIcon, {}), txt('sixtyHours')] }), _jsxs("p", { children: [_jsx("span", { className: styles.price, children: txt('transcribeFor') }), ' ', _jsxs("strong", { children: [(price / 3600).toFixed(2), ' ', currency.toUpperCase(), "/min"] })] })] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('allStandard')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('fiveUsers')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('fiftyGiga')] })] }));
    }
    if (lookupKey === 'enterprise') {
        return (_jsxs(Flex, { vertical: true, children: [_jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('unlimitedCredit')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('allStandard')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('unlimitedTeam')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('unlimitedStorage')] })] }));
    }
    if (lookupKey === 'beey_business_monthly') {
        return (_jsxs(Flex, { vertical: true, children: [_jsxs("span", { children: [_jsxs(Space, { children: [_jsx(HexagonIcon, {}), txt('tenHours')] }), _jsxs("p", { children: [_jsx("span", { className: styles.price, children: txt('transcribeFor') }), ' ', _jsxs("strong", { children: [(price / 600).toFixed(2), ' ', currency.toUpperCase(), "/min"] })] })] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('allStandard')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('tenUsers')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('hundredGiga')] })] }));
    }
    if (lookupKey === 'beey_business_yearly') {
        return (_jsxs(Flex, { vertical: true, children: [_jsxs("span", { children: [_jsxs(Space, { children: [_jsx(HexagonIcon, {}), txt('hundredAndTwentyHours')] }), _jsxs("p", { children: [_jsx("span", { className: styles.price, children: txt('transcribeFor') }), ' ', _jsxs("strong", { children: [(price / 7200).toFixed(2), ' ', currency.toUpperCase(), "/min"] })] })] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('allStandard')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('tenUsers')] }), _jsxs(Space, { className: styles.feature, children: [_jsx(HexagonIcon, {}), txt('hundredGiga')] })] }));
    }
    return null;
};
export default ProductFeatures;
