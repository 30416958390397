import EventEmitter from 'events';
import { useEffect, useState } from 'react';
class SavingStateHandler {
    constructor(status) {
        this.eventEmitter = new EventEmitter();
        this.status = status;
    }
    addEventListener(listener) {
        this.eventEmitter.on('saving-state', listener);
    }
    removeEventListener(listener) {
        this.eventEmitter.off('saving-state', listener);
    }
    updateStatus(status) {
        if (this.status !== status) {
            const oldStatus = this.status;
            this.status = status;
            this.eventEmitter.emit('saving-state', status, oldStatus);
        }
    }
}
// saving state handler will be created only once when the page is loaded and lives
// until the page is closed. So we don't need to worry about cleanup.
export const savingStateHandler = new SavingStateHandler('saved');
export const useSavingStatus = () => {
    const [status, setStatus] = useState('saved');
    useEffect(() => {
        const listener = (newStatus) => setStatus(newStatus);
        savingStateHandler.addEventListener(listener);
        return () => savingStateHandler.removeEventListener(listener);
    }, []);
    return status;
};
