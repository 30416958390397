import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import clsx from 'clsx';
import { Divider, Select, Tooltip, } from 'antd';
import { ColumnWidthOutlined, ColumnHeightOutlined, DoubleRightOutlined, DoubleLeftOutlined, ZoomInOutlined, ZoomOutOutlined, } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import IconButton from 'components/IconButton';
import { useSubModeTutorial } from '../subModeTutorial';
import styles from './style.module.less';
const { Option } = Select;
const SoundWaveToolbar = ({ soundWave, zoom }) => {
    const { tutorialGoNext } = useSubModeTutorial();
    const [collapsed, setCollapsed] = useState(true);
    const [zoomAxis, setZoomAxis] = useState('horizontal');
    const handleZoom = (axis, zoomDirection) => {
        if (axis === 'horizontal') {
            soundWave.zoomCanvasHorizontally(zoomDirection, null);
        }
        else {
            soundWave.zoomCanvasVertically(zoomDirection);
        }
        if (zoomDirection === 'zoomIn') {
            tutorialGoNext();
        }
    };
    const handleSelectChange = (axis) => {
        setZoomAxis(axis);
    };
    const keyboardShortcutHorizontal = `Ctrl+${txt('mouseWheel')}`;
    const keyboardShortcutVertical = `Ctrl+Shift+${txt('mouseWheel')}`;
    const keyboardShortcut = zoomAxis === 'horizontal' ? keyboardShortcutHorizontal : keyboardShortcutVertical;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: clsx(styles.collapsedToolbar, {
                    [styles.hiddenToolbar]: !collapsed,
                    'sub-mode-tutorial-step-zoom': collapsed,
                }), children: [_jsxs("div", { className: styles.selectWithArrow, children: [_jsx("div", { className: styles.arrowDown }), _jsxs(Select, { className: styles.selectZoom, value: zoomAxis, popupClassName: styles.selectZoomDropdown, bordered: false, placement: "bottomRight", suffixIcon: null, size: "small", popupMatchSelectWidth: false, onChange: handleSelectChange, children: [_jsx(Option, { value: "horizontal", children: _jsx(Tooltip, { title: txt('horizontalZoom'), placement: "left", children: _jsx(ColumnWidthOutlined, {}) }) }), _jsx(Option, { value: "vertical", children: _jsx(Tooltip, { title: txt('verticalZoom'), placement: "left", children: _jsx(ColumnHeightOutlined, {}) }) })] })] }), _jsx(IconButton, { onClick: () => handleZoom(zoomAxis, 'zoomIn'), tooltip: `${txt('zoomIn')} (${keyboardShortcut})`, children: _jsx(ZoomInOutlined, {}) }), _jsx(IconButton, { onClick: () => handleZoom(zoomAxis, 'zoomOut'), tooltip: `${txt('zoomOut')} (${keyboardShortcut})`, children: _jsx(ZoomOutOutlined, {}) }), _jsx(Divider, { className: styles.dividerOfCollapsedToolbar }), _jsx(Tooltip, { title: txt('expandSidebar'), placement: "left", children: _jsx(DoubleLeftOutlined, { className: styles.toolbarArrow, onClick: () => setCollapsed(!collapsed) }) })] }), _jsxs("div", { className: clsx({
                    [styles.noneToolbar]: collapsed,
                    [`${styles.expandedToolbar} sub-mode-tutorial-step-zoom`]: !collapsed,
                }), children: [_jsxs("div", { children: [_jsx(ColumnHeightOutlined, {}), _jsxs("span", { className: styles.zoomText, children: [txt('verticalZoom'), ":"] })] }), _jsxs("div", { className: styles.controlIcons, children: [_jsx(IconButton, { onClick: () => handleZoom('vertical', 'zoomOut'), tooltip: `${txt('zoomOut')} (${keyboardShortcutVertical})`, tooltipPlacement: "top", children: _jsx(ZoomOutOutlined, {}) }), _jsx("span", { className: styles.zoomCount, children: `(${zoom.vertical.toFixed(1)} x)` }), _jsx(IconButton, { onClick: () => handleZoom('vertical', 'zoomIn'), tooltip: `${txt('zoomIn')} (${keyboardShortcutVertical})`, tooltipPlacement: "topLeft", children: _jsx(ZoomInOutlined, {}) })] }), _jsxs("div", { children: [_jsx(ColumnWidthOutlined, {}), _jsxs("span", { className: styles.zoomText, children: [txt('horizontalZoom'), ":"] })] }), _jsxs("div", { className: styles.controlIcons, children: [_jsx(IconButton, { onClick: () => handleZoom('horizontal', 'zoomOut'), tooltip: `${txt('zoomOut')} (${keyboardShortcutHorizontal})`, tooltipPlacement: "top", children: _jsx(ZoomOutOutlined, {}) }), _jsx("span", { className: styles.zoomCount, children: `(${zoom.horizontal.toFixed(1)} x)` }), _jsx(IconButton, { onClick: () => handleZoom('horizontal', 'zoomIn'), tooltip: `${txt('zoomIn')} (${keyboardShortcutHorizontal})`, tooltipPlacement: "topLeft", children: _jsx(ZoomInOutlined, {}) })] }), _jsx(Divider, { className: styles.dividerHorizontal }), _jsx(Tooltip, { title: txt('collapseSidebar'), placement: "bottomLeft", children: _jsx(DoubleRightOutlined, { className: `${styles.toolbarArrow} ${styles.toolbarArrowRight} ${styles.arrowHorizontal}`, onClick: () => setCollapsed(!collapsed) }) })] })] }));
};
export default SoundWaveToolbar;
