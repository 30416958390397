import EventEmitter from 'events';
import * as clientEnv from 'libs/client-env';
export const PLAYBACK_RATE_STEP = 0.1;
export const VOLUME_STEP = 1;
export const MAX_SPEED = 2.5;
export const MIN_SPEED = 0.2; // Lower speeds do not work in Firefox
export var PlaybackEvents;
(function (PlaybackEvents) {
    PlaybackEvents["PlayingUpdate"] = "playing";
    PlaybackEvents["TimeUpdate"] = "time";
    PlaybackEvents["SpeedUpdate"] = "speed";
    PlaybackEvents["VolumeUpdate"] = "volume";
    PlaybackEvents["DurationUpdate"] = "duration";
    PlaybackEvents["Update"] = "update";
    PlaybackEvents["InternalPlayerTimeUpdate"] = "internal-player-time-update";
    PlaybackEvents["ResolutionUpdate"] = "resolution-update";
})(PlaybackEvents || (PlaybackEvents = {}));
export default class Playback {
    constructor(time = 0, duration = 0) {
        this.playing = false;
        this.time = time;
        this.speed = Number(clientEnv.getPlayerSpeed());
        this.volume = Number(clientEnv.getVolume()) / 100;
        this.duration = duration;
        this.internalPlayerTime = time;
        this.resolution = null;
        this.emitter = new EventEmitter();
    }
    addEventListener(event, listener) {
        this.emitter.on(event, listener);
    }
    oneTimeEventListener(event, listener) {
        this.emitter.once(event, listener);
    }
    removeEventListener(event, listener) {
        this.emitter.off(event, listener);
    }
    updatePlaying(playing) {
        if (playing !== undefined && this.playing !== playing) {
            this.playing = playing;
            this.emitter.emit(PlaybackEvents.PlayingUpdate, playing);
            return true;
        }
        return false;
    }
    updateTime(time) {
        if (time !== undefined && this.time !== time) {
            this.time = time;
            this.emitter.emit(PlaybackEvents.TimeUpdate, time);
            return true;
        }
        return false;
    }
    updateSpeed(speed) {
        if (speed !== undefined && this.speed !== speed) {
            this.speed = speed;
            this.emitter.emit(PlaybackEvents.SpeedUpdate, speed);
            return true;
        }
        return false;
    }
    updateVolume(volume) {
        if (volume !== undefined && this.volume !== volume) {
            this.volume = volume;
            this.emitter.emit(PlaybackEvents.VolumeUpdate, volume);
            return true;
        }
        return false;
    }
    updateDuration(duration) {
        if (duration !== undefined && this.duration !== duration) {
            this.duration = duration;
            this.emitter.emit(PlaybackEvents.DurationUpdate, duration);
            return true;
        }
        return false;
    }
    updateInternalPlayerTime(internalPlayerTime) {
        if (internalPlayerTime !== undefined && this.internalPlayerTime !== internalPlayerTime) {
            this.internalPlayerTime = internalPlayerTime;
            this.emitter.emit(PlaybackEvents.InternalPlayerTimeUpdate, internalPlayerTime);
            return true;
        }
        return false;
    }
    updateResolution(resolution) {
        if (resolution !== undefined && this.resolution !== resolution) {
            this.resolution = resolution;
            this.emitter.emit(PlaybackEvents.ResolutionUpdate, resolution);
            return true;
        }
        return false;
    }
    update({ playing, time, speed, volume, duration, internalPlayerTime, resolution, }) {
        const playingChanged = this.updatePlaying(playing);
        const timeChanged = this.updateTime(time);
        const speedChanged = this.updateSpeed(speed);
        const volumeChanged = this.updateVolume(volume);
        const durationChanged = this.updateDuration(duration);
        const internalPlayerTimeChanged = this.updateInternalPlayerTime(internalPlayerTime);
        const resolutionChanged = this.updateResolution(resolution);
        if (playingChanged
            || timeChanged
            || speedChanged
            || volumeChanged
            || durationChanged
            || internalPlayerTimeChanged
            || resolutionChanged) {
            this.emitter.emit(PlaybackEvents.Update);
        }
    }
    stepTimeBy(step) {
        this.seekTo(this.time + step);
    }
    seekTo(time) {
        const clampedTime = this.clampTime(time);
        this.update({ time: clampedTime, internalPlayerTime: clampedTime });
    }
    // set time for everything except currentTime of the media player (internalPlayerTime).
    // prevents buffering data that is not needed.
    scrubTo(time) {
        const clampedTime = this.clampTime(time);
        this.update({ time: clampedTime });
    }
    ended() {
        this.update({ playing: false, time: this.duration, internalPlayerTime: this.duration });
    }
    changeDuration(duration) {
        const change = { duration };
        if (this.time > duration) {
            change.time = duration;
        }
        this.update(change);
    }
    changeResolution(resolution) {
        this.update({ resolution });
    }
    setPlaying(playing) {
        if (this.duration === 0) {
            return;
        }
        if (this.time === this.duration) {
            // override default behavior of playing from end,
            // which is restarting playback from beginning
            this.stepTimeBy(-1);
        }
        if (playing) {
            if (this.time !== this.internalPlayerTime) {
                // if play was requested while scrubbing, finalize the scrubbing before playing
                this.seekTo(this.time);
            }
        }
        this.update({ playing });
    }
    play() {
        this.setPlaying(true);
    }
    pause() {
        this.setPlaying(false);
    }
    togglePlay() {
        this.setPlaying(!this.playing);
    }
    playFromTime(time) {
        this.seekTo(time);
        this.play();
    }
    delayAudio(time) {
        this.stepTimeBy(-1.0 * time);
        this.play();
    }
    setSpeedTo(speed) {
        if (speed < MIN_SPEED) {
            this.update({ speed: MIN_SPEED });
        }
        else if (speed >= MAX_SPEED) {
            this.update({ speed: MAX_SPEED });
        }
        else {
            this.update({ speed });
        }
    }
    setVolumeTo(volume) {
        if (volume < 0) {
            this.update({ volume: 0 });
        }
        else if (volume >= 1) {
            this.update({ volume: 1 });
        }
        else {
            this.update({ volume });
        }
    }
    fasterBy(step) {
        this.setSpeedTo(this.speed + step);
    }
    slowerBy(step) {
        this.setSpeedTo(this.speed - step);
    }
    speedUp() {
        return this.fasterBy(PLAYBACK_RATE_STEP);
    }
    slowDown() {
        return this.slowerBy(PLAYBACK_RATE_STEP);
    }
    clampTime(time) {
        if (time < 0) {
            return 0;
        }
        if (time > this.duration) {
            return this.duration;
        }
        return time;
    }
}
