import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import ResponsiveTable, { useTableView, empiricalScrollHeightAdjust } from 'components/ResponsiveTable';
import { useSession } from 'components/Authenticated';
import { DeleteOutlined, EditOutlined, LoadingOutlined, } from '@ant-design/icons';
import { App, Button, Form, Input, Popconfirm, Spin, } from 'antd';
import FormModal from 'components/FormModal';
import IconButton from 'components/IconButton';
import { fetchSpeakerRoleList, fetchSpeakerRoleSuggestions, deleteSpeakerRole, addSpeakerRole, updateSpeakerRole, } from 'api/speaker-api';
import searchStyles from 'components/SearchInput/style.module.less';
import SearchInput from 'components/SearchInput';
import { txt } from 'libs/i18n';
import AddButton from 'components/AddButton';
import DownloadButton from 'components/DownloadButton';
import { handleRolesExport } from 'libs/download-csv';
import styles from './style.module.less';
const LOADING_ROLES = 'loading';
const ROLES_TABLE_ID = 'roles-table'; // Must be unique across all tables in Beey!!
const SCROLL_HEIGHT_ADJUST = empiricalScrollHeightAdjust(225);
const RolesTable = ({ contentHeight }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [roles, setRoles] = useState(LOADING_ROLES);
    const [rolesModal, setRolesModal] = useState({ isVisible: false, action: 'add' });
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const tableView = useTableView(ROLES_TABLE_ID);
    const headerText = rolesModal.action === 'add' ? txt('addRole') : txt('editRole');
    const buttonText = rolesModal.action === 'add' ? txt('add') : txt('save');
    const fetchRoles = async () => {
        try {
            const roleList = await fetchSpeakerRoleList(session.connection);
            setRoles(roleList);
        }
        catch (error) {
            global.logger.error('Failed to get speakers role from database', {}, error);
            void message.error(txt('failedToFetchRole'));
        }
    };
    const handleDeleteRole = async (roleId) => {
        try {
            await deleteSpeakerRole(session.connection, roleId);
            void fetchRoles();
            void message.success(txt('roleDeleted'), 2);
        }
        catch (error) {
            global.logger.error('Failed to delete role from database', {}, error);
            void message.error(txt('failedToDeleteRole'));
        }
    };
    const handleOpenEditRoleModal = (record) => {
        setRolesModal({ isVisible: true, action: 'edit' });
        form.setFieldsValue(record);
    };
    const handleOpenAddRoleModal = () => {
        setRolesModal({ isVisible: true, action: 'add' });
    };
    const onClose = () => {
        setRolesModal(Object.assign(Object.assign({}, rolesModal), { isVisible: false }));
    };
    const handleSubmit = async (formValues) => {
        const { role, key } = formValues;
        const trimmedRole = role.trim();
        setIsLoading(true);
        if (rolesModal.action === 'add') {
            try {
                await addSpeakerRole(session.connection, trimmedRole);
                void message.success(txt('roleSaved'), 2);
            }
            catch (error) {
                global.logger.error('Failed to save role to the database', {}, error);
                void message.error(txt('failedToSaveRole'));
            }
        }
        if (rolesModal.action === 'edit') {
            try {
                await updateSpeakerRole(session.connection, key, trimmedRole);
                void message.success(txt('roleUpdated'), 2);
            }
            catch (error) {
                global.logger.error('Failed to update role in the database', {}, error);
                void message.error(txt('failedToUpdateRole'));
            }
        }
        form.resetFields();
        setIsLoading(false);
        void fetchRoles();
        onClose();
    };
    const handleFindRole = async (searchQuery) => {
        const matchedRoles = await fetchSpeakerRoleSuggestions(session.connection, searchQuery);
        const parsedRoles = matchedRoles.map((matchedRole) => ({
            role: matchedRole.Value,
            key: matchedRole.Id,
        }));
        setRoles(parsedRoles);
    };
    const columns = [
        {
            title: txt('role'),
            dataIndex: 'role',
        },
        {
            title: '',
            dataIndex: 'editRole',
            fixed: 'right',
            width: '40px',
            render: (_, record) => (_jsx(IconButton, { onClick: () => handleOpenEditRoleModal(record), children: _jsx(EditOutlined, {}) })),
        },
        {
            title: '',
            dataIndex: 'deleteRole',
            fixed: 'right',
            width: '40px',
            render: (_, record) => (_jsx(Popconfirm, { title: txt('deleteRole'), onConfirm: () => handleDeleteRole(record.key), okText: txt('delete'), okType: "primary", okButtonProps: { danger: true }, cancelText: txt('cancel'), children: _jsx(IconButton, { danger: true, children: _jsx(DeleteOutlined, {}) }) })),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.rolesTable, children: [_jsxs("div", { className: searchStyles.toolbar, children: [_jsx(AddButton, { title: txt('addRole'), onClick: handleOpenAddRoleModal }), _jsx(SearchInput, { placeholder: txt('findRole'), onSearch: handleFindRole }), _jsx(DownloadButton, { onHandleExport: async () => handleRolesExport(session.connection), title: txt('downloadInCSV') })] }), _jsx(ResponsiveTable, { className: styles.table, tableView: tableView, onViewChange: fetchRoles, dataSource: roles !== LOADING_ROLES ? roles : [], recordCount: roles !== LOADING_ROLES ? roles.length : 0, columns: columns, rowKey: (role) => role.key, loading: roles === LOADING_ROLES, pageSizeOptions: ['5', '10', '25', '50', '100', '150'], scroll: { y: contentHeight - SCROLL_HEIGHT_ADJUST }, stripeRows: true })] }), _jsx(FormModal, { footer: [
                    _jsxs(Button, { type: "primary", htmlType: "submit", disabled: isLoading, onClick: () => form.submit(), className: styles.saveButton, children: [isLoading
                                ? _jsx(Spin, { className: styles.spinnerIcon, indicator: _jsx(LoadingOutlined, {}) })
                                : null, buttonText] }, "submit"),
                ], open: rolesModal.isVisible, onCancel: onClose, className: styles.rolesModal, children: _jsxs(Form, { form: form, initialValues: {
                        role: '',
                        key: 0,
                    }, labelCol: { span: 4 }, labelAlign: "left", onFinish: handleSubmit, requiredMark: false, preserve: false, children: [_jsx("h3", { children: headerText }), _jsx(Form.Item, { name: "role", label: txt('role'), rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: txt('noRoleName'),
                                },
                                {
                                    validator: async (_, value) => {
                                        const duplicitRole = roles.find((role) => role.role.trim() === value.trim());
                                        if (duplicitRole === undefined) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(txt('duplicitRole'));
                                    },
                                },
                            ], children: _jsx(Input, { autoFocus: true }) }), _jsx(Form.Item, { name: "key", className: styles.hiddenField, children: _jsx(Input, { type: "hidden" }) })] }) })] }));
};
export default RolesTable;
