import { jsx as _jsx } from "react/jsx-runtime";
import IconButton from 'components/IconButton';
import { DeleteOutlined } from '@ant-design/icons';
import { getRectFromElement } from 'libs/positions';
import clsx from 'clsx';
import React from 'react';
import styles from './style.module.less';
const DELETE_BUTTON_WIDTH = 25;
const DeleteWidgetButton = ({ onClick, widgetTrigger, className, position, }) => {
    const triggerPosition = getRectFromElement(widgetTrigger);
    return (_jsx(IconButton, { danger: true, className: clsx(styles.button, className, { [styles.buttonRtl]: position.isRightToLeft }), style: {
            position: 'fixed',
            zIndex: 100,
            top: triggerPosition.top - 24,
            left: position.isRightToLeft
                ? triggerPosition.left - DELETE_BUTTON_WIDTH
                : triggerPosition.left + triggerPosition.width - 7,
        }, onClick: () => {
            if (onClick !== undefined) {
                onClick(widgetTrigger);
            }
        }, children: _jsx(DeleteOutlined, {}) }));
};
export default DeleteWidgetButton;
