import * as clientEnv from 'libs/client-env';
import { BeeyLocale } from './locale';
const uiLocaleCodes = [
    'en-US', // English (United States)
    'cs-CZ', // Czech (Czech Republic)
    'pl-PL', // Polish (Poland)
    'de-DE', // German (Germany)
    'hr-HR', // Croatian (Croatia)
    'sl-SI', // Slovenian (Slovenia)
    'sk-SK', // Slovak (Slovakia)
    'es-ES', // Spanish (Spain)
    'ru-RU', // Russian (Russia)
    'uk-UA', // Ukrainian (Ukraine)
    'nb-NO', // Norwegian (Norway)
    'it-IT', // Italian (Italy)
    'sv-SE', // Swedish (Sweden)
    'sr-Latn-RS', // Serbian (Serbia) in Latin script
];
const isUiLocaleCode = (code) => {
    return uiLocaleCodes.find((locale) => locale === code) !== undefined;
};
export class UiLocale extends BeeyLocale {
    static fromCode(code) {
        const locale = BeeyLocale.fromCode(code);
        if (locale === null) {
            throw new Error(`DEV ERROR: Invalid locale code: ${code} in uiLocaleCodes`);
        }
        return new UiLocale(locale.lang, locale.script, locale.country);
    }
    static fromLocale(locale) {
        if (!isUiLocaleCode(locale.code)) {
            return null;
        }
        return this.fromCode(locale.code);
    }
}
export const defaultUiLocale = UiLocale.fromCode('en-US');
const findUiLocaleByLangCode = (code) => {
    const locale = BeeyLocale.fromCode(code);
    if (locale === null) {
        return null;
    }
    return UiLocale.fromLocale(locale);
};
export const getUiLocales = () => uiLocaleCodes.map(UiLocale.fromCode);
export const getUiLocaleFromBrowser = () => {
    const browserLocale = BeeyLocale.fromCode(navigator.language);
    if (browserLocale !== null) {
        const uiLocale = UiLocale.fromLocale(browserLocale);
        if (uiLocale !== null) {
            return uiLocale;
        }
    }
    const langCode = navigator.language.split('-')[0];
    const uiLocale = findUiLocaleByLangCode(langCode);
    if (uiLocale !== null) {
        return uiLocale;
    }
    return defaultUiLocale;
};
export const getCurrentUiLocale = () => {
    const storedCode = clientEnv.getUILocale();
    if (storedCode !== null) {
        const beeyLocale = BeeyLocale.fromCode(storedCode);
        if (beeyLocale !== null) {
            const uiLocale = UiLocale.fromLocale(beeyLocale);
            if (uiLocale !== null) {
                return uiLocale;
            }
        }
    }
    const uiLocale = getUiLocaleFromBrowser();
    clientEnv.setUILocale(uiLocale.code);
    return uiLocale;
};
export const setCurrentUiLocale = (locale) => {
    clientEnv.setUILocale(locale.code);
};
