import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alerts } from 'components/Alerts';
import { supportedBrowsers } from 'libs/browser';
import { useAppContext } from 'App/app-context';
import { txt } from 'libs/i18n';
const getWarningMessage = (browserStatus) => {
    if (browserStatus.reason === 'safari') {
        return txt('unsupportedSafari');
    }
    if (browserStatus.reason === 'apple') {
        return txt('unsupportedApple');
    }
    return `${txt('unsupportedBrowser')} ${txt('supportedBrowser')}`;
};
const BrowserInfo = () => {
    const { browserStatus } = useAppContext();
    const capitalizeBrowserName = (name) => {
        if (name === 'edge-chromium') {
            return 'Microsoft Edge';
        }
        return name[0].toUpperCase() + name.slice(1);
    };
    const supportedBrowsersList = supportedBrowsers.map((browser) => (_jsx("li", { children: `${capitalizeBrowserName(browser.name)} ${browser.version}+` }, browser.name)));
    if (browserStatus.access === 'deny') {
        return Alerts.error(_jsxs(_Fragment, { children: [_jsx("p", { children: `${browserStatus.reason === 'old-apple' ? txt('unsupportedBrowserAndOperatingSystem') : txt('unsupportedBrowser')} ${txt('supportedBrowser')}` }), _jsxs("ul", { children: [browserStatus.reason === 'old-apple' && (_jsx("li", { children: `${txt('iosAndMacVersion')} 15+` })), _jsx("br", {}), supportedBrowsersList] })] }));
    }
    if (browserStatus.access === 'warn') {
        return Alerts.warning(_jsxs(_Fragment, { children: [_jsx("p", { children: getWarningMessage(browserStatus) }), _jsx("ul", { children: supportedBrowsersList })] }));
    }
    return null;
};
export default BrowserInfo;
