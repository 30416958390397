import { Alerts } from 'components/Alerts';
import { txt } from 'libs/i18n';
const alertCodes = [
    'checkVPN',
    'accessDenied',
    'invalidCredentials',
    'accountBlocked',
    'contactAdmin',
    'tenantDenied',
    'unexpectedError',
];
export const isAlertCode = (code) => (alertCodes.includes(code));
const LoginAlert = ({ code }) => {
    switch (code) {
        case 'checkVPN':
            return Alerts.error(txt('checkVpn'));
        case 'accessDenied':
            return Alerts.error(txt('accessDenied'));
        case 'invalidCredentials':
            return Alerts.warning(txt('invalidCredentials'));
        case 'accountBlocked':
            return Alerts.error(txt('accountBlocked'));
        case 'contactAdmin':
            return Alerts.error(txt('contactAdmin'));
        case 'tenantDenied':
            return Alerts.error(txt('tenantDenied'));
        default:
            return Alerts.error(txt('unexpectedError'));
    }
};
export default LoginAlert;
