import Player from './player';
export default class HTMLPlayer extends Player {
    constructor(domElement, playback, onReadyToPlay) {
        super(domElement, playback, onReadyToPlay);
        this.onLoadedMetadata = (e) => {
            if (e.target === null)
                return;
            const target = e.target;
            this.onReadyToPlay(target.videoHeight > 0);
            this.playback.changeDuration(target.duration);
            this.playback.changeResolution(target.videoHeight > 0
                ? { width: target.videoWidth, height: target.videoHeight }
                : null);
        };
        domElement.load();
        this.handlePlaybackUpdate();
    }
}
