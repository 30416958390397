import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import FormModal from 'components/FormModal';
import { txt } from 'libs/i18n';
import TranslateModalContent from './TranslateModalContent';
import styles from './style.module.less';
const TranslateModal = ({ visible, onCancel, enqueueProjectUpdate, project, }) => {
    const modalHeader = (_jsxs(_Fragment, { children: [_jsx("h2", { children: txt('smartTranslation') }), _jsx("p", { className: styles.infoText, children: txt('timeStampsRemain') })] }));
    return (_jsx(FormModal, { muteShortcuts: ['Tab', 'Shift+Tab'], title: modalHeader, className: styles.translateModal, open: visible, onCancel: onCancel, cancelText: txt('cancel'), footer: false, children: _jsx(TranslateModalContent, { project: project, onCancel: onCancel, enqueueProjectUpdate: enqueueProjectUpdate }) }));
};
export default TranslateModal;
