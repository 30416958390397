import { Speaker } from 'api/speaker';
import { BeeyLocale } from 'libs/locales/locale';
const SPEAKER_LABELS = {
    af: 'Spreker',
    sq: 'Folës',
    am: 'ታሪክ',
    ar: 'متكلم',
    hy: 'Խոսող',
    az: 'Nitqçi',
    ba: 'Сөйләүче',
    cnr: 'Govornik',
    eu: 'Hizlari',
    be: 'Арыгаць',
    bn: 'কথোপকথনকারী',
    bi: 'Spika',
    bs: 'Govornik',
    bg: 'Говорител',
    my: 'စကားဝိုင်း',
    ca: 'Orador',
    zh: '说话者',
    co: 'Parrulacci',
    hr: 'Govornik',
    cs: 'Mluvčí',
    da: 'Højttaler',
    nl: 'Spreker',
    en: 'Speaker',
    eo: 'Parolanto',
    et: 'Kõneleja',
    fo: 'Talandi',
    fj: 'Vosa',
    fi: 'Puhuja',
    fr: 'Orateur',
    fy: 'Sprekker',
    gd: 'Labhairt',
    gl: 'Falante',
    ka: 'ლალარი',
    de: 'Sprecher',
    el: 'Ομιλητής',
    kl: 'Ataqqarnermi',
    gn: 'Ñanemo',
    gu: 'વાતચીત',
    ht: 'Pale',
    ha: 'Magana',
    he: 'דובר',
    hi: 'वक्ता',
    hu: 'Hangszóró',
    is: 'Ræðumaður',
    id: 'Pembicara',
    iu: 'ᓂᑕᑕᖅᑐᒐᒃᑯᑦ',
    ga: 'Cainteoir',
    it: 'Altoparlante',
    ja: '話者',
    jv: 'Panutur',
    kn: 'ಸಾಕ್ಷಾತ್ಕಾರಕ',
    ks: 'بولنے والا',
    kk: 'Сөйлесуші',
    km: 'អ្នកនិយាយ',
    ky: 'Суроолорчу',
    kg: 'Kóngóla',
    ko: '화자',
    ku: 'Gotar',
    lo: 'ຜູ້ເວົ້າພາສາ',
    la: 'Loquens',
    lv: 'Runātājs',
    lt: 'Kalbėtojas',
    lb: 'Sprooch',
    mk: 'Говорник',
    ms: 'Penceramah',
    ml: 'പ്രസംഗകന്‍',
    mt: 'Titkellem',
    gv: 'Gynsagh',
    mi: 'Kōrerorero',
    mn: 'Яригч',
    na: 'Aōre',
    nv: 'Díí bíítah',
    ne: 'बोलाने',
    no: 'Taler',
    nb: 'Høyttaler',
    nn: 'Talar',
    ps: 'ژوندل',
    fa: 'سخن‌گو',
    pl: 'Mówca',
    pt: 'Orador',
    pa: 'ਬੋਲਨ ਵਾਲਾ',
    qu: 'Takichaq',
    ro: 'Vorbitor',
    ru: 'Спикер',
    sc: 'Oratole',
    sr_Latn: 'Govornik',
    sn: 'Kukumbira',
    sd: 'چڪڙڻ',
    si: 'කතාවක්',
    sk: 'Rečník',
    sl: 'Govorec',
    so: 'Ku hadlaaya',
    st: 'Tsebisoa',
    es: 'Orador',
    su: 'Pamarene',
    sw: 'Msema',
    ss: 'Kukhuluma',
    sv: 'Talare',
    tl: 'Tagapagsalita',
    ty: 'Parla\'a',
    tg: 'Суҳбаткунанда',
    ta: 'பேசுபவர்',
    tt: 'Сүзгеч',
    te: 'మాటలాడు',
    th: 'ผู้พูด',
    bo: 'ཁྱེད་རྩལ་པ',
    to: 'Kau fakataha',
    tr: 'Konuşmacı',
    tk: 'Söýgişi',
    ug: 'سۆزلەشكۈنچى',
    uk: 'Oратор',
    ur: 'بولنے والا',
    uz: 'Nutuqshi',
    vi: 'Người nói',
    cy: 'Siaradwr',
    xh: 'Umbhali',
    yi: 'רעדנדיקער',
    yo: 'Alárè',
};
const isSpeakerLabelKey = (key) => {
    return Object.keys(SPEAKER_LABELS).includes(key);
};
export default class SpeakerIdentification {
    constructor(editorController) {
        var _a;
        this.addTemporarySpeaker = (speakerNode) => {
            this.temporarySpeaker = speakerNode;
        };
        this.fixTemporarySpeaker = (xmlSpeaker) => {
            this.editorController.execTextChange({ runAligner: false, requestSave: false }, () => {
                if (this.temporarySpeaker === null) {
                    global.logger.error('cannot fix temporary speaker - no temporary speaker.');
                    return;
                }
                if (this.editorController.quill === undefined) {
                    return;
                }
                const index = this.editorController.getIndex(this.temporarySpeaker);
                if (index === -1) {
                    global.logger.error('cannot fix temporary speaker - temporary speaker does not exist.');
                    return;
                }
                let newSpeaker = null;
                if (xmlSpeaker === null) {
                    // backend sends null if no speaker is identified.
                    this.temporarySpeaker = null;
                    return;
                }
                newSpeaker = Speaker.fromXMLString(xmlSpeaker);
                const matchingDocumentSpeaker = newSpeaker.dbid !== null
                    ? this.editorController.speakers.getSpeakerByDbid(newSpeaker.dbid)
                    : null;
                this.editorController.speakers.removeSpeakerOnIndex(index, 'user');
                const lastButOneSpeaker = this.editorController.speakers.findLastSpeaker(index - 2);
                // Create new speaker label only if it does not match last speaker.
                if ((lastButOneSpeaker === null || lastButOneSpeaker === void 0 ? void 0 : lastButOneSpeaker.dbid) !== newSpeaker.dbid) {
                    if (matchingDocumentSpeaker === null) {
                        this.editorController.speakers.addDocumentSpeaker(newSpeaker);
                    }
                    this.editorController.speakers.addSpeakerOnIndex(matchingDocumentSpeaker !== null && matchingDocumentSpeaker !== void 0 ? matchingDocumentSpeaker : newSpeaker, index, 'user');
                }
                this.temporarySpeaker = null; // reset temporary speaker to detect double speaker assignment
            });
        };
        this.getOrCreateSpeaker = (diarizationCode) => {
            const { getSpeakerByDiarizationCode } = this.editorController.speakers;
            const alreadyEncounteredSpeaker = getSpeakerByDiarizationCode(diarizationCode);
            if (alreadyEncounteredSpeaker) {
                return alreadyEncounteredSpeaker;
            }
            const newSpeaker = this.createSpeaker(diarizationCode);
            return newSpeaker;
        };
        this.reset = () => {
            this.speakerCounter = 0;
        };
        this.createSpeaker = (diarizationCode, color) => {
            var _a, _b;
            this.speakerCounter += 1;
            const id = `unidentified${this.speakerCounter}`;
            return new Speaker(this.unknownSpeakerLabel, String(this.speakerCounter), null, id, null, diarizationCode !== null && diarizationCode !== void 0 ? diarizationCode : null, true, (_b = (_a = this.editorController.getLanguage()) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : null, new Map(), color !== null && color !== void 0 ? color : null);
        };
        this.editorController = editorController;
        this.speakerCounter = 0;
        this.temporarySpeaker = null;
        const language = (_a = editorController.getLanguage()) !== null && _a !== void 0 ? _a : BeeyLocale.const('en-US');
        const languageKey = language.toLocalizationKey();
        if (isSpeakerLabelKey(languageKey)) {
            this.unknownSpeakerLabel = SPEAKER_LABELS[languageKey];
        }
        else {
            this.unknownSpeakerLabel = SPEAKER_LABELS.en;
        }
    }
    updateSpeakerCounter(speaker) {
        if (speaker.isUnknown) {
            const speakerNumber = Number(speaker.lastName);
            if (!Number.isNaN(speakerNumber) && speakerNumber > this.speakerCounter) {
                this.speakerCounter = speakerNumber;
            }
        }
    }
}
