import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Card } from 'antd';
import { CreditCardOutlined, DatabaseOutlined, HistoryOutlined, TeamOutlined, } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import PlanUsage from 'screens/DashboardScreen/pages/AccountPage/CreditInfo/TeamPlan/PlanUsage';
import { useEffect, useState } from 'react';
import { fetchTeamPlan } from 'api/admin/teams-api';
import { useSession } from 'components/Authenticated';
import { BYTES_IN_GIGABYTE } from 'libs/utils';
import { getAllowedNumberOfMembers } from 'api/team-api';
import styles from '../style.module.less';
const TeamPlanAdmin = ({ teamId, storageStatistics, teamMembersNumber, hasUnlimitedStorage, }) => {
    const [teamPlan, setTeamPlan] = useState('loading');
    const { session } = useSession();
    const usedStorage = storageStatistics !== 'loading'
        ? (storageStatistics.storagePerCount.reduce((acc, cur) => acc + cur.bytes, 0) / BYTES_IN_GIGABYTE).toFixed(2) : 0;
    const isSubscribed = teamPlan !== 'loading' && teamPlan.lookupKey !== 'beey_start';
    useEffect(() => {
        const fetchPlan = async () => {
            const plan = await fetchTeamPlan(session.connection, teamId);
            setTeamPlan(plan);
        };
        void fetchPlan();
    }, []);
    return (_jsxs(Card, { bordered: false, className: styles.card, style: { width: '100%' }, title: (_jsxs(_Fragment, { children: [_jsxs("h2", { children: [_jsx(CreditCardOutlined, {}), "\u00A0", txt('teamPlanUsage')] }), _jsxs("p", { children: [' ', txt('teamPlanUsageLeft'), ":"] })] })), children: [isSubscribed ? (_jsxs(_Fragment, { children: [_jsx(PlanUsage, { icon: _jsx(HistoryOutlined, {}), total: teamPlan.fullSubscriptionCredit, remaining: teamPlan.remainingSubscriptionCredit, unit: "min", decimal: false }), _jsx(PlanUsage, { icon: _jsx(TeamOutlined, {}), total: getAllowedNumberOfMembers(teamPlan.lookupKey), remaining: getAllowedNumberOfMembers(teamPlan.lookupKey) - teamMembersNumber, unit: txt('fromUsers').toLowerCase(), decimal: false })] })) : null, hasUnlimitedStorage ? (_jsx("p", { style: { textAlign: 'center' }, children: txt('userHasUnlimitedStorage') })) : (_jsx(PlanUsage, { icon: _jsx(DatabaseOutlined, {}), total: teamPlan !== 'loading' ? Number(teamPlan.productMetadata.storageLimit) : 10, remaining: teamPlan !== 'loading' ? Number(teamPlan.productMetadata.storageLimit) - Number(usedStorage) : 0, unit: "GB", decimal: true }))] }));
};
export default TeamPlanAdmin;
