import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Upload, Input, Button, Divider, Space, } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import fileUploadStyles from 'components/SingleFileUpload/style.module.less';
import isUrl from 'is-valid-http-url';
import { MediaFile, MediaUrl } from 'screens/DashboardScreen/media-upload/media-origin';
import clsx from 'clsx';
import { MediaOriginsList } from '../media-origins-list';
import MediaOriginView from './MediaOriginView';
import styles from './style.module.less';
const { Dragger } = Upload;
// NOTE: Media types must be listed to work on Apple devices
const SUPPORTED_MEDIA_TYPES = 'video/*, audio/*, .flv, .mov, .mkv, .avi, .wmv, .mp4, .mpeg, .mpg, .webm, .mp3, .wav, .flac, .ogg, .m4a, .aac, .wma, .opus';
const SHORTENED_URLS = ['fb.watch'];
const MediaOriginsUpload = ({ inputId, mediaOriginsList = new MediaOriginsList([]), isSingleFileUpload, onChange = () => { }, }) => {
    const [urlAdress, setUrlAdress] = useState({
        path: '',
        validityError: 'invalid',
    });
    const { session } = useSession();
    const handleFileOriginsChange = ({ fileList }) => {
        const mediaFiles = fileList.map((mediafile) => new MediaFile(mediafile));
        onChange(new MediaOriginsList(mediaFiles, mediaOriginsList.urls));
    };
    const handleAddUrlOrigin = () => {
        const urlFile = new MediaUrl(urlAdress.path);
        onChange(mediaOriginsList.addUrl(urlFile));
        setUrlAdress({ path: '', validityError: 'invalid' });
    };
    const handleUrlInputChange = async ({ target: { value } }) => {
        const inputValue = value.trim();
        if (SHORTENED_URLS.some((url) => inputValue.includes(url))) {
            setUrlAdress({ path: inputValue, validityError: 'shortened' });
            return Promise.reject(txt('invalidShortUrl'));
        }
        setUrlAdress({ path: inputValue, validityError: 'none' });
        if (await isUrl(inputValue)) {
            return Promise.resolve();
        }
        setUrlAdress({ path: inputValue, validityError: 'invalid' });
        return Promise.reject(txt('invalidUrl'));
    };
    const handleRemoveFile = (mediaFile) => {
        onChange(mediaOriginsList.removeFile(mediaFile));
    };
    const handleRemoveUrl = (mediaUrl) => {
        onChange(mediaOriginsList.removeUrl(mediaUrl));
    };
    const validUrl = urlAdress.path !== '' && urlAdress.validityError !== 'none';
    const isUploadLimited = isSingleFileUpload && mediaOriginsList.files.length > 0;
    return (_jsxs("div", { className: styles.filesUpload, children: [_jsxs(Dragger, { id: inputId, disabled: !mediaOriginsList.areOriginsResolved() || isUploadLimited, multiple: !isSingleFileUpload, className: clsx(fileUploadStyles.fileDragger, { [styles.fileDraggerDisabled]: isUploadLimited }), showUploadList: false, beforeUpload: () => false, onChange: handleFileOriginsChange, accept: SUPPORTED_MEDIA_TYPES, fileList: mediaOriginsList.files.map((mediaFile) => mediaFile.file), children: [_jsx(UploadOutlined, { className: fileUploadStyles.fileDraggerIcon }), mediaOriginsList.areOriginsResolved() ? (_jsx("p", { children: txt('dragAndDrop') })) : (_jsxs("p", { className: fileUploadStyles.fileDraggerHint, children: [_jsx(LoadingOutlined, { style: { marginRight: '5px' } }), _jsx("span", { children: txt('analysing') })] }))] }), session.login.hasClaim('services:link') ? (_jsxs(_Fragment, { children: [_jsx(Divider, { className: styles.orDivider, children: txt('orLabel') }), _jsx("span", { className: styles.urlInputLabel, children: txt('insertUrl') }), _jsxs(Space.Compact, { className: styles.urlInput, children: [_jsx(Input, { id: "input-link-url", type: "url", disabled: isUploadLimited, className: styles.urlInputField, placeholder: "https://", value: urlAdress.path, onChange: handleUrlInputChange }), _jsx(Button, { id: "action-add-link-url", disabled: urlAdress.validityError !== 'none', className: styles.urlInputButton, type: "default", onClick: handleAddUrlOrigin, children: txt('add') })] }), validUrl
                        ? _jsx("p", { className: styles.urlInputError, children: urlAdress.validityError === 'shortened' ? txt('invalidShortUrl') : txt('invalidUrl') })
                        : null] })) : null, _jsxs("div", { className: styles.mediaOriginsView, children: [mediaOriginsList.files.map((mediaFile) => (_jsx(MediaOriginView, { mediaOrigin: mediaFile, onRemoveOrigin: handleRemoveFile }, mediaFile.file.uid))), mediaOriginsList.urls.map((mediaUrl) => (_jsx(MediaOriginView, { mediaOrigin: mediaUrl, onRemoveOrigin: handleRemoveUrl }, mediaUrl.getId())))] })] }));
};
export default MediaOriginsUpload;
