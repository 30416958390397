import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef, useEffect, Fragment, } from 'react';
import { nanoid } from 'nanoid';
import { useAppContext, screenBreakpoints } from 'App/app-context';
import { CAPTION_EVENTS } from '../captions';
import { usePlaybackContext } from '../../WithMediaPlayback';
import DocumentTooltip from './DocumentTooltip';
import DocumentWidget from '../DocumentWidget';
import DeleteWidgetButton from '../DocumentWidget/DeleteButton';
const DocumentHoverElement = ({ children, editorController, disabled }) => {
    var _a, _b;
    const { captions } = usePlaybackContext();
    const [hoverElements, setHoverElements] = useState([]);
    const [lastSpeakerTrigger, setLastSpeakerTrigger] = useState(null);
    const [lastSectionTrigger, setSectionTrigger] = useState(null);
    const { screenSize } = useAppContext();
    const lastTrigger = useRef(null);
    const lastMousePos = useRef([0, 0]);
    const getHoverElements = (trigger) => {
        const output = [];
        // NOTE: Keywords should not trigger any hover elements.
        if (trigger.closest('keyword') !== null) {
            return [];
        }
        const speakerTrigger = trigger.closest('.speaker span');
        if (speakerTrigger !== null) {
            setLastSpeakerTrigger(trigger);
            output.push({
                trigger,
                type: 'widget',
                variant: 'deleteSpeaker',
            });
        }
        const speakerDeleteButton = trigger.closest('.speaker-delete');
        if (speakerDeleteButton !== null && lastSpeakerTrigger !== null) {
            output.push({
                trigger: lastSpeakerTrigger,
                type: 'widget',
                variant: 'deleteSpeaker',
            });
        }
        const tooltipTriggers = ['timeAnchor', 'warning', '.unaligned'];
        for (let i = 0; i < tooltipTriggers.length; i += 1) {
            const tooltipType = tooltipTriggers[i];
            const element = trigger.closest(tooltipType);
            if (element !== null) {
                output.push({
                    title: tooltipType, trigger: element, type: 'tooltip',
                });
            }
        }
        const sectionTrigger = trigger.closest('.section warning, .section uneditable');
        if (sectionTrigger !== null) {
            setSectionTrigger(trigger);
            output.push({
                trigger,
                type: 'widget',
                variant: 'deleteSection',
            });
        }
        const sectionDeleteButton = trigger.closest('.section-delete');
        if (sectionDeleteButton !== null && lastSectionTrigger !== null) {
            output.push({
                trigger: lastSectionTrigger, type: 'widget', variant: 'deleteSection',
            });
        }
        lastTrigger.current = trigger;
        return output;
    };
    const generateElements = () => {
        const elmUnderCursor = document.elementFromPoint(lastMousePos.current[0], lastMousePos.current[1]);
        if (elmUnderCursor === null) {
            if (hoverElements.length > 0) {
                setHoverElements([]);
                return;
            }
            return;
        }
        if (lastTrigger.current === elmUnderCursor) {
            return;
        }
        const elements = getHoverElements(elmUnderCursor);
        if (elements.length > 0) {
            setHoverElements(elements);
        }
        else if (elements.length === 0 && hoverElements.length > 0) {
            setHoverElements([]);
        }
    };
    const handleMouseMove = (e) => {
        lastMousePos.current = [
            e.nativeEvent.clientX,
            e.nativeEvent.clientY,
        ];
        generateElements();
    };
    const handleScroll = () => {
        setHoverElements([]);
    };
    const removeSpeaker = (trigger) => {
        const speakerNode = trigger.parentElement;
        if (speakerNode) {
            editorController.handleRemoveSpeaker(speakerNode);
        }
    };
    const removeSection = (trigger) => {
        const sectionNode = trigger.parentElement;
        if (sectionNode) {
            editorController.sections.removeSection(sectionNode);
        }
    };
    useEffect(() => {
        const handleCaptionUpdate = () => {
            generateElements();
        };
        captions.addEventListener(CAPTION_EVENTS.CHANGED, handleCaptionUpdate);
        return () => {
            captions.removeEventListener(CAPTION_EVENTS.CHANGED, handleCaptionUpdate);
        };
    }, []);
    const isRightToLeft = (_b = (_a = editorController.getLanguage()) === null || _a === void 0 ? void 0 : _a.isRightToLeft()) !== null && _b !== void 0 ? _b : false;
    return (_jsxs("div", { onMouseMove: handleMouseMove, onScrollCapture: handleScroll, children: [hoverElements.map((hoverElement) => (_jsxs(Fragment, { children: [hoverElement.type === 'tooltip' && (_jsx(DocumentTooltip, { type: hoverElement.title, triggerElement: hoverElement.trigger })), screenSize.width > screenBreakpoints.lg && (_jsxs(_Fragment, { children: [hoverElement.type === 'widget' && hoverElement.variant === 'deleteSpeaker' && !disabled && (_jsx(DocumentWidget, { widgetTrigger: hoverElement.trigger, WidgetComponent: DeleteWidgetButton, className: "speaker-delete", onClick: removeSpeaker, isRightToLeft: isRightToLeft, editorController: editorController })), hoverElement.type === 'widget' && hoverElement.variant === 'deleteSection' && !disabled && (_jsx(DocumentWidget, { widgetTrigger: hoverElement.trigger, WidgetComponent: DeleteWidgetButton, className: "section-delete", onClick: removeSection, isRightToLeft: isRightToLeft, editorController: editorController }))] }))] }, nanoid(5)))), children] }));
};
const MemoizedHoverElement = React.memo(DocumentHoverElement);
export default MemoizedHoverElement;
