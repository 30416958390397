const defaultScreenFraction = {
    widthFraction: 0.6,
    heightFraction: 0.2,
};
export const getDialogPosition = (dialogSize, defaultFractionalPos = defaultScreenFraction, userFractionalPos) => {
    const fractionalPosition = userFractionalPos !== null && userFractionalPos !== void 0 ? userFractionalPos : defaultFractionalPos;
    const x = window.innerWidth * fractionalPosition.widthFraction;
    const y = window.innerHeight * fractionalPosition.heightFraction;
    if (window.innerWidth - x < dialogSize.width) {
        return {
            x: 20,
            y: window.innerHeight * defaultFractionalPos.heightFraction,
        };
    }
    return { x, y };
};
