import Quill from 'quill';
import { txt } from 'libs/i18n';
const BlockBlot = Quill.import('blots/block');
const SUMMARY = 'summary';
const formatNode = (node, value) => {
    // eslint-disable-next-line no-param-reassign
    node.className = `${SUMMARY} ${SUMMARY}-${value}`;
    if (value === 'button') {
        // eslint-disable-next-line no-param-reassign
        node.dataset.button = txt('generateSummary');
    }
};
class SummaryBlot extends BlockBlot {
    static create(value) {
        const node = super.create(value);
        formatNode(node, value);
        return node;
    }
    static formats(domNode) {
        if (domNode.className.includes('summary-button')) {
            return 'button';
        }
        if (domNode.className.includes('summary-nobutton')) {
            return 'nobutton';
        }
        return null;
    }
    format(name, value) {
        if (name === SummaryBlot.blotName && Boolean(value)) {
            formatNode(this.domNode, value);
        }
        else {
            super.format(name, value);
        }
    }
    formats() {
        const formats = super.formats();
        formats.summary = SummaryBlot.formats(this.domNode);
        return formats;
    }
    attach() {
        if (this.parent != null) {
            this.scroll = this.parent.scroll;
        }
    }
}
SummaryBlot.blotName = SUMMARY;
export default SummaryBlot;
