import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { fetchSubscriptions } from 'api/team-api';
import { useSession } from 'components/Authenticated';
import AzurePlans from './AzurePlans';
import StripePlans from './StripePlans';
const CreditInfo = ({ totalCredit, payAsYouGoCredit, }) => {
    const { session } = useSession();
    const [subscriptions, setSubscriptions] = useState([]);
    const getSubscriptions = async () => {
        const subs = await fetchSubscriptions(session.connection);
        setSubscriptions(subs);
    };
    useEffect(() => {
        void getSubscriptions();
    }, []);
    return (subscriptions.length === 0 ? (_jsx(StripePlans, { totalCredit: totalCredit, payAsYouGoCredit: payAsYouGoCredit })) : (_jsx(AzurePlans, { subscriptions: subscriptions, totalCredit: totalCredit, payAsYouGoCredit: payAsYouGoCredit, onRefetchSubscriptions: getSubscriptions })));
};
export default CreditInfo;
