const charSets = {
    lower: 'abcdefghijkmnopqrstuvwxyz'.split(''),
    upper: 'ACDEFGHJKLMNPQRSTUVWXYZ'.split(''),
    digit: '12345679'.split(''),
};
const GENERATED_PSW_LENGTH = 24;
const selectCharSet = () => {
    const keys = Object.keys(charSets);
    const index = Math.floor(Math.random() * keys.length);
    return keys[index];
};
const selectChar = (charSet) => {
    const index = Math.floor(Math.random() * charSet.length);
    return charSet[index];
};
const generateRandom = (length) => {
    let text = '';
    let uniqueChars = 0;
    const setsIncluded = [];
    for (let i = 0; i < length; i += 1) {
        const charSet = selectCharSet();
        if (!setsIncluded.includes(charSet)) {
            setsIncluded.push(charSet);
        }
        const newChar = selectChar(charSets[charSet]);
        if (!text.includes(newChar)) {
            uniqueChars += 1;
        }
        text += newChar;
    }
    return { text, uniqueChars, setsIncluded };
};
export const generatePassword = (settings) => {
    const { requiredUniqueChars, requiredLength } = settings;
    const length = Math.max(requiredLength, GENERATED_PSW_LENGTH);
    let password = generateRandom(length);
    while (password.uniqueChars < requiredUniqueChars
        || password.setsIncluded.length < Object.keys(charSets).length) {
        password = generateRandom(length);
    }
    return password.text;
};
