import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { App, Button, Card, Divider, Flex, Form, Input, Typography, } from 'antd';
import ResponsiveTable, { empiricalScrollHeightAdjust, useTableView } from 'components/ResponsiveTable';
import { addManagementGroup, addManagementGroupCredit, fetchManagementGroups, removeManagementGroupCredit, updateManagementGroup, } from 'api/admin/management-groups-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import { EditOutlined } from '@ant-design/icons';
import IconButton from 'components/IconButton';
import AddButton from 'components/AddButton';
import FormModal from 'components/FormModal';
import { useDashpage } from 'screens/DashboardScreen/components/DashboardPage/dashpage-context';
import styles from './style.module.less';
import CreditSettings from '../components/CreditSettings';
const GROUPS_TABLE_ID = 'groups-table'; // Must be unique across all tables in Beey!!
const SCROLL_HEIGHT_ADJUST = empiricalScrollHeightAdjust(205);
const { Text } = Typography;
const ManagementGroupSubpage = () => {
    const { session } = useSession();
    const { contentHeight } = useDashpage();
    const tableView = useTableView(GROUPS_TABLE_ID);
    const { message } = App.useApp();
    const [managementGroups, setManagementGroups] = useState('loading');
    const initialValues = {
        groupName: '',
        notes: '',
        externalId: '',
        id: undefined,
    };
    const [modal, setModal] = useState({
        action: 'add',
        visible: false,
        formValues: initialValues,
        creditSettings: { credit: 0, creditDelta: 0, visible: false },
    });
    const [form] = Form.useForm();
    const fetchGroupList = async () => {
        const response = await fetchManagementGroups(session.connection);
        setManagementGroups(response.list);
        return response.list;
    };
    useEffect(() => {
        if (modal.visible) {
            form.setFieldsValue(modal.formValues);
        }
    }, [modal.visible]);
    useEffect(() => {
        void fetchGroupList();
    }, []);
    const updateGroupCredit = async () => {
        const groups = await fetchGroupList();
        if (modal.visible && modal.action === 'edit' && managementGroups !== 'loading') {
            const updatedGroup = groups.find((group) => group.id === form.getFieldValue('id'));
            if (updatedGroup !== undefined) {
                setModal((prevModal) => (Object.assign(Object.assign({}, prevModal), { creditSettings: Object.assign(Object.assign({}, prevModal.creditSettings), { credit: updatedGroup.credit, creditDelta: 0 }) })));
            }
        }
    };
    const handleCreditOperation = async (operation) => {
        const { creditDelta } = modal.creditSettings;
        const id = form.getFieldValue('id');
        if (id === undefined) {
            void message.error(txt('creditChangeFail'));
            return;
        }
        // FIXME: @EvaMach replace try catch with Result, when BE ready.
        try {
            await (operation === 'add'
                ? addManagementGroupCredit(session.connection, id, creditDelta)
                : removeManagementGroupCredit(session.connection, id, creditDelta));
            void updateGroupCredit();
            void message.success(txt('creditSuccess'));
        }
        catch (error) {
            void message.error(txt('unknownError'));
        }
    };
    const handleCreditDeltaChanged = (value) => {
        if (value !== null) {
            setModal((prevModal) => (Object.assign(Object.assign({}, prevModal), { creditSettings: Object.assign(Object.assign({}, prevModal.creditSettings), { creditDelta: value }) })));
        }
    };
    const formItems = (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: txt('groupTitle'), name: "groupName", rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: txt('noGroupTitle'),
                    },
                    ({ getFieldValue }) => ({
                        validator: (_, groupName) => {
                            const currentGroupName = getFieldValue('groupName');
                            if (managementGroups !== 'loading') {
                                const doesGroupExist = managementGroups.some((managementGroup) => managementGroup.groupName === groupName);
                                if (doesGroupExist && groupName !== currentGroupName) {
                                    return Promise.reject(txt('groupExists'));
                                }
                                return Promise.resolve();
                            }
                            return Promise.reject(txt('waitForGroups'));
                        },
                    }),
                ], children: _jsx(Input, { autoFocus: true }) }), _jsx(Form.Item, { label: txt('groupDescription'), name: "notes", children: _jsx(Input, {}) }), _jsx(Form.Item, { label: txt('externalId'), name: "externalId", children: _jsx(Input, {}) }), _jsx(Form.Item, { className: styles.hidden, name: "id", children: _jsx(Input, { type: "hidden" }) })] }));
    const openEditModal = (record) => {
        setModal({
            action: 'edit',
            visible: true,
            formValues: record,
            creditSettings: { credit: record.credit, creditDelta: 0, visible: true },
        });
    };
    const openAddModal = () => {
        setModal({
            action: 'add',
            visible: true,
            formValues: initialValues,
            creditSettings: { credit: 0, creditDelta: 0, visible: false },
        });
    };
    const handleAddGroup = async (group) => {
        const { groupName, notes, externalId } = group;
        const newGroup = await addManagementGroup(session.connection, groupName, notes, externalId === '' ? undefined : externalId);
        setModal((prevModal) => (Object.assign(Object.assign({}, prevModal), { action: 'edit', creditSettings: Object.assign(Object.assign({}, prevModal.creditSettings), { visible: true }) })));
        form.setFieldValue('id', newGroup.id);
        void fetchGroupList();
    };
    const handleEditGroup = async (group) => {
        const { groupName, notes, id, externalId, } = group;
        const result = await updateManagementGroup(session.connection, id, groupName, notes, externalId === '' ? undefined : externalId);
        if (result.isSuccess()) {
            void message.success(txt('groupUpdated'));
            void fetchGroupList();
        }
        else {
            void message.error(txt('unknownError'));
        }
    };
    const columns = [
        {
            title: txt('groupTitle'),
            dataIndex: 'groupName',
            render: (groupName, { id }) => (_jsx(Link, { to: { pathname: '/admin/users', state: { groupId: id } }, children: groupName })),
        },
        {
            title: txt('groupDescription'),
            dataIndex: 'notes',
            render: (notes) => (_jsx("div", { children: notes })),
        },
        {
            title: txt('credit'),
            dataIndex: 'credit',
            render: (credit) => (_jsx("div", { children: credit })),
        },
        {
            title: '',
            key: 'Edit',
            width: '40px',
            render: (record) => {
                return (_jsx(IconButton, { onClick: () => openEditModal(record), children: _jsx(EditOutlined, {}) }));
            },
        },
    ];
    const handleSubmit = async (group) => {
        if (modal.action === 'add') {
            await handleAddGroup(group);
        }
        else {
            await handleEditGroup(group);
        }
    };
    return (_jsx("section", { className: styles.groupsSubpage, children: _jsxs(Card, { bordered: false, className: styles.groupsCard, children: [_jsx(AddButton, { className: styles.addGroupButton, onClick: () => openAddModal(), title: txt('addGroup') }), _jsx(ResponsiveTable, { bordered: true, columns: columns, className: styles.groupsTable, dataSource: managementGroups === 'loading' ? [] : managementGroups, tableView: tableView, loading: managementGroups === 'loading', rowKey: (group) => group.id, recordCount: managementGroups.length, scroll: { y: contentHeight - SCROLL_HEIGHT_ADJUST }, pageSizeOptions: ['5', '10', '20', '50', '100'] }), _jsxs(FormModal, { open: modal.visible, title: modal.action === 'add' ? txt('addGroup') : txt('editGroup'), onCancel: () => setModal(Object.assign(Object.assign({}, modal), { visible: false })), footer: null, forceRender: true, children: [_jsxs(Form, { form: form, labelCol: { span: 9 }, wrapperCol: { sm: { offset: 3 } }, labelAlign: "left", initialValues: modal.formValues, onFinish: handleSubmit, children: [formItems, _jsx(Flex, { justify: "end", children: _jsx(Button, { type: "primary", htmlType: "submit", children: modal.action === 'add' ? txt('add') : txt('save') }) })] }), modal.creditSettings.visible === true && (_jsxs(_Fragment, { children: [_jsx(Text, { strong: true, children: txt('credit') }), _jsx(Divider, { className: styles.divider }), _jsx(CreditSettings, { remainingCredit: modal.creditSettings.credit, creditDelta: modal.creditSettings.creditDelta, onCreditDeltaChanged: handleCreditDeltaChanged, onOperation: handleCreditOperation })] }))] })] }) }));
};
export default ManagementGroupSubpage;
