import { jsx as _jsx } from "react/jsx-runtime";
import styles from './style.module.less';
const TIMESTAMP_WIDTH = 65;
const SpeakerTimestamp = ({ position, widgetTrigger }) => {
    const timestamp = widgetTrigger.dataset.timestamplabel;
    return (_jsx("span", { className: styles.timestamp, style: {
            position: 'absolute',
            zIndex: 100,
            top: position.top,
            left: position.isRightToLeft
                ? position.left - TIMESTAMP_WIDTH
                : position.left + position.width,
        }, children: timestamp }));
};
export default SpeakerTimestamp;
