import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Tooltip, Button, Row, Col, Form, Divider, Switch, Alert, App, } from 'antd';
import AbandonProjects from 'screens/DashboardScreen/components/AbandonProjects';
import { txt } from 'libs/i18n';
import * as TeamAPI from 'api/team-api';
import { useSession } from 'components/Authenticated';
import { ApiError } from '@newtontechnologies/beey-api-js-client/receivers';
import styles from './style.module.less';
const TeamPreferences = ({ team }) => {
    const { session, updateTeamSettings } = useSession();
    const { message } = App.useApp();
    const [abandonProject, setAbandonProject] = useState({
        enable: team !== 'loading' && team.abandonProjectsIn !== null,
        inDays: (team === 'loading' || team.abandonProjectsIn === null) ? null : Number(team.abandonProjectsIn.split('.')[0]),
    });
    const [form] = Form.useForm();
    const isTeamOwner = session.login.hasClaim('team:owner');
    const handleResetDeletion = async (resetValue) => {
        try {
            await TeamAPI.teamOwnerAbandonProjectsIn(session.connection, resetValue);
            void message.success(txt('cancelAutomaticalDeletion'));
        }
        catch (error) {
            if (error instanceof ApiError) {
                global.logger.error('Resetting automatical deletion failed', {}, error);
                void message.error(txt('resetAutomaticalDeletionFail'));
            }
        }
    };
    const handleAbandonProjectsChange = (changedValues, isReset) => {
        setAbandonProject(changedValues);
        if (isReset === true) {
            void handleResetDeletion(changedValues);
        }
    };
    const handleAbandonProjects = async () => {
        if (abandonProject.inDays === null) {
            void message.error(txt('missingDays'));
            return;
        }
        try {
            await TeamAPI.teamOwnerAbandonProjectsIn(session.connection, abandonProject);
            void message.success(txt('successAutomaticalDeletion'));
        }
        catch (error) {
            if (error instanceof ApiError) {
                global.logger.error('Setting automatical deletion failed', {}, error);
                void message.error(txt('settingAutomaticalDeletionFail'));
            }
        }
    };
    const handleFormValuesChanged = (values) => {
        updateTeamSettings(values);
    };
    // NOTE: we do not allow to switch this settings on without a claim,
    // but we allow to switch it off.
    const isSummaryButtonEnabled = session.login.hasClaim('services:summarize')
        || form.getFieldsValue().summariesAllowed;
    return (_jsxs(_Fragment, { children: [_jsx(Row, { className: styles.automaticDeletion, children: _jsxs(Col, { span: 24, children: [_jsxs("div", { className: "account-section__title", children: [_jsx(SettingOutlined, {}), txt('teamSettings')] }), !isTeamOwner ? (_jsx("span", { className: styles.alert, children: _jsx(Alert, { message: txt('ownerCanEdit'), type: "info", showIcon: true }) })) : null, _jsx(AbandonProjects, { value: abandonProject, onChange: handleAbandonProjectsChange, isTeamOwner: isTeamOwner, children: _jsx(Tooltip, { title: abandonProject.enable === null || !abandonProject.enable ? txt('enableDeletion') : null, children: _jsx(Button, { className: styles.buttonSave, type: "primary", onClick: handleAbandonProjects, disabled: abandonProject.enable === null || !abandonProject.enable || !isTeamOwner, children: txt('save') }) }) })] }) }), _jsx(Divider, { className: styles.divider }), _jsxs(Form, { initialValues: Object.assign({}, session.teamSettings), onValuesChange: handleFormValuesChanged, form: form, className: styles.teamSettings, children: [_jsxs("div", { className: styles.settingsItem, children: [_jsx(Form.Item, { label: txt('sectionTags'), name: "sectionTagsAllowed", valuePropName: "checked", labelCol: { span: 24 }, className: styles.settingsHeader, children: _jsx(Switch, { size: "small", disabled: !isTeamOwner }) }), _jsx("div", { children: txt('showSectionTags') })] }), _jsxs("div", { className: styles.settingsItem, children: [_jsx(Tooltip, { title: isSummaryButtonEnabled ? null : txt('missingPermission'), children: _jsx(Form.Item, { label: txt('summaries'), name: "summariesAllowed", valuePropName: "checked", labelCol: { span: 24 }, className: styles.settingsHeader, children: _jsx(Switch, { size: "small", disabled: !isSummaryButtonEnabled || !isTeamOwner }) }) }), _jsx("div", { children: txt('showSummaries') })] })] })] }));
};
export default TeamPreferences;
