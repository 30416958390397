import { jsx as _jsx } from "react/jsx-runtime";
import { getRectFromElement } from 'libs/positions';
const DocumentWidget = ({ widgetTrigger, WidgetComponent, onClick, editorController, isRightToLeft, disabled, isCaptionMode, className, }) => {
    var _a;
    if (widgetTrigger === null) {
        global.logger.error('No widget trigger found');
        return null;
    }
    const triggerPosition = getRectFromElement(widgetTrigger);
    const editorElement = (_a = editorController.quill) === null || _a === void 0 ? void 0 : _a.root;
    if (editorElement === undefined) {
        return null;
    }
    const editorPosition = getRectFromElement(editorElement);
    const relativePosition = {
        top: triggerPosition.top - editorPosition.top,
        left: triggerPosition.left - editorPosition.left,
        width: triggerPosition.width,
        isRightToLeft,
    };
    return (_jsx(WidgetComponent, { position: relativePosition, widgetTrigger: widgetTrigger, disabled: disabled, onClick: onClick, editorController: editorController, isCaptionMode: isCaptionMode, className: className }));
};
export default DocumentWidget;
