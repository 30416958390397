export class UploadQueue {
    constructor(finished, active, queued, isSolitary) {
        this.finished = finished;
        this.active = active;
        this.queued = queued;
        this.isSolitary = isSolitary;
    }
    get remainingCount() {
        if (this.active === null) {
            return this.queued.length;
        }
        return this.queued.length + 1;
    }
    get successCount() {
        return this.finished
            .filter((item) => item.status === 'success')
            .length;
    }
    get totalCount() {
        return this.finished.length + this.remainingCount;
    }
    get isRunning() {
        if (this.active !== null) {
            return true;
        }
        return this.queued.some((item) => item.status === 'waiting');
    }
    get shouldStartTranscription() {
        if (this.active === null) {
            return false;
        }
        if (this.active.isUploadOnly) {
            return false;
        }
        if (this.active.status !== 'running') {
            return false;
        }
        return this.active.progress === 0;
    }
    get itemToActivate() {
        if (this.queued.length === 0) {
            return null;
        }
        if (this.active === null) {
            const [item] = this.queued;
            if (item.status === 'waiting') {
                return item;
            }
        }
        return null;
    }
    static createEmpty() {
        return new UploadQueue([], null, [], false);
    }
    clear() {
        if (this.remainingCount === 0) {
            return UploadQueue.createEmpty();
        }
        return this;
    }
    enqueue(items) {
        const isSolitary = (items.length === 1
            && this.active === null
            && this.queued.length === 0);
        const queuedItems = items.map((item) => (Object.assign(Object.assign({}, item), { status: 'waiting' })));
        return new UploadQueue(this.finished, this.active, [...this.queued, ...queuedItems], isSolitary);
    }
    activateNext() {
        const { itemToActivate } = this;
        if (itemToActivate === null) {
            return this;
        }
        return new UploadQueue(this.finished, Object.assign(Object.assign({}, itemToActivate), { status: 'prepared' }), this.queued.slice(1), this.isSolitary);
    }
    progress(uploadedFile) {
        if (this.active === null) {
            return this;
        }
        // NOTE: Do not create a new object if nothing changed.
        if (uploadedFile.phase === this.active.status
            && uploadedFile.progress === this.active.progress) {
            return this;
        }
        if (uploadedFile.phase === 'running') {
            return new UploadQueue(this.finished, Object.assign(Object.assign({}, this.active), { status: 'running', progress: uploadedFile.progress, projectId: uploadedFile.projectId }), this.queued, this.isSolitary);
        }
        const finishedItems = [
            ...this.finished,
            {
                uploadTarget: Object.assign({}, this.active.uploadTarget),
                mediaFile: this.active.mediaFile,
                isUploadOnly: this.active.isUploadOnly,
                id: this.active.id,
                status: uploadedFile.phase,
                failedReason: uploadedFile.failedReason,
            },
        ];
        if (uploadedFile.phase === 'severed') {
            const canceledItems = this.queued.map((item) => (Object.assign(Object.assign({}, item), { status: 'canceled' })));
            return new UploadQueue([...finishedItems, ...canceledItems], null, [], this.isSolitary);
        }
        return new UploadQueue(finishedItems, null, this.queued, this.isSolitary);
    }
}
