import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useSession } from 'components/Authenticated';
export const Authorized = ({ claim, userRole, children }) => {
    const { session } = useSession();
    if (claim !== undefined && !session.login.hasClaim(claim)) {
        return null;
    }
    if (userRole !== undefined && session.login.user.role !== userRole) {
        return null;
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return _jsx(_Fragment, { children: children });
};
