import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from 'antd';
import { useMuteShortcuts } from 'libs/shortcuts';
const FormModal = (props) => {
    const { open, muteShortcuts = [] } = props;
    useMuteShortcuts(open !== null && open !== void 0 ? open : false, ...muteShortcuts);
    return (_jsx(Modal, Object.assign({ getContainer: false, destroyOnClose: true, 
        // SEE: https://stackoverflow.com/a/65641605
        forceRender: open }, props)));
};
export default FormModal;
