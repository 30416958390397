import EventEmitter from 'events';
import { formatMediaTime } from 'libs/format-number';
import { getAllMatchIndices } from './text-utils';
export default class TimestampLabels {
    constructor(editorControler, enabled) {
        this.mediaStartDatetime = null;
        this.update = (changeFrom, changeTo, forceUpdate = false) => {
            if (!this.enabled && !forceUpdate) {
                return;
            }
            const from = Math.max(0, changeFrom - 1); // include previous character
            const to = changeTo;
            const text = this.editorController.getText(from, to - from);
            const lineEndIndices = getAllMatchIndices(text, /\n/g);
            this.editorController.execTextChange({ updateMetadata: false, runAligner: false, requestSave: false }, () => {
                lineEndIndices.forEach((i) => {
                    const documentIndex = from + i;
                    const lineFormat = this.editorController.getLineFormat(documentIndex - 1);
                    if (lineFormat.speaker === undefined)
                        return;
                    try {
                        const speakerChild = this.getSpeakerChildAtIndex(documentIndex - 1);
                        if (speakerChild === null)
                            return;
                        const nextWordTimestamp = Math.round(this.editorController.textMetadata.getBeginAtIndex(documentIndex + 1));
                        const prettyTimestamp = formatMediaTime(nextWordTimestamp, this.mediaStartDatetime);
                        if (this.enabled
                            && nextWordTimestamp !== Infinity // Speaker at document end has no timestamp after it
                        ) {
                            if (speakerChild.dataset.timestamplabel !== prettyTimestamp) {
                                // optimization - replacing dataset value for identical value triggers quill update
                                speakerChild.dataset.timestamplabel = prettyTimestamp;
                                this.emitter.emit('timestamp-changed');
                            }
                        }
                        else {
                            delete speakerChild.dataset.timestamplabel;
                        }
                    }
                    catch (error) {
                        global.logger.error('failed to display timestamp', {}, error);
                    }
                });
            });
        };
        this.editorController = editorControler;
        this.enabled = enabled;
        this.emitter = new EventEmitter();
    }
    addEventListener(event, listener) {
        this.emitter.on(event, listener);
    }
    removeEventListener(event, listener) {
        this.emitter.off(event, listener);
    }
    updateMediaStartDatetime(mediaStartDatetime) {
        if (this.mediaStartDatetime === mediaStartDatetime) {
            return;
        }
        this.mediaStartDatetime = mediaStartDatetime;
        this.update(0, this.editorController.getLength());
    }
    updateTimeRange(timeFrom, timeTo) {
        const { from } = this.editorController.textMetadata.getTimestampsAtTime(timeFrom);
        const { to } = this.editorController.textMetadata.getTimestampsAtTime(timeTo);
        this.update(from, to);
    }
    setEnabled(value) {
        if (this.enabled === value)
            return;
        this.enabled = value;
        this.update(0, this.editorController.getLength(), true);
    }
    getSpeakerChildAtIndex(index) {
        if (this.editorController.quill === undefined)
            return null;
        const [speakerBlot] = this.editorController.quill.getLeaf(index);
        let { domNode } = speakerBlot;
        while (domNode.parentElement) {
            if (domNode.parentElement.tagName === 'H4') {
                return domNode;
            }
            domNode = domNode.parentElement;
        }
        return null;
    }
}
