import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Col, Divider, Row, Spin, } from 'antd';
import { useSession } from 'components/Authenticated';
import { UserOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import ApaAccountTab from './ApaAccountTab';
import BillingInfo from './BillingInfo';
import styles from './style.module.less';
import Authentification from './Authentification';
const MyProfile = ({ canChangePassword, teamOwner }) => {
    const { session } = useSession();
    const { login } = session;
    const canBuyCredit = login.hasClaim('orders:create') && login.hasClaim('team:owner');
    if (login.hasClaim('signIn:apa')) {
        return _jsx(ApaAccountTab, {});
    }
    return (_jsxs(Row, { gutter: [16, 16], className: styles.myProfile, children: [canChangePassword && (_jsxs(Col, { xs: 24, sm: 24, md: 12, xxl: 9, children: [_jsxs("div", { className: styles.user, children: [_jsx(UserOutlined, { className: styles.icon }), _jsxs("div", { children: [_jsx("p", { className: styles.email, children: login.user.email }), _jsx("p", { className: styles.role, children: login.user.role }), login.hasClaim('team:owner')
                                        ? (_jsx("p", { className: styles.role, children: txt('teamOwner') })) : (_jsx("p", { children: teamOwner !== 'loading' ? `${txt('team')}: ${teamOwner}` : _jsx(Spin, {}) }))] })] }), _jsx(Authentification, {}), _jsx("p", { className: styles.deleteText, children: txt('deleteAccount') }), _jsx(Divider, { className: styles.divider }), _jsx("div", { className: styles.deleteButton, children: _jsx(Button, { type: "primary", size: "small", danger: true, onClick: () => window.open(`${txt('deleteSelfLink')}${login.user.email}`, '_blank'), children: txt('askForDeletion') }) })] })), canBuyCredit && (_jsx(Col, { xs: 24, sm: 24, md: 12, xl: 10, xxl: 8, children: _jsx(BillingInfo, {}) }))] }));
};
export default MyProfile;
