import { jsx as _jsx } from "react/jsx-runtime";
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import ProjectAlert from '../../ProjectAlert';
const reportNotEnoughCredit = (login) => {
    // type of user should be defined during refactoring
    let paragraph = txt('contactTeamOwner');
    if (login.hasClaim('team:owner')) {
        if (login.hasClaim('orders:create')) {
            paragraph = txt('notEnoughCreditError');
        }
        else if (login.hasClaim('signIn:apa')) {
            paragraph = 'Sie haben nicht genug Guthaben. Wenden Sie sich bitte an APA-Salesdesk';
        }
        else {
            paragraph = txt('contactDistributor');
        }
    }
    return {
        title: txt('notEnoughCreditTitle'),
        paragraphs: [
            paragraph,
        ],
        contactSupport: false,
        errorType: 'error',
    };
};
const reportNotEnoughStorage = (login) => {
    let paragraph = txt('contactTeamOwner');
    if (login.hasClaim('team:owner')) {
        if (login.hasClaim('orders:create')) {
            paragraph = txt('notEnoughStorageLong');
        }
        else if (login.hasClaim('signIn:apa')) {
            paragraph = 'Sie haben nicht genügend Speicherplatz. Wenden Sie sich bitte an APA-Salesdesk:';
        }
        else {
            paragraph = txt('contactDistributor');
        }
    }
    return {
        title: txt('notEnoughStorage'),
        paragraphs: [
            paragraph,
        ],
        contactSupport: false,
        errorType: 'error',
    };
};
const reportProjectError = (projectError) => {
    const { session } = useSession();
    if (projectError.category === 'Upload') {
        if (projectError.reason === 'TeamStorageQuotaExceeded') {
            return reportNotEnoughStorage(session.login);
        }
        return {
            title: txt('uploadErrorTitle'),
            paragraphs: [
                txt('uploadError'),
            ],
            contactSupport: true,
            errorType: 'error',
        };
    }
    if (projectError.category === 'Transcoding') {
        return {
            title: txt('transcodingErrorTitle'),
            paragraphs: [
                txt('transcodingError'),
            ],
            contactSupport: true,
            errorType: 'error',
        };
    }
    if (projectError.category === 'Transcription') {
        return {
            title: txt('transcriptionErrorTitle'),
            paragraphs: [
                txt('transcriptionError'),
            ],
            contactSupport: true,
            errorType: 'error',
        };
    }
    if (projectError.category === 'Client') {
        if (projectError.reason === 'TrsxLoadFail') {
            return {
                title: txt('trsxLoadErrorTitle'),
                paragraphs: [
                    txt('trsxLoadError'),
                ],
                contactSupport: true,
                errorType: 'error',
            };
        }
        return {
            title: txt('missingRecording'),
            paragraphs: [
                txt('missingRecordingBody'),
            ],
            contactSupport: true,
            errorType: 'warning',
        };
    }
    if (projectError.reason === 'NotEnoughCredit') {
        return reportNotEnoughCredit(session.login);
    }
    if (projectError.reason === 'ServerShutdown') {
        return {
            title: txt('serverShutdownTitle'),
            paragraphs: [
                txt('serverShutdown'),
            ],
            contactSupport: true,
            errorType: 'error',
        };
    }
    if (projectError.reason === 'Canceled') {
        return {
            title: txt('canceledProject'),
            paragraphs: [
                txt('canceledProjectError'),
            ],
            contactSupport: true,
            errorType: 'error',
        };
    }
    return {
        title: txt('unknownProjectErrorTitle'),
        paragraphs: [
            txt('unknownProjectError'),
        ],
        contactSupport: true,
        errorType: 'error',
    };
};
const ErrorAlert = ({ projectError, onClick }) => {
    const errorReport = reportProjectError(projectError);
    const description = (errorReport.paragraphs.map((parText, index) => (
    // eslint-disable-next-line react/no-array-index-key
    parText === null ? null : _jsx("p", { children: parText }, index))));
    return (_jsx(ProjectAlert, { type: errorReport.errorType, title: errorReport.title, description: description, contactSupport: errorReport.contactSupport, onClick: onClick }));
};
export default ErrorAlert;
