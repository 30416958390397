import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { subtitlesDefaults } from 'api/settings/user-settings';
import { Checkbox, Tooltip } from 'antd';
import { Speaker } from 'api/speaker';
import { txt } from 'libs/i18n';
import SpeakerColorPicker from 'components/SpeakerColorPicker';
import styles from './style.module.less';
const COLOR_BUTTON_WIDTH = 24;
const SpeakerColorButton = ({ position, widgetTrigger, disabled, editorController, initialColor, }) => {
    var _a, _b, _c;
    const backgroundColor = (_a = editorController.captions.parameters) === null || _a === void 0 ? void 0 : _a.defaultColor;
    const defaultSpeakerColor = backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : subtitlesDefaults.speakerColor;
    const speakerNode = widgetTrigger.parentElement;
    const [speakerColor, setSpeakerColor] = useState(initialColor);
    const getSelectedSpeaker = () => {
        if (speakerNode === null) {
            return null;
        }
        const speakerId = Speaker.getId(speakerNode);
        return editorController.speakers.getSpeakerById(speakerId);
    };
    useEffect(() => {
        if (speakerColor !== initialColor) {
            setSpeakerColor(initialColor);
        }
    }, [initialColor]);
    useEffect(() => {
        const onDefaultColorChanged = () => {
            const selectedSpeaker = getSelectedSpeaker();
            if (selectedSpeaker === null || editorController.captions.parameters === null) {
                return;
            }
            if (editorController.captions.parameters.defaultColor !== speakerColor
                && selectedSpeaker.isDefaultColor) {
                setSpeakerColor(editorController.captions.parameters.defaultColor);
            }
        };
        onDefaultColorChanged();
    }, [(_b = editorController.captions.parameters) === null || _b === void 0 ? void 0 : _b.defaultColor]);
    const handleColorChange = (color, isDefaultColor) => {
        const selectedSpeaker = getSelectedSpeaker();
        if (selectedSpeaker === null) {
            return;
        }
        editorController.handleSpeakerColorChange(selectedSpeaker, color, isDefaultColor);
        setSpeakerColor(color);
    };
    const handleCheckedDefaultColor = (value) => {
        handleColorChange(defaultSpeakerColor, value.target.checked);
    };
    const colorPickerPosition = () => {
        return {
            position: 'absolute',
            zIndex: 100,
            top: position.top + 2,
            left: position.isRightToLeft
                ? position.left + position.width + COLOR_BUTTON_WIDTH
                : position.left - COLOR_BUTTON_WIDTH,
        };
    };
    return (_jsx(Tooltip, { title: disabled === true ? txt('readOnlyProject') : false, destroyTooltipOnHide: true, children: _jsx("div", { style: colorPickerPosition(), children: _jsx(SpeakerColorPicker, { buttonType: "circle", value: speakerColor, onChange: (color) => handleColorChange(color, false), disabled: disabled, children: _jsxs("div", { className: styles.defaultColorWrapper, children: [_jsx(Checkbox, { checked: (_c = getSelectedSpeaker()) === null || _c === void 0 ? void 0 : _c.isDefaultColor, onChange: handleCheckedDefaultColor }), _jsx("span", { className: styles.defaultColorText, children: txt('chooseDefaultColor') }), _jsx("div", { className: styles.colorBox, style: { backgroundColor: defaultSpeakerColor } })] }) }) }) }));
};
export default SpeakerColorButton;
