import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import { fetchExpiringCredit } from 'api/team-api';
import dayjs from 'dayjs';
import { separateThousands } from '../TeamPlan/LastPayment';
const CreditValidity = () => {
    const { session } = useSession();
    const [credit, setCredit] = useState([]);
    useEffect(() => {
        const fetchCreditValidity = async () => {
            const expiringCredit = await fetchExpiringCredit(session.connection);
            setCredit(expiringCredit.list);
        };
        void fetchCreditValidity();
    }, []);
    const columns = [
        {
            title: txt('credit'),
            dataIndex: 'credit',
            key: 'credit',
            render: (minutes) => separateThousands(minutes),
        },
        {
            title: txt('expiration'),
            dataIndex: 'expiration',
            key: 'expiration',
            render: (date) => {
                if (date === null)
                    return '';
                return (dayjs(date).format('DD/MM/YYYY'));
            },
        },
    ];
    return (_jsx(Table, { columns: columns, dataSource: credit, pagination: false, rowKey: (record) => record.credit }));
};
export default CreditValidity;
