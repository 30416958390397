import { jsx as _jsx } from "react/jsx-runtime";
import { Popover } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import CircleButton from 'components/CircleButton';
import { txt } from 'libs/i18n';
import UserMenu from './UserMenu';
import styles from './style.module.less';
import headerStyles from '../style.module.less';
const UserControls = () => (_jsx(Popover, { trigger: ['click'], content: _jsx(UserMenu, {}), overlayClassName: styles.popover, arrow: false, placement: "bottomRight", children: _jsx(CircleButton, { id: "user-profile-button", content: _jsx(UserOutlined, {}), tooltip: txt('userProfile'), tooltipPlacement: "bottomLeft", className: headerStyles.headerButton }) }));
export default UserControls;
