import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Collapse, Space, Form, InputNumber, Select, Switch, Tooltip, } from 'antd';
import { txt } from 'libs/i18n';
import { SubtitlesFormatSchema } from 'api/settings/user-settings';
import { useSession } from 'components/Authenticated';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS, SUBTITLE_LENGTH } from '../../CaptionReviewModal';
import styles from './style.module.less';
const DownloadSubtitles = ({ isCaptionMode, subtitleLength, form, additionalInfo, highlightingMode, isTutorialRunning, }) => {
    const { session } = useSession();
    const allSubtitlesAllowed = session.login.hasClaim('subtitles:all');
    const subtitlesFormat = Form.useWatch('subtitlesFormat', form);
    const formatNotSupportingFormatting = ['srt_FB', 'stl', 'ttml'];
    const handleChangeFormat = (format) => {
        form.setFieldValue('formatting', !formatNotSupportingFormatting.includes(format));
        if (format === 'srt' || format === 'srt_FB') {
            form.setFieldValue('unHighlightedColor', '#ffffff');
        }
    };
    const subtitlesChunked = (highlightingMode === 'OneWordChunks' || highlightingMode === 'HalfSecondChunks');
    return (_jsxs("div", { children: [_jsxs(Space, { children: [_jsx(InfoCircleOutlined, {}), txt(isCaptionMode ? 'subtitleRevised' : 'subtitleAutomatic')] }), !subtitlesChunked && (_jsx(Form.Item, { label: `${txt(isCaptionMode ? 'selectedSubtitleLength' : 'chooseLineLength')}:`, children: isCaptionMode ? (_jsx("span", { children: `${subtitleLength !== null && subtitleLength !== void 0 ? subtitleLength : -1} ${txt('charactersPerLine')}` })) : (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: "maxLineLength", label: `${txt('chooseLineLength')}:`, noStyle: true, required: true, rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: SUBTITLE_LENGTH.min,
                                    max: SUBTITLE_LENGTH.max,
                                    message: `${txt('range')} ${SUBTITLE_LENGTH.min} - ${SUBTITLE_LENGTH.max}`,
                                },
                            ], children: _jsx(InputNumber, { type: "number", autoFocus: true }) }), ' ', txt('charactersPerLine')] })) })), _jsx(Form.Item, { name: "subtitlesFormat", label: txt('chooseSubtFormat'), children: _jsx(Select, { autoFocus: isCaptionMode, onChange: (format) => handleChangeFormat(format), children: allSubtitlesAllowed ? (SubtitlesFormatSchema.values.map((format) => (_jsx(Select.Option, { value: format, children: format === 'srt_FB' ? txt('exportSrtFB') : format }, format)))) : (SubtitlesFormatSchema.values
                        .filter((format) => session.login.hasClaim(`subtitles:${format.split('_')[0]}`))
                        .map((format) => (_jsx(Select.Option, { value: format, children: format === 'srt_FB' ? txt('exportSrtFB') : format }, format)))) }) }), _jsx(Form.Item, { name: isCaptionMode ? 'formatting' : 'upperCaseAllText', label: txt(isCaptionMode ? 'includeColorAndPosition' : 'setAllCaps'), valuePropName: "checked", children: _jsx(Switch, { disabled: isCaptionMode && formatNotSupportingFormatting.includes(subtitlesFormat), size: "small" }) }), !isCaptionMode && (_jsx(Tooltip, { title: isTutorialRunning ? txt('disabledDuringTutorial') : null, placement: "left", children: _jsx(Collapse, { bordered: false, ghost: true, className: styles.advancedSettings, collapsible: isTutorialRunning ? 'disabled' : undefined, items: [{
                            key: '1',
                            label: txt('advancedSettings'),
                            // NOTE: Collapse has to be forceRendered to enable
                            // access to its form item initial values when closed.
                            forceRender: true,
                            children: (_jsxs(_Fragment, { children: [_jsx(Form.Item, { name: "speakerSignPlacement", label: txt('speakerSignPlacement'), children: _jsx(Select, { options: [
                                                {
                                                    value: 'utteranceStartOnly',
                                                    label: txt('utteranceStartOnly'),
                                                },
                                                {
                                                    value: 'multiSpeakerCaptionsOnly',
                                                    label: txt('multiSpeakerCaptionsOnly'),
                                                },
                                                {
                                                    value: 'none',
                                                    label: txt('noPlacement'),
                                                },
                                            ] }) }), _jsx(Form.Item, { name: "pauseBetweenCaptions", label: txt('pauseBetweenCaptions'), children: _jsx(Select, { options: [
                                                {
                                                    value: 0,
                                                    label: '0 ms',
                                                },
                                                {
                                                    value: 0.08,
                                                    label: '80 ms',
                                                },
                                            ] }) }), _jsxs(Form.Item, { label: txt('autofillPauseBetweenCaptions'), tooltip: {
                                            title: `${txt('autofillPauseBetweenCaptionsInfo')} ${txt('range')}\u00A0${AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.min}\u00A0-\u00A0${AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.max}`,
                                            icon: _jsx(InfoCircleOutlined, {}),
                                        }, children: [_jsx(Form.Item, { name: "autofillPauseBetweenCaptionsMs", noStyle: true, rules: [
                                                    {
                                                        type: 'number',
                                                        min: AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.min,
                                                        max: AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.max,
                                                        message: `${txt('range')} ${AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.min} - ${AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.max} ms`,
                                                    },
                                                ], children: _jsx(InputNumber, { precision: 0, step: 10 }) }), ' ms'] })] })),
                        }] }) })), (isCaptionMode && additionalInfo !== null) && (_jsx(Alert, { className: styles.formatAlert, type: "info", showIcon: true, message: additionalInfo }))] }));
};
export default DownloadSubtitles;
