import EventEmitter from 'events';
export default class ProjectStateHandler {
    constructor(messageStream, status) {
        this.eventEmitter = new EventEmitter();
        this.messageStream = messageStream;
        this.status = status;
        this.streamListeners = [];
    }
    addEventListener(event, listener) {
        this.eventEmitter.on(event, listener);
    }
    removeEventListener(event, listener) {
        this.eventEmitter.off(event, listener);
    }
    addStreamListener(subsystem, type, listener) {
        this.messageStream.addMessageListener(subsystem, type, listener);
        this.streamListeners.push({ subsystem, type, listener });
    }
    clearStreamListeners() {
        this.streamListeners.forEach(({ subsystem, type, listener }) => {
            this.messageStream.removeMessageListener(subsystem, type, listener);
        });
    }
    destroy() {
        this.clearStreamListeners();
    }
    getStatus() {
        return this.status;
    }
    updateStatus(status) {
        if (this.status !== status) {
            const oldStatus = this.status;
            this.status = status;
            this.eventEmitter.emit('status-update', status, oldStatus);
        }
    }
}
