import { escapeXMLString } from 'libs/xml-utils';
export default class TrsxSection {
    constructor(name, tags, id) {
        this.name = name;
        this.paragraphs = [];
        this.tags = tags;
        this.summary = '';
        this.id = id;
    }
    addParagraph(paragraph) {
        this.paragraphs.push(paragraph);
    }
    addSummary(summary) {
        this.summary += `${summary}\\n`;
    }
    serialize() {
        const escapedName = escapeXMLString(this.name);
        const tagsAttribute = this.tags.length > 0 ? ` tags="${escapeXMLString(this.tags.join('#'))}"` : '';
        const summaryAttribute = this.summary !== '' ? ` summary="${escapeXMLString(this.summary)}"` : '';
        const idAttribute = this.id !== null && this.id !== '' ? ` id="${escapeXMLString(this.id)}"` : '';
        const header = `    <se name="${escapedName}"${summaryAttribute}${tagsAttribute}${idAttribute}>\n`;
        const inner = this.paragraphs.map((pa) => pa.serialize()).join('');
        const footer = '    </se>\n';
        return header + inner + footer;
    }
}
