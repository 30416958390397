import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import IconButton from 'components/IconButton';
import { DownOutlined, UpOutlined, CloseOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import ActiveItemView from './ActiveItemView';
import QueueItemView from './QueueItemView';
import { useDashboard, useUploadQueue } from '../dashboard-context';
import styles from './style.module.less';
const beforeUnloadHandler = (e) => {
    e.preventDefault();
    // Users never see this message, but there needs to be a string
    // eslint-disable-next-line no-param-reassign
    e.returnValue = 'There are unfinished uploads. Leaving the page will cancel them.';
};
const UploadOverlay = () => {
    const { uploadQueueController } = useDashboard();
    const uploadQueue = useUploadQueue();
    const [isExpanded, setIsExpanded] = useState(true);
    useEffect(() => {
        if (uploadQueue.isRunning) {
            window.addEventListener('beforeunload', beforeUnloadHandler);
        }
        else {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        }
    }, [uploadQueue.isRunning]);
    if (uploadQueue.totalCount === 0) {
        return null;
    }
    const header = `${txt('uploaded')} ${uploadQueue.successCount}/${uploadQueue.totalCount}`;
    return (_jsxs("div", { className: styles.uploadOverlay, children: [_jsxs("div", { className: styles.uploadOverlayHeader, children: [_jsx("span", { children: header }), _jsxs("div", { children: [_jsx(IconButton, { onClick: () => setIsExpanded(!isExpanded), children: isExpanded ? _jsx(DownOutlined, {}) : _jsx(UpOutlined, {}) }), _jsx(IconButton, { onClick: () => uploadQueueController.clear(), disabled: uploadQueue.remainingCount > 0, children: _jsx(CloseOutlined, {}) })] })] }), isExpanded ? (_jsxs("div", { className: styles.uploadOverlayBody, children: [uploadQueue.finished.map((item) => (_jsx(QueueItemView, { item: item }, item.id))), _jsx(ActiveItemView, { item: uploadQueue.active }), uploadQueue.queued.map((item) => (_jsx(QueueItemView, { item: item }, item.id)))] })) : null] }));
};
export default UploadOverlay;
