import { fetchLastError } from 'api/project-api';
import ProjectStateHandler from './project-state-handler';
const isFalseAlarm = (message) => {
    // INTERIM - backend fires false alarms for some recordings.
    // see https://trello.com/c/T9NauiUp/1528
    // final fix: see https://trello.com/c/In6SrIKk/1819
    // Ignore such messages.
    if (message.subsystem === 'MediaFileIndexing') {
        return true;
    }
    return false;
};
export default class ProjectErrorHandler extends ProjectStateHandler {
    constructor(messageStream, onProjectUpdated) {
        super(messageStream, 'no-error');
        this.handleFirstFailure = async (message) => {
            if (isFalseAlarm(message)) {
                global.logger.warn('ignoring message', message);
                return;
            }
            this.onProjectUpdated(); // needed to update processing state
            await this.updateServerError();
            this.clearStreamListeners();
            global.logger.error('first project failure', {
                message: JSON.stringify(message),
                error: JSON.stringify(this.status),
            });
        };
        this.onProjectUpdated = onProjectUpdated;
        this.addStreamListener('*', 'Failed', this.handleFirstFailure);
    }
    projectReloaded(project) {
        if (project.processingState === 'None') {
            this.updateStatus({ category: 'Client', reason: 'MissingRecording' });
        }
        else if (project.processingState === 'Failed') {
            void this.updateServerError();
        }
        else if (project.processingState === 'Canceled') {
            this.updateStatus({ category: 'General', reason: 'Canceled' });
        }
    }
    async updateServerError() {
        if (this.status !== 'no-error') {
            return;
        }
        const error = await fetchLastError(this.messageStream.connection, this.messageStream.projectId);
        this.updateStatus(error !== null && error !== void 0 ? error : 'no-error');
    }
}
