import Quill from 'quill';
import { txt } from 'libs/i18n';
const BlockBlot = Quill.import('blots/block');
export const valueToClasses = (value) => {
    if (value === null) {
        return '';
    }
    const sectionIdClass = value.id !== null ? `id-${value.id}` : '';
    const tagNameClasses = value.tags
        .map((tag) => `tagname-${tag}`)
        .join(' ');
    return `section ${tagNameClasses} ${sectionIdClass}`;
};
const valueToLabel = (value) => {
    if (value === null) {
        return '';
    }
    if (value.tags.length === 0) {
        return `+ ${txt('addTags')}`;
    }
    const label = value.tags
        .map((text) => `#${decodeURIComponent(text)}`)
        .join(' ');
    return label;
};
const classesToValue = (classes) => {
    const classNames = classes.split(' ');
    if (!classNames.includes('section')) {
        return null;
    }
    const tags = classNames
        .filter((className) => className.startsWith('tagname-'))
        .map((className) => className.substring('tagname-'.length, className.length));
    const idFromClass = classNames.filter((className) => className.startsWith('id-'));
    const id = idFromClass.length === 0 ? '' : idFromClass[0].split('-')[1];
    return { tags, id };
};
const formatNode = (node, value) => {
    // eslint-disable-next-line no-param-reassign
    node.className = valueToClasses(value);
    // eslint-disable-next-line no-param-reassign
    node.dataset.tags = valueToLabel(value);
};
class SectionBlot extends BlockBlot {
    static create(value) {
        const node = super.create(value);
        formatNode(node, value);
        return node;
    }
    static formats(domNode) {
        return classesToValue(domNode.className);
    }
    format(name, value) {
        if (name === 'section' && Boolean(value)) {
            formatNode(this.domNode, value);
        }
        else {
            super.format(name, value);
        }
    }
    formats() {
        const formats = super.formats();
        formats.section = SectionBlot.formats(this.domNode);
        return formats;
    }
    attach() {
        if (this.parent != null) {
            this.scroll = this.parent.scroll;
        }
    }
}
SectionBlot.blotName = 'section';
SectionBlot.tagName = 'h2';
export default SectionBlot;
