import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Divider, Spin, Tabs } from 'antd';
import { fetchAllTranscriptionOptions } from 'api/admin/information-api';
import { listApps } from '@newtontechnologies/beey-api-js-client/apps-api';
import { editorClaimList, subtitlesClaimList, } from 'api/user-claims';
import { useSession } from 'components/Authenticated';
import { txt, localeTxt } from 'libs/i18n';
import LanguageProfiles from './LanguageProfiles';
import styles from './style.module.less';
import ClaimsTable from './ClaimsTable';
import ClaimsBulkChange from './ClaimsBulkChange';
const UserClaims = ({ userId, claimSet, onRefetchClaims }) => {
    const { session } = useSession();
    const [appList, setAppList] = useState('loading');
    const [transcriptionOptions, setTranscriptionOptions,] = useState('loading');
    const getAppsList = async () => {
        try {
            const apps = await listApps(session.connection);
            const receivedAppList = apps.list.map((app) => ({
                title: `App ${app.name}`,
                apiName: `apps:${app.name}`,
            }));
            setAppList(receivedAppList);
        }
        catch (error) {
            global.logger.error('Failed to get apps list', {}, error);
        }
    };
    const getTranscriptionOptions = async () => {
        try {
            const options = await fetchAllTranscriptionOptions(session.connection);
            setTranscriptionOptions(options.sort((opt1, opt2) => (localeTxt(opt1.locale).localeCompare(localeTxt(opt2.locale)))));
        }
        catch (error) {
            global.logger.error('Failed to get transcription profiles list', {}, error);
        }
    };
    useEffect(() => {
        void getAppsList();
        void getTranscriptionOptions();
    }, []);
    if (claimSet === 'loading' || appList === 'loading' || transcriptionOptions === 'loading') {
        return _jsx(Spin, { className: styles.claimsSpin });
    }
    const allTranscriptionProfiles = transcriptionOptions.flatMap((option) => (option.profiles));
    const tabPanes = [
        {
            key: '1',
            label: txt('edit'),
            children: (_jsx(ClaimsTable, { claimsList: editorClaimList, claimSet: claimSet, userId: userId, onRefetchClaims: onRefetchClaims })),
        },
        {
            key: '2',
            label: txt('subtitles'),
            children: (_jsx(ClaimsTable, { claimsList: subtitlesClaimList, claimSet: claimSet, userId: userId, onRefetchClaims: onRefetchClaims })),
        },
        {
            key: '3',
            label: txt('apps'),
            children: (_jsx(ClaimsTable, { claimsList: appList, claimSet: claimSet, userId: userId, onRefetchClaims: onRefetchClaims })),
        },
        {
            key: '4',
            label: txt('transcribers'),
            children: (_jsxs(_Fragment, { children: [session.login.hasClaim('admin:superadmin') ? (_jsxs("div", { children: [_jsxs("span", { className: styles.title, children: [txt('allTranscriptionProfiles'), ":"] }), _jsx("div", { className: styles.switch, children: _jsx(ClaimsBulkChange, { claimSet: claimSet, claimsList: allTranscriptionProfiles.map((profile) => ({
                                        title: `${profile.lang} ${String(profile.tags[0])}`,
                                        apiName: profile.claim,
                                        tags: [profile.tags[0]],
                                    })), userId: userId, onRefetchClaims: onRefetchClaims }) })] })) : null, _jsx(Divider, {}), transcriptionOptions.map((option) => (_jsx(LanguageProfiles, { locale: option.locale, claimSet: claimSet, profiles: option.profiles, userId: userId, onRefetchClaims: onRefetchClaims }, option.locale.code)))] })),
        },
    ];
    return (_jsx(Tabs, { defaultActiveKey: "1", items: tabPanes }));
};
export default UserClaims;
