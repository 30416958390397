import { changeCapitalLetter } from 'libs/quill-utils';
export default class QuillBindings {
    constructor(editorController) {
        this.getIndexRelativeToSummary = (index, direction) => {
            const documentLength = this.editorController.getLength();
            let i = direction === 'after' ? index + 1 : index - 1;
            const isValidIndex = (indexNumber) => {
                if (direction === 'after') {
                    return index < documentLength;
                }
                return indexNumber >= 0;
            };
            while (isValidIndex(i)) {
                if (this.editorController.getLineFormat(i).summary === undefined) {
                    return i;
                }
                i = direction === 'after' ? i += 1 : i -= 1;
            }
            return index;
        };
        this.deleteNonEditableBlock = (index) => {
            if (this.editorController.quill === undefined) {
                throw Error('editorController.quill is undefined');
            }
            const block = this.editorController.quill.getLeaf(index)[0].parent;
            this.editorController.deleteText(this.editorController.getIndex(block), block.length() + 1, 'user');
        };
        this.enterHandler = (range) => {
            const formats = this.editorController.getLineFormat(range.index);
            const nextFormats = this.editorController.getLineFormat(range.index + 1);
            // NOTE: Suppress enter if pressed with focus inside content-editable false.
            if (!this.editorController.isEditable(formats))
                return false;
            // NOTE: Insert empty space after summary.
            if (nextFormats.summary !== undefined && formats.summary === undefined) {
                const indexAfterSummary = this.getIndexRelativeToSummary(range.index, 'after');
                this.editorController.insertTextWithFormat(indexAfterSummary, '\n', {
                    header: null, section: false, speaker: null, summary: null,
                }, 'user');
                this.editorController.setSelection(indexAfterSummary, 0);
                return false;
            }
            if ((this.editorController.isNonTranscriptFormat(formats)) && this.editorController.getText(range.index, 1) === '\n') {
                // NOTE: Fixes bug: enter at the end of a heading removed formatting of the heading.
                this.editorController.insertTextWithFormat(range.index + 1, '\n', {
                    header: null, section: false, speaker: null, summary: formats.summary !== undefined ? 'nobutton' : null,
                }, 'user');
                this.editorController.setSelection(range.index + 1, 0);
                return false;
            }
            if ((this.editorController.isNonTranscriptFormat(formats))) {
                this.editorController.insertTextWithFormat(range.index, '\n', formats, 'user');
                this.editorController.setSelection(range.index + 1, 0);
                return false;
            }
            return true;
        };
        this.deleteRange = (range, currentFormats) => {
            if (this.editorController.quill === undefined) {
                return false;
            }
            const startLine = this.editorController.quill.getLine(range.index)[0];
            const endLine = this.editorController.quill.getLine(range.index + range.length)[0];
            if (startLine === endLine && !this.editorController.isEditable(currentFormats)) {
                this.editorController.deleteText(this.editorController.getIndex(startLine), startLine.length() + 1, 'user');
                return false;
            }
            if (!this.editorController.isEditable(currentFormats)) {
                // NOTE: Prevents partial deletion of speaker.
                return false;
            }
            return true;
        };
        this.deleteHandler = (range) => {
            const prevFormats = this.editorController.getLineFormat(range.index - 1);
            const nextFormats = this.editorController.getLineFormat(range.index + 1);
            const currentFormats = this.editorController.getLineFormat(range.index);
            const prevChar = this.editorController.getText(range.index - 1, 1);
            const nextChar = this.editorController.getText(range.index, 1);
            if (range.length > 0) {
                return this.deleteRange(range, currentFormats);
            }
            if (!this.editorController.isEditable(nextFormats)) {
                if (prevChar === '\n' && this.editorController.isEditable(prevFormats)) {
                    this.editorController.deleteText(range.index - 1, 1);
                    return false;
                }
                this.deleteNonEditableBlock(range.index + 1);
                this.editorController.setSelection(range.index, 0, 'user');
                return false;
            }
            const isNextFormatHeading = nextFormats.header !== undefined
                || nextFormats.section !== undefined;
            const isCurrentFormatHeading = currentFormats.header !== undefined
                || currentFormats.section !== undefined;
            if (isNextFormatHeading
                && (isNextFormatHeading !== isCurrentFormatHeading)
                && nextChar === '\n') {
                if (prevChar === '\n') {
                    this.editorController.deleteText(range.index - 1, 1);
                    return false;
                }
                // NOTE: Prevents accidental merging of the paragraph and the heading.
                this.editorController.deleteText(range.index + 1, 1, 'user');
                this.editorController.setSelection(range.index + 1, 0, 'user');
                return false;
            }
            // NOTE: Prevents merging of summary with different formats.
            if (currentFormats.summary !== undefined && (nextChar === '\n' && nextFormats.summary === undefined))
                return false;
            if (nextFormats.summary !== undefined && currentFormats.summary === undefined) {
                if (prevChar === '\n') {
                    this.editorController.deleteText(range.index - 1, 1);
                    return false;
                }
                if (range.index >= this.editorController.getLength() - 2)
                    return true;
                const indexAfterSummary = this.getIndexRelativeToSummary(range.index, 'after');
                // NOTE: Deletes whole uneditable block (speaker or section) after summary
                if (!this.editorController.isEditable(this.editorController.getLineFormat(indexAfterSummary))) {
                    this.deleteNonEditableBlock(indexAfterSummary);
                    this.editorController.setSelection(indexAfterSummary, 0, 'user');
                    return false;
                }
                this.editorController.deleteText(indexAfterSummary, 1, 'user');
                this.editorController.setSelection(indexAfterSummary, 0, 'user');
                return false;
            }
            // NOTE: Prevents accidental merging of the paragraph and the heading.
            if (isCurrentFormatHeading && !isNextFormatHeading) {
                if (nextChar === '\n') {
                    this.editorController.deleteText(range.index + 1, 1, 'user');
                    this.editorController.setSelection(range.index + 1, 0, 'user');
                    return false;
                }
                this.editorController.deleteText(range.index + 1, 1, 'user');
                this.editorController.setSelection(range.index + 1, 0, 'user');
                return false;
            }
            if (nextChar === '.' || nextChar === '?' || nextChar === '!') {
                changeCapitalLetter(this.editorController.quill, range.index + 1, true);
            }
            return true;
        };
        this.backspaceHandler = (range) => {
            const prevFormats = this.editorController.getLineFormat(range.index - 1);
            const currentFormats = this.editorController.getLineFormat(range.index);
            const nextFormats = this.editorController.getLineFormat(range.index + 1);
            const prevChar = this.editorController.getText(range.index - 1, 1);
            const nextChar = this.editorController.getText(range.index, 1);
            const isPrevFormatHeading = prevFormats.header !== undefined
                || prevFormats.section !== undefined;
            const isCurrentFormatHeading = currentFormats.header !== undefined
                || currentFormats.section !== undefined;
            if (range.length > 0) {
                return this.deleteRange(range, currentFormats);
            }
            if (!this.editorController.isEditable(prevFormats)) {
                if (nextChar === '\n'
                    && range.index < this.editorController.getLength() - 1
                    && this.editorController.isEditable(nextFormats)) {
                    // NOTE: Deletes empty line.
                    this.editorController.deleteText(range.index, 1);
                    return false;
                }
                this.deleteNonEditableBlock(range.index - 1);
                return false;
            }
            if (isPrevFormatHeading
                && (isPrevFormatHeading !== isCurrentFormatHeading)
                && prevChar === '\n') {
                if (nextChar === '\n') {
                    this.editorController.deleteText(range.index, 1);
                    return false;
                }
                // NOTE: Prevent merging of summary with different formats.
                if (currentFormats.summary !== undefined)
                    return false;
                // NOTE: Prevents accidental merging of the paragraph and the heading.
                if (range.index <= 1)
                    return true;
                this.editorController.deleteText(range.index - 2, 1, 'user');
                this.editorController.setSelection(range.index - 2, 0, 'user');
                return false;
            }
            if (prevFormats.summary !== undefined && currentFormats.summary === undefined) {
                if (nextChar === '\n') {
                    this.editorController.deleteText(range.index, 1);
                    return false;
                }
                if (range.index <= 1)
                    return true;
                const indexBeforeSummary = this.getIndexRelativeToSummary(range.index, 'before');
                this.editorController.deleteText(indexBeforeSummary, 1, 'user');
                this.editorController.setSelection(range.index - 2, 0, 'user');
                return false;
            }
            if (prevChar === '.' || prevChar === '?' || prevChar === '!') {
                changeCapitalLetter(this.editorController.quill, range.index, true);
            }
            return true;
        };
        this.editorController = editorController;
    }
    /* creates bindings for quill keyboard module. They are useful for disabling or partially
       overriding default quill shortcuts.
    */
    getBindings() {
        return {
            undo: {
                key: 'z',
                shortKey: true,
                handler: () => false,
            },
            redo: {
                key: 'z',
                shortKey: true,
                shiftKey: true,
                handler: () => false,
            },
            altredo: {
                key: 'y',
                shortKey: true,
                handler: () => false,
            },
            bold: {
                key: 'b',
                shortKey: true,
                handler: () => false,
            },
            italic: {
                key: 'i',
                shortKey: true,
                handler: () => false,
            },
            underline: {
                key: 'u',
                shortKey: true,
                // on Mac it underlines the word, it does not return false
                handler: () => false,
            },
            tab: {
                key: 9,
                handler: () => false,
            },
            suppress_enter: {
                key: 'enter',
                shiftKey: false,
                handler: this.enterHandler,
            },
            add_speaker: {
                key: 'enter',
                shiftKey: true,
                handler: () => false,
            },
            delete_override: {
                key: 'delete',
                ctrlKey: null,
                shiftKey: null,
                handler: this.deleteHandler,
            },
            backspace_override: {
                key: 'backspace',
                ctrlKey: null,
                shiftKey: null,
                handler: this.backspaceHandler,
            },
        };
    }
}
