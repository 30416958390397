import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createAppLink } from 'api/model/services/services-api';
import { fragileTxt } from 'libs/i18n';
import { AppIcon } from 'components/CustomIcons';
import styles from './style.module.less';
const getAppDescription = (endpoint) => {
    const nameWithoutSlash = endpoint.substring(1).replace(/[^a-zA-Z]/g, '');
    const description = fragileTxt(`${nameWithoutSlash}Description`);
    return description !== null && description !== void 0 ? description : '';
};
const AppCard = ({ name, endpoint }) => {
    const appLink = createAppLink(endpoint);
    return (_jsxs("div", { className: styles.appCard, children: [_jsx(AppIcon, { className: styles.icon }), _jsxs("div", { children: [_jsx("a", { className: styles.cardName, href: appLink, target: "_blank", rel: "noreferrer", children: name }), _jsx("div", { children: getAppDescription(endpoint) })] })] }));
};
export default AppCard;
