import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Popover, Radio } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import * as clientEnv from 'libs/client-env';
import { txt } from 'libs/i18n';
import './style.less';
const { Group } = Radio;
const EditorWidthSelector = ({ onEditorWidthSelected, children, popoverVisible, onPopoverVisible, }) => {
    const handleWidthSelectClick = ({ target }) => {
        onEditorWidthSelected(target.value);
        clientEnv.setEditorWidth(target.value);
    };
    const popoverTitle = () => (_jsxs("div", { className: "editor-width-title", children: [_jsx("h4", { children: txt('editorWidthTitle') }), _jsx("button", { type: "button", onClick: () => onPopoverVisible(false), children: _jsx(CloseOutlined, {}) })] }));
    const popoverContent = () => (_jsxs(Group, { className: "editor-width-selector", onChange: handleWidthSelectClick, defaultValue: clientEnv.getEditorWidth(), size: "small", children: [_jsx(Radio.Button, { value: "auto", children: txt('editorWidthAuto') }), _jsx(Radio.Button, { value: "narrow", children: txt('editorWidthNarrow') }), _jsx(Radio.Button, { value: "medium", children: txt('editorWidthMedium') }), _jsx(Radio.Button, { value: "wide", children: txt('editorWidthWide') })] }));
    return (_jsx(Popover, { content: popoverContent, title: popoverTitle, trigger: "click", placement: "rightTop", open: popoverVisible, onOpenChange: (openChange) => onPopoverVisible(openChange), children: children }));
};
export default EditorWidthSelector;
