import { Mutex } from 'async-mutex';
import RecognitionHandler from 'libs/media-processing/recognition-handler';
import Delta from 'quill-delta';
import { capitalizeWord, trimStart } from './text-utils';
export class Recognition {
    constructor(editorController, messageStream, onHistoryLoaded) {
        this.wasHistoryLoaded = false;
        this.handleTranscriptionComplete = async () => {
            // handleMessage ensures that this is executed after handling all messages
            await this.handleMessage(() => {
                this.flushPhraseBuffer();
                this.editorController.lastHandledPhraseTime = Infinity;
                this.editorController.lastHandledMessageId = Infinity;
                this.editorController.documentAligner.finalizePhrases(this.editorController.playback.duration);
                this.editorController.timestampLabels.update(0, this.editorController.getLength());
                this.cleanupEditor();
            }, []);
        };
        this.destroy = () => {
            this.recognitionHandler.destroy();
        };
        this.shouldSkipMessage = ({ id }) => (id <= this.editorController.lastHandledMessageId);
        this.handleMessage = async (handler, params) => {
            const release = await this.messageMutex.acquire();
            try {
                await handler(...params);
            }
            catch (error) {
                global.logger.error('handling message failed', {}, error);
            }
            finally {
                release();
            }
        };
        this.handleAddWord = (word, wsMessage) => {
            if (this.shouldSkipMessage(wsMessage)) {
                return;
            }
            this.editorController.lastHandledMessageId = wsMessage.id;
            this.editorController.lastHandledPhraseTime = word.end;
            this.phraseBuffer.push(word);
            if (wsMessage.source === 'socket') {
                this.flushPhraseBuffer();
                if (!this.wasHistoryLoaded) {
                    this.onHistoryLoaded();
                }
                this.wasHistoryLoaded = true;
            }
        };
        this.handleAddSpeaker = (diarizationCode, wsMessage) => {
            if (this.shouldSkipMessage(wsMessage)) {
                return;
            }
            this.flushPhraseBuffer();
            this.editorController.speakers.createSpeakerOnEnd(diarizationCode);
        };
        this.handleSpeakerId = (xmlSpeaker, wsMessage) => {
            if (this.shouldSkipMessage(wsMessage)) {
                return;
            }
            this.editorController.speakers.speakerIdentification.fixTemporarySpeaker(xmlSpeaker);
        };
        this.cleanupEditor = () => {
            const lastCharacterIndex = this.editorController.getLength() - 1;
            const lastButOneBlot = this.editorController.getBlock(lastCharacterIndex).prev;
            const isLastButOneIndexParagraphEnd = this.editorController.getText(lastCharacterIndex - 1, 1) === '\n';
            // Removing redundant last speaker except if there is only one speaker
            if (lastButOneBlot !== null
                && lastButOneBlot.domNode.tagName === 'H4'
                && isLastButOneIndexParagraphEnd) {
                this.editorController.execTextChange({ requestSave: false }, () => {
                    this.editorController.speakers.removeSpeaker(lastButOneBlot, 'api');
                    // NOTE: delete also the line end left behind by the speaker
                    this.editorController.deleteText(this.editorController.getLength() - 1, 1, 'api');
                });
            }
        };
        this.editorController = editorController;
        this.recognitionHandler = new RecognitionHandler(messageStream);
        this.onHistoryLoaded = onHistoryLoaded;
        this.messageMutex = new Mutex();
        this.phraseBuffer = [];
        this.recognitionHandler.addRecognitionListener('word', (...params) => this.handleMessage(this.handleAddWord, params));
        this.recognitionHandler.addRecognitionListener('speaker', (...params) => this.handleMessage(this.handleAddSpeaker, params));
        this.recognitionHandler.addRecognitionListener('speaker-id', (...params) => this.handleMessage(this.handleSpeakerId, params));
    }
    flushPhraseBuffer() {
        const wordsToAdd = this.phraseBuffer.splice(0, this.phraseBuffer.length);
        this.addWords(wordsToAdd);
    }
    fixFirstWord(event) {
        let text = capitalizeWord(event.text).word;
        text = trimStart(text);
        // eslint-disable-next-line no-param-reassign
        event.text = text;
    }
    addWords(events) {
        if (events.length === 0)
            return;
        this.editorController.execTextChange({ updateMetadata: false, runAligner: false, requestSave: false }, () => {
            const documentLength = this.editorController.getLength();
            const isFirstInLine = this.editorController.getText(this.editorController.getLength() - 2, 1) === '\n';
            if (documentLength <= 1 || isFirstInLine) {
                this.fixFirstWord(events[0]);
            }
            const delta = new Delta();
            if (documentLength > 1) {
                // insert before the terminating line end.
                // Avoid retain of length 0 - it causes quill to crash.
                delta.retain(documentLength - 1);
            }
            let timestampIndex = documentLength - 2; // index of the last letter of the last line
            for (let i = 0; i < events.length; i += 1) {
                const { begin, end, text, isNoise, } = events[i];
                this.editorController.documentAligner.loadPhrase(text, begin, end);
                if (!isNoise && text.length > 0) {
                    delta.insert(text, {
                        speaker: false,
                    });
                    timestampIndex += text.length;
                    this.editorController.textMetadata.addTimestampAtEndOnIndex(timestampIndex, begin, end);
                }
            }
            this.editorController.updateContents(delta, 'api');
        });
    }
}
