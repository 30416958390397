import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Card, Tooltip } from 'antd';
import AddButton from 'components/AddButton';
import { CloseOutlined, CrownOutlined, UserOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { fetchManagementGroups } from 'api/admin/management-groups-api';
import { useSession } from 'components/Authenticated';
import { useAppContext, screenBreakpoints } from 'App/app-context';
import { getUiLocales } from 'libs/locales/ui';
import { localeTxt, sortedLocales, txt } from 'libs/i18n';
import ColumnHeader from 'components/ColumnHeader';
import ResponsiveTable, { useTableView, empiricalScrollHeightAdjust, } from 'components/ResponsiveTable';
import dayjs from 'dayjs';
import SearchInput from 'components/SearchInput';
import searchStyles from 'components/SearchInput/style.module.less';
import { fetchAllUsers } from 'api/admin/users-api';
import NewUserForm from './NewUserForm';
import TeamIndicator from './TeamIndicator/indext';
import { useDashpage } from '../../../components/DashboardPage/dashpage-context';
import styles from './style.module.less';
const locales = sortedLocales(getUiLocales());
const formatDate = (date) => dayjs(date).format('DD. MM. YYYY');
const formatDateWithTime = (date) => dayjs(date).format('DD. MM. YYYY HH:mm');
const USERS_TABLE_ID = 'users-table'; // Must be unique across all tables in Beey!!
const SCROLL_HEIGHT_ADJUST = empiricalScrollHeightAdjust(205);
const UsersSubpage = () => {
    var _a, _b, _c;
    const { contentHeight } = useDashpage();
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState('loading');
    const [newUserFormVisible, setNewUserFormVisible] = useState(false);
    const [highlightedText, setHighlightedText] = useState(null);
    const { session } = useSession();
    const { screenSize } = useAppContext();
    const { state } = useLocation();
    const groupId = (state === null || state === void 0 ? void 0 : state.groupId) === undefined ? null : [state.groupId];
    const defaultSieve = {
        filterDates: [],
        sortBy: 'email',
        sortOrder: 'ascend',
        substr: '',
        filters: {
            role: null,
            language: null,
            groupId,
            email: null,
        },
    };
    const tableView = useTableView(USERS_TABLE_ID, {
        sieve: defaultSieve,
    });
    const sortByParams = (sortBy, sortOrder, substr, filters) => {
        tableView.setSieve({
            sortBy, sortOrder, substr, filters, filterDates: [],
        });
    };
    const { sortBy, sortOrder, filters, substr, } = (_a = tableView.getViewParams().sieve) !== null && _a !== void 0 ? _a : defaultSieve;
    const oldSieve = tableView.getViewParams().sieve;
    useEffect(() => {
        const fetchManagementGroupsState = async () => {
            const response = await fetchManagementGroups(session.connection);
            setGroups(response.list);
        };
        if (session.login.hasClaim('admin:superadmin')) {
            void fetchManagementGroupsState();
        }
        tableView.setSieve(Object.assign(Object.assign({}, oldSieve), { filters: Object.assign(Object.assign({}, filters), { groupId }) }));
    }, []);
    const [substrEmail, setSubstrEmail] = useState((_b = tableView.getViewParams().sieve) === null || _b === void 0 ? void 0 : _b.substr);
    const fetchUsers = async () => {
        var _a, _b, _c, _d, _e;
        const { currentPage, pageSize } = tableView.getViewParams();
        const groupIds = (_b = (_a = filters === null || filters === void 0 ? void 0 : filters.groupId) === null || _a === void 0 ? void 0 : _a.map((id) => Number(id))) !== null && _b !== void 0 ? _b : null;
        const usersList = await fetchAllUsers(session.connection, {
            skip: pageSize * (currentPage - 1),
            count: pageSize,
            orderby: sortBy,
            order: sortOrder === 'ascend' ? 'ascending' : 'descending',
            substr,
            role: (_c = filters === null || filters === void 0 ? void 0 : filters.role) !== null && _c !== void 0 ? _c : undefined,
            language: (_d = filters === null || filters === void 0 ? void 0 : filters.language) !== null && _d !== void 0 ? _d : undefined,
            groupId: groupIds,
            email: (_e = filters === null || filters === void 0 ? void 0 : filters.email) !== null && _e !== void 0 ? _e : undefined,
        });
        setUsers(usersList);
        if (usersList.listedCount === 0) {
            setHighlightedText(null);
        }
        else {
            setHighlightedText(substrEmail !== null && substrEmail !== void 0 ? substrEmail : null);
        }
    };
    const newUserCreated = () => {
        setNewUserFormVisible(false);
        void fetchUsers();
    };
    const highlightSubstr = (string, substring) => {
        // NOTE: Email search is case insensitive. To compare the email with the searched substring,
        // it has to be converted both to upper or lower case.
        const upperCaseSubstr = substring.toUpperCase();
        const startSubstr = string.toUpperCase().indexOf(upperCaseSubstr);
        const endSubstr = startSubstr + upperCaseSubstr.length;
        return (_jsxs("span", { children: [string.substring(0, startSubstr), _jsx("span", { className: "highlightSubstr", children: string.substring(startSubstr, startSubstr + upperCaseSubstr.length) }), string.substring(endSubstr)] }));
    };
    const handleInputChange = ({ target }) => {
        if (target.value === '') {
            sortByParams('email', 'ascend', target.value, filters);
        }
        setSubstrEmail(target.value.trim());
        setHighlightedText(null);
    };
    const handleEmailSearch = (searchedSubstr) => {
        if (searchedSubstr.length >= 2) {
            tableView.setCurrentPage(1);
            tableView.setSieve(Object.assign(Object.assign({}, oldSieve), { substr: searchedSubstr, filterDates: [] }));
        }
    };
    const handleSort = (columnTitle, sortingOrder) => {
        tableView.setSieve({
            sortBy: columnTitle,
            sortOrder: sortingOrder,
            filters,
            substr,
            filterDates: [],
        });
    };
    const columns = [
        {
            title: (_jsx(ColumnHeader, { title: "email", sortOrder: sortOrder, isSorting: sortBy === 'email', onSort: handleSort, columnKey: "email" })),
            key: 'email',
            fixed: 'left',
            className: 'email-col',
            width: '25%',
            filterMultiple: false,
            filters: [
                { text: txt('multiTeamOwner'), value: true },
            ],
            filteredValue: (filters === null || filters === void 0 ? void 0 : filters.email) || null,
            onFilter: (value, user) => {
                return user.isTeamOwner === value && user.teamMemberCount > 1;
            },
            render: (user) => (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: `User id: ${user.id} | Team id: ${user.teamId}`, children: _jsx(UserOutlined, {}) }), _jsx(Link, { className: styles.userLink, to: `users/edituser/${user.id}`, children: highlightedText !== null && highlightedText !== '' ? highlightSubstr(user.email, highlightedText) : user.email }), user.teamMemberCount > 1 && user.isTeamOwner
                        ? (_jsx(Tooltip, { title: txt('multiTeamOwner'), children: _jsx(CrownOutlined, {}) })) : null, user.isTeamOwner ? null : _jsx(TeamIndicator, { teamId: user.teamId })] })),
        },
        {
            title: txt('group'),
            align: 'left',
            width: '10%',
            key: 'groupId',
            filters: groups.map((group) => ({ text: group.groupName, value: group.id })),
            filterMultiple: false,
            filteredValue: (filters === null || filters === void 0 ? void 0 : filters.groupId) || null,
            render: (user) => (user.managementGroupName),
        },
        {
            title: txt('usersLanguage'),
            align: 'left',
            width: '10%',
            key: 'language',
            filters: locales.map((locale) => ({ text: localeTxt(locale), value: locale.code })),
            filterMultiple: false,
            filteredValue: (filters === null || filters === void 0 ? void 0 : filters.language) || null,
            render: (user) => user.uiLocale.lang,
        },
        {
            title: (_jsx(ColumnHeader, { title: "credit", sortOrder: sortOrder, isSorting: sortBy === 'credit', onSort: handleSort, columnKey: "credit" })),
            className: 'credit-col',
            key: 'credit',
            align: 'left',
            width: '10%',
            render: (user) => `${user.teamCredit} min`,
        },
        {
            title: txt('chargedMinutes'),
            className: 'transcribed-col',
            align: 'left',
            width: screenSize.width < screenBreakpoints.xxl ? '20%' : '10%',
            render: (user) => `${Math.round(user.transcribedMinutes)} min`,
        },
        {
            title: (_jsx(ColumnHeader, { title: "created", sortOrder: sortOrder, isSorting: sortBy === 'created', onSort: handleSort, columnKey: "created" })),
            className: 'created-col',
            align: 'left',
            width: '15%',
            render: (user) => `${formatDate(user.created)}`,
        },
        {
            title: (_jsx(Tooltip, { title: txt('userActivity'), children: _jsx("div", { children: _jsx(ColumnHeader, { title: "activity", sortOrder: sortOrder, isSorting: sortBy === 'updated', onSort: handleSort, columnKey: "updated" }) }) })),
            className: 'updated-col',
            align: 'left',
            width: '15%',
            render: (user) => (_jsx(Tooltip, { title: formatDateWithTime(user.updated), children: _jsx("span", { className: styles.showDetail, children: formatDate(user.updated) }) })),
        },
        {
            title: txt('role'),
            align: 'left',
            width: '10%',
            key: 'role',
            className: 'role-col',
            filters: [
                { text: 'Promo', value: 'Promo' },
                { text: 'Enterprise', value: 'Enterprise' },
                { text: 'Standard', value: 'Standard' },
                { text: 'Admin', value: 'Admin' },
                { text: 'Developer', value: 'Developer' },
            ],
            filteredValue: (filters === null || filters === void 0 ? void 0 : filters.role) || null,
            render: (user) => user.role,
            onFilter: (value, user) => {
                return user.role.includes(value);
            },
        },
    ];
    const isSortingOrFiltering = !(sortBy === 'email'
        && sortOrder === 'ascend'
        && filters !== undefined
        && filters.role === null
        && filters.language === null
        && filters.groupId === null
        && filters.email === null);
    const toolbar = (_jsxs("div", { className: clsx('toolbar', styles.toolbar, searchStyles.fullWidthToolbar), children: [_jsxs("div", { className: clsx('toolbar__left', styles.toolbarLeft), children: [_jsx(AddButton, { id: "action-create-user", title: txt('newUser'), onClick: () => setNewUserFormVisible(true) }), isSortingOrFiltering ? (_jsxs(Button, { type: "link", onClick: () => tableView.setSieve({
                            sortBy: 'email',
                            sortOrder: 'ascend',
                            substr: substrEmail,
                            filters: {
                                role: null, language: null, groupId: null, email: null,
                            },
                            filterDates: [],
                        }), children: [_jsx(CloseOutlined, {}), _jsx("span", { children: txt('clearAll') })] })) : null] }), _jsx(SearchInput, { placeholder: txt('searchEmail'), onChange: handleInputChange, onSearch: handleEmailSearch, initialValues: { search: (_c = tableView.getViewParams().sieve) === null || _c === void 0 ? void 0 : _c.substr }, className: clsx('toolbar__right'), value: substrEmail })] }));
    return (_jsxs(Card, { className: "users-subpage", bordered: false, children: [toolbar, _jsx(ResponsiveTable, { tableView: tableView, className: styles.usersTable, dataSource: users !== 'loading' ? users.list : [], rowKey: (user) => user.id, recordCount: users !== 'loading' ? users.totalCount : 0, columns: session.login.hasClaim('admin:superadmin')
                    ? columns
                    : columns.filter((column) => column.key !== 'groupId'), onViewChange: fetchUsers, scroll: { x: 1100, y: contentHeight - SCROLL_HEIGHT_ADJUST }, loading: users === 'loading', locale: {
                    filterConfirm: txt('filter'),
                    filterReset: txt('clearFilter'),
                } }), _jsx(NewUserForm, { modalVisible: newUserFormVisible, onCreate: newUserCreated, onCancel: () => setNewUserFormVisible(false) })] }));
};
export default UsersSubpage;
