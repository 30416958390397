export const normalizeString = (string) => (string.toLowerCase()
    // NOTE: Represent diacritics using combining characters.
    .normalize('NFD')
    // NOTE: Remove diacritics.
    .replace(/[\u0300-\u036f]/g, '')
    // NOTE: Polish 'ł' converted to 'l'.
    .replace(/[\u0141-\u0142]/g, 'l')
    // NOTE: Croatian 'đ' converted to 'd'
    .replace(/[\u0110-\u0111]/g, 'd'));
export const normalizeStringForSelectFilter = (string) => (normalizeString(string)
    // NOTE: Removes multiple space between words if present
    .replace(/\s+/g, ' ')
    .split(' ')
    .join(''));
