const escape = {
    '"': '&quot;',
    "'": '&apos;',
    '<': '&lt;',
    '>': '&gt;',
    '&': '&amp;',
};
const unescape = {
    '&quot;': '"',
    '&apos;': "'",
    '&lt;': '<',
    '&gt;': '>',
    '&amp;': '&',
};
const ESCAPE_REGEX = new RegExp(Object.keys(escape).join('|'), 'g');
const UNESCAPE_REGEX = new RegExp(Object.keys(unescape).join('|'), 'g');
export const escapeXMLString = (str) => str.replace(ESCAPE_REGEX, (match) => escape[match]);
export const unescapeXMLString = (str) => str.replace(UNESCAPE_REGEX, (match) => unescape[match]);
export const getAttributes = (element) => {
    const { attributes } = element;
    const output = {};
    for (let i = 0; i < attributes.length; i += 1) {
        const attribute = attributes[i];
        output[attribute.nodeName] = attribute.nodeValue;
    }
    return output;
};
export const extractFirstValueByTagName = (xml, tagName) => {
    const xmlParser = new DOMParser();
    const xmlDoc = xmlParser.parseFromString(xml, 'text/xml');
    const values = xmlDoc.getElementsByTagName(tagName);
    if (values.length === 0) {
        return null;
    }
    return values[0].textContent;
};
export const getFirstElementByTagName = (root, tagName) => {
    const elements = root.getElementsByTagName(tagName);
    if (elements.length === 0) {
        return null;
    }
    return elements[0];
};
export const areSummariesInDocument = (xmlDoc) => (Array.from(xmlDoc.getElementsByTagName('se')).some((element) => {
    const summary = element.getAttribute('summary');
    return summary !== null && summary !== '\\n';
}));
