import Quill from 'quill';
import { inlineblotOrder } from './inline-blot-order';
const UNEDITABLE = 'uneditable';
const Inline = Quill.import('blots/inline');
class UneditableBlot extends Inline {
    static create(value) {
        const node = super.create(value);
        if (inlineblotOrder.includes(UNEDITABLE)) {
            node.className = UNEDITABLE;
            node.setAttribute('contenteditable', false);
        }
        else {
            // NOTE: if the format name is missing in blot order, it causes bugs in quill,
            // see https://github.com/quilljs/quill/issues/2312
            throw new Error('Blot is not listed in inline blot order');
        }
        return node;
    }
    static formats(domNode) {
        return UneditableBlot.valueFromDomNode(domNode);
    }
    static valueFromDomNode(domNode) {
        if (domNode.classList.contains(UNEDITABLE)) {
            return true;
        }
        return false;
    }
    format(name, value) {
        if (name === UneditableBlot.blotName) {
            if (value) {
                this.domNode.classList.add(UneditableBlot.blotName);
                this.domNode.setAttribute('contenteditable', false);
            }
            else {
                this.domNode.classList.remove(UneditableBlot.blotName);
                this.domNode.setAttribute('contenteditable', true);
            }
        }
        else {
            super.format(name, value);
        }
    }
    formats() {
        const formats = super.formats();
        formats.uneditable = UneditableBlot.formats(this.domNode);
        return formats;
    }
    attach() {
        if (this.parent != null) {
            this.scroll = this.parent.scroll;
        }
    }
}
UneditableBlot.blotName = UNEDITABLE;
export default UneditableBlot;
UneditableBlot.blotName = UNEDITABLE;
// NOTE: setting a custom tag name prevents some really weird bugs in quill.
UneditableBlot.tagName = UNEDITABLE;
