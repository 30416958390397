import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './style.less';
const animStyleFromSize = (size) => {
    switch (size) {
        case 'lg':
            return {
                width: '5rem',
                height: '5rem',
                margin: '2rem',
            };
        case 'md':
            return {
                width: '3rem',
                height: '3rem',
                margin: '1rem',
            };
        case 'sm':
        default:
            return {
                width: '2rem',
                height: '2rem',
                margin: '0.5rem',
            };
    }
};
const Loading = ({ size = 'md', message }) => (_jsxs("div", { className: "waiting", children: [_jsx("div", { className: "waiting__anim", style: animStyleFromSize(size) }), message !== undefined && (_jsx("div", { className: "waiting__message", children: message }))] }));
export default Loading;
