const STORAGE_KEY = 'paginations';
const loadData = () => {
    const dataAsString = window.localStorage.getItem(STORAGE_KEY);
    return dataAsString === null ? {} : JSON.parse(dataAsString);
};
const storeData = (jsonData) => {
    const dataAsString = JSON.stringify(jsonData);
    window.localStorage.setItem(STORAGE_KEY, dataAsString);
};
export default {
    storePageSize(tableId, pageSize) {
        const data = loadData();
        data[tableId] = pageSize;
        storeData(data);
    },
    getPageSize(tableId, defaultSize) {
        var _a;
        const data = loadData();
        return (_a = data[tableId]) !== null && _a !== void 0 ? _a : defaultSize;
    },
};
