import { jsx as _jsx } from "react/jsx-runtime";
import { detectBrowser } from 'libs/browser';
import { useSession } from 'components/Authenticated';
import { useBeeyTutorial } from 'screens/DashboardScreen/beeyTutorial';
import { useSubModeTutorial } from '../subModeTutorial';
import { usePlaybackContext } from '../WithMediaPlayback';
const EditorKeyboardControls = ({ className, children, waveCanvas, replayCaption, }) => {
    var _a;
    const { playback, playerState } = usePlaybackContext();
    const { session } = useSession();
    const { settings } = session.login.user;
    const operatingSystem = (_a = detectBrowser()) === null || _a === void 0 ? void 0 : _a.os;
    const beeyTutorialContext = useBeeyTutorial();
    const subModeTutorialContext = useSubModeTutorial();
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft' && event.altKey) {
            event.preventDefault();
            return false;
        }
        if (event.key === 'Left' && event.altKey) {
            event.preventDefault();
            return false;
        }
        if (event.key === 'Right' && event.altKey) {
            event.preventDefault();
            return false;
        }
        if (playerState.status !== 'ready') {
            return true;
        }
        if (event.key === 'Tab') {
            if (event.shiftKey) {
                if (beeyTutorialContext.currentStep === 2) {
                    beeyTutorialContext.tutorialGoNext();
                }
            }
        }
        if ((event.key === 'Backspace' || event.key === 'Delete') && subModeTutorialContext.currentStep === 3) {
            subModeTutorialContext.tutorialGoNext();
        }
        const ctrlOrAltKey = operatingSystem === 'Mac OS' || operatingSystem === 'iOS' ? event.ctrlKey : event.altKey;
        // Ctrl key for Mac, iPhones and iPads, Alt key for the others OS
        // https://github.com/DamonOehlman/detect-browser/blob/master/src/index.ts#L169
        if (event.nativeEvent.code === 'KeyJ' && ctrlOrAltKey) {
            event.preventDefault();
            playback.stepTimeBy(-1.0 * settings.jumpInPlayback);
            return false;
        }
        if (event.nativeEvent.code === 'KeyL' && ctrlOrAltKey) {
            event.preventDefault();
            playback.stepTimeBy(+1.0 * settings.jumpInPlayback);
            return false;
        }
        if (event.nativeEvent.code === 'KeyN' && ctrlOrAltKey) {
            event.preventDefault();
            playback.slowDown();
            return false;
        }
        if (event.nativeEvent.code === 'KeyM' && ctrlOrAltKey) {
            event.preventDefault();
            playback.speedUp();
            return false;
        }
        if (event.nativeEvent.code === 'Comma' && ctrlOrAltKey) {
            event.preventDefault();
            playback.stepTimeBy(-0.04);
            return false;
        }
        if (event.nativeEvent.code === 'Period' && ctrlOrAltKey) {
            event.preventDefault();
            playback.stepTimeBy(0.04);
            return false;
        }
        if (event.nativeEvent.code === 'KeyB' && event.ctrlKey) {
            event.preventDefault();
            if (subModeTutorialContext.currentStep === 0) {
                subModeTutorialContext.tutorialGoNext();
            }
            return false;
        }
        if (waveCanvas !== null && (event.nativeEvent.code === 'KeyP' && event.altKey)) {
            event.preventDefault();
            replayCaption();
            return false;
        }
        return true;
    };
    return (_jsx("div", { role: "presentation", className: className, onKeyDown: handleKeyDown, children: children }));
};
export default EditorKeyboardControls;
