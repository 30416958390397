import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { txt } from 'libs/i18n';
import { createTutorial } from 'components/Tutorial/';
import TutorialTooltip from 'components/Tutorial/TutorialTooltip';
import { Typography } from 'antd';
const { Text } = Typography;
const BEEY_TUTORIAL_STEP_COUNT = 7;
export const getBeeyTutorialSteps = () => ([
    {
        target: '.beey-tutorial-step-open',
        disableBeacon: true,
        content: (_jsx(TutorialTooltip, { stepCount: BEEY_TUTORIAL_STEP_COUNT, stepIndex: 0, children: txt('beeyTutorialStepOpen') })),
    },
    {
        target: '.beey-tutorial-step-edit',
        disableBeacon: true,
        styles: {
            tooltip: {
                width: '400px',
            },
        },
        content: (_jsxs(_Fragment, { children: [_jsxs(TutorialTooltip, { stepCount: BEEY_TUTORIAL_STEP_COUNT, stepIndex: 1, children: [txt('beeyTutorialStepEdit'), ' ', _jsx(Text, { italic: true, children: "Enter" })] }), _jsxs("p", { className: "tutorial__tip", children: [_jsx("strong", { children: "Pro tip: " }), txt('beeyTutorialTip')] })] })),
    },
    {
        target: '.beey-tutorial-step-play',
        disableBeacon: true,
        styles: {
            tooltip: {
                width: '340px',
            },
        },
        content: (_jsx(TutorialTooltip, { stepCount: BEEY_TUTORIAL_STEP_COUNT, stepIndex: 2, children: txt('beeyTutorialStepPlay') })),
    },
    {
        target: '.beey-tutorial-step-speaker',
        disableBeacon: true,
        placement: 'top-start',
        content: (_jsx(TutorialTooltip, { stepCount: BEEY_TUTORIAL_STEP_COUNT, stepIndex: 3, children: txt('beeyTutorialStepSpeakerName') })),
    },
    {
        target: '.beey-tutorial-step-subtitles',
        disableBeacon: true,
        content: (_jsx(TutorialTooltip, { stepCount: BEEY_TUTORIAL_STEP_COUNT, stepIndex: 4, children: txt('beeyTutorialStepSubtitles') })),
    },
    {
        target: '.beey-tutorial-step-choose-subs',
        disableBeacon: true,
        styles: {
            tooltip: {
                zIndex: '1000',
            },
        },
        content: (_jsx(TutorialTooltip, { stepCount: BEEY_TUTORIAL_STEP_COUNT, stepIndex: 5, children: txt('beeyTutorialStepChooseSubs') })),
    },
    {
        target: '.beey-tutorial-step-format',
        disableBeacon: true,
        placement: 'right-end',
        content: (_jsx(TutorialTooltip, { stepCount: BEEY_TUTORIAL_STEP_COUNT, stepIndex: 6, children: txt('beeyTutorialStepChooseFormat') })),
    },
]);
export const [useBeeyTutorial, BeeyTutorial, BeeyTutorialContext] = createTutorial('beey');
