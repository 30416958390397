import { loginByAuthString, loginByCredentials, signupByCredentials, logoutUser, } from 'api/user-api';
import { ApiConnection, } from '@newtontechnologies/beey-api-js-client/connection';
import { createKeycloakIdentityProvider, BeeyIdentityProvider, } from '@newtontechnologies/beey-api-js-client/identity-provider';
import { Result } from 'monadix/result';
import { fetchCurrentTeamSettings } from './team-api';
export class OpenedSession {
    constructor(connection, login, teamSettings) {
        this.connection = connection;
        this.login = login;
        this.teamSettings = teamSettings;
    }
    withLogin(login) {
        return new OpenedSession(this.connection, login, this.teamSettings);
    }
    withUser(user) {
        return this.withLogin(this.login.withUser(user));
    }
    withTeamSettings(teamSettings) {
        return new OpenedSession(this.connection, this.login, teamSettings);
    }
    async explicitLogout() {
        await this.connection.close();
    }
}
const setupConnection = (identityProvider, clearSession, onVersionMismatch) => {
    const connection = new ApiConnection(identityProvider, VERSION);
    connection.on('close', async (reason) => {
        if (reason === 'explicit') {
            const logout = await logoutUser(connection);
            if (logout.redirectUrl !== null) {
                window.location.href = logout.redirectUrl;
            }
        }
        clearSession();
    });
    connection.on('version-mismatch', onVersionMismatch);
    return connection;
};
export const openByKeycloak = async (clearSession, onVersionMismatch) => {
    const keycloak = await createKeycloakIdentityProvider();
    const authString = await keycloak.retrieveAuthString();
    const loginResult = await loginByAuthString(authString, onVersionMismatch);
    if (loginResult.isFail()) {
        return loginResult;
    }
    const connection = setupConnection(keycloak, clearSession, onVersionMismatch);
    const teamSettings = await fetchCurrentTeamSettings(connection);
    return Result.success(new OpenedSession(connection, loginResult.get(), teamSettings));
};
export const openByAuthString = async (authString, clearSession, onVersionMismatch) => {
    if (authString.startsWith('Apa-Bearer ')) {
        return openByKeycloak(clearSession, onVersionMismatch);
    }
    const loginResult = await loginByAuthString(authString, onVersionMismatch);
    if (loginResult.isFail()) {
        return loginResult;
    }
    const login = loginResult.get();
    const identityProvider = new BeeyIdentityProvider(login.token);
    const connection = setupConnection(identityProvider, clearSession, onVersionMismatch);
    const teamSettings = await fetchCurrentTeamSettings(connection);
    return Result.success(new OpenedSession(connection, login, teamSettings));
};
export const openByCredentials = async ({ email, password }, dataProtectionConsent, clearSession, onVersionMismatch) => {
    const loginResult = await loginByCredentials(email.trim(), password, dataProtectionConsent, onVersionMismatch);
    if (loginResult.isFail()) {
        return loginResult;
    }
    const login = loginResult.get();
    const identityProvider = new BeeyIdentityProvider(login.token);
    const connection = setupConnection(identityProvider, clearSession, onVersionMismatch);
    const teamSettings = await fetchCurrentTeamSettings(connection);
    return Result.success(new OpenedSession(connection, login, teamSettings));
};
export const openByRegistering = async (email, password, uiLocale, userOrigin, userField, clearSession, onVersionMismatch) => {
    const login = await signupByCredentials(email.trim(), password, uiLocale, userOrigin, userField);
    const identityProvider = new BeeyIdentityProvider(login.token);
    const connection = setupConnection(identityProvider, clearSession, onVersionMismatch);
    const teamSettings = await fetchCurrentTeamSettings(connection);
    return new OpenedSession(connection, login, teamSettings);
};
