import WaveformData from 'waveform-data';
import { buffer } from '@newtontechnologies/beey-api-js-client/receivers';
const createWaveformFromBuffer = async (waveBuffer, audioContext, amplitudeScale) => {
    const options = {
        audio_context: audioContext,
        array_buffer: waveBuffer,
        scale: 128, // NOTE: vertical scale
        amplitude_scale: amplitudeScale,
    };
    return new Promise((resolve, reject) => {
        WaveformData.createFromAudio(options, (err, waveform) => {
            if (err instanceof DOMException) {
                reject(err);
            }
            else {
                resolve(waveform);
            }
        });
    });
};
export const createScaledWaveformFromUrl = async (url, verticalScale, connection) => {
    const audioContext = new AudioContext();
    const waveBuffer = await connection.authFetch()
        .url(url)
        .receive(buffer())
        .fetch();
    return createWaveformFromBuffer(waveBuffer, audioContext, verticalScale);
};
export const createWaveformFromUrl = async (url, connection) => {
    const waveform = await createScaledWaveformFromUrl(url, 1, connection);
    // NOTE: Check if scaling is required.
    const maximum = waveform.channel(0).max_array().reduce((a, b) => Math.max(a, b), -Infinity);
    const requiredVerticalScale = 128 / maximum;
    if (requiredVerticalScale > 3) { // we need to normalize scale, wave is not big enough
        // NOTE: It is necessary to fetch the audio again, because waveform-data clears
        // buffer after generating the wave.
        return createScaledWaveformFromUrl(url, requiredVerticalScale, connection);
    }
    return waveform;
};
