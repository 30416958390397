import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import { fetchApiToken, renewToken } from 'api/user-api';
import { Button, Popconfirm, Space, Spin, Typography, } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import ChangePasswordForm from '../ChangePasswordForm';
import styles from './style.module.less';
const { Paragraph } = Typography;
const Authentification = () => {
    const { session } = useSession();
    const [token, setToken] = useState('loading');
    const getApiToken = async () => {
        const apiToken = await fetchApiToken(session.connection);
        setToken(apiToken.apiToken);
    };
    const generateNewToken = async () => {
        setToken('loading');
        const newToken = await renewToken(session.connection, token);
        setToken(newToken.apiToken);
    };
    useEffect(() => {
        void getApiToken();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(Space, { className: styles.authentification, children: [_jsx(LockOutlined, {}), txt('authentification')] }), _jsx(ChangePasswordForm, {}), session.login.hasClaim('account:apiToken') ? (_jsxs(_Fragment, { children: [_jsxs("h4", { children: [txt('apiToken'), ":"] }), _jsx(Paragraph, { copyable: { tooltips: [(txt('copyToClipboard')), txt('textCopied')] }, className: styles.token, children: token === 'loading' ? _jsx(Spin, {}) : token }), _jsx(Popconfirm, { title: txt('invalidateToken'), placement: "bottomLeft", okType: "primary", overlayClassName: styles.popconfirm, okButtonProps: { danger: true }, onConfirm: () => generateNewToken(), okText: txt('yes'), cancelText: txt('cancel'), children: _jsx(Button, { type: "primary", size: "small", children: txt('renewToken') }) })] })) : null] }));
};
export default Authentification;
