import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button } from 'antd';
import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { txt } from 'libs/i18n';
import AuthButton from '../AuthButton';
import styles from './style.module.less';
const DEFAULT_PROVIDERS = ['Google', 'Facebook'];
const AuthProviders = ({ providers }) => {
    const [areMoreProvidersExpanded, setAreMoreProvidersExpanded] = useState(false);
    const areMoreProviders = providers.some((provider) => !DEFAULT_PROVIDERS.includes(provider.key));
    const renderProviders = (providersList) => (_jsx(_Fragment, { children: providersList
            .map((provider) => (_jsx(AuthButton, { onClick: () => {
                window.location.href = apiV2.externalLogin.url({ provider: provider.key });
            }, iconSrc: `/authprovider-icons/${provider.key.toLocaleLowerCase()}.svg`, children: `${txt('continueWith')} ${provider.value}` }, provider.key))) }));
    return (_jsxs("div", { className: styles.providers, children: [renderProviders(providers.filter((provider) => DEFAULT_PROVIDERS.includes(provider.key))), areMoreProviders && areMoreProvidersExpanded
                ? (_jsx(_Fragment, { children: renderProviders(providers
                        .filter((provider) => !DEFAULT_PROVIDERS.includes(provider.key))) }))
                : (_jsx(Button
                // NOTE: type link is here only for styling purposes
                , { 
                    // NOTE: type link is here only for styling purposes
                    className: styles.buttonMore, type: "link", onClick: () => setAreMoreProvidersExpanded(true), children: `${txt('more')}...` }))] }));
};
export default AuthProviders;
