import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Modal, Progress } from 'antd';
import * as ProjectAPI from 'api/project-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
const ProjectsDeleteDialog = ({ projects, onClose, onDeleteFinished }) => {
    const { session } = useSession();
    const [progress, setProgress] = useState(0);
    const deleteProject = async (project) => {
        await ProjectAPI.trashProject(session.connection, project);
        setProgress(progress + 1);
    };
    const deleteAllProjects = async () => {
        setProgress(0);
        const settled = await Promise.allSettled(projects.map(deleteProject));
        const successCount = settled.filter(({ status }) => status === 'fulfilled').length;
        const failCount = projects.length - successCount;
        if (failCount > 0) {
            global.logger.error('failed to delete project', { failed: failCount });
        }
        setProgress(projects.length);
        onClose();
        onDeleteFinished(successCount, failCount);
    };
    useEffect(() => {
        void deleteAllProjects();
    }, []);
    const isDone = progress === projects.length;
    const percentProgress = Math.round((progress / projects.length) * 100);
    return (_jsxs(Modal, { title: txt('projDeletion'), open: true, closable: false, maskClosable: false, footer: false, children: [isDone ? (_jsxs("p", { id: "delProgressionID", className: "text-center", children: [txt('delSuccess'), _jsx("strong", { children: projects.length })] })) : (_jsx("p", { children: txt('delProgression') })), _jsx(Progress, { percent: percentProgress })] }));
};
export default ProjectsDeleteDialog;
