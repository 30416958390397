import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Col, Row, Pagination, } from 'antd';
import { nanoid } from 'nanoid';
import { KeyIcon } from 'components/CustomIcons';
import styles from './style.module.less';
import { getContrastColor } from '../../EditorPage/DocumentEditor/keywords';
const KeywordsResults = ({ highlight, projectId }) => {
    const [resultOrderTrascription, setResultOrderTrascription] = useState(1);
    return (_jsx("td", { className: styles.keywordsResults, colSpan: 8, children: _jsxs(Row, { children: [_jsxs(Col, { xs: 5, xxl: 3, className: `${styles.gutterRow} ${styles.pagination}`, children: [_jsx(Pagination, { current: resultOrderTrascription, onChange: setResultOrderTrascription, simple: true, total: highlight.textList.length, defaultPageSize: 1, hideOnSinglePage: true, showSizeChanger: false, size: "small" }), _jsx(KeyIcon, { className: styles.searchedItem })] }), _jsx(Col, { xs: 19, xxl: 21, className: styles.gutterRow, children: _jsx("div", { className: styles.resultsWrapper, children: highlight.textList[resultOrderTrascription - 1].map((item) => (_jsx("span", { style: { backgroundColor: item.highlight !== null ? item.highlight : undefined }, className: `${styles.keywordText} ${item.highlight !== null ? styles.keywordResult : ''}`, children: item.timestampMs === null ? (_jsx("span", { children: item.text })) : (_jsx("a", { style: {
                                    color: item.highlight !== null
                                        ? getContrastColor(item.highlight) : undefined,
                                }, className: styles.link, href: `/edit/${projectId}?timestamp=${item.timestampMs}&search=${item.text}`, rel: "noreferrer", children: item.text })) }, nanoid(5)))) }) })] }) }));
};
export default KeywordsResults;
