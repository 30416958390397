import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { App } from 'antd';
import { RollbackOutlined, FieldTimeOutlined, DownloadOutlined, UploadOutlined, FormOutlined, } from '@ant-design/icons';
import { downloadTextFormat, } from 'screens/DashboardScreen/pages/EditorPage/ProjectMenuPanel/exports';
import { txt } from 'libs/i18n';
import * as ProjectAPI from 'api/project-api';
import { useSession } from 'components/Authenticated';
import ConfirmImportTrsxModal from 'components/ConfirmImportTrsxModal';
import { disabledGuards } from '../disabledGuards';
import ControlsSection from '../ControlsSection';
import ControlsButton from '../ControlsButton';
const selectFiles = (accept) => new Promise((resolve) => {
    const filesInput = document.createElement('input');
    filesInput.type = 'file';
    filesInput.accept = accept;
    filesInput.onchange = () => resolve(filesInput.files);
    filesInput.click();
});
const ProjectMenuAdvanced = ({ project, editorController, disabledChecks, buttonClassName, }) => {
    const { session } = useSession();
    const { notification, message } = App.useApp();
    const [uploadTrsxModal, setUploadTrsxModal] = useState({
        isVisible: false, trsx: '',
    });
    const [isVisibleResetTrsxModal, setIsVisibleResetTrsxModal] = useState(false);
    const alignDocument = () => {
        try {
            editorController.alignDocument();
            void message.success(txt('alignmentSuccess'));
        }
        catch (error) {
            global.logger.error('failed to align document', {}, error);
            notification.error({
                message: txt('alignmentFail'),
                placement: 'topRight',
                duration: 3,
            });
        }
    };
    const handleDownloadText = (format) => {
        downloadTextFormat(editorController.exportDocument(), project.name, format);
    };
    const handleUploadTrsx = async () => {
        const files = await selectFiles('.trsx');
        if (files === null || files.length === 0) {
            return;
        }
        const trsx = await files[0].text();
        setUploadTrsxModal({ isVisible: true, trsx });
    };
    const uploadTrsx = () => {
        try {
            void editorController.importTrsx(uploadTrsxModal.trsx, true, project);
            void message.success(txt('trsxUploaded'));
        }
        catch (error) {
            void message.error(txt('trsxUploadFail'));
            global.logger.error('Failed to import transcription', {}, error);
        }
    };
    const resetTranscription = async () => {
        try {
            const trsx = await ProjectAPI.fetchTrsx(session.connection, project, 'originalTrsx');
            editorController.importTrsx(trsx, true, project);
            void message.success(txt('resetSuccessful'));
        }
        catch (error) {
            void message.error(txt('resetFailed'));
            global.logger.error('Failed to import transcription', {}, error);
        }
    };
    const activateSectionHeadings = async () => {
        try {
            await ProjectAPI.updateProjectHeadingsMetadata(session.connection, project, {
                pattern: '^\\d{1,3} .*$',
                options: [
                    { name: '128 nadpis varianta první', id: '0' },
                    { name: '234 nadpis varianta druhá', id: '1' },
                ],
            });
            void message.success(txt('done'));
            window.location.reload();
        }
        catch (error) {
            void message.error(txt('unexpectedError'));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ControlsSection, { title: "", children: [_jsx(ControlsButton, { icon: _jsx(DownloadOutlined, {}), className: buttonClassName, label: txt('dlTrsx'), busyMessage: `${txt('downloading')}…`, action: () => handleDownloadText('trsx'), disabledMessage: disabledGuards([
                            disabledChecks.editorHasContent,
                        ]) }), _jsx(ControlsButton, { icon: _jsx(DownloadOutlined, {}), className: buttonClassName, label: txt('dlBdd'), accessClaim: "editor:subtitleMode", busyMessage: `${txt('downloading')}…`, action: () => handleDownloadText('bdd'), disabledMessage: disabledGuards([
                            disabledChecks.editorHasContent,
                        ]) }), _jsx(ControlsButton, { icon: _jsx(UploadOutlined, {}), className: buttonClassName, label: txt('upTranscript'), action: handleUploadTrsx, disabledMessage: disabledGuards([
                            disabledChecks.successProject,
                            disabledChecks.mediaProcessingComplete,
                            disabledChecks.readOnly,
                        ]) }), _jsx(ControlsButton, { icon: _jsx(FieldTimeOutlined, {}), className: buttonClassName, label: txt('alignAll'), action: alignDocument, disabledMessage: disabledGuards([
                            disabledChecks.successProject,
                            disabledChecks.editorHasContent,
                            disabledChecks.mediaProcessingComplete,
                            disabledChecks.readOnly,
                        ]) }), _jsx(ControlsButton, { icon: _jsx(RollbackOutlined, {}), className: buttonClassName, label: txt('reset'), action: () => setIsVisibleResetTrsxModal(true), disabledMessage: disabledGuards([
                            disabledChecks.mediaProcessingComplete,
                            disabledChecks.readOnly,
                        ]) }), session.login.hasClaim('editor:sectionHeadingsTesting') && (_jsx(ControlsButton, { icon: _jsx(FormOutlined, {}), className: buttonClassName, label: txt('sectionHeadingsTesting'), action: activateSectionHeadings, disabledMessage: disabledGuards([
                            disabledChecks.successProject,
                            disabledChecks.editorHasContent,
                            disabledChecks.mediaProcessingComplete,
                        ]) }))] }), uploadTrsxModal.isVisible ? (_jsx(ConfirmImportTrsxModal, { isVisible: uploadTrsxModal.isVisible, asyncAction: uploadTrsx, title: txt('uploadTrsxConfirm'), content: txt('changesOverwritten'), okText: txt('yesUpload'), onCancel: () => setUploadTrsxModal(Object.assign(Object.assign({}, uploadTrsxModal), { isVisible: false })) })) : null, isVisibleResetTrsxModal ? (_jsx(ConfirmImportTrsxModal, { isVisible: isVisibleResetTrsxModal, asyncAction: resetTranscription, title: txt('resetTranscription'), content: txt('changesOverwritten'), okText: txt('yesReset'), onCancel: () => setIsVisibleResetTrsxModal(false) })) : null] }));
};
export default ProjectMenuAdvanced;
