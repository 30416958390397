import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { fragileTxt, txt } from 'libs/i18n';
import clsx from 'clsx';
import styles from './style.module.less';
import Claim from '../Claim';
import ClaimsBulkChange from '../ClaimsBulkChange';
const ClaimsTable = ({ claimsList, userId, onRefetchClaims, claimSet, }) => {
    const columns = [
        {
            title: 'Claim',
            dataIndex: 'title',
            render: (title) => { var _a; return (_a = fragileTxt(title)) !== null && _a !== void 0 ? _a : title; },
        },
        {
            title: 'Status',
            align: 'left',
            render: (claim) => (_jsx(Claim, { userId: userId, claimApiName: claim.apiName, claimSet: claimSet, onRefetchClaims: onRefetchClaims }, claim.title)),
        },
        {
            title: txt('defaultByRole'),
            dataIndex: 'apiName',
            align: 'center',
            render: (claimApiName) => {
                const isOnByDefault = claimSet.roleClaims.includes(claimApiName);
                return (isOnByDefault
                    ? _jsx(CheckOutlined, { className: clsx(styles.checkIcon, styles.opacity) })
                    : _jsx(CloseOutlined, { className: clsx(styles.closeIcon, styles.opacity) }));
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ClaimsBulkChange, { claimsList: claimsList, userId: userId, onRefetchClaims: onRefetchClaims, claimSet: claimSet }), _jsx(Table, { columns: columns, dataSource: claimsList, rowKey: (record) => record.title })] }));
};
export default ClaimsTable;
