import Quill from 'quill';
const Inline = Quill.import('blots/inline');
// borrowed from https://github.com/quilljs/quill/issues/2096
// beause we had the same issue.
export default class CustomColor extends Inline {
    constructor(domNode, value) {
        super(domNode, value);
        // Map <font> properties
        // eslint-disable-next-line no-param-reassign
        domNode.style.color = domNode.color;
        const span = this.replaceWith(new Inline(Inline.create()));
        span.children.forEach((child) => {
            if (Boolean(child.attributes))
                child.attributes.copy(span);
            if (Boolean(child.unwrap))
                child.unwrap();
        });
        this.remove();
        // This whole code is abhorrent, repugnent and insalting
        // to the human eyes. Sometime, we need to fix this somehow.
        // eslint-disable-next-line no-constructor-return
        return span;
    }
}
