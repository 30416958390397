import { getCurrentUiLocale } from './locales/ui';
import { localisation } from './localisation';
export const txt = (key) => {
    var _a;
    const currentLocale = getCurrentUiLocale();
    const translation = localisation[key];
    const country = currentLocale.country.toLowerCase();
    // @ts-expect-error localisation for the country may or may not be in the localisation.
    return (_a = translation[country]) !== null && _a !== void 0 ? _a : translation.us;
};
export const fragileTxt = (key) => {
    var _a;
    const currentLocale = getCurrentUiLocale();
    // @ts-expect-error localisation for the key may or may not be in the localisation.
    const translation = localisation[key];
    if (translation === undefined) {
        return undefined;
    }
    const country = currentLocale.country.toLowerCase();
    return (_a = translation[country]) !== null && _a !== void 0 ? _a : translation.us;
};
export const localeTxt = (locale) => {
    var _a;
    const lang = (_a = fragileTxt(`lang_${locale.lang}`)) !== null && _a !== void 0 ? _a : locale.lang;
    return locale.script === undefined
        ? `${lang} (${locale.country})`
        : `${lang} (${locale.script} ${locale.country})`;
};
export const sortedLocales = (locales) => [...locales].sort((loc1, loc2) => localeTxt(loc1).localeCompare(localeTxt(loc2)));
