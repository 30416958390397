import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button, Col, Flex, InputNumber, Row, } from 'antd';
import { txt } from 'libs/i18n';
import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import styles from './style.module.less';
// SEE: https://ant.design/components/input-number/#components-input-number-demo-formatter
export const regexThousandsSeparator = /\B(?=(\d{3})+(?!\d))/g;
const CreditSettings = ({ remainingCredit, creditDelta, onCreditDeltaChanged, onOperation, }) => {
    return (_jsxs(_Fragment, { children: [_jsxs(Row, { className: styles.row, children: [_jsx(Col, { span: 9, children: _jsx("span", { children: txt('remainingCredit') }) }), _jsx(Col, { offset: 3, children: _jsx("span", { children: `${remainingCredit}\xa0min` }) })] }), _jsxs(Row, { className: styles.row, children: [_jsx(Col, { span: 9, children: _jsx("span", { children: txt('modifyCredit') }) }), _jsx(Col, { offset: 3, span: 12, children: _jsxs(Flex, { align: "center", children: [_jsx(InputNumber, { className: styles.creditInput, id: "input-credit", min: 0, max: 1000000000, onKeyDown: (event) => {
                                        if (!(event.key === 'Backspace') && !/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }, formatter: (value) => (value === undefined ? String(creditDelta) : value.toString().replace(regexThousandsSeparator, ' ')), parser: (value) => (value === undefined ? creditDelta : Number(value.replace(/\$\s?|( *)/g, ''))), precision: 0, value: creditDelta, onChange: onCreditDeltaChanged }), _jsxs("div", { className: styles.buttons, children: [_jsx(Button, { className: styles.creditButton, onClick: () => onOperation('subtract'), children: _jsx(MinusCircleFilled, {}) }), _jsx(Button, { className: styles.creditButton, onClick: () => onOperation('add'), children: _jsx(PlusCircleFilled, {}) })] })] }) })] })] }));
};
export default CreditSettings;
