import { jsx as _jsx } from "react/jsx-runtime";
import { Form, Input } from 'antd';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const { Search } = Input;
const SearchInput = ({ placeholder, onSearch, onChange, value, className, initialValues, suffix, }) => (_jsx(Form, { className: className, initialValues: initialValues, children: _jsx(Form.Item, { name: "search", className: styles.search, rules: [
            {
                min: 2,
                message: `${txt('minChars')} 2`,
            }
        ], children: _jsx(Search, { className: styles.searchInput, placeholder: placeholder, allowClear: true, enterButton: true, value: value, onSearch: onSearch, onChange: onChange, suffix: suffix }) }) }));
export default SearchInput;
