import { s } from './sanitize';
import { SubtitlesTemplateSchema } from './user-settings';
export const TeamSectionTagSchema = s.object({
    key: s.string(''),
    title: s.string(''),
});
const transformSubtitleTemplates = (templateList) => {
    if (templateList.length === 0) {
        return [SubtitlesTemplateSchema.sanitize()];
    }
    return templateList;
};
export const TeamSettingsSchema = s.object({
    summariesAllowed: s.boolean(false),
    sectionTagsAllowed: s.boolean(false),
    sectionTags: s.array(TeamSectionTagSchema),
    isStrictRoleSelection: s.boolean(false),
    teamTags: s.array(s.string('')),
    subtitlesTemplates: s.array(SubtitlesTemplateSchema).default([SubtitlesTemplateSchema.sanitize()]).transform(transformSubtitleTemplates),
});
export const defaultTeamSettings = TeamSettingsSchema.sanitize({});
export const sanitizeTeamSettings = (data) => TeamSettingsSchema.sanitize(data);
