import { jsx as _jsx } from "react/jsx-runtime";
import { Spin } from 'antd';
import { fetchKeycloakConfig } from '@newtontechnologies/beey-api-js-client/identity-provider';
import { txt } from 'libs/i18n';
import { useEffect, useState } from 'react';
const ApaAccountTab = () => {
    const [linkUrl, setLinkUrl] = useState(null);
    const updateLink = async () => {
        const config = await fetchKeycloakConfig();
        setLinkUrl(`${config.apaConfiguration.url}/auth/realms/${config.apaConfiguration.realm}/account`);
    };
    useEffect(() => {
        void updateLink();
    }, []);
    if (linkUrl === null) {
        return _jsx(Spin, {});
    }
    return _jsx("a", { href: linkUrl, target: "_blank", rel: "noreferrer", children: txt('manageAccount') });
};
export default ApaAccountTab;
