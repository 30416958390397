var Op;
(function (Op) {
    Op[Op["Begin"] = 0] = "Begin";
    Op[Op["Insert"] = 1] = "Insert";
    Op[Op["Substitute"] = 2] = "Substitute";
    Op[Op["Match"] = 3] = "Match";
    Op[Op["Delete"] = 4] = "Delete";
})(Op || (Op = {}));
const PATTERN_LENGTH = 15;
export class Alignment {
    constructor(targetSequence, distance, insertionPenalty, deletionPenalty, clean, chunkSize) {
        this.targetSequence = targetSequence;
        this.distance = distance;
        this.insertionPenalty = insertionPenalty;
        this.deletionPenalty = deletionPenalty;
        this.clean = clean;
        this.chunkSize = chunkSize;
    }
    // the lower tolerance, the more likely it is to reject a match
    static pickBestMatchFromScores(scores, tolerance) {
        const bestMatchScore = Math.max(...scores);
        const bestMatchIndex = scores.indexOf(bestMatchScore);
        const goodMatchIndices = scores.reduce((accumulator, value, index) => {
            if (value >= bestMatchScore * (tolerance + 0.2))
                accumulator.push(index);
            return accumulator;
        }, []);
        const firstGoodMatch = goodMatchIndices[0];
        const lastGoodMatch = goodMatchIndices[goodMatchIndices.length - 1];
        if (lastGoodMatch - firstGoodMatch > PATTERN_LENGTH) {
            return null; // match not unique enough
        }
        if (bestMatchScore < PATTERN_LENGTH * (1 - tolerance)) {
            return null; // match not good enough
        }
        return bestMatchIndex;
    }
    push(newEntry) {
        this.targetSequence.push(newEntry);
    }
    // Find the optimal operations to match sourceSequence with target targetSequence.
    match(sourceSequence, from, to, deadlineUnixTime) {
        if (Date.now() > deadlineUnixTime) {
            throw Error('alignment not found in time, aborting.');
        }
        if (to < from) {
            // eslint-disable-next-line no-param-reassign
            to = from;
        }
        if (to - from > this.chunkSize && sourceSequence.length > this.chunkSize) {
            // divide and conquer. Dynamic time warping has O(N^2) time complexity,
            // so it cannot be used directly on long documents. getPivots is faster: O(N)
            // We use getPivots recursively to split the problem to smaller chunks,
            // that can be computed by dynamic time warping faster.
            const [sourcePivot, targetPivot] = this.getPivots(sourceSequence, from, to);
            const { distance: distance1, matchIndices: matchIndices1 } = this.match(sourceSequence.slice(0, sourcePivot), from, targetPivot, deadlineUnixTime);
            const { distance: distance2, matchIndices: matchIndices2 } = this.match(sourceSequence.slice(sourcePivot), targetPivot, to, deadlineUnixTime);
            const distance = distance1 + distance2;
            const matchIndices = matchIndices1.concat(matchIndices2);
            const output = { distance, matchIndices };
            return output;
        }
        return this.dynamicTimeWarping(sourceSequence, from, to, deadlineUnixTime);
    }
    // See https://en.wikipedia.org/wiki/Dynamic_time_warping for algorithm description
    dynamicTimeWarping(sourceSequence, from, to, deadlineUnixTime) {
        // matrix[i][j] is the weighted levenshtein distance of
        // sourceSequence[:i] and targetSequence[from:from+j]
        const matrix = [];
        const ops = [];
        const aslice = this.targetSequence.slice(from, to);
        for (let i = 0; i <= sourceSequence.length; i += 1) {
            matrix[i] = [];
            ops[i] = [];
        }
        matrix[0][0] = 0;
        ops[0][0] = Op.Begin;
        for (let j = 1; j <= aslice.length; j += 1) {
            matrix[0][j] = matrix[0][j - 1] + this.insertionPenalty(aslice[j - 1]);
            ops[0][j] = Op.Begin;
        }
        for (let i = 1; i <= sourceSequence.length; i += 1) {
            matrix[i][0] = matrix[i - 1][0] + this.deletionPenalty(sourceSequence[i - 1]);
            ops[i][0] = Op.Delete;
        }
        // Fill in the rest of the matrix
        for (let i = 1; i <= sourceSequence.length; i += 1) {
            if (Date.now() > deadlineUnixTime) {
                throw Error('alignment not found in time, aborting.');
            }
            for (let j = 1; j <= aslice.length; j += 1) {
                const wordDistance = this.distance(aslice[j - 1], sourceSequence[i - 1]);
                const substitution = matrix[i - 1][j - 1] + wordDistance;
                const insertion = matrix[i][j - 1] + this.insertionPenalty(aslice[j - 1], sourceSequence[i - 1]);
                const deletion = matrix[i - 1][j] + this.deletionPenalty(sourceSequence[i - 1]);
                if (substitution < insertion && substitution < deletion) {
                    matrix[i][j] = substitution;
                    if (wordDistance === 0) {
                        ops[i][j] = Op.Match;
                    }
                    else {
                        ops[i][j] = Op.Substitute;
                    }
                }
                else if (insertion < deletion) {
                    matrix[i][j] = insertion;
                    ops[i][j] = Op.Insert;
                }
                else {
                    matrix[i][j] = deletion;
                    ops[i][j] = Op.Delete;
                }
            }
        }
        let i = sourceSequence.length;
        let j = aslice.length;
        let op = ops[i][j];
        const matchIndices = new Array(sourceSequence.length).fill(0);
        while (op !== Op.Begin && i >= 0 && j >= 0) {
            if (op === Op.Substitute) {
                i -= 1;
                j -= 1;
            }
            else if (op === Op.Delete) {
                i -= 1;
            }
            else if (op === Op.Insert) {
                j -= 1;
            }
            else if (op === Op.Match) {
                i -= 1;
                j -= 1;
            }
            if (op !== Op.Insert) { // "if i changed"
                matchIndices[i] = from + Math.min(j, aslice.length - 1);
            }
            op = ops[i][j];
        }
        return {
            distance: matrix[sourceSequence.length][aslice.length],
            matchIndices,
        };
    }
    findBestMatchForPattern(pattern, targetFrom, targetTo, tolerance, patternStart) {
        const matchScores = [];
        const patternSet = new Set(pattern);
        for (let j = targetFrom; j < targetTo - pattern.length; j += 1) {
            let matchScore = 0;
            for (let k = j - pattern.length; k < j; k += 1) {
                const value = this.targetSequence[k];
                if (patternSet.has(value)) {
                    matchScore += 1;
                }
            }
            matchScores.push(matchScore);
        }
        const bestMatchInRange = Alignment.pickBestMatchFromScores(matchScores, tolerance);
        if (bestMatchInRange === null)
            return [null, null]; // no match good enough found
        const bestMatchIndex = targetFrom + bestMatchInRange;
        const [patternMatch, wordMatchTarget] = this.getMatchingWords(pattern, this.targetSequence.slice(bestMatchIndex - PATTERN_LENGTH, bestMatchIndex));
        if (patternMatch === null || wordMatchTarget === null) {
            return [null, null];
        }
        return [patternStart + patternMatch, bestMatchIndex - PATTERN_LENGTH + wordMatchTarget];
    }
    getCountInArray(element, array) {
        let count = 0;
        for (let i = 0; i < array.length; i += 1) {
            if (array[i] === element)
                count += 1;
        }
        return count;
    }
    getMatchingWords(source, target) {
        for (let i = 0; i < source.length; i += 1) {
            // matching words must have similar index
            for (let j = Math.max(0, i - 2); j < Math.min(target.length, i + 3); j += 1) {
                if (source[i] === target[j]) {
                    // matching words must be unique in both sequences
                    if (this.getCountInArray(source[i], source) === 1
                        && this.getCountInArray(target[j], target) === 1) {
                        return [i, j];
                    }
                }
            }
        }
        return [null, null];
    }
    // pivots is a single match found around the middle of the sequence.
    // The pivots can be found fast and should be reliable.
    getPivots(source, targetFrom, targetTo) {
        // NOTE: range where we search for pivots on source sequence
        // is chosen empirically. It is important that it is around the middle
        // and that is long enough to find some match somewhere.
        const pivotRangeStart = Math.floor(source.length * 0.5);
        const pivotRangeEnd = Math.floor(source.length * 0.8);
        const increment = PATTERN_LENGTH;
        // NOTE: We start with lower tolerance and when no match is found,
        // we try it with higher tolerance. The starting value and step is chosen
        // empirically so that there is high chance to find a match with the starting
        // value and so that the search does not last too long even when we need to try
        // some higher values.
        for (let tolerance = 0.3; tolerance <= 1; tolerance += 0.3) {
            for (let patternStart = pivotRangeStart; patternStart < pivotRangeEnd; patternStart += increment) {
                const [pivot1, pivot2] = this.getPivotsAt(source, targetFrom, targetTo, patternStart, tolerance);
                if (pivot1 !== null && pivot2 !== null) {
                    return [pivot1, pivot2];
                }
            }
            global.logger.info(`no match found with tolerance ${tolerance}`);
        }
        throw new Error('no match found');
    }
    getPivotsAt(source, targetFrom, targetTo, patternStart, tolerance) {
        const patternEnd = patternStart + PATTERN_LENGTH;
        const rawPattern = source.slice(patternStart, patternEnd);
        const pattern = rawPattern.map((x) => this.clean(x));
        const [matchSource, matchTarget] = this.findBestMatchForPattern(pattern, targetFrom, targetTo, tolerance, patternStart);
        if (matchSource === null || matchTarget === null) {
            return [null, null];
        }
        return [matchSource, matchTarget];
    }
}
