// NOTE: Locales are represented according to RFC 5646 as language-[Script-]REGION where:
// - language is the shortest ISO 639 code in lowercase
// - Script is an optional ISO 15924 code in titlecase
// - REGION is ISO 3166-1 code in uppercase.
const localeFormat = /^([a-z]{2,3})-(([A-Z][a-z]{3})-)?([A-Z]{2})$/;
export class BeeyLocale {
    constructor(lang, script, country) {
        this.country = country;
        this.script = script;
        this.lang = lang;
        this.code = script === undefined
            ? `${lang}-${country}`
            : `${lang}-${script}-${country}`;
    }
    static fromCode(code) {
        const match = code.match(localeFormat);
        if (match === null) {
            return null;
        }
        const lang = match[1];
        const script = match[3];
        const country = match[4];
        return new BeeyLocale(lang, script, country);
    }
    // NOTE: This should only be used to init locales that are known to be valid.
    // if the supplied code is invalid the app should crash in development.
    static const(code) {
        const locale = BeeyLocale.fromCode(code);
        if (locale === null) {
            throw new Error(`Invalid locale code: ${code}`);
        }
        return locale;
    }
    toLocalizationKey() {
        return this.script === undefined
            ? `${this.lang}`
            : `${this.lang}_${this.script}`;
    }
    equals(other) {
        return this.code === other.code;
    }
    isRightToLeft() {
        const rightToLeftLanguages = [
            'ar', // Arabic
            'arc', // Aramaic
            'dv', // Divehi
            'fa', // Persian
            'ha', // Hausa
            'he', // Hebrew
            'khw', // Khowar
            'ks', // Kashmiri
            'ku', // Kurdish
            'ps', // Pashto
            'ur', // Urdu
            'yi', // Yiddish
        ];
        return rightToLeftLanguages.includes(this.lang);
    }
}
