import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { txt } from 'libs/i18n';
import IconButton from 'components/IconButton';
import dayjs from 'dayjs';
import styles from './style.module.less';
export const separateThousands = (number) => new Intl.NumberFormat().format(number);
export const separateThousandsWithDecimals = (number) => new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
const LastPayment = ({ lastOrder, onManagePlan }) => {
    return (_jsxs("div", { className: styles.paymentInfo, children: [_jsxs("h4", { children: [txt('lastPayment'), ":"] }), _jsxs(Space, { className: styles.payment, wrap: true, children: [lastOrder.status === 'Successful'
                        ? _jsx(CheckCircleFilled, { className: styles.iconActive })
                        : _jsx(CloseCircleOutlined, { className: styles.iconFailed }), _jsx("span", { children: lastOrder.status === 'Successful' ? txt('paid') : txt('paymentOf') }), _jsx("span", { children: separateThousandsWithDecimals(lastOrder.amount) }), _jsx("span", { children: lastOrder.currency }), _jsx("span", { children: lastOrder.status === 'Successful' ? '' : txt('failedOn') }), _jsx("span", { children: dayjs(lastOrder.date).format('DD/MM/YYYY') })] }), _jsx(IconButton, { onClick: () => onManagePlan(null), className: styles.iconButton, children: txt('seeDetails') })] }));
};
export default LastPayment;
