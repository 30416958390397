import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'antd';
import { useSession } from 'components/Authenticated';
import { screenBreakpoints, useAppContext } from 'App/app-context';
import DashboardPageHeader from '../DashboardPageHeader';
import { DashpageContext } from './dashpage-context';
import './style.less';
export const getAppTheme = (session) => {
    if (session.login.hasClaim('dataTheme:apa')) {
        return 'apa';
    }
    return '';
};
const DashboardPage = ({ title, toolbar, extraControls, children, }) => {
    const { session } = useSession();
    const { screenSize } = useAppContext();
    const headerHeight = screenSize.width < screenBreakpoints.md ? 90 : 45;
    const contextValue = useMemo(() => ({
        contentHeight: screenSize.height - headerHeight,
    }), [screenSize.height, headerHeight]);
    return (_jsxs(Layout.Content, { className: "dashpage", children: [_jsx(Helmet, { bodyAttributes: { 'data-theme': `${getAppTheme(session)}` }, title: session.login.hasClaim('dataTheme:apa') ? 'Speech-to-Text' : `${title} | Beey` }), _jsxs(DashpageContext.Provider, { value: contextValue, children: [_jsx(DashboardPageHeader, { extraControls: extraControls, children: toolbar !== null && toolbar !== void 0 ? toolbar : null }), _jsx(Layout.Content, { className: "dashpage__content", children: typeof children === 'function' ? children(contextValue) : children })] })] }));
};
export default DashboardPage;
