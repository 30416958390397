import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { ApiError } from '@newtontechnologies/beey-api-js-client/receivers';
import { labelTrsx } from 'api/project-api';
import deepEqual from 'fast-deep-equal';
import { subtitlesDefaults, TransparencySchema, } from 'api/settings/user-settings';
import { Button, Divider, Modal, Form, Switch, Collapse, Alert, Radio, Select, Tooltip, App, } from 'antd';
import SpeakerColorPicker from 'components/SpeakerColorPicker';
import * as clientEnv from 'libs/client-env';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import * as ProjectAPI from 'api/project-api';
import { fetchCurrentTeamSubsTemplates } from 'api/team-api';
import RangeInput from './RangeInput';
import TemplateSelect from './TemplateSelect';
import { useSubModeTutorial } from '../../subModeTutorial';
import { captionParametersFromSubtitlesSettings, subtitlesSettingsFromCaptionParameters } from './parameters-conversion';
import { captionFontNames } from './caption-fonts';
import CaptionPosition from './CaptionPosition';
import './style.less';
const { Option } = Select;
const SPEED_WARNING = {
    critical: { min: 16, max: 30 },
    normal: { min: 12, max: 30 },
};
const AUTOMATIC_SPEED = { min: 8, max: 30 };
const MINIMAL_TIME = { min: 0.1, max: 3 };
export const SUBTITLE_LENGTH = { min: 15, max: 60 };
export const AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS = { min: 0, max: 500 };
const CaptionReviewModal = ({ visible, onCancel, project, isCaptionMode, enqueueProjectUpdate, editorController, isWaveVisible, onWaveVisibilityChange, }) => {
    var _a, _b;
    const { captions } = editorController;
    const { session, updateUserSettings, updateTeamSettings } = useSession();
    const { message } = App.useApp();
    const [convertingSubtitles, setConvertingSubtitles] = useState(false);
    const userSavedSubtitlesSettings = session.login.user.settings.subtitles;
    const userSavedTemplate = session.login.user.settings.teamSubtitlesTemplate;
    const currentDocumentSettings = captions.parameters === null
        ? null
        : subtitlesSettingsFromCaptionParameters(captions.parameters);
    const initialValues = Object.assign(Object.assign({}, userSavedSubtitlesSettings), currentDocumentSettings);
    const [teamTemplates, setTeamTemplates] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState({
        name: (_a = userSavedTemplate === null || userSavedTemplate === void 0 ? void 0 : userSavedTemplate.name) !== null && _a !== void 0 ? _a : '',
        settings: initialValues,
        isEditable: (_b = userSavedTemplate === null || userSavedTemplate === void 0 ? void 0 : userSavedTemplate.isEditable) !== null && _b !== void 0 ? _b : false,
        edited: false,
    });
    const [form] = Form.useForm();
    const { Panel } = Collapse;
    const { tutorialContinue, tutorialGoNext, retrieveTutorialState } = useSubModeTutorial();
    const isTutorialRunning = retrieveTutorialState() === 'running';
    const getCurrentTemplate = (templates, formValues) => {
        const currentTemplateName = captions.parameters === null
            ? currentTemplate.name
            : captions.parameters.templateName;
        const usedTeamTemplate = templates.find((template) => template.name === currentTemplateName);
        if (usedTeamTemplate === undefined) {
            return Object.assign(Object.assign({}, currentTemplate), { name: '' });
        }
        if (!deepEqual(usedTeamTemplate.settings, formValues)) {
            if (!isCaptionMode) {
                return Object.assign(Object.assign({}, currentTemplate), { name: '' });
            }
            return Object.assign(Object.assign({}, currentTemplate), { name: usedTeamTemplate.name, edited: true, isEditable: usedTeamTemplate.isEditable });
        }
        return Object.assign(Object.assign({}, currentTemplate), { name: usedTeamTemplate.name, isEditable: usedTeamTemplate.isEditable });
    };
    useEffect(() => {
        if (visible === true) {
            const fetchTeamTemplates = async () => {
                const subtitlesTemplates = await fetchCurrentTeamSubsTemplates(session.connection);
                // NOTE: Current template needs to be updated with up-to-date captions parameters
                // that are null on first render.
                setCurrentTemplate(Object.assign(Object.assign({}, getCurrentTemplate(subtitlesTemplates, initialValues)), { settings: initialValues }));
                setTeamTemplates([...subtitlesTemplates]);
            };
            void fetchTeamTemplates();
            tutorialContinue();
            form.setFieldsValue(initialValues);
        }
    }, [visible]);
    const turnOffsubtitleRevision = async () => {
        try {
            const trsx = await ProjectAPI.fetchProjectTrsx(session.connection, project);
            editorController.importTrsx(trsx, true, project, true);
            void message.success(txt('captionsRemoved'));
        }
        catch (error) {
            void message.error(txt('removeCaptionsFailed'));
            global.logger.error('Failed to reset captions', {}, error);
        }
        onCancel();
    };
    const updatePreview = (values) => {
        try {
            editorController.captions.updateCaptionParameters(Object.assign({}, captionParametersFromSubtitlesSettings(values)));
            updateUserSettings({
                subtitles: values,
                teamSubtitlesTemplate: currentTemplate.edited ? null : currentTemplate,
            });
            void message.success(txt('subtitleUpdateSuccess'));
            onCancel();
        }
        catch (error) {
            global.logger.error('failed to update captions preview for unhandled reason', {}, error);
            void message.error(txt('unexpectedError'));
        }
    };
    const generatePreview = async (values) => {
        try {
            const captionParameters = captionParametersFromSubtitlesSettings(values);
            await enqueueProjectUpdate(async (initialProject) => {
                const updatedProject = await labelTrsx(session.connection, initialProject, captionParameters);
                const trsx = await ProjectAPI.fetchTrsx(session.connection, project, 'currentTrsx');
                editorController.importTrsx(trsx, true, updatedProject, false);
                return updatedProject;
            });
            updateUserSettings({
                subtitles: values,
                teamSubtitlesTemplate: currentTemplate.edited ? null : currentTemplate,
            });
            void message.success(txt('subtitleMessageSuccess'));
        }
        catch (error) {
            if (error instanceof ApiError && error.response.status === 409) {
                void message.error(txt('subtitleMessageErrorTryAgain'));
                global.logger.warn('failed to generate captions preview because of access token conflict');
            }
            if (error instanceof ApiError && error.response.status === 422) {
                if (error.errorMessage === 'Subtitle line length must be greater or equal 30.') {
                    void message.error(`${txt('minSubtitleLineLength')} ${SUBTITLE_LENGTH.min}`);
                    global.logger.error('Subtitle line length must be greater or equal 30.', {}, error);
                }
                else if (error.errorMessage === 'Subtitle line length must be less or equal 50.') {
                    void message.error(`${txt('maxSubtitleLineLength')} ${SUBTITLE_LENGTH.max}`);
                    global.logger.error('Subtitle line length must be less or equal 50.', {}, error);
                }
                else if (error.errorMessage === 'Subtitle line length must be a positive integer.') {
                    void message.error(txt('integerSubtitleLineLength'));
                    global.logger.error('Subtitle line length must be a positive integer.', {}, error);
                }
                else {
                    void message.error(error.errorMessage);
                    global.logger.error('Validation of input value failed.', {}, error);
                }
            }
            else {
                void message.error(txt('subtitleMessageError'));
                global.logger.error('failed to generate captions preview for unhandled reason', {}, error);
                throw error;
            }
        }
        onCancel();
    };
    const handleValuesChange = () => {
        const changedSettings = Object.assign(Object.assign({}, currentTemplate.settings), form.getFieldsValue());
        const usedTemplate = teamTemplates.find((template) => template.name === currentTemplate.name);
        setCurrentTemplate(Object.assign(Object.assign({}, currentTemplate), { name: currentTemplate.isEditable ? currentTemplate.name : '', settings: changedSettings, edited: usedTemplate === undefined
                ? false
                : !deepEqual(usedTemplate.settings, changedSettings) }));
    };
    const handleSubmit = async (formValues) => {
        const subsSettings = Object.assign(Object.assign({}, formValues), { templateName: currentTemplate.name });
        if (isCaptionMode) {
            updatePreview(subsSettings);
        }
        else {
            await generatePreview(subsSettings);
        }
        if (clientEnv.getIsWaveVisible() && !isWaveVisible) {
            onWaveVisibilityChange(true);
        }
    };
    const handleTemplatesUpdated = (newTemplates) => {
        setTeamTemplates([...newTemplates]);
        updateTeamSettings({
            subtitlesTemplates: [
                ...newTemplates,
            ],
        });
    };
    const handleTemplateSelected = (template) => {
        form.setFieldsValue(template.settings);
        setCurrentTemplate(Object.assign({}, template));
    };
    const handleCancel = () => {
        var _a;
        setCurrentTemplate(Object.assign(Object.assign({}, currentTemplate), { name: (_a = userSavedTemplate === null || userSavedTemplate === void 0 ? void 0 : userSavedTemplate.name) !== null && _a !== void 0 ? _a : '', settings: initialValues, edited: false }));
        onCancel();
    };
    const modalHeader = (isCaptionMode ? (_jsx("h2", { children: txt('subtitleSettings') })) : (_jsxs(_Fragment, { children: [_jsx("h2", { children: txt('convertToSubtitles') }), _jsx("p", { children: txt('subtitleModeDescription') })] })));
    const fontSizeOptions = () => {
        const options = [];
        const minFontSize = 8;
        const maxFontSize = 40;
        for (let i = minFontSize; i <= maxFontSize; i += 2) {
            options.push(_jsx(Option, { value: i, children: i }, i));
        }
        return options;
    };
    const onModalOK = () => {
        form.submit();
        tutorialGoNext();
    };
    return (_jsx(Modal, { className: "subtitle-revision-modal sub-mode-tutorial-step-save-settings", title: modalHeader, footer: [
            _jsx(Button, { type: "default", onClick: handleCancel, disabled: retrieveTutorialState() === 'running', children: txt('cancel') }, "back"),
            _jsx(Button, { type: "primary", htmlType: "submit", onClick: onModalOK, disabled: convertingSubtitles, children: isCaptionMode ? txt('save') : txt('convertToSubtitles') }, "submit"),
        ], open: visible, onCancel: handleCancel, width: 560, destroyOnClose: true, keyboard: !isTutorialRunning, closable: !isTutorialRunning, children: _jsxs(Form, { form: form, colon: false, initialValues: initialValues, onFinish: async (values) => {
                setConvertingSubtitles(true);
                try {
                    await handleSubmit(Object.assign(Object.assign({}, initialValues), values));
                }
                catch (error) {
                    logger.error('failed to convert to subtitles', {}, error);
                }
                setConvertingSubtitles(false);
            }, labelWrap: true, onValuesChange: handleValuesChange, children: [_jsx(TemplateSelect, { templates: teamTemplates, currentTemplate: currentTemplate, onTemplatesUpdated: handleTemplatesUpdated, onTemplateSelected: handleTemplateSelected }), _jsxs("div", { className: "subtitle-revision-modal__section", children: [_jsx("div", { className: "subtitle-revision-modal__section-title", children: txt('subtitleFormat') }), _jsx(RangeInput, { label: txt('lineLength'), required: true, name: "maxLineLength", description: txt('maxLineLengthDescription'), min: SUBTITLE_LENGTH.min, max: SUBTITLE_LENGTH.max, step: 1, unit: txt('charactersPerLine'), form: form, disabled: isCaptionMode, restoreValue: subtitlesDefaults.maxLineLength }), _jsx(Form.Item, { label: txt('defaultSubsColor'), name: "defaultColor", children: _jsx(SpeakerColorPicker, { onChange: (color) => form.setFieldValue('defaultColor', color) }) }), _jsxs(Form.Item, { label: txt('fontStyleAndSize'), className: "font-name-and-size", children: [_jsx(Form.Item, { name: "defaultFontName", noStyle: true, children: _jsx(Select, { children: captionFontNames.map((defaultFontName) => (_jsx(Option, { value: defaultFontName, children: _jsx("span", { style: { fontFamily: defaultFontName }, children: defaultFontName }) }, defaultFontName))) }) }), _jsx(Form.Item, { name: "defaultFontSize", noStyle: true, children: _jsx(Select, { className: "font-size", children: fontSizeOptions() }) })] }), _jsx(Form.Item, { name: "defaultBackgroundTransparency", label: txt('backgroundTransparency'), children: _jsx(Select, { children: TransparencySchema.entries().map(([key, literal]) => (_jsx(Option, { value: literal.value, children: txt(key) }, key))) }) }), _jsx(Form.Item, { name: "defaultCaptionPosition", label: txt('subtitlePosition'), tooltip: {
                                title: txt('defaultPosition'),
                                icon: _jsx(InfoCircleOutlined, {}),
                            }, children: _jsx(CaptionPosition, { onValuesChange: handleValuesChange, form: form, initialValue: initialValues.defaultCaptionPosition }) }), _jsx(Form.Item, { name: "upperCaseAllText", label: txt('setAllCaps'), valuePropName: "checked", children: _jsx(Switch, { size: "small" }) })] }), _jsxs("div", { className: "subtitle-revision-modal__section warning-settings", children: [_jsx("div", { className: "subtitle-revision-modal__section-title", children: txt('warningSettings') }), _jsx(RangeInput, { label: txt('automaticSpeed'), name: "automaticSpeed", description: txt('automaticSpeedDescription'), min: AUTOMATIC_SPEED.min, max: AUTOMATIC_SPEED.max, step: 0.1, unit: txt('charactersPerSecond'), switchable: true, onValuesChange: handleValuesChange, form: form, restoreValue: subtitlesDefaults.automaticSpeed, disabledValue: null, validator: ({ getFieldValue }) => ({
                                validator(_, value) {
                                    const speedWarning = getFieldValue('speedWarning');
                                    const speedCriticalWarning = getFieldValue('speedCriticalWarning');
                                    if (value === null) {
                                        if (speedWarning !== null || speedCriticalWarning !== null) {
                                            // if some warning is set, automatic speed must be set.
                                            return Promise.reject(txt('automaticSpeedHigh'));
                                        }
                                        return Promise.resolve();
                                    }
                                    if (speedWarning !== null
                                        && value > speedWarning) {
                                        return Promise.reject(txt('automaticSpeedHigh'));
                                    }
                                    if (speedCriticalWarning !== null
                                        && value > speedCriticalWarning) {
                                        return Promise.reject(txt('automaticSpeedHigh'));
                                    }
                                    return Promise.resolve();
                                },
                            }) }), _jsx(RangeInput, { label: txt('speedCriticalWarn'), name: "speedCriticalWarning", description: txt('speedCriticalWarnDescription'), min: SPEED_WARNING.critical.min, max: SPEED_WARNING.critical.max, step: 0.1, unit: txt('charactersPerSecond'), switchable: true, onValuesChange: handleValuesChange, restoreValue: subtitlesDefaults.speedCriticalWarning, disabledValue: null, form: form }), _jsx(RangeInput, { label: txt('speedWarn'), name: "speedWarning", description: txt('speedWarnDescription'), min: SPEED_WARNING.normal.min, max: SPEED_WARNING.normal.max, step: 0.1, unit: txt('charactersPerSecond'), switchable: true, onValuesChange: handleValuesChange, form: form, restoreValue: subtitlesDefaults.speedWarning, disabledValue: null, validator: ({ getFieldValue }) => ({
                                validator(_, value) {
                                    const speedCriticalWarning = getFieldValue('speedCriticalWarning');
                                    if (value === null) {
                                        return Promise.resolve();
                                    }
                                    if (speedCriticalWarning !== null
                                        && value >= speedCriticalWarning) {
                                        return Promise.reject(txt('speedWarnHigh'));
                                    }
                                    return Promise.resolve();
                                },
                            }) })] }), _jsx(Tooltip, { title: isTutorialRunning ? txt('disabledDuringTutorial') : null, placement: "left", children: _jsx("div", { children: _jsx(Collapse, { bordered: false, ghost: true, className: "subtitle-revision-modal__advanced-settings", collapsible: isTutorialRunning ? 'disabled' : undefined, items: [{
                                    key: '1',
                                    label: txt('advancedSettings'),
                                    // NOTE: Collapse has to be forceRendered to enable
                                    // access to its form item initial values when closed.
                                    forceRender: true,
                                    children: (_jsxs(_Fragment, { children: [_jsx(RangeInput, { className: "warning-settings", label: txt('autofillPauseBetweenCaptions'), name: "autofillPauseBetweenCaptionsMs", description: txt('autofillPauseBetweenCaptionsInfo'), min: AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.min, max: AUTOFILL_PAUSE_BETWEEN_CAPTIONS_MS.max, step: 10, unit: "ms", switchable: true, onValuesChange: handleValuesChange, form: form, restoreValue: 500, disabledValue: 0 }), _jsx(RangeInput, { label: txt('minDuration'), name: "minDuration", description: txt('minDurationDescription'), min: MINIMAL_TIME.min, max: MINIMAL_TIME.max, step: 0.1, unit: txt('seconds'), required: true, form: form, restoreValue: subtitlesDefaults.minDuration }), _jsx(Form.Item, { label: txt('pauseBetweenCaptions'), children: _jsx(Form.Item, { name: "pauseBetweenCaptions", noStyle: true, children: _jsx(Select, { options: [
                                                            {
                                                                value: 0,
                                                                label: '0 ms',
                                                            },
                                                            {
                                                                value: 0.08,
                                                                label: '80 ms',
                                                            },
                                                        ] }) }) }), _jsx(Form.Item, { label: txt('spaceAtLineEnd'), name: "spaceAtLineEnd", valuePropName: "checked", children: _jsx(Switch, { size: "small" }) }), _jsx(Form.Item, { label: txt('subtitlerMaxLineCount'), tooltip: isCaptionMode ? {
                                                    title: txt('changeDisabled'),
                                                    icon: _jsx(InfoCircleOutlined, {}),
                                                } : null, className: "subtitle-revision-modal__radio-btns", children: _jsx(Form.Item, { name: "subtitlerMaxLineCount", children: _jsxs(Radio.Group, { disabled: isCaptionMode, children: [_jsx(Radio, { value: 1, children: `1 ${txt('line')}` }), _jsx(Radio, { value: 2, children: `2 ${txt('twoLines')}` })] }) }) }), _jsx(Form.Item, { label: txt('enablePresegmentation'), tooltip: {
                                                    title: isCaptionMode ? txt('changeDisabled') : txt('enablePresegmentationTooltip'),
                                                    icon: _jsx(InfoCircleOutlined, {}),
                                                }, name: "enablePresegmentation", valuePropName: "checked", children: _jsx(Switch, { size: "small", disabled: isCaptionMode }) }), _jsx(Form.Item, { label: txt('speakerSignPlacement'), children: _jsx(Form.Item, { name: "speakerSignPlacement", noStyle: true, children: _jsx(Select, { options: [
                                                            {
                                                                value: 'utteranceStartOnly',
                                                                label: txt('utteranceStartOnly'),
                                                            },
                                                            {
                                                                value: 'multiSpeakerCaptionsOnly',
                                                                label: txt('multiSpeakerCaptionsOnly'),
                                                            },
                                                            {
                                                                value: 'none',
                                                                label: txt('noPlacement'),
                                                            },
                                                        ] }) }) }), _jsx(Form.Item, { label: txt('useSpeakerName'), name: "useSpeakerName", valuePropName: "checked", dependencies: ['speakerSignPlacement'], rules: [
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (getFieldValue('speakerSignPlacement') === 'none' && value) {
                                                                return Promise.reject(txt('nameRequiresSign'));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ], children: _jsx(Switch, { size: "small" }) })] })),
                                }] }) }) }), isCaptionMode ? (_jsx(Tooltip, { title: isTutorialRunning ? txt('disabledDuringTutorial') : null, placement: "left", children: _jsx("div", { children: _jsx(Collapse, { ghost: true, bordered: false, collapsible: isTutorialRunning ? 'disabled' : undefined, children: _jsxs(Panel, { forceRender: visible, header: txt('switchToTranscription'), children: [_jsx(Alert, { message: txt('subtitleResetWarning'), type: "warning", showIcon: true }), _jsx("div", { className: "subtitle-revision-modal__danger-btn", children: _jsx(Button, { type: "primary", danger: true, onClick: turnOffsubtitleRevision, disabled: retrieveTutorialState() === 'running', children: txt('switchToTranscription') }) })] }, "2") }) }) })) : null, _jsx(Divider, {}), _jsx("div", { className: "subtitle-revision-modal__footer", children: _jsx("div", { children: currentTemplate.edited ? (_jsx(Alert, { message: txt('editedTemplate'), type: "warning", showIcon: true })) : null }) })] }) }));
};
export default CaptionReviewModal;
