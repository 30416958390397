import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
export const formatNumber = (number, decimalPlaces, roundType) => {
    const multiplied = number * 10 ** decimalPlaces;
    let rounded = 0;
    if (roundType === 'floor') {
        rounded = Math.floor(multiplied);
    }
    else {
        rounded = Math.ceil(multiplied);
    }
    return (rounded / 10 ** decimalPlaces).toFixed(decimalPlaces);
};
export const formatTime = (time, timeFormat) => {
    return dayjs.utc(Math.round(time * 1000)).format(timeFormat);
};
export const formatMediaTime = (seconds, startDatetime) => {
    return startDatetime === null || startDatetime === undefined
        ? dayjs.utc(seconds * 1000).format('HH:mm:ss')
        : startDatetime.add(seconds, 'second').format('HH:mm:ss');
};
