export default class TrsxParagraph {
    constructor(speaker, begin, end, forceLabel, captionMetadata) {
        this.s = speaker;
        this.begin = begin;
        this.end = end;
        this.forceLabel = forceLabel;
        this.phrases = [];
        this.captionMetadata = captionMetadata;
    }
    addPhrase(phrase) {
        this.phrases.push(phrase);
    }
    serialize() {
        const header = `      <pa s="${this.s}" b="${this.begin}" e="${this.end}"`
            + ` scribeForceLabel="${this.forceLabel}"`
            // Parameter captionsForceSpeakerSign was requested by Marek Bohac.
            // It forces speaker sign in subtitles even when speaker does not change.
            // For now it is always the same as scribeForceLabel.
            + ` captionsForceSpeakerSign="${this.forceLabel}">\n`;
        const inner = this.phrases.map((p) => p.serialize()).join('');
        const footer = '      </pa>\n';
        return header + inner + footer;
    }
}
