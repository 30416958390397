import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
// NOTE: notification needs to be imported as a static function,
// because it can be called from code independent on react.
import { notification } from 'antd';
import { txt } from 'libs/i18n';
const SaveError = () => (_jsxs("div", { children: [_jsx("h3", { children: txt('saveErrorTitle') }), _jsx("p", { children: txt('checkInternetConnection') }), _jsxs("p", { children: [txt('problemPersists'), _jsx("a", { href: txt('contactLink'), target: "_blank", rel: "noreferrer", children: ` ${txt('contactUs')}.` })] })] }));
export const displayNotification = (saveNotification) => {
    if (saveNotification.type === 'someone-edited') {
        notification.warning({
            message: txt('someoneEditedHead'),
            description: `${txt('someoneEditedReloaded')} ${txt('userThatEdited')} ${String(saveNotification.details)}`,
            placement: 'topRight',
            duration: 0,
        });
    }
    if (saveNotification.type === 'upload-failed') {
        notification.error({
            message: _jsx(SaveError, {}),
            placement: 'topRight',
            duration: 0,
        });
    }
    if (saveNotification.type === 'export-failed') {
        notification.error({
            message: txt('saveErrorTitle'),
            description: `${txt('saveFailedRefresh')} ${String(saveNotification.details)}`,
            placement: 'topRight',
            duration: 0,
        });
    }
};
