// @ts-ignore shaka player lacks typing https://github.com/shaka-project/shaka-player/issues/1030
import shaka from 'shaka-player/dist/shaka-player.compiled.debug';
import Player from './player';
export default class ShakaPlayer extends Player {
    constructor(domElement, playback, onReadyToPlay, manifestURL, connection, project) {
        super(domElement, playback, onReadyToPlay);
        this.onLoadedMetadata = (event, initTime) => {
            const loadTime = new Date().getTime() - initTime;
            global.logger.info('shaka player loaded', {
                loadTime: loadTime / 1000,
                projectName: this.project.name,
            });
            this.onReadyToPlay(this.shaka.isAudioOnly() === false);
            this.playback.changeDuration(this.shaka.seekRange().end);
        };
        this.startDurationPolling = () => {
            this.stopDurationPolling();
            this.durationPollTimer = window.setInterval(() => {
                this.playback.changeDuration(this.shaka.seekRange().end);
                if (this.shaka.isLive() === false) {
                    this.stopDurationPolling();
                }
            }, 1000);
        };
        this.stopDurationPolling = () => {
            if (this.durationPollTimer !== null) {
                clearInterval(this.durationPollTimer);
                this.durationPollTimer = null;
            }
        };
        this.durationPollTimer = null;
        this.shaka = new shaka.Player(domElement);
        this.project = project;
        this.shaka.getNetworkingEngine().registerRequestFilter((type, request) => {
            if (type === shaka.net.NetworkingEngine.RequestType.MANIFEST
                || type === shaka.net.NetworkingEngine.RequestType.SEGMENT) {
                // eslint-disable-next-line no-param-reassign
                request.headers.Authorization = connection.getOutdatedAuthString();
            }
        });
        this.shaka.addEventListener('error', (e) => {
            global.logger.error('shaka error', { event: JSON.stringify(e) });
        });
        this.shaka.load(manifestURL).then(() => {
            if (this.shaka.isLive() === true) {
                this.startDurationPolling();
            }
        });
    }
    destroy() {
        super.destroy();
        this.stopDurationPolling();
        this.shaka.destroy();
    }
}
