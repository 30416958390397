import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip } from 'antd';
import { HourglassOutlined, WarningOutlined, StopOutlined, CloseCircleFilled, CheckCircleFilled, } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import clsx from 'clsx';
import styles from '../style.module.less';
const QueueItemView = ({ item }) => {
    const iconClass = clsx(styles.uploadItemStatus, styles.uploadItemIcon);
    let icon = null;
    let tooltipText = null;
    if (item.status === 'waiting') {
        icon = _jsx(HourglassOutlined, { className: iconClass });
        tooltipText = txt('inQueue');
    }
    else if (item.status === 'success') {
        icon = _jsx(CheckCircleFilled, { style: { color: '#90ee90' }, className: iconClass });
        tooltipText = txt('uploadFinishedMsg');
    }
    else if (item.status === 'canceled') {
        icon = _jsx(StopOutlined, { className: iconClass });
        tooltipText = txt('uploadCanceledMsg');
    }
    else if (item.status === 'severed') {
        icon = _jsx(CloseCircleFilled, { style: { color: '#ff0000' }, className: iconClass });
        tooltipText = txt('uploadSeveredMsg');
    }
    else {
        icon = _jsx(WarningOutlined, { className: iconClass });
        tooltipText = item.failedReason === 'invalid-trsx'
            ? txt('failedToUploadTrsx')
            : txt('uploadFailedMsg');
    }
    const projectName = item.uploadTarget.type === 'new' ? item.uploadTarget.projectName : item.uploadTarget.project.name;
    return (_jsxs("div", { className: styles.uploadItem, children: [_jsx(Tooltip, { title: tooltipText, children: icon }), _jsx("div", { className: styles.uploadItemName, children: projectName })] }));
};
export default QueueItemView;
