import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSession } from 'components/Authenticated';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { addUserClaims, removeUserClaims, resetUserClaims, } from 'api/admin/users-api';
import { txt } from 'libs/i18n';
import clsx from 'clsx';
import styles from './style.module.less';
import iconStyles from '../ClaimsTable/style.module.less';
const Claim = ({ userId, claimApiName, claimSet, onRefetchClaims, }) => {
    const { session } = useSession();
    const isOnByDefault = claimSet.roleClaims.includes(claimApiName);
    const getCurrentState = () => {
        if (claimSet.addedClaims.includes(claimApiName)) {
            return 'enabled';
        }
        if (claimSet.removedClaims.includes(claimApiName)) {
            return 'disabled';
        }
        return 'default';
    };
    const currentState = getCurrentState();
    const handleClick = async () => {
        if (currentState === 'enabled') {
            await removeUserClaims(session.connection, userId, [claimApiName]);
        }
        else if (currentState === 'disabled') {
            await resetUserClaims(session.connection, userId, [claimApiName]);
        }
        else {
            await addUserClaims(session.connection, userId, [claimApiName]);
        }
        onRefetchClaims();
    };
    let buttonContent;
    let buttonClass = styles.button;
    if (currentState === 'enabled') {
        buttonContent = (_jsxs(Space, { children: [_jsx(CheckOutlined, { className: iconStyles.checkIcon }), txt('overriden')] }));
    }
    else if (currentState === 'disabled') {
        buttonContent = (_jsxs(Space, { children: [_jsx(CloseOutlined, { className: iconStyles.closeIcon }), txt('overriden')] }));
    }
    else {
        buttonClass = clsx(styles.defaultButton, styles.button);
        buttonContent = (_jsxs(Space, { children: [isOnByDefault ? (_jsx(CheckOutlined, { className: iconStyles.checkIcon })) : (_jsx(CloseOutlined, { className: iconStyles.closeIcon })), txt('default')] }));
    }
    return (_jsx("button", { className: buttonClass, onClick: handleClick, type: "button", children: buttonContent }));
};
export default Claim;
