class SpeakerSuggestionWebSocket {
    constructor(endpoint) {
        this.revive = () => {
            try {
                this.websocket.close();
            }
            catch (error) {
                // if closing websocket fails, ignore it
                global.logger.warn('failed to close crashed websocket', {}, error);
            }
            this.websocket = this.connect();
        };
        this.send = (message) => this.websocket.send(message);
        this.onMessageHandler = () => { };
        this.connect = () => {
            const websocket = new WebSocket(this.endpoint);
            websocket.onmessage = this.onMessageHandler;
            return websocket;
        };
        this.endpoint = endpoint;
        this.websocket = this.connect();
    }
    setOnMessage(handler) {
        this.onMessageHandler = handler;
        this.websocket.onmessage = this.onMessageHandler;
    }
    close() {
        this.websocket.close();
    }
}
export default SpeakerSuggestionWebSocket;
