import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const ConfirmImportTrsxModal = ({ isVisible, title, content, okText, asyncAction, onCancel, }) => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (loading) {
            // NOTE: React calls the function before the UI is rerendered. But importing trsx
            // can be computationally demanding and that is why it has to be called after
            // rerendering the UI to let the user now, that loading is occuring.
            setTimeout(() => {
                asyncAction();
                onCancel();
            }, 100);
        }
    }, [loading]);
    return (_jsx(Modal, { className: styles.confirmationModal, title: (_jsxs(_Fragment, { children: [_jsx(WarningOutlined, { className: styles.iconWarning }), ' ', title] })), open: isVisible, onCancel: onCancel, footer: [
            _jsx(Button, { type: "default", onClick: onCancel, children: txt('cancel') }, "back"),
            _jsxs(Button, { type: "primary", danger: true, disabled: loading, onClick: () => setLoading(true), children: [loading ? _jsx(LoadingOutlined, {}) : null, okText] }, "submit"),
        ], children: _jsx("p", { children: content }) }));
};
export default ConfirmImportTrsxModal;
