import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getRectFromElement, getRectElementPositionAsync } from 'libs/positions';
import React, { useEffect, useRef, useState } from 'react';
import deepEqual from 'fast-deep-equal';
import { useSession } from 'components/Authenticated';
import DocumentWidget from '../DocumentWidget';
import SectionTag from '../DocumentWidget/SectionTag';
import useResizeObserver from '../../MediaPlayer/use-resize-observer';
import SpeakerWidget from '../DocumentWidget/SpeakerWidget';
const DocumentWidgets = ({ editorController, isRightToLeft, isCaptionMode, onClick, disabled, editorRef, }) => {
    const { session } = useSession();
    const speakerNodes = editorController.speakers.getSpeakerNodes();
    const sectionNodes = editorController.sections.getSectionNodes();
    const widgetsRef = useRef(null);
    // NOTE: We need the state to re-render the component but do not use positions directly.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [positions, setPositions] = useState(() => ({
        speakers: getRectElementPositionAsync(speakerNodes),
        sections: getRectElementPositionAsync(sectionNodes),
    }));
    const prevPositions = useRef({
        speakers: [],
        sections: [],
        scrollOffset: 0,
    });
    const isOnlyScrollDifference = (prevPosition, currentPosition, editorElement) => {
        if (prevPosition === undefined) {
            return false;
        }
        const previousRelativePos = prevPosition.top - editorElement.offsetTop;
        const currentRelativePos = currentPosition.top - editorElement.offsetTop;
        const roundedPosDifference = Math.round((currentRelativePos - previousRelativePos) * 10) / 10;
        const roundedScrollDifference = Math.round((prevPositions.current.scrollOffset - editorElement.scrollTop) * 10) / 10;
        if (roundedPosDifference === roundedScrollDifference) {
            return true;
        }
        return false;
    };
    const setNewPositions = () => {
        if (editorRef.current === null) {
            return;
        }
        const newPositions = {
            speakers: getRectElementPositionAsync(speakerNodes),
            sections: getRectElementPositionAsync(sectionNodes),
        };
        prevPositions.current = {
            speakers: newPositions.speakers,
            sections: newPositions.sections,
            scrollOffset: editorRef.current.scrollTop,
        };
        setPositions({ speakers: newPositions.speakers, sections: newPositions.sections });
    };
    const handlePositionsUpdate = (event) => {
        const arrayOfSpeakersNodes = [...speakerNodes];
        const speakersBeginIndex = arrayOfSpeakersNodes.map((node) => editorController.speakers.getSpeakerIndexRange(node).begin);
        const closestHighestSpeakerIndex = speakersBeginIndex.findIndex((index) => index >= event.editedFrom);
        // NOTE: No speaker found or editor not initialized.
        if (closestHighestSpeakerIndex === -1 || editorRef.current === null) {
            return;
        }
        const closestSpeakerPosition = getRectFromElement(arrayOfSpeakersNodes[closestHighestSpeakerIndex]);
        const prevPosition = prevPositions.current.speakers[closestHighestSpeakerIndex];
        if (deepEqual(prevPosition, closestSpeakerPosition) === false) {
            if (isOnlyScrollDifference(prevPosition, closestSpeakerPosition, editorRef.current)) {
                return;
            }
            setNewPositions();
        }
    };
    const handleWidgetsChanged = () => {
        setNewPositions();
    };
    useResizeObserver({
        callback: setNewPositions,
        elementRef: widgetsRef,
    });
    useEffect(() => {
        handlePositionsUpdate({ editedFrom: 0, editedTo: 0 });
    }, [session.login.user.settings.editorFontDelta]);
    useEffect(() => {
        editorController.addEventListener('text-changed', handlePositionsUpdate);
        editorController.addEventListener('widget-changed', handleWidgetsChanged);
        return () => {
            editorController.removeEventListener('text-changed', handlePositionsUpdate);
            editorController.removeEventListener('widget-changed', handleWidgetsChanged);
        };
    }, []);
    const generateSectionTags = () => {
        if (!editorController.areSectionTagsAllowed) {
            return null;
        }
        const sectionTags = Array.from(sectionNodes, (node) => {
            return (_jsx(DocumentWidget, { WidgetComponent: SectionTag, widgetTrigger: node, onClick: onClick, disabled: disabled, editorController: editorController, isRightToLeft: isRightToLeft, isCaptionMode: isCaptionMode }));
        });
        return sectionTags;
    };
    const generateSpeakerWidgets = () => {
        const speakerWidgets = Array.from(speakerNodes, (node, index) => {
            const speakerChild = node.firstElementChild;
            return (_jsx("div", { children: _jsx(DocumentWidget, { WidgetComponent: SpeakerWidget, widgetTrigger: speakerChild, onClick: onClick, disabled: disabled, editorController: editorController, isCaptionMode: isCaptionMode, isRightToLeft: isRightToLeft }) }, index));
        });
        return speakerWidgets;
    };
    return (_jsxs("div", { ref: widgetsRef, children: [generateSectionTags(), generateSpeakerWidgets()] }));
};
const MemoizedWidgets = React.memo(DocumentWidgets);
export default MemoizedWidgets;
