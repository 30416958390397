import { BeeyLocale } from './locale';
import { getCurrentUiLocale } from './ui';
export const defaultRecognitionLocale = BeeyLocale.const('en-US');
export const selectInitialTranscriptionOption = (options, suggestedLocale = null) => {
    if (options.length === 0) {
        return null;
    }
    const selected = suggestedLocale !== null && suggestedLocale !== void 0 ? suggestedLocale : getCurrentUiLocale();
    const exactOption = options.find((opt) => opt.locale.equals(selected));
    if (exactOption !== undefined) {
        return exactOption;
    }
    const similarOption = options.find((opt) => opt.locale.lang === selected.lang);
    if (similarOption !== undefined) {
        return similarOption;
    }
    const defaultOption = options.find((opt) => opt.locale.equals(defaultRecognitionLocale));
    if (defaultOption !== undefined) {
        return defaultOption;
    }
    return options[0];
};
export const selectInitialTranscriptionProfile = (option, suggestedProfile = null) => {
    if (suggestedProfile === null) {
        return 'default';
    }
    if (option.profiles.some((profile) => profile.profile === suggestedProfile)) {
        return suggestedProfile;
    }
    return 'default';
};
