import Quill from 'quill';
import { Speaker } from 'api/speaker';
import { escapeXMLString } from 'libs/xml-utils';
const BlockBlot = Quill.import('blots/block');
const generateClasses = (formatValue) => {
    const valueFields = formatValue.split(' ');
    if (valueFields.length > 1) {
        const [id, captionColor] = valueFields;
        return `speaker speakerid-${id} caption-color-${captionColor}`;
    }
    const id = valueFields[0];
    return `speaker speakerid-${id}`;
};
class SpeakerBlot extends BlockBlot {
    static create(value) {
        const node = super.create(value);
        node.className = generateClasses(value);
        node.setAttribute('contenteditable', false);
        return node;
    }
    static formats(domNode) {
        try {
            return Speaker.getQuillFormatValueFromNode(domNode);
        }
        catch (e) {
            global.logger.error('cannot extract speaker format from node', {}, e);
        }
        // NOTE: Sometimes when pasting speakers from Microsoft Word, speaker id is lost.
        // We use the speaker label as fallback. Also we make sure that there are no
        // characters that would mess up serialization
        return escapeXMLString(domNode.innerText.replace(' ', '_'));
    }
    format(name, value) {
        if (name === 'speaker' && value) {
            this.domNode.className = generateClasses(value);
            this.domNode.setAttribute('contenteditable', false);
        }
        else {
            super.format(name, value);
        }
    }
    formats() {
        const formats = super.formats();
        formats.speaker = SpeakerBlot.formats(this.domNode);
        return formats;
    }
    attach() {
        if (this.parent != null) {
            this.scroll = this.parent.scroll;
        }
    }
}
SpeakerBlot.blotName = 'speaker';
SpeakerBlot.tagName = 'h4';
export default SpeakerBlot;
