import { jsx as _jsx } from "react/jsx-runtime";
import { CheckCircleOutlined, CloseCircleOutlined, HourglassOutlined, LoadingOutlined, } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import { Tooltip } from 'antd';
const JobState = ({ state }) => {
    if (state === 'WAITING') {
        return _jsx(Tooltip, { title: (txt('inQueue')), children: _jsx(HourglassOutlined, {}) });
    }
    if (state === 'RUNNING') {
        return _jsx(Tooltip, { title: (txt('preparing')), children: _jsx(LoadingOutlined, {}) });
    }
    if (state === 'SUCCESS') {
        return _jsx(CheckCircleOutlined, { style: { color: '#52c41a' } });
    }
    return _jsx(CloseCircleOutlined, { style: { color: '#ff0000' } });
};
export default JobState;
