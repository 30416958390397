import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Space, Tooltip } from 'antd';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
import modalStyles from '../style.module.less';
const RequiredCredit = ({ mediaOriginsList }) => {
    const { session } = useSession();
    if (!session.login.hasClaim('paidFeatures:enabled')) {
        return (_jsx(Alert, { type: "info", className: styles.trialAlert, showIcon: true, message: txt('trialCredit') }));
    }
    const credit = mediaOriginsList.computeRequiredCredit();
    if (credit === 0 || credit === 'cannot-compute') {
        return null;
    }
    if (credit === 'loading') {
        return (_jsxs("div", { className: styles.loadingDurationAlert, children: [_jsx(LoadingOutlined, {}), ' ', _jsx("span", { className: styles.loadingDurationMessage, children: (txt('durationRecognition')) })] }));
    }
    return (_jsxs("div", { className: modalStyles.creditSectionInfo, children: [_jsx("span", { className: `${modalStyles.label} ${modalStyles.creditSectionLabel}`, children: `${txt('estimatedCredit')}: ` }), credit === 'unknown'
                ? (_jsxs("span", { className: styles.durationAlert, children: [_jsx("span", { children: txt('durationUnknown') }), _jsx("div", { children: txt('checkCredit') })] }))
                : (_jsxs(Space, { children: [_jsx("span", { children: `${credit} min` }), _jsx(Tooltip, { title: txt('finalCreditPrice'), children: _jsx(InfoCircleOutlined, {}) })] }))] }));
};
export default RequiredCredit;
