import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import appParams from 'app-params.json5';
import { useSession } from 'components/Authenticated';
import { IdcardOutlined, LogoutOutlined, UserOutlined, GlobalOutlined, ShoppingCartOutlined, } from '@ant-design/icons';
import { Divider } from 'antd';
import { storeUserUiLocale } from 'api/user-api';
import { useAppContext } from 'App/app-context';
import { sortedLocales, txt } from 'libs/i18n';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import LanguageSelect from 'components/LanguageSelect';
import { getCurrentUiLocale, getUiLocales } from 'libs/locales/ui';
import styles from './style.module.less';
import tacrLogo from './img/tacr-logo.png';
import norGrantsLogo from './img/norway-grants-logo.png';
import euLogo from './img/eu-logo.png';
import mspLogo from './img/msp-logo.png';
const UserMenu = () => {
    const { changeUiLocale } = useAppContext();
    const { session } = useSession();
    const canBuyCredit = session.login.hasClaim('orders:create') && session.login.hasClaim('team:owner');
    const logout = () => {
        void session.explicitLogout();
    };
    const generateLogos = () => {
        if (session.login.hasClaim('dataTheme:msp')) {
            return (_jsxs("div", { className: clsx(styles.logos, styles.msp), children: [_jsx("img", { width: "160", className: styles.logo, src: mspLogo, alt: "Ministry of Justice logo" }), _jsx("img", { width: "160", className: styles.logo, src: euLogo, alt: "European union logo" })] }));
        }
        return (_jsxs("div", { className: styles.logos, children: [_jsx("img", { width: "25", className: clsx(styles.logo, styles.logoTacr), src: tacrLogo, alt: "Tacr logo" }), _jsx("img", { width: "36", className: styles.logo, src: norGrantsLogo, alt: "Norway grants logo" })] }));
    };
    const handleChangeUiLocale = (newLocale) => {
        changeUiLocale(newLocale);
        void storeUserUiLocale(session.connection, newLocale);
    };
    const uiLocales = sortedLocales(getUiLocales());
    return (_jsxs("div", { children: [_jsxs("div", { className: styles.user, children: [_jsx(UserOutlined, {}), _jsxs("div", { className: styles.userInfo, children: [_jsx("p", { className: styles.userDetail, children: _jsx("strong", { children: ` ${session.login.user.email}` }) }), _jsx("p", { className: styles.userDetail, children: session.login.user.role })] })] }), _jsx(Divider, { className: clsx(styles.dividerTop, styles.divider) }), _jsx("div", { className: styles.link, children: _jsxs(Link, { to: "/account/myprofile", children: [_jsx(IdcardOutlined, { className: styles.icon }), _jsx("span", { children: txt('myProfile') })] }) }), canBuyCredit ? (_jsx("div", { className: styles.link, children: _jsxs(Link, { to: "/account/credit", children: [_jsx(ShoppingCartOutlined, { className: styles.icon }), _jsx("span", { children: txt('creditAndPayments') })] }) })) : null, _jsxs("div", { className: styles.language, children: [_jsx(GlobalOutlined, { className: styles.icon }), _jsxs("span", { className: styles.label, children: [txt('language'), ":"] }), _jsx("span", { className: styles.langControls, children: _jsx(LanguageSelect, { locales: uiLocales, value: getCurrentUiLocale(), onChange: handleChangeUiLocale }) })] }), _jsx("div", { className: styles.link, children: _jsxs("button", { id: "logoutID", className: styles.logoutButton, type: "button", onClick: logout, children: [_jsx(LogoutOutlined, { className: styles.icon }), txt('logout')] }) }), _jsx(Divider, { className: clsx(styles.divider, styles.dividerBottom) }), _jsxs("div", { className: styles.productInfo, children: [_jsxs("div", { className: styles.beeyInfo, children: ["\u00A9 \u00A0", `${new Date().getFullYear()} ${appParams.productName} by `, _jsx("a", { href: appParams.company.webpage, target: "_blank", rel: "noopener noreferrer", children: appParams.company.name }), _jsx("br", {}), `version ${VERSION}`] }), _jsx(Divider, { type: "vertical", className: styles.divider }), generateLogos()] })] }));
};
export default UserMenu;
