import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Spin, Switch, Table } from 'antd';
import { txt } from 'libs/i18n';
import { BYTES_IN_GIGABYTE } from 'libs/utils';
import styles from './style.module.less';
const StorageStatisticsInfo = ({ storageStatistics, onStorageStatisticsChange, userDataOnly }) => {
    const columns = [
        {
            title: userDataOnly ? txt('userStatistics') : txt('storageStatistics'),
            dataIndex: 'bytes',
            render: (bytes) => (_jsxs("span", { children: [(bytes / BYTES_IN_GIGABYTE).toFixed(2), ' ', "GB"] })),
        },
        {
            title: txt('sharedWithTeamCount'),
            dataIndex: 'shareCount',
            render: (shareCount) => (_jsx("span", { children: shareCount - 1 })),
        },
    ];
    const totalStorageSize = () => {
        if (storageStatistics !== 'noshow' && storageStatistics !== 'loading') {
            return (_jsxs("p", { className: styles.total, children: [txt('total'), (storageStatistics.storagePerCount.reduce((acc, cur) => acc + cur.bytes, 0) / BYTES_IN_GIGABYTE).toFixed(2), ' ', "GB"] }));
        }
        return null;
    };
    if (storageStatistics === 'noshow') {
        return null;
    }
    if (storageStatistics === 'loading') {
        return (_jsxs(_Fragment, { children: [_jsx("p", { children: txt('longLoading') }), _jsx(Spin, {})] }));
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.switch, children: [_jsx("span", { className: styles.allData, children: txt('allData') }), _jsx(Switch, { onChange: onStorageStatisticsChange, value: userDataOnly }), _jsx("span", { className: styles.userData, children: txt('userData') })] }), _jsx(Table, { columns: columns, dataSource: storageStatistics.storagePerCount, rowKey: (record) => record.shareCount, pagination: {
                    size: 'small',
                    pageSize: 5,
                }, footer: () => (totalStorageSize()) })] }));
};
export default StorageStatisticsInfo;
