import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckOutlined, CloseOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Flex, Popconfirm, Space, } from 'antd';
import { addUserClaims, removeUserClaims, resetUserClaims } from 'api/admin/users-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import buttonStyles from 'components/DownloadButton/style.module.less';
import styles from './style.module.less';
const ClaimsBulkChange = ({ claimsList, userId, onRefetchClaims, claimSet, }) => {
    const { session } = useSession();
    const handleClaimsBulkChange = async (action) => {
        const claimsApiNames = claimsList.map((claim) => claim.apiName);
        if (action === 'enable') {
            await addUserClaims(session.connection, userId, claimsApiNames);
        }
        if (action === 'disable') {
            await removeUserClaims(session.connection, userId, claimsApiNames);
        }
        const claimsToReset = claimsApiNames
            .filter((claimApiName) => (claimSet.addedClaims.includes(claimApiName)
            || claimSet.removedClaims.includes(claimApiName)));
        if (action === 'reset') {
            await resetUserClaims(session.connection, userId, claimsToReset);
        }
        onRefetchClaims();
    };
    return (_jsxs(Flex, { gap: "small", wrap: "wrap", justify: "center", children: [_jsx(Flex, { justify: "right", className: styles.reset, children: _jsx(Popconfirm, { title: txt('enableAllClaims'), description: txt('enableAllClaimsInfo'), onConfirm: () => handleClaimsBulkChange('enable'), children: _jsx(Button, { size: "small", type: "primary", className: buttonStyles.button, children: _jsxs(Space, { children: [_jsx(CheckOutlined, { className: styles.checkIcon }), txt('enableAllClaims')] }) }) }) }), _jsx(Flex, { justify: "right", className: styles.reset, children: _jsx(Popconfirm, { title: txt('disableAllClaims'), description: txt('disableAllClaimsInfo'), onConfirm: () => handleClaimsBulkChange('disable'), children: _jsx(Button, { size: "small", type: "primary", className: buttonStyles.button, children: _jsxs(Space, { children: [_jsx(CloseOutlined, { className: styles.closeIcon }), txt('disableAllClaims')] }) }) }) }), _jsx(Flex, { justify: "right", className: styles.reset, children: _jsx(Popconfirm, { title: txt('resetToDefault'), description: txt('resetToDefaultInfo'), onConfirm: () => handleClaimsBulkChange('reset'), children: _jsx(Button, { size: "small", type: "primary", className: buttonStyles.button, children: _jsxs(Space, { children: [_jsx(RedoOutlined, {}), txt('resetToDefault')] }) }) }) })] }));
};
export default ClaimsBulkChange;
