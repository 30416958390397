import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Button, Form, Input, } from 'antd';
import { fetchPasswordSettings } from 'api/user-api';
import { Alerts } from 'components/Alerts';
import { txt } from 'libs/i18n';
import formStyles from '../../LoginScreen/LoginForm/style.module.less';
import styles from './style.module.less';
const SignUpFormEmail = ({ onContinue, signUpValues, isAlertVisible, isLoginEnabled, onBackToSignUp, }) => {
    const [requiredLength, setRequiredLength] = useState(6);
    const [form] = Form.useForm();
    useEffect(() => {
        const getRequiredPwdLength = async () => {
            const passwordSettings = await fetchPasswordSettings();
            setRequiredLength(passwordSettings.requiredLength);
        };
        void getRequiredPwdLength();
    }, []);
    return (_jsxs(_Fragment, { children: [isAlertVisible && Alerts.warning(txt('alreadyExists')), _jsxs(Form, { form: form, onFinish: onContinue, className: formStyles.form, initialValues: signUpValues, children: [_jsx("p", { children: txt('enterEmailPassword') }), _jsx(Form.Item, { name: "email", className: formStyles.formInput, rules: [
                            {
                                required: true,
                                transform: (value) => value.trim(),
                                type: 'email',
                                message: txt('pleaseEmail'),
                            },
                            {
                                max: 254,
                                message: txt('maxCharacters'),
                            },
                        ], children: _jsx(Input, { placeholder: txt('email'), autoFocus: true, size: "large" }) }), _jsx(Form.Item, { name: "password", className: formStyles.formInput, hasFeedback: true, rules: [
                            {
                                required: true,
                                message: txt('yourPassword'),
                            },
                            {
                                min: requiredLength,
                                whitespace: true,
                                message: `${txt('minChars')}${requiredLength}`,
                            },
                            {
                                max: 128,
                                message: txt('maxCharacters'),
                            },
                        ], children: _jsx(Input, { type: "password", placeholder: txt('pw'), size: "large" }) }), _jsx(Form.Item, { name: "passwordConfirm", className: formStyles.signUpInput, dependencies: ['password'], hasFeedback: true, rules: [
                            {
                                required: true,
                                message: txt('yourPassword'),
                            },
                            {
                                min: requiredLength,
                                whitespace: true,
                                message: `${txt('minChars')}${requiredLength}`,
                            },
                            {
                                max: 128,
                                message: txt('maxCharacters'),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    // eslint-disable-next-line prefer-promise-reject-errors
                                    return Promise.reject(txt('noMatch'));
                                },
                            }),
                        ], children: _jsx(Input, { type: "password", placeholder: txt('confirmPW'), size: "large" }) }), _jsx("div", { children: _jsx(Button, { className: formStyles.formButton, type: "primary", htmlType: "submit", size: "large", disabled: !isLoginEnabled, children: txt('continue') }) }), _jsx("div", { className: styles.backButton, children: _jsx(Button, { type: "link", onClick: onBackToSignUp, children: txt('backLabel') }) })] })] }));
};
export default SignUpFormEmail;
