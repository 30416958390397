import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useMemo, useState, createContext, } from 'react';
import MediaSource from 'libs/media-processing/media-source';
import * as clientEnv from 'libs/client-env';
import Playback from '../MediaPlayer/playback';
const loadingPlayerState = {
    status: 'loading',
    video: 'no-video',
};
const disabledPlayerState = {
    status: 'disabled',
    video: 'no-video',
};
export const playbackContext = createContext({
    mediaSource: MediaSource.NONE,
    playback: new Playback(),
    // @ts-ignore
    captions: null,
    playerState: loadingPlayerState,
    setReadyToPlay: () => { },
    setShowVideo: () => { },
});
export const usePlaybackContext = () => useContext(playbackContext);
const buildPlayerState = (mediaSource, projectErrorStatus, mediaLoadingStatus, videoDisplayStatus) => {
    if (projectErrorStatus !== 'no-error') {
        return disabledPlayerState;
    }
    if (!mediaSource.isAvailable()) {
        return disabledPlayerState;
    }
    if (mediaLoadingStatus.isLoading) {
        return loadingPlayerState;
    }
    if (!mediaLoadingStatus.mediaHasVideo) {
        return {
            status: 'ready',
            video: 'no-video',
        };
    }
    return {
        status: 'ready',
        video: videoDisplayStatus,
    };
};
const WithMediaPlayback = ({ mediaSource, playback, captions, projectErrorStatus, children, }) => {
    const [mediaLoadingStatus, setMediaLoadingStatus] = useState({
        isLoading: true,
        mediaHasVideo: false,
    });
    const [videoDisplayStatus, setVideoDisplayStatus] = useState({
        isVisible: clientEnv.getIsVideoVisible(),
        isMaximized: true,
    });
    const setReadyToPlay = (mediaHasVideo) => setMediaLoadingStatus({
        isLoading: false,
        mediaHasVideo,
    });
    const setShowVideo = (isVisible, isMaximized = true) => {
        setVideoDisplayStatus({ isVisible, isMaximized });
        clientEnv.setIsVideoVisible(isVisible);
    };
    const playerState = buildPlayerState(mediaSource, projectErrorStatus, mediaLoadingStatus, videoDisplayStatus);
    const value = useMemo(() => ({
        mediaSource,
        playback,
        captions,
        playerState,
        setReadyToPlay,
        setShowVideo,
    }), [
        mediaSource,
        playback,
        captions,
        playerState,
        setReadyToPlay,
        setShowVideo,
    ]);
    return (_jsx(playbackContext.Provider, { value: value, children: children }));
};
export default WithMediaPlayback;
