import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tooltip, Progress } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import clsx from 'clsx';
import styles from '../style.module.less';
const ActiveItemView = ({ item }) => {
    if (item === null) {
        return null;
    }
    let icon = null;
    let tooltipText = null;
    if (item.status === 'running') {
        icon = (_jsx(Progress, { className: clsx(styles.uploadItemStatus, styles.uploadItemProgress), size: 30, type: "circle", percent: item.progress }));
        tooltipText = txt('uploading');
    }
    else {
        icon = _jsx(LoadingOutlined, { className: clsx(styles.uploadItemIcon, styles.uploadItemStatus) });
        tooltipText = txt('uploadConnectingMsg');
    }
    const projectName = item.uploadTarget.type === 'new' ? item.uploadTarget.projectName : item.uploadTarget.project.name;
    return (_jsxs("div", { className: styles.uploadItem, children: [_jsx(Tooltip, { title: tooltipText, children: icon }), _jsx("div", { className: styles.uploadItemName, children: projectName })] }));
};
export default ActiveItemView;
