import { txt } from 'libs/i18n';
import { formatTime } from 'libs/format-number';
import { getAllMatchIndices, TIME_ANCHOR_SYMBOL, } from './text-utils';
const TIME_ANCHOR_REGEX = new RegExp(TIME_ANCHOR_SYMBOL, 'g');
export default class TimeAnchors {
    constructor(editorControler) {
        this.requireInsertTimestampConfirm = true;
        this.handleTextChanged = (editedFrom, editedTo) => {
            this.restoreMetadata(editedFrom, editedTo);
            this.cleanupFormatting(editedFrom, editedTo);
        };
        this.editorController = editorControler;
    }
    forceTimestampAndAdjust(timeAdjustment) {
        const selection = this.editorController.getSelection();
        if (selection === null)
            return;
        const currentBegin = this.editorController.textMetadata.getBeginAtIndex(selection.index);
        // round to nicer value before adjusting
        this.insertTimeAnchor(Math.round(currentBegin * 10) / 10 + timeAdjustment);
    }
    insertTimeAnchor(seconds_, textIndex = null, shouldAutomaticallyAdjust = false, minimumDistanceFromOtherTimestampLeft = 0, minimumDistanceFromOtherTimestampRight = 0, silent = false) {
        var _a, _b;
        let seconds = seconds_;
        let index = null;
        if (textIndex === null) {
            const selection = this.editorController.getSelection();
            if (selection === null)
                return;
            index = this.editorController.getWordStart(selection.index);
        }
        else {
            index = textIndex;
        }
        const format = this.editorController.getLineFormat(index);
        if (this.editorController.isNonTranscriptFormat(format)) {
            void this.editorController.onMessage('info', txt('timestampPlacementError'));
            return;
        }
        if (index > 0 && this.editorController.getText(index - 1, 1) === TIME_ANCHOR_SYMBOL) {
            // replace previous anchor
            this.insertTimeAnchor(seconds, index - 1, shouldAutomaticallyAdjust, minimumDistanceFromOtherTimestampLeft, minimumDistanceFromOtherTimestampRight, silent);
            return;
        }
        const nextAnchor = (_a = this.editorController.textMetadata.getMetadataAfterIndex('timeAnchor', index + 1).value) !== null && _a !== void 0 ? _a : Infinity;
        const previousAnchor = (_b = this.editorController.textMetadata.getMetadataBeforeIndex('timeAnchor', index - 1).value) !== null && _b !== void 0 ? _b : -Infinity;
        const lowerBound = Math.max(0, previousAnchor + minimumDistanceFromOtherTimestampLeft);
        const upperBound = nextAnchor - minimumDistanceFromOtherTimestampRight;
        if (seconds <= lowerBound) {
            if (shouldAutomaticallyAdjust) {
                seconds = lowerBound;
            }
            else if (seconds < 0) {
                void this.editorController.onMessage('error', txt('timestampUnderZero'));
                return;
            }
            else {
                void this.editorController.onMessage('error', txt('conflictingPreviousTimestamp'));
                return;
            }
        }
        if (seconds >= upperBound) {
            if (shouldAutomaticallyAdjust) {
                seconds = upperBound;
                if (seconds < lowerBound) {
                    // This is a hypothetical edge case.
                    global.logger.error('new time anchor is too close to both the previous and to the next.');
                    return;
                }
            }
            else {
                void this.editorController.onMessage('error', txt('conflictingNextTimestamp'));
                return;
            }
        }
        const previousTime = this.editorController.textMetadata.getTimestampsAtIndex(index).begin;
        const newTime = seconds;
        if (Math.abs(previousTime - newTime) > 10 && this.requireInsertTimestampConfirm) {
            // eslint-disable-next-line no-alert, no-restricted-globals
            if (!confirm(`${txt('confirmTimestamp')}\n\
          ${txt('previousTimestamp')}: ${formatTime(previousTime, 'HH:mm:ss.SSS')}\n
          ${txt('newTimestamp')}: ${formatTime(newTime, 'HH:mm:ss.SSS')}`)) {
                return;
            }
        }
        // NOTE: In some cases users need to do large timestamp adjustments many times in a single
        // document and they would need to confirm this warning many times, so we don't show it again
        // after it is confirmed once in this document.
        this.requireInsertTimestampConfirm = false;
        this.insertTimestampWithoutAdjusting(seconds, index);
        if (!silent) {
            void this.editorController.onMessage('success', `${txt('timestampSuccess')} ${formatTime(seconds, 'HH:mm:ss.SSS')}`, 0.5);
        }
    }
    insertTimestampWithoutAdjusting(seconds, index) {
        var _a;
        const newFormat = {
            timeAnchor: seconds,
        };
        if (this.editorController.getText(index, 1) === TIME_ANCHOR_SYMBOL) {
            // replacing previous time anchor
            this.editorController.formatText(index, 1, newFormat, 'api');
        }
        else {
            this.editorController.insertTextWithFormat(index, TIME_ANCHOR_SYMBOL, newFormat, 'user');
            if (((_a = this.editorController.getSelection()) === null || _a === void 0 ? void 0 : _a.index) === index) {
                this.editorController.setSelection(index + 1, 0);
            }
        }
        this.editorController.getHistory().cutoff();
        this.editorController.textMetadata.addMetadata('timeAnchor', index, seconds);
        this.editorController.documentAligner.alignRangeWithExpanding(index - 100, index + 100);
        void this.editorController.captions.resolveUpdateRequests();
    }
    restoreMetadata(from, to) {
        const text = this.editorController.getText(from, to - from);
        const timeAnchorIndices = getAllMatchIndices(text, TIME_ANCHOR_REGEX);
        timeAnchorIndices.forEach((i) => {
            if (this.editorController.quill === undefined)
                return;
            const index = from + i;
            const anchorValue = this.editorController.getFormat(index, 1).timeAnchor;
            this.editorController.textMetadata.addMetadata('timeAnchor', index, Number(anchorValue));
        });
    }
    /*
    After editing around time anchors, user can create text that is not a time anchor, but
    has time anchor format. This function automatically fixes such cases.
    */
    cleanupFormatting(editedFrom, editedTo) {
        for (let i = editedFrom; i <= editedTo; i += 1) {
            if (this.editorController.getFormat(i, 1).timeAnchor !== undefined
                && this.editorController.getText(i, 1) !== TIME_ANCHOR_SYMBOL) {
                this.editorController.formatText(i, 1, { timeAnchor: null }, 'api');
            }
        }
    }
}
