import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Checkbox, Form, Select, } from 'antd';
import { txt } from 'libs/i18n';
import formStyles from '../../LoginScreen/LoginForm/style.module.less';
import styles from './style.module.less';
const SurveyForm = ({ surveyAnswers, onSubmit, onChange }) => {
    const [form] = Form.useForm();
    return (_jsxs(Form, { form: form, className: styles.dataConsentForm, layout: "vertical", initialValues: surveyAnswers, onFinish: onSubmit, onValuesChange: (_, answers) => onChange(answers), children: [_jsx(Form.Item, { name: "origin", className: styles.dataConsentFormItem, label: txt('usersOriginQuestion'), rules: [
                    {
                        validator: (_, value) => {
                            return value ? Promise.resolve() : Promise.reject(txt('pleaseChooseAnswer'));
                        },
                    },
                ], children: _jsxs(Select, { children: [_jsx(Select.Option, { value: "searchEngineOrigin", children: txt('searchEngineOrigin') }, "searchEngineOrigin"), _jsx(Select.Option, { value: "articleOrigin", children: txt('articleOrigin') }, "articleOrigin"), _jsx(Select.Option, { value: "adOrigin", children: txt('adOrigin') }, "adOrigin"), _jsx(Select.Option, { value: "socialNetworkOrigin", children: txt('socialNetworkOrigin') }, "socialNetworkOrigin"), _jsx(Select.Option, { value: "acquaintanceOrigin", children: txt('acquaintanceOrigin') }, "acquaintanceOrigin"), _jsx(Select.Option, { value: "conferenceOrigin", children: txt('conferenceOrigin') }, "conferenceOrigin"), _jsx(Select.Option, { value: "otherOrigin", children: txt('otherOrigin') }, "otherOrigin")] }) }), _jsx(Form.Item, { name: "field", className: styles.dataConsentFormItem, label: txt('usersFieldQuestion'), rules: [
                    {
                        validator: (_, value) => (value ? Promise.resolve() : Promise.reject(txt('pleaseChooseAnswer'))),
                    },
                ], children: _jsxs(Select, { children: [_jsx(Select.Option, { value: "coachingField", children: txt('coachingField') }, "coachingField"), _jsx(Select.Option, { value: "conferencesField", children: txt('conferencesField') }, "conferencesField"), _jsx(Select.Option, { value: "researchField", children: txt('researchField') }, "researchField"), _jsx(Select.Option, { value: "financesField", children: txt('financesField') }, "financesField"), _jsx(Select.Option, { value: "publicAdministrationField", children: txt('publicAdministrationField') }, "publicAdministrationField"), _jsx(Select.Option, { value: "mediaField", children: txt('mediaField') }, "mediaField"), _jsx(Select.Option, { value: "lawField", children: txt('lawField') }, "lawField"), _jsx(Select.Option, { value: "marketingField", children: txt('marketingField') }, "marketingField"), _jsx(Select.Option, { value: "healthField", children: txt('healthField') }, "healthField"), _jsx(Select.Option, { value: "nonprofitField", children: txt('nonprofitField') }, "nonprofitField"), _jsx(Select.Option, { value: "contentCreationField", children: txt('contentCreationField') }, "content CreationField"), _jsx(Select.Option, { value: "humanResourcesField", children: txt('humanResourcesField') }, "humanResourcesField"), _jsx(Select.Option, { value: "studentField", children: txt('studentField') }, "studentField"), _jsx(Select.Option, { value: "videoProdField", children: txt('videoProdField') }, "videoProdField"), _jsx(Select.Option, { value: "otherField", children: txt('other') }, "otherField")] }) }), _jsx(Form.Item, { name: "dataConsent", valuePropName: "checked", rules: [
                    {
                        validator: (_, value) => (value ? Promise.resolve() : Promise.reject(txt('agreementOnSignup'))),
                    },
                ], children: _jsxs(Checkbox, { children: [txt('conditionsAgreement'), _jsx("a", { href: txt('termsConditionsUrl'), target: "_blank", rel: "noreferrer", children: txt('termsConditions') }), txt('dataAgreement'), _jsx("a", { href: txt('dataAgreementUrl'), target: "_blank", rel: "noreferrer", children: txt('myPersonalData') }), _jsx("span", { className: "asterisk", children: "*" })] }) }), _jsx(Button, { className: formStyles.formButton, type: "primary", htmlType: "submit", children: txt('createAccount') })] }));
};
export default SurveyForm;
