import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Select, Form, Button, AutoComplete, Spin, Alert, Input, Tabs, } from 'antd';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import { useEffect, useState } from 'react';
import { fetchHeadingsMetadata } from 'api/project-api';
import FormModal from 'components/FormModal';
import { DeleteOutlined } from '@ant-design/icons';
import ModalFooter from 'components/ModalFooter';
import { filterOption } from 'libs/filterOption';
import styles from './style.module.less';
const { Option } = Select;
const SectionNameModal = ({ visible, target, onClose, onSubmit, project, onSectionRemoved, }) => {
    const { session } = useSession();
    const [headingsMetadata, setHeadingsMetadata] = useState('loading');
    const [form] = Form.useForm();
    const initializeSuggestedNames = async () => {
        const metadata = await fetchHeadingsMetadata(session.connection, project);
        if (metadata === null) {
            setHeadingsMetadata('fail');
            return;
        }
        try {
            setHeadingsMetadata(metadata);
        }
        catch (error) {
            global.logger.error('failed to parse metadata', { metadata }, error);
            setHeadingsMetadata('fail');
        }
    };
    useEffect(() => {
        if (visible) {
            void initializeSuggestedNames();
        }
    }, [visible]);
    const handleSelectChange = (value, option) => {
        form.setFieldsValue({ id: option.key, name: option.value });
    };
    const handleSubmit = (values) => {
        onSubmit(values, target);
        form.resetFields();
    };
    const renderFormContent = () => {
        if (headingsMetadata === 'loading') {
            return _jsx(Spin, {});
        }
        if (headingsMetadata === 'fail') {
            return (_jsx(Alert, { message: txt('unexpectedError'), type: "error", showIcon: true }));
        }
        return (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: txt('sectionName'), name: "name", rules: [
                        () => ({
                            validator(_, value) {
                                if (!RegExp(headingsMetadata.pattern).test(value)) {
                                    return Promise.reject(txt('sectionNameValidation'));
                                }
                                return Promise.resolve();
                            },
                        }),
                        () => ({
                            validator(_, value) {
                                if (value !== '' && !headingsMetadata.options.some(((option) => option.name === value))) {
                                    return Promise.reject(txt('sectionNameChanged'));
                                }
                                return Promise.resolve();
                            },
                            warningOnly: true,
                        }),
                    ], children: _jsx(AutoComplete, { placeholder: `${txt('selectSectionName')}...`, filterOption: filterOption, popupClassName: styles.optionsPopup, popupMatchSelectWidth: false, onSelect: handleSelectChange, children: headingsMetadata.options.map((option) => (_jsx(Option, { value: option.name, label: option.name, children: _jsx("span", { children: option.name }) }, option.id))) }) }), _jsx(Form.Item, { hidden: true, name: "id", children: _jsx(Input, {}) })] }));
    };
    const removeSection = () => {
        if (target !== null) {
            onSectionRemoved(target);
            onClose();
        }
    };
    const isMetadataValid = headingsMetadata !== 'fail' && headingsMetadata !== 'loading';
    const tabItems = [
        {
            key: 'editSection',
            label: txt('editSection'),
            children: (_jsxs(_Fragment, { children: [_jsx(Form, { form: form, onFinish: handleSubmit, children: renderFormContent() }), _jsx(ModalFooter, { disabled: !isMetadataValid, form: form, onCancel: onClose, children: txt('generate') })] })),
        },
        {
            key: 'removeSection',
            label: _jsx("div", { children: _jsx(DeleteOutlined, {}) }),
            children: (_jsxs("div", { className: styles.removeSection, children: [_jsx("div", { children: txt('removeSection') }), _jsx(Button, { onClick: removeSection, type: "primary", danger: true, children: txt('remove') })] })),
        },
    ];
    return (_jsx(FormModal, { footer: false, open: visible, onCancel: onClose, children: _jsx(Tabs, { defaultActiveKey: "editSection", items: target !== null ? tabItems : tabItems.slice(0, 1) }) }));
};
export default SectionNameModal;
