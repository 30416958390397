import { createContext, useContext } from 'react';
export const screenBreakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1680,
};
// NOTE: The default value for react context is not used in this app.
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AppContext = createContext(undefined);
export const useAppContext = () => useContext(AppContext);
