import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useHistory } from 'react-router-dom';
import { Layout, Drawer, Menu, Modal, } from 'antd';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import clsx from 'clsx';
import { useSavingStatus } from 'screens/DashboardScreen/pages/EditorPage/DocumentEditor/saving-state-handler';
import { fullMenuContent } from './menu-content';
import DashMenuHead from '../DashMenuHead';
import './style.less';
const createLabel = (item, requireNavigationConfirm, collapsed, parentLink) => {
    if (item.key === null) {
        return undefined;
    }
    if (collapsed) {
        // NOTE: Excluding the text after collapsing fixes bug: icons in menu disappers when
        // focused by tab while menu is collapsed.
        return _jsx(Link, { to: `${parentLink}/${item.key}` });
    }
    if (requireNavigationConfirm || item.subItems !== undefined) {
        return txt(item.key);
    }
    return _jsx(Link, { to: `${parentLink}/${item.key}`, children: txt(item.key) });
};
const createAntMenuItems = (items, requireNavigationConfirm, collapsed, parentLink = '') => items.map((item) => {
    if (item.key === null) {
        return ({
            type: 'divider',
        });
    }
    return ({
        key: item.key,
        icon: item.icon,
        title: txt(item.key),
        label: createLabel(item, requireNavigationConfirm, collapsed, parentLink),
        children: item.subItems === undefined
            ? undefined
            : createAntMenuItems(item.subItems, requireNavigationConfirm, false, `/${item.key}`),
    });
});
const DashMenu = ({ collapsed, isMobile, activeLink, allowedForTeam, onMenuCollapse, }) => {
    const { session: { login } } = useSession();
    const history = useHistory();
    const [modal, contextHolder] = Modal.useModal();
    const menuContent = fullMenuContent
        .filterByStatus(login.claims, login.user.role)
        .filter((item) => item.allowedForTeam === undefined
        || item.allowedForTeam === allowedForTeam);
    const savingStatus = useSavingStatus();
    const requireNavigationConfirm = savingStatus === 'saving' || savingStatus === 'retrying';
    const handleSelect = ({ keyPath }) => {
        if (requireNavigationConfirm) {
            void modal.confirm({
                title: `${txt('confirmLeaving')}`,
                content: `${txt('unsavedChanges')}`,
                cancelText: `${txt('cancel')}`,
                okText: `${txt('leave')}`,
                okButtonProps: { type: 'primary', danger: true },
                onOk() {
                    history.push(`/${keyPath.reverse().join('/')}`);
                },
            });
        }
        if (isMobile) {
            onMenuCollapse(true);
        }
    };
    const menu = (_jsx(Menu, { mode: isMobile ? 'inline' : 'vertical', items: createAntMenuItems(menuContent.items, requireNavigationConfirm, collapsed), selectedKeys: [activeLink], onSelect: handleSelect }));
    const getMenuFooter = () => {
        if (login.hasClaim('dataTheme:apa')) {
            return _jsx("div", { className: clsx('dashmenu__tail', { 'dashmenu__tail--small': collapsed }) });
        }
        return null;
    };
    if (isMobile) {
        return (_jsxs(Drawer, { className: "dashmenu", placement: "left", open: !collapsed, onClose: () => onMenuCollapse(true), closeIcon: false, children: [_jsx(DashMenuHead, {}), menu, getMenuFooter()] }));
    }
    return (_jsxs(Layout.Sider, { className: "dashmenu", collapsed: collapsed, collapsedWidth: "3.5rem", width: "14.5rem", breakpoint: "md", children: [_jsx(DashMenuHead, {}), menu, contextHolder, getMenuFooter()] }));
};
export default DashMenu;
