import PaginationStorage from './pagination-storage';
export default class TableView {
    constructor(tableId, history, defaultViewParams) {
        this.tableId = tableId;
        this.history = history;
        this.defaultViewParams = defaultViewParams;
    }
    getViewParams() {
        var _a;
        const { location: { state } } = this.history;
        const params = (_a = state === null || state === void 0 ? void 0 : state.tableViewParams) === null || _a === void 0 ? void 0 : _a[this.tableId];
        if (params === undefined) {
            return this.defaultViewParams;
        }
        if (params.version !== global.VERSION) {
            // New version of frontend was deployed. Discard stale data
            return this.defaultViewParams;
        }
        return params;
    }
    setPagination(currentPage, pageSize) {
        const viewParams = this.getViewParams();
        this.setViewParams(Object.assign(Object.assign({}, viewParams), { currentPage: currentPage !== null && currentPage !== void 0 ? currentPage : 1, pageSize: pageSize !== null && pageSize !== void 0 ? pageSize : 20 }));
    }
    setCurrentPage(currentPage) {
        const viewParams = this.getViewParams();
        this.setViewParams(Object.assign(Object.assign({}, viewParams), { currentPage }));
    }
    setSieve(sieve) {
        const viewParams = this.getViewParams();
        this.setViewParams(Object.assign(Object.assign({}, viewParams), { sieve }));
    }
    setViewParams(viewParams) {
        var _a, _b, _c;
        const { location } = this.history;
        const params = (_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.tableViewParams) !== null && _b !== void 0 ? _b : {};
        if (((_c = params[this.tableId]) === null || _c === void 0 ? void 0 : _c.pageSize) !== viewParams.pageSize) {
            PaginationStorage.storePageSize(this.tableId, viewParams.pageSize);
        }
        params[this.tableId] = Object.assign({ version: global.VERSION }, viewParams);
        this.history.replace(location.pathname, Object.assign(Object.assign({}, location.state), { tableViewParams: params }));
    }
}
