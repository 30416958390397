import { bind } from '@react-rxjs/core';
import { fetchProjectsList, } from 'api/project-api';
import { useRef } from 'react';
import { BehaviorSubject } from 'rxjs';
import { throttle } from 'throttle-debounce';
const applyTrackedValues = (project, trackedValues) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const mediaInfo = project.mediaInfo === null
        ? null
        : Object.assign(Object.assign({}, project.mediaInfo), { hasVideo: (_a = trackedValues.hasVideo) !== null && _a !== void 0 ? _a : project.mediaInfo.hasVideo });
    const processingState = (_b = trackedValues.processingState) !== null && _b !== void 0 ? _b : project.processingState;
    const processingStateDetail = trackedValues.processingStateDetail === null
        ? null
        : {
            uploading: (_f = (_d = (_c = trackedValues.processingStateDetail) === null || _c === void 0 ? void 0 : _c.uploading) !== null && _d !== void 0 ? _d : (_e = project.processingStateDetail) === null || _e === void 0 ? void 0 : _e.uploading) !== null && _f !== void 0 ? _f : false,
            transcribed: (_k = (_h = (_g = trackedValues.processingStateDetail) === null || _g === void 0 ? void 0 : _g.transcribed) !== null && _h !== void 0 ? _h : (_j = project.processingStateDetail) === null || _j === void 0 ? void 0 : _j.transcribed) !== null && _k !== void 0 ? _k : 'none',
        };
    return Object.assign(Object.assign({}, project), { queued: (_l = trackedValues.queued) !== null && _l !== void 0 ? _l : project.queued, mediaInfo,
        processingState,
        processingStateDetail });
};
class RunningStates {
    constructor() {
        this.states = {};
        this.applyToProjectsList = (list) => {
            const updatedItems = list.items.map((listed) => {
                const trackedValues = this.states[listed.id];
                if (trackedValues === undefined) {
                    return listed;
                }
                return applyTrackedValues(listed, trackedValues);
            });
            return {
                items: updatedItems,
                query: list.query,
                totalCount: list.totalCount,
            };
        };
    }
    updateState(projectId, newValues) {
        this.states[projectId] = Object.assign(Object.assign({}, this.states[projectId]), newValues);
    }
}
const subject$ = new BehaviorSubject('loading');
export class TrackedProjects {
    constructor(socket) {
        this.runningStates = null;
        this.reloadWithThrottle = throttle(7000, false, () => {
            if (this.list !== 'loading') {
                void this.reload(this.list.query, false);
            }
        });
        this.handleMessage = (message) => {
            const { subsystem, type, projectId } = message;
            if (type === 'Started') {
                if (subsystem === 'ChainControl') {
                    if (this.list !== 'loading') {
                        void this.reload(this.list.query, false);
                    }
                    return;
                }
                if (subsystem === 'Upload') {
                    this.updateProject(projectId, {
                        processingState: 'InProgress',
                        processingStateDetail: {
                            uploading: true,
                        },
                    });
                    return;
                }
                if (subsystem === 'TranscriptionQueueTracking') {
                    this.updateProject(projectId, {
                        processingState: 'InProgress',
                        queued: true,
                    });
                    return;
                }
                if (subsystem === 'TranscriptionStreaming') {
                    this.updateProject(projectId, {
                        processingState: 'InProgress',
                        processingStateDetail: {
                            transcribed: 'unknown',
                        },
                    });
                    return;
                }
                return;
            }
            if (message.type === 'Progress') {
                if (subsystem === 'ProjectUpdates') {
                    this.reloadWithThrottle();
                    return;
                }
                if (subsystem === 'MediaIdentification') {
                    this.updateProject(projectId, {
                        duration: message.data.duration,
                        hasVideo: message.data.mediaInfo.hasVideo,
                    });
                    return;
                }
                if (subsystem === 'TranscriptionStreaming') {
                    if (message.data.transcribed === null) {
                        // NOTE: Last recognition message sending the length of the whole recording.
                        // We can safely ignore this message. The Completed message arrives
                        // immediately after this.
                        return;
                    }
                    this.updateProject(projectId, {
                        processingState: 'InProgress',
                        processingStateDetail: {
                            transcribed: { time: message.data.transcribed },
                        },
                    });
                    return;
                }
                return;
            }
            if (type === 'Completed') {
                if (subsystem === 'Upload') {
                    this.updateProject(projectId, {
                        processingStateDetail: {
                            uploading: false,
                        },
                    });
                    return;
                }
                if (subsystem === 'TranscriptionQueueTracking') {
                    this.updateProject(projectId, {
                        processingState: 'InProgress',
                        queued: false,
                    });
                    return;
                }
                if (subsystem === 'TranscriptionStreaming') {
                    this.updateProject(projectId, {
                        processingState: 'InProgress',
                        processingStateDetail: {
                            uploading: false,
                            transcribed: 'none',
                        },
                    });
                    return;
                }
                if (subsystem === 'ChainControl') {
                    this.updateProject(projectId, {
                        processingState: 'Completed',
                        processingStateDetail: null,
                    });
                    return;
                }
                return;
            }
            if (type === 'Failed') {
                this.updateProject(projectId, {
                    processingState: 'Failed',
                    processingStateDetail: null,
                });
            }
        };
        this.socket = socket;
        this.socket.onMessage = this.handleMessage;
    }
    get list() {
        return subject$.value;
    }
    async reload(query, invalidate = true) {
        this.runningStates = new RunningStates();
        if (invalidate) {
            subject$.next('loading');
        }
        const projectsList = await fetchProjectsList(this.socket.connection, query);
        subject$.next(this.runningStates.applyToProjectsList(projectsList));
        this.runningStates = null;
    }
    findListItemIndex(projectId) {
        if (this.list === 'loading') {
            return -1;
        }
        return this.list.items.findIndex((item) => String(item.id) === projectId);
    }
    updateProject(projectId, newValues) {
        if (this.runningStates !== null) {
            this.runningStates.updateState(projectId, newValues);
            return;
        }
        if (this.list === 'loading') {
            return;
        }
        const projectItemIndex = this.findListItemIndex(projectId);
        if (projectItemIndex === -1) {
            return;
        }
        const newItem = applyTrackedValues(this.list.items[projectItemIndex], newValues);
        subject$.next({
            items: [
                ...this.list.items.slice(0, projectItemIndex),
                newItem,
                ...this.list.items.slice(projectItemIndex + 1),
            ],
            query: this.list.query,
            totalCount: this.list.totalCount,
        });
    }
}
const [useList] = bind(subject$, subject$.value);
export const useTrackedProjects = (socket) => {
    const trackedProjects = useRef(null);
    if (trackedProjects.current == null) {
        trackedProjects.current = new TrackedProjects(socket);
    }
    return [useList(), trackedProjects.current];
};
