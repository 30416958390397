import { SPACE_REGEX_G } from './text-utils';
export default class ExpandableAbbreviations {
    constructor(editorController) {
        this.handleTextChanged = (delta, source) => {
            var _a, _b, _c;
            if (!this.enabled)
                return;
            // NOTE: Return when deleting (the whole) text in the document.
            if (source === 'api' || ((_a = delta.ops[0]) === null || _a === void 0 ? void 0 : _a.delete) !== undefined || ((_b = delta.ops[1]) === null || _b === void 0 ? void 0 : _b.delete) !== undefined) {
                return;
            }
            // NOTE: The delta of the first character has a different order of ops. The insert operation
            // is on index 0 for the first char in contrast to other chars, where the index of insert
            // operation is on index 1.
            if (delta.ops[0].insert !== undefined) {
                // NOTE: Ignore empty spaces.
                if (delta.ops[0].insert.toString().match(SPACE_REGEX_G) !== null)
                    return;
                this.buffer = {
                    index: 0,
                    insert: delta.ops[0].insert.toString(),
                };
                return;
            }
            // NOTE: Adding newline without empty space is excluded.
            const isOtherOpsThanInsert = delta.ops[1].insert === undefined || delta.ops[1].insert.toString() === '\n';
            // NOTE: Reset buffer if another operation (op) then insert is carried out.
            if (isOtherOpsThanInsert && this.buffer !== null) {
                this.handleSelectionChanged();
                return;
            }
            if (delta.ops[1].insert !== undefined) {
                // NOTE: Add only chars of length 1 to the buffer.
                if (delta.ops[1].insert.toString().length > 1)
                    return;
                // NOTE: Ignore empty spaces written before abbreviations.
                if (delta.ops[1].insert.toString().match(SPACE_REGEX_G) !== null && this.buffer === null)
                    return;
                if (this.buffer === null) {
                    this.buffer = {
                        index: (_c = delta.ops[0].retain) !== null && _c !== void 0 ? _c : 0,
                        insert: delta.ops[1].insert.toString(),
                    };
                    return;
                }
                this.buffer.insert = [this.buffer.insert, delta.ops[1].insert].join('');
            }
        };
        this.handleEventKeySpace = () => {
            if (!this.enabled)
                return;
            if (this.buffer === null || this.abbreviationsList === null)
                return;
            for (let index = 0; index < this.abbreviationsList.length; index += 1) {
                if (this.abbreviationsList[index].abbreviation === this.buffer.insert) {
                    const expandedWord = this.abbreviationsList[index].text;
                    const prevBuffer = this.buffer;
                    // NOTE: .deleteText() will reset the buffer.
                    this.editorController.deleteText(this.buffer.index, this.buffer.insert.length);
                    this.editorController.insertText(prevBuffer.index, expandedWord);
                    this.editorController.setSelection(prevBuffer.index + expandedWord.length, 0);
                    return;
                }
            }
            this.handleSelectionChanged();
        };
        this.handleSelectionChanged = () => {
            if (!this.enabled || this.buffer === null)
                return;
            this.buffer = null;
        };
        this.editorController = editorController;
        this.enabled = false;
        this.buffer = null;
        this.abbreviationsList = null;
    }
    setEnabled(enabled) {
        if (this.enabled === enabled)
            return;
        this.enabled = enabled;
    }
    setAbbreviationsList(abbreviations) {
        if (!this.enabled)
            return;
        if (abbreviations.length === 0)
            return;
        this.abbreviationsList = abbreviations;
    }
}
