import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, Tooltip } from 'antd';
import { InfoCircleFilled, CloseCircleFilled, ExclamationCircleFilled, } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import { useUploadQueue } from 'screens/DashboardScreen/dashboard-context';
import styles from './style.module.less';
const ProjectAlert = ({ type, title, description, contactSupport, closable, onClick, }) => {
    const uploadQueue = useUploadQueue();
    const queueNotEmpty = uploadQueue.isRunning && !uploadQueue.isSolitary;
    const icon = {
        info: _jsx(InfoCircleFilled, {}),
        warning: _jsx(ExclamationCircleFilled, {}),
        error: _jsx(CloseCircleFilled, {}),
    }[type];
    return (_jsx(Alert, { className: styles.projectAlert, type: type, showIcon: true, icon: icon, message: title, description: (_jsxs(_Fragment, { children: [description, (type === 'error' || type === 'warning')
                    && (_jsx(Tooltip, { title: queueNotEmpty ? txt('waitTillQueueEmpty') : undefined, children: _jsx(Button, { className: styles.uploadButton, disabled: queueNotEmpty, onClick: onClick, children: txt('uploadAgain') }) })), contactSupport === true ? (_jsxs("p", { children: [txt('contactSupport'), _jsx("a", { href: txt('contactLink'), children: txt('contactUs') }), "."] })) : null] })), closable: closable }));
};
export default ProjectAlert;
