import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useSession } from 'components/Authenticated';
import { Tooltip, Spin } from 'antd';
import { fetchTeam } from 'api/admin/teams-api';
import { TeamIcon } from 'components/CustomIcons';
import { txt } from 'libs/i18n';
const TeamIndicator = ({ teamId }) => {
    const { session } = useSession();
    const [owner, setOwner] = useState(null);
    const handleOpenChange = async (open) => {
        if (open && owner === null) {
            const team = await fetchTeam(session.connection, Number(teamId));
            setOwner(team.ownerEmail);
        }
    };
    const tooltipTitle = (_jsxs("span", { children: [txt('teamMember'), ":", ' ', owner !== null && owner !== void 0 ? owner : _jsx(Spin, {})] }));
    return (_jsx(Tooltip, { onOpenChange: handleOpenChange, title: tooltipTitle, children: _jsx("span", { children: _jsx(TeamIcon, { className: "team-icon" }) }) }));
};
export default TeamIndicator;
