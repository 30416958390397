import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Button } from 'antd';
import { useSession } from 'components/Authenticated';
import { handleMembersProjectsExport } from 'libs/download-csv';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const ExpandedRowFooter = (email, id, projects, filterDates) => {
    const { session } = useSession();
    let numberOfProjectsInfo;
    const { length } = projects;
    if (length >= 10) {
        numberOfProjectsInfo = (_jsx("span", { children: _jsxs("em", { children: [txt('showLastProjects'), ' ', length, ' )'] }) }));
    }
    else if (length < 10 && length > 0) {
        numberOfProjectsInfo = (_jsx("span", { children: _jsxs("em", { children: [txt('showing'), ' ', length, ' / ', length, ' ', length > 1 ? txt('projectsOutOf') : txt('projectOutOf')] }) }));
    }
    else {
        numberOfProjectsInfo = (_jsx("div", { children: _jsx("em", { children: txt('noProjects') }) }));
    }
    return (_jsxs("div", { className: styles.footer, children: [numberOfProjectsInfo, length > 0
                ? (_jsx(Button, { type: "primary", onClick: () => handleMembersProjectsExport(id, email, filterDates, session.connection), children: txt('downloadCSV') })) : null] }));
};
export default ExpandedRowFooter;
