import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { json } from '@newtontechnologies/beey-api-js-client/receivers';
import { createProjectFromApi } from './model/project';
export const transcribe = (connection, projectId, lang, withPPC = true, withVAD = true, saveTrsx = true) => connection.authFetch()
    .url(apiV2.projects.id(projectId).transcribe.url({
    lang, withPPC, withVAD, saveTrsx,
}))
    .post()
    .receive(json())
    .fetch();
export const enqueue = (connection, projectId, language, transcriptionProfile = 'default', withPPC = true, withVAD = true, withDiarization = true, saveTrsx = true, withSpeakerId = true) => connection.authFetch()
    .url(apiV2.projects.id(projectId).enqueue.url({
    projectId,
    lang: language.code,
    withPPC,
    withVAD,
    saveTrsx,
    transcriptionProfile,
    withSpeakerId,
    withDiarization,
}))
    .receive(json().map(createProjectFromApi))
    .fetch();
export const fetchQueuedProjectsIds = (connection) => connection.authFetch()
    .url(apiV2.projects.queue.url())
    .receive(json())
    .fetch();
