import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { App, Button, Form, Input, Popconfirm, Space, Spin, } from 'antd';
import SingleFileUpload from 'components/SingleFileUpload';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import { useEffect, useState } from 'react';
import { deleteExportTemplate, fetchExportTemplates, uploadExportTemplate, } from 'api/admin/teams-api';
import IconButton from 'components/IconButton';
import styles from './style.module.less';
const ExportTemplates = ({ teamId }) => {
    const [form] = Form.useForm();
    const [exportTemplates, setExportTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { session } = useSession();
    const { message } = App.useApp();
    const getExportTemplates = async () => {
        setExportTemplates(await fetchExportTemplates(session.connection, teamId));
    };
    useEffect(() => {
        void getExportTemplates();
    }, []);
    const deleteTemplate = async (template) => {
        await deleteExportTemplate(session.connection, teamId, template);
        await getExportTemplates();
    };
    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            await uploadExportTemplate(session.connection, teamId, values.name, values.template);
            await getExportTemplates();
            void message.success(txt('uploadFinishedMsg'));
        }
        catch (error) {
            global.logger.error('failed to upload export template', {}, error);
            void message.error(txt('unexpectedError'));
        }
        setIsLoading(false);
        form.resetFields();
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.templates, children: [_jsxs("div", { className: styles.templates, children: [txt('exportTemplates'), ":"] }), exportTemplates === 'loading' ? (_jsx(Spin, {})) : (exportTemplates.map((template) => {
                        return (_jsx("ul", { children: _jsxs(Space, { children: [_jsx("li", { children: template.name }), _jsx(Popconfirm, { title: txt('deleteTemplate'), okType: "primary", okButtonProps: { danger: true }, onConfirm: () => deleteTemplate(template.name), okText: txt('yesDelete'), cancelText: txt('cancel'), children: _jsx(IconButton, { danger: true, children: _jsx(DeleteOutlined, {}) }) })] }) }, template.name));
                    }))] }), _jsxs(_Fragment, { children: [_jsx("span", { children: txt('uploadExportTemplate') }), _jsxs(Form, { onFinish: handleSubmit, labelCol: { sm: { span: 9 } }, labelWrap: true, form: form, labelAlign: "left", className: styles.form, children: [_jsx(Form.Item, { name: "template", valuePropName: "uploadFile", rules: [
                                    {
                                        required: true,
                                        message: txt('uploadTemplate'),
                                    },
                                ], children: _jsx(SingleFileUpload, { label: txt('exportTemplate'), accept: ".xsl" }) }), _jsx(Form.Item, { name: "name", label: txt('name'), rules: [
                                    {
                                        validator: async (_, value) => {
                                            if (exportTemplates !== 'loading' && exportTemplates.filter((template) => template.name === value).length > 0) {
                                                return Promise.reject(txt('templateExists'));
                                            }
                                            if (value === undefined || value === '') {
                                                return Promise.reject(txt('nameTemplate'));
                                            }
                                            if (value === 'default') {
                                                return Promise.reject(txt('unallowedName'));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ], children: _jsx(Input, {}) }), _jsx("div", { className: styles.saveButton, children: _jsxs(Button, { htmlType: "submit", type: "primary", disabled: isLoading, children: [isLoading ? _jsx(LoadingOutlined, {}) : null, txt('save')] }, "submit") })] })] })] }));
};
export default ExportTemplates;
