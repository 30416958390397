import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { useState, useEffect, useMemo } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { storeUserUiLocale } from 'api/user-api';
import * as clientEnv from 'libs/client-env';
import { Authenticated } from 'components/Authenticated';
import LoginScreen from 'screens/LoginScreen';
import SignUpScreen from 'screens/SignUpScreen';
import PaymentScreen from 'screens/PaymentScreen';
import ResetPasswordScreen from 'screens/ResetPasswordScreen';
import DashboardScreen from 'screens/DashboardScreen';
import { setCurrentUiLocale, getCurrentUiLocale } from 'libs/locales/ui';
import { getBrowserStatus } from 'libs/browser';
import VersionMismatchOverlay from 'components/VersionMismatchOverlay';
import { App, ConfigProvider } from 'antd';
import { AppContext, screenBreakpoints, } from './app-context';
import { beeyTheme, apaTheme } from '../ant-config';
const browserStatus = getBrowserStatus();
const isLoginEnabled = browserStatus.access === 'ok' || browserStatus.access === 'warn';
const inferSizeLabel = (windowWidth) => {
    if (windowWidth < screenBreakpoints.sm) {
        return 'xs';
    }
    if (windowWidth < screenBreakpoints.md) {
        return 'sm';
    }
    if (windowWidth < screenBreakpoints.lg) {
        return 'md';
    }
    if (windowWidth < screenBreakpoints.xl) {
        return 'lg';
    }
    if (windowWidth < screenBreakpoints.xxl) {
        return 'xl';
    }
    return 'xxl';
};
const BeeyApp = () => {
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        label: inferSizeLabel(window.innerWidth),
    });
    const [uiLocale, setUILocale] = useState(() => getCurrentUiLocale());
    const [session, setSession] = useState('continue');
    const [versionMatch, setVersionMatch] = useState('perfect');
    const [theme, setTheme] = useState(beeyTheme);
    useEffect(() => {
        const handleResize = () => setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight,
            label: inferSizeLabel(window.innerWidth),
        });
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const changeUiLocale = (locale) => {
        setCurrentUiLocale(locale);
        setUILocale(locale);
        dayjs.locale(locale.lang);
    };
    const updateSession = (openedSession) => {
        setSession(openedSession);
        setTheme(openedSession.login.hasClaim('dataTheme:apa') ? apaTheme : beeyTheme);
        clientEnv.setAuthString(openedSession.login.token);
        clientEnv.setUserEmail(openedSession.login.user.email);
        if (openedSession.login.user.uiLocale.code !== uiLocale.code) {
            void storeUserUiLocale(openedSession.connection, uiLocale);
        }
    };
    const clearSession = () => {
        setSession('closed');
        clientEnv.clearAuthString();
    };
    const triggerVersionMismatch = (mismatch) => {
        if (versionMatch !== 'incompatible' && mismatch !== versionMatch) {
            setVersionMatch(mismatch);
        }
    };
    const appContextValue = useMemo(() => ({
        screenSize,
        uiLocale,
        browserStatus,
        versionMatch,
        triggerVersionMismatch,
        isLoginEnabled,
        changeUiLocale,
        session,
        updateSession,
        clearSession,
    }), [
        screenSize,
        uiLocale,
        browserStatus,
        versionMatch,
        triggerVersionMismatch,
        isLoginEnabled,
        changeUiLocale,
        session,
        updateSession,
        clearSession,
    ]);
    return (_jsx(ConfigProvider, { theme: theme, children: _jsx(App, { children: _jsx(AppContext.Provider, { value: appContextValue, children: _jsx(VersionMismatchOverlay, { children: _jsxs(Switch, { children: [_jsx(Redirect, { exact: true, from: "/", to: "/projects" }), _jsx(Redirect, { exact: true, from: "/documents", to: "/projects" }), _jsx(Redirect, { exact: true, from: "/docs", to: "/projects" }), _jsx(Redirect, { exact: true, from: "/projs", to: "/projects" }), _jsx(Route, { exact: true, path: "/signup", component: SignUpScreen }), _jsx(Route, { exact: true, path: "/signup/createpassword", component: ResetPasswordScreen }), _jsx(Route, { exact: true, path: "/login", component: LoginScreen }), _jsx(Route, { exact: true, path: "/login/resetpassword", component: ResetPasswordScreen }), _jsxs(Authenticated, { children: [_jsx(Route, { exact: true, path: "/payment", component: PaymentScreen }), _jsx(Route, { path: "/:page", component: DashboardScreen })] })] }) }) }) }) }));
};
export default BeeyApp;
