import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Tag, Button } from 'antd';
import { txt } from 'libs/i18n';
import './style.less';
const tagIds = [
    'inAnyField',
    'projectname',
    'transcription',
    'speakers',
    'notes',
];
const FulltextSearchTags = ({ searchParams, onTagRemoved, onResetFulltextSearch, }) => (_jsxs(_Fragment, { children: [tagIds.map((tagId) => {
            const tagText = `${txt(tagId)}: ${searchParams[tagId]}`;
            const tagContent = tagText.length <= 40 ? tagText : `${tagText.slice(0, 40)}...`;
            return searchParams[tagId] === '' ? null : (_jsxs(Tag, { onClose: () => onTagRemoved(tagId), closable: true, className: "fulltext-search-tag", children: [_jsx("span", { className: "fulltext-search-tag__wide", children: tagText }), _jsx("span", { className: "fulltext-search-tag__narrow", children: tagContent })] }, tagId));
        }), _jsx(Button, { className: "fulltext-reset-button", onClick: onResetFulltextSearch, children: _jsx("span", { children: txt('clearSearch') }) })] }));
export default FulltextSearchTags;
