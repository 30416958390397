import dayjs from 'dayjs';
import { areSummariesInDocument, getAttributes, getFirstElementByTagName } from 'libs/xml-utils';
import duration from 'dayjs/plugin/duration';
import { isRawDiarizationCode, Speaker } from 'api/speaker';
import { isoToSeconds } from 'libs/duration';
import Delta from 'quill-delta';
import { isNoise } from 'libs/is-noise';
import { parseVttFormat } from 'libs/vtt-format-parser';
import { SpeakerSignPlacementSchema, TransparencySchema, } from 'api/settings/user-settings';
import DocumentExporter from './document-exporter';
import TextMetadata from './text-metadata';
import { defaultCaptionParameters } from './captions';
import { CAPTION_END_SYMBOL, formatTimeStampMarker, TIME_ANCHOR_SYMBOL, } from './text-utils';
dayjs.extend(duration);
const EXPECTED_METADATA = [
    'timeAnchor',
    'displayTimeStamp',
    'captionEnd',
    'captionLineBreak',
    'caption',
    'captionFormat',
    'forceDisplay',
];
export default class TrsxManager {
    constructor(editorController, creatorUserName) {
        this.transcriptionAttributes = null;
        this.meta = null;
        this.importFromTime = 0;
        this.currentEndIndex = 0;
        this.lastSpeakerId = null;
        this.isLastParagraphTerminated = true;
        this.tryAddToDocumentSpeakers = (speaker) => {
            const { speakers } = this.editorController;
            const matchingDocumentSpeaker = speakers.getSpeakerById(speaker.id);
            if (matchingDocumentSpeaker === null) {
                speakers.addDocumentSpeaker(speaker);
            }
        };
        this.shouldInsertSpeaker = (speaker, paragraph) => {
            let insertSpeaker = true;
            const speakerTrsxId = paragraph.getAttribute('s');
            const forceLabel = paragraph.getAttribute('scribeForceLabel') === 'true';
            if (speakerTrsxId === this.lastSpeakerId)
                insertSpeaker = false;
            if (speaker.id === this.nullSpeakerId)
                insertSpeaker = false;
            if (forceLabel)
                insertSpeaker = true;
            if (!this.isLastParagraphTerminated) {
                // NOTE: This means that the paragraph was already imported partially from
                // partial trsx.
                insertSpeaker = false;
                this.isLastParagraphTerminated = true;
            }
            return insertSpeaker;
        };
        this.editorController = editorController;
        this.xmlParser = new DOMParser();
        this.creatorUserName = creatorUserName;
        // dictionary mapping the trsx ids (used only while importing) to the speaker objects.
        this.trsxSpeakers = {};
        this.nullSpeakerId = null;
        this.captionMetadata = null;
        this.lastEditorUsername = null;
        this.lastPlaybackTime = null;
        this.trsxCreatedTime = null;
    }
    export() {
        return new DocumentExporter(this.editorController, this.editorController.textMetadata, this.editorController.speakers, this.creatorUserName, this.meta, this.editorController.lastHandledPhraseTime, this.editorController.lastHandledMessageId, this.editorController.captions.parameters);
    }
    import(trsx, append = false, importFromTime = 0, removeCaptions = false, ignoreHeadings = false) {
        this.importFromTime = importFromTime;
        this.currentEndIndex = append
            ? this.editorController.getLength()
            : 0;
        if (!append) {
            this.editorController.textMetadata = new TextMetadata();
            this.editorController.speakers.setDocumentSpeakers([]);
            this.editorController.speakers.speakerIdentification.reset();
        }
        const xmlDoc = this.xmlParser.parseFromString(trsx, 'text/xml');
        this.importSpeakers(xmlDoc);
        const metaTags = xmlDoc.getElementsByTagName('meta');
        this.meta = metaTags.length > 0 ? metaTags[0] : document.createElement('meta');
        const partialTrsxElement = getFirstElementByTagName(this.meta, 'PartialTrsx');
        let lastMessageId = null;
        let lastPhraseTime = null;
        if (partialTrsxElement) {
            lastMessageId = Number(partialTrsxElement.getAttribute('lastmessageid'));
            const isoLastPhraseTime = partialTrsxElement.getAttribute('lastphrasetime');
            lastPhraseTime = isoLastPhraseTime !== null
                ? dayjs.duration(isoLastPhraseTime).asSeconds() : null;
        }
        this.editorController.lastHandledMessageId = lastMessageId !== null && lastMessageId !== void 0 ? lastMessageId : Infinity;
        this.editorController.lastHandledPhraseTime = lastPhraseTime !== null && lastPhraseTime !== void 0 ? lastPhraseTime : Infinity;
        const lastEditorUsernameElement = getFirstElementByTagName(this.meta, 'LastEditorUserName');
        this.lastEditorUsername = lastEditorUsernameElement !== null
            ? lastEditorUsernameElement.textContent : null;
        const lastPlaybackPosition = getFirstElementByTagName(this.meta, 'EditorLastPlaybackPosition');
        if (lastPlaybackPosition) {
            this.lastPlaybackTime = isoToSeconds(lastPlaybackPosition.innerHTML);
        }
        if (removeCaptions) {
            Array.from(this.meta.getElementsByTagName('CaptionMeta')).forEach((child) => this.meta.removeChild(child));
        }
        const captionMetadata = getFirstElementByTagName(this.meta, 'CaptionMeta');
        if (captionMetadata) {
            this.captionMetadata = this.parseCaptionMetadata(captionMetadata);
        }
        else {
            this.captionMetadata = null;
        }
        this.meta.removeAttribute('xmlns');
        const trancriptionElement = getFirstElementByTagName(xmlDoc, 'transcription');
        if (trancriptionElement === null) {
            throw Error('trsx is missing transcription element');
        }
        this.transcriptionAttributes = trancriptionElement.attributes;
        this.trsxCreatedTime = this.transcriptionAttributes.created !== undefined
            ? this.transcriptionAttributes.created.value : null;
        if (areSummariesInDocument(xmlDoc)) {
            this.editorController.summary.setEnabled(true);
        }
        const chapters = xmlDoc.getElementsByTagName('ch');
        let delta = new Delta();
        if (append) {
            // terminating line end will be replaced by space.
            delta = delta
                .retain(this.editorController.getLength() - 1)
                .delete(1)
                .insert(' ');
            this.isLastParagraphTerminated = false;
        }
        for (let i = 0; i < chapters.length; i += 1) {
            delta = delta.concat(this.generateChapterDelta(chapters[i], ignoreHeadings));
        }
        if (append) {
            this.editorController.updateContents(delta, 'user');
        }
        else {
            if (delta.length() === 0) {
                delta.push({ insert: '\n' }); // document must end with newline, even empty document
            }
            this.setQuillContents(delta);
        }
        // insert empty line at start to allow editing before the first speaker
        if (this.editorController.getLineFormat(0).speaker !== undefined) {
            const emptyLineDelta = new Delta({ ops: [{ insert: '\n' }] });
            this.editorController.updateContents(emptyLineDelta, 'user');
            // this must be handled explicitly, because import is being run with
            // automatic timestamps updates disabled
            this.editorController.textMetadata.applyDelta(emptyLineDelta);
        }
        this.editorController.textMetadata.length = this.editorController.getLength();
        this.editorController.getHistory().clear();
    }
    generateChapterDelta(chapter, ignoreHeadings) {
        let delta = new Delta();
        const sections = chapter.getElementsByTagName('se');
        const name = chapter.getAttribute('name');
        if (name === null) {
            throw new Error('a chapter is missing name attribute');
        }
        if (name !== '' && !ignoreHeadings) {
            delta.push({
                insert: `${name}\n`,
                attributes: { header: 1 },
            });
            this.currentEndIndex += name.length + 1;
        }
        for (let i = 0; i < sections.length; i += 1) {
            delta = delta.concat(this.generateSectionDelta(sections[i], ignoreHeadings));
        }
        return delta;
    }
    generateSectionDelta(section, ignoreHeadings) {
        var _a, _b;
        this.lastSpeakerId = null;
        let delta = new Delta();
        const paragraphs = section.getElementsByTagName('pa');
        const name = section.getAttribute('name');
        const summary = (_a = section.getAttribute('summary')) !== null && _a !== void 0 ? _a : '\\n';
        const tags = section.getAttribute('tags') !== null ? (_b = section.getAttribute('tags')) === null || _b === void 0 ? void 0 : _b.split('#') : [];
        const id = section.getAttribute('id');
        if (name === null) {
            throw new Error('a section is missing name attribute');
        }
        const shouldGenerateHeading = !ignoreHeadings && (name !== '' || summary !== '\\n');
        if (shouldGenerateHeading) {
            const isSectionNameMissing = this.editorController.sectionNamesList !== null
                && !this.editorController.sectionNamesList.options.some((option) => option.name === name);
            delta.push({
                insert: `${name}\n`,
                attributes: {
                    section: {
                        id: id === '' ? null : id,
                        tags,
                    },
                    uneditable: this.editorController.sectionNamesList !== null,
                    warning: isSectionNameMissing ? 'section-name-missing' : undefined,
                },
            });
            this.currentEndIndex += name.length + 1;
            if (this.editorController.summary.getEnabled()) {
                const summaryBlocks = summary.split('\\n').slice(0, -1);
                summaryBlocks.forEach((summaryBlockText) => {
                    delta.push({
                        insert: `${summaryBlockText}\n`,
                        attributes: { summary: 'button' },
                    });
                    this.currentEndIndex += summaryBlockText.length + 1;
                });
            }
        }
        for (let i = 0; i < paragraphs.length; i += 1) {
            delta = delta.concat(this.generateParagraphDelta(paragraphs[i]));
        }
        return delta;
    }
    getWordsFromPhrases(phrases) {
        var _a;
        const words = [];
        for (let i = 0; i < phrases.length; i += 1) {
            const phrase = phrases[i];
            const metadata = {};
            EXPECTED_METADATA.forEach((name) => {
                const value = phrase.getAttribute(name);
                if (value !== null) {
                    metadata[name] = value;
                }
            });
            const isoBegin = phrase.getAttribute('b');
            const isoEnd = phrase.getAttribute('e');
            if (isoBegin === null || isoEnd === null) {
                throw new Error('a phrase is missing timestamps');
            }
            const begin = dayjs.duration(isoBegin).asSeconds();
            const end = dayjs.duration(isoEnd).asSeconds();
            const word = {
                begin,
                end: Math.max(begin, end), // backend bug workaround - sometimes end is before begin
                text: (_a = phrase.textContent) !== null && _a !== void 0 ? _a : '',
                metadata,
            };
            if (word.text === '' && Object.keys(metadata).length === 0) {
                // omit empty phrases
                continue;
            }
            if (!isNoise(word.text)
                && word.begin >= this.importFromTime) {
                words.push(word);
            }
        }
        return words;
    }
    generateParagraphDelta(paragraph) {
        let delta = new Delta();
        const phrases = paragraph.getElementsByTagName('p');
        const speakerTrsxId = paragraph.getAttribute('s');
        if (speakerTrsxId === null) {
            throw new Error('a paragraph is missing speaker id');
        }
        const isoParagraphEnd = paragraph.getAttribute('e');
        if (isoParagraphEnd === null) {
            throw new Error('a paragraph is missing attribute "e"');
        }
        const paragraphEnd = dayjs.duration(isoParagraphEnd).asSeconds();
        const fromFrontend = paragraph.getAttribute('scribeForceLabel') !== null;
        if (paragraphEnd < this.importFromTime)
            return new Delta();
        const words = this.getWordsFromPhrases(phrases);
        if (words.length === 0 && !fromFrontend)
            return new Delta();
        let speaker = this.trsxSpeakers[speakerTrsxId];
        // NOTE: Create unknown speaker number only for speakers that are really added to
        // the document (do not number empty paragraphs).
        if (speaker && this.shouldInsertSpeaker(speaker, paragraph)) {
            if (isRawDiarizationCode(speaker.lastName)) {
                const diarizationCode = speaker.lastName;
                speaker = this.editorController.speakers
                    .speakerIdentification.createSpeaker(diarizationCode);
                this.trsxSpeakers[speakerTrsxId] = speaker;
            }
            this.editorController.speakers.speakerIdentification.updateSpeakerCounter(speaker);
            this.tryAddToDocumentSpeakers(speaker);
            const label = speaker.composeLabel();
            delta.push({ insert: `${label}` });
            // the speaker must be applied to the newline only,
            // that means format the whole line.
            delta.push({ attributes: { speaker: speaker.getQuillFormatValue() }, insert: '\n' });
            this.currentEndIndex += label.length + 1;
        }
        this.lastSpeakerId = speakerTrsxId;
        for (let i = 0; i < words.length; i += 1) {
            const isLastWord = i === words.length - 1;
            delta = delta.concat(this.generateWordDelta(words[i], isLastWord));
        }
        delta.push({
            insert: '\n',
        });
        this.currentEndIndex += 1;
        return delta;
    }
    generateWordDelta(word, isLastWord) {
        let delta = new Delta();
        if (word.metadata.caption === 'extraText') {
            if (this.captionMetadata === null) {
                // NOTE: No caption metadata indicates that all information regarding captions
                // should be removed, including this extra text.
                return new Delta();
            }
            const display = word.text.length > 0 ? 'show' : 'hide';
            this.editorController.textMetadata.addMetadata('speakerSign', this.currentEndIndex - 1, {
                content: word.text,
                display: word.metadata.forceDisplay !== undefined ? display : 'default',
            });
            return delta;
        }
        // NOTE: Must be here, time stamp precedes the word.
        if (word.metadata.displayTimeStamp !== undefined) {
            delta = delta.concat(this.generateTimeStampMarkerDelta(word.begin));
        }
        if (word.text.length > 0) {
            delta.push({
                insert: word.text,
                attributes: {
                    speaker: false,
                    timeStampMarker: false,
                },
            });
            this.currentEndIndex += word.text.length;
            this.editorController.textMetadata.addTimestampAtEndOnIndex(isLastWord ? this.currentEndIndex : this.currentEndIndex - 1, word.begin, word.end);
        }
        if (word.metadata.captionEnd !== undefined && this.captionMetadata) {
            delta = delta.concat(this.generateCaptionEndDelta(word.metadata.captionEnd));
        }
        if (word.metadata.timeAnchor !== undefined) {
            delta = delta.concat(this.generateTimeAnchorDelta(word.metadata.timeAnchor));
        }
        if (word.metadata.caption === 'lineBreak' && this.captionMetadata) {
            this.addCaptionLineBreak(isLastWord);
        }
        if (word.metadata.captionFormat !== undefined && this.captionMetadata) {
            this.addCaptionFormat(word.metadata.captionFormat);
        }
        return delta;
    }
    generateTimeAnchorDelta(value) {
        const valueAsSeconds = dayjs.duration(value).asSeconds();
        const delta = new Delta([{
                insert: TIME_ANCHOR_SYMBOL,
                attributes: {
                    speaker: false,
                    timeAnchor: valueAsSeconds,
                },
            }]);
        this.currentEndIndex += 1;
        this.editorController.textMetadata.addTimestampAtEndOnIndex(this.currentEndIndex - 1, valueAsSeconds, valueAsSeconds);
        this.editorController.textMetadata.addMetadata('timeAnchor', this.currentEndIndex - 1, valueAsSeconds);
        return delta;
    }
    generateTimeStampMarkerDelta(time) {
        const timeStampMarker = formatTimeStampMarker(time);
        const delta = new Delta([{
                insert: timeStampMarker,
                attributes: {
                    timeStampMarker: true,
                },
            }]);
        this.currentEndIndex += timeStampMarker.length;
        this.editorController.textMetadata.addMetadata('displayTimeStamp', this.currentEndIndex - 1, true);
        return delta;
    }
    generateCaptionEndDelta(value) {
        const valueAsSeconds = dayjs.duration(value).asSeconds(); // new format - iso duration
        const delta = new Delta([{
                insert: CAPTION_END_SYMBOL,
                attributes: {
                    speaker: false,
                },
            }]);
        this.currentEndIndex += 1;
        this.editorController.textMetadata.addMetadata('captionEnd', this.currentEndIndex - 1, valueAsSeconds);
        return delta;
    }
    addCaptionLineBreak(isAtParagraphEnd) {
        const index = isAtParagraphEnd ? this.currentEndIndex : this.currentEndIndex - 1;
        this.editorController.textMetadata.addMetadata('captionLineBreak', index, true);
    }
    addCaptionFormat(format) {
        this.editorController.textMetadata.addMetadata('captionFormat', this.currentEndIndex - 1, format);
    }
    importSpeakers(trsx) {
        this.trsxSpeakers = {};
        const spElements = trsx.getElementsByTagName('sp');
        if (spElements.length === 0)
            return;
        const xmlSpeakers = spElements[0];
        const speakers = xmlSpeakers.getElementsByTagName('s');
        for (let i = 0; i < speakers.length; i += 1) {
            const xmlSpeaker = speakers[i];
            const speaker = Speaker.fromXMLElement(xmlSpeaker);
            const trsxId = xmlSpeaker.getAttribute('id');
            if (trsxId !== null) {
                this.trsxSpeakers[trsxId] = speaker;
            }
        }
    }
    /*
    The function setContents from quill API behaves unpredictably - sometimes it adds additional
    newline at end and sometimes not. This function does the same thing as quill.setContents,
    but more reliably.
    */
    setQuillContents(delta) {
        this.editorController.deleteText(0, this.editorController.getLength(), 'user');
        // after deleting, there is only a newline in the editor
        this.editorController.updateContents(delta, 'user');
        // now there is the new content and after that there is the newline, that was there before
        this.editorController.deleteText(this.editorController.getLength() - 1, 1);
        // now the additional newline is deleted and contents are exactly the inserted delta
    }
    parseCaptionMetadata(captionMetadata) {
        var _a, _b, _c, _d, _e, _f, _g;
        const rawAttributes = getAttributes(captionMetadata);
        const toSeconds = (value, defaulValue) => (value === undefined ? defaulValue : isoToSeconds(value));
        const toNumber = (value, defaulValue) => {
            const parsed = Number(value);
            if (Number.isNaN(parsed)) {
                return defaulValue;
            }
            return parsed;
        };
        const toBoolean = (value, defaulValue) => {
            if (value === undefined) {
                return defaulValue;
            }
            if (value.toLocaleLowerCase() === 'true') {
                return true;
            }
            if (value.toLocaleLowerCase() === 'false') {
                return false;
            }
            return defaulValue;
        };
        const toTransparency = (value, defaulValue) => (TransparencySchema.values().default(defaulValue).sanitize(Number(value)));
        const toSpeakerSignPlacement = (value, defaulValue) => (SpeakerSignPlacementSchema.default(defaulValue).sanitize(value));
        const toSpeakerColor = (value, defaulValue) => (value === undefined ? defaulValue : value);
        const toCaptionFormat = (value, defaulValue) => {
            if (value === undefined) {
                return defaulValue;
            }
            return parseVttFormat(value);
        };
        return {
            triDotString: (_a = rawAttributes.triDotString) !== null && _a !== void 0 ? _a : defaultCaptionParameters.triDotString,
            speakerSign: (_b = rawAttributes.speakerSign) !== null && _b !== void 0 ? _b : defaultCaptionParameters.speakerSign,
            templateName: (_c = rawAttributes.templateName) !== null && _c !== void 0 ? _c : defaultCaptionParameters.templateName,
            defaultFontName: (_d = rawAttributes.defaultFontName) !== null && _d !== void 0 ? _d : defaultCaptionParameters.defaultFontName,
            defaultBackgroundColor: ((_e = rawAttributes.defaultBackgroundColor) !== null && _e !== void 0 ? _e : defaultCaptionParameters.defaultBackgroundColor),
            speakerSignPlacement: toSpeakerSignPlacement(rawAttributes.speakerSignPlacement, defaultCaptionParameters.speakerSignPlacement),
            defaultColor: toSpeakerColor(rawAttributes.defaultColor, defaultCaptionParameters.defaultColor),
            triDotDuration: toSeconds(rawAttributes.triDotDuration, defaultCaptionParameters.triDotDuration),
            pauseBetweenCaptions: toSeconds(rawAttributes.pauseBetweenCaptions, defaultCaptionParameters.pauseBetweenCaptions),
            autofillPauseBetweenCaptions: toSeconds(rawAttributes.autofillPauseBetweenCaptions, defaultCaptionParameters.autofillPauseBetweenCaptions),
            minDuration: toSeconds(rawAttributes.minDuration, defaultCaptionParameters.minDuration),
            maxDuration: toSeconds(rawAttributes.maxDuration, defaultCaptionParameters.maxDuration),
            defaultFontSize: toNumber(rawAttributes.defaultFontSize, defaultCaptionParameters.defaultFontSize),
            speedWarning: toNumber(rawAttributes.speedWarning, defaultCaptionParameters.speedWarning),
            speedCriticalWarning: toNumber(rawAttributes.speedCriticalWarning, defaultCaptionParameters.speedCriticalWarning),
            automaticSpeed: toNumber(rawAttributes.automaticSpeed, defaultCaptionParameters.automaticSpeed),
            subtitlerMaxLineCount: toNumber(rawAttributes.subtitlerMaxLineCount, defaultCaptionParameters.subtitlerMaxLineCount),
            maxLineLength: toNumber(rawAttributes.subtitlerMaxLineLength, defaultCaptionParameters.maxLineLength),
            defaultBackgroundTransparency: toTransparency(rawAttributes.defaultBackgroundTransparency, defaultCaptionParameters.defaultBackgroundTransparency),
            keepInnerLinesStripped: toBoolean(rawAttributes.keepInnerLinesStripped, defaultCaptionParameters.keepInnerLinesStripped),
            useSpeakerName: toBoolean(rawAttributes.useSpeakerName, defaultCaptionParameters.useSpeakerName),
            enablePresegmentation: toBoolean(rawAttributes.enablePresegmentation, defaultCaptionParameters.enablePresegmentation),
            defaultCaptionPosition: toCaptionFormat(rawAttributes.defaultCaptionPosition, defaultCaptionParameters.defaultCaptionPosition),
            upperCaseAllText: toBoolean(rawAttributes.upperCaseAllText, defaultCaptionParameters.upperCaseAllText),
            highlightingMode: (_f = rawAttributes.highlightingMode) !== null && _f !== void 0 ? _f : defaultCaptionParameters.highlightingMode,
            unHighlightedColor: (_g = rawAttributes.unHighlightedColor) !== null && _g !== void 0 ? _g : defaultCaptionParameters.unHighlightedColor,
        };
    }
}
