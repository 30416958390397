import { detect } from 'detect-browser';
export const supportedBrowsers = [
    { name: 'chrome', version: '70' },
    { name: 'firefox', version: '60' },
    { name: 'edge-chromium', version: '86' },
    { name: 'opera', version: '66' },
];
export const detectBrowser = () => {
    const info = detect();
    if (info === null) {
        return null;
    }
    const correctedInfo = Object.assign({}, info);
    correctedInfo.version = info.version === null ? null : info.version.split('.')[0];
    return correctedInfo;
};
// NOTE: We decided to make exception for iOS 15+, Mac 12+ and Safari 15+.
// The version of Safari equals the OS version. Below these version numbers
// Beey is not supported. The user has to be informed that Beey is in beta
// version for all Apple devices with version 15+.
const checkPartiallySupportedBrowsers = (browser) => {
    if (browser === null || browser.version === null || browser.os === null) {
        return {
            access: 'warn',
            reason: 'uknown',
        };
    }
    // NOTE: These conditions cover Apple computers.
    if (browser.name === 'safari' && Number(browser.version) >= 15) {
        return {
            access: 'warn',
            reason: 'safari',
        };
    }
    if (browser.os === 'Mac OS'
        && (Number(browser.version) >= 13 && Number(browser.version) < 15)) {
        return {
            access: 'warn',
            reason: 'apple',
        };
    }
    if (browser.os === 'Mac OS' && Number(browser.version) < 13) {
        return {
            access: 'deny',
            reason: 'old-apple',
        };
    }
    // NOTE: These conditions cover Apple tablets and phones, for which all browsers
    // are disguised Safari browser.
    if (browser.name === 'ios' && Number(browser.version) >= 15) {
        return {
            access: 'warn',
            reason: 'apple',
        };
    }
    if (browser.os === 'iOS' && Number(browser.version) < 15) {
        return {
            access: 'deny',
            reason: 'old-apple',
        };
    }
    return {
        access: 'warn',
        reason: 'uknown',
    };
};
const checkBrowserSupport = (browser) => {
    if (browser === null || browser.version === null || browser.os === null) {
        return {
            access: 'warn',
            reason: 'uknown',
        };
    }
    const foundSupportedBrowser = supportedBrowsers.find((supportedBrowser) => supportedBrowser.name === browser.name);
    if (foundSupportedBrowser !== undefined) {
        if (Number(browser.version) >= Number(foundSupportedBrowser.version)) {
            return { access: 'ok' };
        }
        return {
            access: 'deny',
            reason: 'old-browser',
        };
    }
    return checkPartiallySupportedBrowsers(browser);
};
export const getBrowserStatus = () => {
    const browser = detectBrowser();
    global.logger.info('browser info', {
        browser,
        windowSize: {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        },
    });
    return checkBrowserSupport(browser);
};
