import deepEqual from 'fast-deep-equal';
export const BYTES_IN_GIGABYTE = 1024 ** 3;
export const BYTES_IN_MEGABYTE = 1024 ** 2;
export const isNullish = (value) => value === null || value === undefined;
export const checkObjectsMatch = (objectA, objectB) => {
    return Object.keys(objectA).every((key) => {
        if (!Object.keys(objectB).includes(key)) {
            return true; // missing entries are not checked
        }
        const valueA = objectA[key];
        const valueB = objectB[key];
        if (valueA instanceof Array && valueB instanceof Array) {
            return deepEqual(valueA, valueB);
        }
        if (valueA instanceof Object && valueB instanceof Object) {
            return checkObjectsMatch(valueA, valueB);
        }
        return valueA === valueB;
    });
};
export const sleep = async (delay) => {
    await new Promise((resolve) => {
        // wait for several seconds
        setTimeout(() => { resolve(); }, delay * 1000);
    });
};
