import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { txt } from 'libs/i18n';
import ResponsiveTooltip from 'components/ResponsiveTooltip';
import clsx from 'clsx';
import styles from './style.module.less';
const CustomizedButton = ({ tooltipTitle, tooltipShortcut, onClick, children, tooltipPlacement, disabledMessage, className, }) => {
    return (_jsx(ResponsiveTooltip, { placement: tooltipPlacement, title: (_jsxs(_Fragment, { children: [_jsx("span", { children: `${txt(disabledMessage !== null && disabledMessage !== void 0 ? disabledMessage : tooltipTitle)} ` }), tooltipShortcut !== '' && disabledMessage === undefined
                    ? (_jsxs(_Fragment, { children: [_jsx("span", { children: "(" }), _jsx("kbd", { className: styles.keyboardShortcut, children: tooltipShortcut }), _jsx("span", { children: ")" })] }))
                    : null] })), children: _jsx("button", { type: "button", className: clsx(styles.iconButton, className), onClick: onClick, disabled: disabledMessage !== undefined, children: children }) }));
};
export default CustomizedButton;
