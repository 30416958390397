import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, } from 'react';
import { Input, Select } from 'antd';
import { fetchAllUsersSimplified } from 'api/admin/users-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import './style.less';
const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const filterUsersByPrefix = (users, prefix) => {
    return users.filter((user) => { var _a; return (_a = user.email) === null || _a === void 0 ? void 0 : _a.toLowerCase().startsWith(prefix); });
};
const validateEmail = (session, input) => {
    const email = input.trim();
    if (email === '' || !emailFormat.test(email)) {
        return 'invalid';
    }
    if (email.toLowerCase() === session.login.user.email.toLowerCase()) {
        return 'self';
    }
    return 'valid';
};
const createValidationMessage = (validity) => {
    if (validity === 'invalid') {
        return txt('emailValidation');
    }
    if (validity === 'self') {
        return txt('selfSharing');
    }
    if (validity === 'not-found') {
        return txt('notFoundUser');
    }
    return null;
};
const UserSearchInput = ({ label, value, onChange, onPressEnter, autoFocus, }) => {
    const { session } = useSession();
    const [allUsers, setAllUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    useEffect(() => {
        const fetchUsers = async () => setAllUsers((await fetchAllUsersSimplified(session.connection))
            .list.filter((user) => { var _a; return ((_a = user.email) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== session.login.user.email.toLowerCase(); }));
        if (session.login.hasClaim('admin:superadmin')) {
            void fetchUsers();
        }
    }, []);
    const handleInputChange = (event) => {
        const email = event.target.value;
        const validity = validateEmail(session, email);
        onChange({ email, validity });
    };
    const handleSelectChange = (email) => {
        onChange({ email, validity: 'valid' });
    };
    const handleSelectSearch = (searchTerm) => {
        const cleaned = searchTerm.toLowerCase().trim();
        if (cleaned.length < 2) {
            onChange({ email: searchTerm, validity: 'unchecked' });
            return;
        }
        const validity = validateEmail(session, searchTerm);
        if (validity === 'self') {
            onChange({ email: searchTerm, validity });
            return;
        }
        const users = filterUsersByPrefix(allUsers, cleaned);
        setFilteredUsers(users);
        if (users.length === 0) {
            onChange({ email: searchTerm, validity: validity === 'invalid' ? 'invalid' : 'not-found' });
        }
        else {
            onChange({ email: searchTerm, validity: 'unchecked' });
        }
    };
    const inputField = session.login.hasClaim('admin:superadmin')
        ? (_jsx(Select, { className: "user-search__field", showSearch: true, placeholder: txt('email'), filterOption: false, onSearch: handleSelectSearch, onChange: handleSelectChange, notFoundContent: null, autoFocus: autoFocus, children: filteredUsers.map((user) => (_jsx(Select.Option, { value: user.email, children: user.email }, user.id))) })) : (_jsx(Input, { className: "user-search__field", placeholder: txt('email'), value: value.email, onChange: handleInputChange, required: true, autoFocus: autoFocus, onPressEnter: onPressEnter }));
    const searchType = session.login.hasClaim('admin:superadmin')
        ? 'select'
        : 'textfield';
    const validationMessage = createValidationMessage(value.validity);
    return (_jsxs("div", { className: `user-search user-search--${searchType}`, children: [_jsxs("div", { className: "user-search__label", children: [label, ":"] }), _jsxs("div", { className: "user-search__input", children: [inputField, validationMessage === null
                        ? null
                        : _jsx("p", { className: "user-search__msg", children: validationMessage })] })] }));
};
export default UserSearchInput;
