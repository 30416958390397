import { serializeToVttFormat } from 'libs/vtt-format-parser';
import { formatTime } from 'libs/format-number';
import { getTextLines } from './caption-utils';
const formatLine = (line) => (line.replace('\u00a0', ' '));
export const asVTT = (captions) => {
    const outputLines = ['WEBVTT'];
    captions.forEach((caption) => {
        outputLines.push('');
        let formatString = serializeToVttFormat(caption.format);
        if (formatString !== '') {
            formatString = ` ${formatString}`;
        }
        outputLines.push(`${formatTime(caption.begin, 'HH:mm:ss.SSS')} --> ${formatTime(caption.end, 'HH:mm:ss.SSS')}`);
        getTextLines(caption.lines).forEach((line) => {
            outputLines.push(formatLine(line));
        });
    });
    return outputLines.join('\r\n');
};
