import { sanitizeUserSettings } from 'api/settings/user-settings';
import { BeeyLocale } from 'libs/locales/locale';
import { UiLocale, defaultUiLocale } from 'libs/locales/ui';
const selectUserLocale = (code) => {
    var _a;
    if (code === null) {
        return defaultUiLocale;
    }
    const locale = BeeyLocale.fromCode(code);
    if (locale === null) {
        return defaultUiLocale;
    }
    return (_a = UiLocale.fromLocale(locale)) !== null && _a !== void 0 ? _a : defaultUiLocale;
};
export const createBaseUserFromApi = (apiBaseUser) => (Object.assign(Object.assign({}, apiBaseUser), { id: String(apiBaseUser.id), uiLocale: selectUserLocale(apiBaseUser.language), teamId: String(apiBaseUser.teamId), settings: sanitizeUserSettings(apiBaseUser.settings) }));
export const createListedUserFromApi = (apiListedUser) => (Object.assign(Object.assign({}, apiListedUser), { id: String(apiListedUser.id), uiLocale: selectUserLocale(apiListedUser.language), teamId: String(apiListedUser.teamId) }));
export const createListedSimplifiedUserFromApi = (apiListedSimplifiedUser) => (Object.assign(Object.assign({}, apiListedSimplifiedUser), { id: String(apiListedSimplifiedUser.id), teamId: String(apiListedSimplifiedUser.teamId) }));
export const createUserFromApi = (apiUserDetail) => (Object.assign(Object.assign({}, apiUserDetail), createBaseUserFromApi(apiUserDetail)));
export class Login {
    constructor(claims, token, user) {
        this.claims = claims;
        this.token = token;
        this.user = user;
    }
    static fromAPI(apiLogin) {
        return new Login(apiLogin.claims, apiLogin.token, Object.assign(Object.assign({}, apiLogin.user), createBaseUserFromApi(apiLogin.user)));
    }
    withUser(user) {
        return new Login(this.claims, this.token, user);
    }
    hasClaim(claim) {
        return this.claims.includes(claim);
    }
}
