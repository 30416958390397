import EventEmitter from 'events';
import { isNoise } from 'libs/is-noise';
import { durationInSeconds } from 'libs/duration';
import ProjectStateHandler from './project-state-handler';
export default class RecognitionHandler extends ProjectStateHandler {
    constructor(messageStream) {
        super(messageStream, 'prestart');
        this.speakersSet = new Set();
        this.emitter = new EventEmitter();
        this.handleRecognitionStarted = () => this.updateStatus('waiting-for-words');
        this.handleASRMsg = (message) => {
            const { data } = message;
            if (data.word !== null) {
                const begin = durationInSeconds(data.word.begin);
                const end = durationInSeconds(data.word.end);
                const { text } = data.word;
                const isPhraseNoise = isNoise(text);
                this.emitRecognitionEvent('word', {
                    begin, end, text, isNoise: isPhraseNoise,
                }, message);
                if (this.status === 'waiting-for-words' && !isPhraseNoise) {
                    this.updateStatus('running');
                }
            }
            else if (data.speakerChangePoint !== null) { // Speaker
                this.emitRecognitionEvent('speaker', data.speakerChangePoint.speakerId, message);
                this.updateStatus('running');
                this.speakersSet.add(data.speakerChangePoint.speakerId);
            }
            else if (data.speakerData !== null) {
                this.emitRecognitionEvent('speaker-id', message.data.speakerData.xmlSpeaker, message);
            }
        };
        this.addStreamListener('TranscriptionStreaming', 'Started', this.handleRecognitionStarted);
        this.addStreamListener('TranscriptionStreaming', 'Progress', this.handleASRMsg);
    }
    addRecognitionListener(event, listener) {
        this.emitter.on(event, listener);
    }
    removeRecognitionListener(event, listener) {
        this.emitter.off(event, listener);
    }
    emitRecognitionEvent(event, ...args) {
        this.emitter.emit(event, ...args);
    }
}
