import { PlaybackEvents } from './playback';
export const PLAYBACK_TICK_INTERVAL = 16;
export default class Player {
    constructor(domElement, playback, onReadyToPlay) {
        this.handlePlaybackUpdate = () => {
            if (this.domElement.currentTime !== this.playback.internalPlayerTime) {
                if (this.playback.internalPlayerTime !== this.lastTickTime) {
                    this.domElement.currentTime = this.playback.internalPlayerTime;
                }
            }
            if (this.domElement.playbackRate !== this.playback.speed) {
                this.domElement.playbackRate = this.playback.speed;
            }
            if (this.domElement.volume !== this.playback.volume) {
                this.domElement.volume = this.playback.volume;
            }
            if (this.domElement.paused === this.playback.playing) {
                if (this.playback.playing) {
                    void this.domElement.play();
                }
                else {
                    this.domElement.pause();
                }
            }
        };
        this.handleLoadedMetadata = (e) => {
            this.onLoadedMetadata(e, this.initTime);
        };
        this.handlePlaybackEnded = () => {
            this.domElement.currentTime = this.domElement.duration; // override rewinding to beginning
            this.playback.ended();
        };
        this.firePlayingTick = () => {
            if (this.domElement.paused) {
                return;
            }
            this.lastTickTime = this.domElement.currentTime;
            this.playback.seekTo(this.lastTickTime);
        };
        this.domElement = domElement;
        this.playback = playback;
        this.onReadyToPlay = onReadyToPlay;
        this.lastTickTime = null;
        this.initTime = new Date().getTime();
        this.onLoadedMetadata = () => { };
        this.domElement.addEventListener('ended', this.handlePlaybackEnded);
        this.domElement.addEventListener('loadedmetadata', this.handleLoadedMetadata);
        playback.addEventListener(PlaybackEvents.Update, this.handlePlaybackUpdate);
        this.loopInterval = window.setInterval(this.firePlayingTick, PLAYBACK_TICK_INTERVAL);
    }
    destroy() {
        this.domElement.removeEventListener('ended', this.handlePlaybackEnded);
        this.domElement.removeEventListener('loadedmetadata', this.handleLoadedMetadata);
        this.playback.removeEventListener(PlaybackEvents.Update, this.handlePlaybackUpdate);
        clearTimeout(this.loopInterval);
    }
}
