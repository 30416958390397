import sanitizeFilename from 'sanitize-filename';
export const downloadUrl = (url, filename) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = sanitizeFilename(filename);
    // TODO if download fails, this does not throw an exception
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};
export const downloadBlobAsFile = (filename, blob) => downloadUrl(window.URL.createObjectURL(blob), filename);
export const downloadTextAsFile = (filename, text, type) => downloadBlobAsFile(filename, new Blob([text], { type }));
export const downloadTextFormat = (exporter, projectName, format) => {
    const filename = `${projectName}.${format}`;
    switch (format) {
        case 'trsx':
            downloadTextAsFile(filename, exporter.exportTRSX(), 'text/xml');
            break;
        case 'txt':
            downloadTextAsFile(filename, exporter.exportTXT(), 'text/plain');
            break;
        case 'bdd':
            downloadTextAsFile(filename, exporter.exportDump(), 'text/json');
            break;
        default:
            throw new Error(`Uknown export format: ${String(format)}.`);
    }
};
