export const captionFontNames = [
    'Arial',
    'Calibri',
    'Consolas',
    'Helvetica',
    'Open Sans',
    'Tahoma',
    'Times New Roman',
    'Trebuchet MS',
    'Verdana',
];
