import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo, useRef, } from 'react';
import { Route, Switch, Redirect, useParams, } from 'react-router-dom';
import Screen from 'components/Screen';
import { useSession } from 'components/Authenticated';
import * as clientEnv from 'libs/client-env';
import { useAppContext, screenBreakpoints } from 'App/app-context';
import InterimEditorRedirect from 'screens/InterimEditorRedirect';
import { App } from 'antd';
import { BeeyTutorial, getBeeyTutorialSteps } from './beeyTutorial';
import DashMenu from './components/DashMenu';
import ProjectsPage from './pages/ProjectsPage';
import AccountPage from './pages/AccountPage';
import AdminPage from './pages/AdminPage';
import EditorPage from './pages/EditorPage';
import UserlexPage from './pages/UserlexPage';
import SpeakersPage from './pages/SpeakersPage';
import AppsPage from './pages/AppsPage';
import UploadOverlay from './UploadOverlay';
import { DashboardContext } from './dashboard-context';
import { UploadQueueController } from './media-upload/upload-queue-controller';
import AbbreviationsPage from './pages/AbbreviationsPage';
import TagsPage from './pages/TagsPage';
const DashboardScreen = () => {
    var _a;
    const { session } = useSession();
    const { teamSettings } = session;
    const { screenSize } = useAppContext();
    const { page } = useParams();
    const { message } = App.useApp();
    const [menuCollapsed, setMenuCollapsed] = useState((_a = clientEnv.getIsMainMenuCollapsed()) !== null && _a !== void 0 ? _a : true);
    const queueControllerRef = useRef(new UploadQueueController(session.connection));
    const handleMenuCollapse = (collapsed) => {
        if (collapsed !== menuCollapsed) {
            setMenuCollapsed(collapsed);
            clientEnv.setIsMainMenuCollapsed(collapsed);
        }
    };
    const toggleMenuCollapse = () => {
        setMenuCollapsed(!menuCollapsed);
        clientEnv.setIsMainMenuCollapsed(!menuCollapsed);
    };
    useEffect(() => {
        if (clientEnv.getIsMainMenuCollapsed() === null) {
            setMenuCollapsed(screenSize.width < screenBreakpoints.xl);
        }
    }, [screenSize]);
    useEffect(() => {
        return () => {
            queueControllerRef.current.dispose();
        };
    }, []);
    const hasMobileMenu = screenSize.width < screenBreakpoints.md;
    const contextValue = useMemo(() => ({
        menuCollapsed,
        hasMobileMenu,
        toggleMenuCollapse,
        uploadQueueController: queueControllerRef.current,
    }), [menuCollapsed, hasMobileMenu, toggleMenuCollapse]);
    return (_jsx(DashboardContext.Provider, { value: contextValue, children: _jsxs(Screen, { children: [_jsx(DashMenu, { collapsed: menuCollapsed, isMobile: hasMobileMenu, activeLink: page, allowedForTeam: teamSettings.sectionTagsAllowed, onMenuCollapse: handleMenuCollapse }), _jsxs(BeeyTutorial, { tutorialSteps: getBeeyTutorialSteps(), children: [_jsx(UploadOverlay, {}), _jsxs(Switch, { children: [_jsx(Route, { exact: true, path: "/projects", component: ProjectsPage }), _jsx(Route, { exact: true, path: "/edit/:projId?", render: (routeProps) => {
                                        const { match, history, location } = routeProps;
                                        return (_jsx(EditorPage, { match: match, history: history, location: location, message: message }));
                                    } }), _jsx(Route, { path: "/edit/:projId?/interimEditorRedirect", component: InterimEditorRedirect }), _jsx(Route, { path: "/account/:subpage?", component: AccountPage }), session.login.hasClaim('AdminUser:post') ? (_jsx(Route, { path: "/admin", component: AdminPage })) : _jsx(Redirect, { from: "/admin", to: "/projects" }), session.login.hasClaim('lexicons:get') ? (_jsx(Route, { exact: true, path: "/userlex", component: UserlexPage })) : _jsx(Redirect, { from: "/userlex", to: "/projects" }), session.login.hasClaim('speakers:list') ? (_jsx(Route, { exact: true, path: "/speakers", component: SpeakersPage })) : _jsx(Redirect, { from: "/speakers", to: "/projects" }), _jsx(Route, { exact: true, path: "/tags", component: TagsPage }), session.login.hasClaim('editor:abbreviations') ? (_jsx(Route, { exact: true, path: "/abbreviations", component: AbbreviationsPage })) : _jsx(Redirect, { from: "/abbreviations", to: "/projects" }), session.login.hasClaim('apps:list') ? (_jsx(Route, { exact: true, path: "/apps", component: AppsPage })) : _jsx(Redirect, { from: "/apps", to: "/projects" })] })] })] }) }));
};
export default DashboardScreen;
