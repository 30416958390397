import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Button, Alert, App, } from 'antd';
import FormModal from 'components/FormModal';
import { shareProject } from 'api/project-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import { isApiError } from '@newtontechnologies/beey-api-js-client/receivers';
import UserSearchInput from './UserSearchInput';
import styles from './style.module.less';
const ProjectShareDialog = ({ projectsToShare, onClose, modalVisible, }) => {
    const { session } = useSession();
    const { notification, message } = App.useApp();
    const [shareWith, setShareWith] = useState({
        email: '',
        validity: 'unchecked',
    });
    const reportSharingResult = ({ total, success, failed, blocked, alreadyShared, }) => {
        if (blocked > 0) {
            // NOTE: If one was blocked, all were blocked
            notification.error({
                message: txt('sharingBlocked'),
                description: txt('sharingBlockedDescription'),
                placement: 'topRight',
                duration: 0,
            });
            return;
        }
        if (success === total) {
            void message.success(txt('sharingSuccessful'));
            return;
        }
        if (alreadyShared === total) {
            void message.info(txt('alreadyShared'));
            return;
        }
        if (failed === 0) {
            void message.success(txt('sharingSuccessful'));
            return;
        }
        notification.error({
            message: txt('failedToShare'),
            description: (_jsx("p", { children: `${txt('projShareCount')}: ${success + alreadyShared}/${total}` })),
            placement: 'topRight',
            duration: 0,
        });
    };
    const handleShare = async () => {
        const settled = await Promise.allSettled(projectsToShare.map((proj) => (shareProject(session.connection, proj, shareWith.email))));
        const finalResult = {
            total: settled.length,
            success: 0,
            failed: 0,
            blocked: 0,
            alreadyShared: 0,
        };
        settled.forEach((result) => {
            if (result.status === 'fulfilled') {
                finalResult.success += 1;
                return;
            }
            const { reason } = result;
            if (isApiError(reason)) {
                if (reason.errorMessage.startsWith('Already shared')) {
                    finalResult.alreadyShared += 1;
                    return;
                }
                if (reason.errorMessage.startsWith('Cannot share with given user')
                    || reason.errorMessage.startsWith('Given user to share')) {
                    global.logger.error('sharing with user not allowed', { error: String(result.reason) });
                    finalResult.blocked += 1;
                    return;
                }
            }
            global.logger.error('failed to share project', { error: String(result.reason) });
            finalResult.failed += 1;
        });
        reportSharingResult(finalResult);
        onClose(finalResult.success);
    };
    const handleShareOnEnter = () => {
        if (shareWith.validity === 'valid') {
            void handleShare();
        }
    };
    return (_jsxs(FormModal, { muteShortcuts: ['Tab', 'Shift+Tab'], className: styles.shareModal, title: txt('shareProjs'), open: modalVisible, onCancel: () => onClose(0), maskClosable: false, footer: [
            _jsx(Button, { onClick: () => onClose(0), children: txt('cancel') }, "cancel"),
            _jsx(Button, { type: "primary", disabled: shareWith.validity !== 'valid', onClick: handleShare, children: txt('share') }, "share"),
        ], children: [_jsxs("p", { children: [txt('projsToShare'), ": \u00A0", projectsToShare.length] }), _jsx(Alert, { type: "info", showIcon: true, message: txt('shareAlert') }), _jsx(UserSearchInput, { label: txt('shareWith'), value: shareWith, autoFocus: true, onChange: setShareWith, onPressEnter: handleShareOnEnter })] }));
};
export default ProjectShareDialog;
