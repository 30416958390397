export const parseSpeaker = (input) => {
    const speaker = {
        firstName: '',
        lastName: '',
        role: '',
    };
    let fullName = input;
    if (input.match(/\(.*\)/)) {
        [, speaker.role] = input.match(/\((.*)\)/);
        fullName = input.replace(/(.*)\(.*\)/, '$1').trim();
    }
    if (fullName.match(/ /)) {
        speaker.firstName = fullName.replace(/(.*) .*/, '$1');
        speaker.lastName = fullName.replace(/.* (.*)/, '$1');
    }
    else {
        speaker.firstName = fullName;
    }
    return speaker;
};
