import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import cookies from 'js-cookie';
import Helmet from 'react-helmet';
import { Divider } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import * as clientEnv from 'libs/client-env';
import IntroScreen from 'components/IntroScreen';
import Screen from 'components/Screen';
import { useAppContext } from 'App/app-context';
import { fetchProviders } from 'api/providers-api';
import { openByAuthString, openByCredentials, openByKeycloak, } from 'api/session';
import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
import { txt } from 'libs/i18n';
import Loading from 'components/Loading';
import LoginAlert, { isAlertCode } from './LoginAlert';
import AuthProviders from './AuthProviders';
import LoginForm from './LoginForm';
import DataConsentModal from './DataConsentConfirmation';
import styles from './style.module.less';
const EXTERNAL_AUTH_COOKIE = 'beey_external_auth';
const getExternalAuth = () => {
    if (window.location.hash.includes('session_state')
        || window.location.hash.includes('loginApa')) {
        return {
            type: 'apa',
        };
    }
    const authString = cookies.get(EXTERNAL_AUTH_COOKIE);
    if (authString === undefined) {
        return null;
    }
    return {
        type: 'openid',
        authString,
    };
};
const getAlertCode = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const maybeAlert = urlParams.get('alert');
    if (maybeAlert === null || !isAlertCode(maybeAlert)) {
        return null;
    }
    return maybeAlert;
};
const LoginScreen = () => {
    var _a;
    const history = useHistory();
    const { updateSession, clearSession, isLoginEnabled, triggerVersionMismatch, } = useAppContext();
    const [externalAuth] = useState(getExternalAuth());
    const [consentModalVisible, setConsentModalVisible] = useState(false);
    const [providers, setProviders] = useState('loading');
    const [credentials, setCredentials] = useState({
        email: (_a = clientEnv.getUserEmail()) !== null && _a !== void 0 ? _a : '',
        password: '',
    });
    const [alert, setAlert] = useState(getAlertCode());
    const initSession = (session) => {
        updateSession(session);
        history.push('/projects');
    };
    const loginWithProvider = async (auth) => {
        // FIXME: Dirty workaround for hotfix to redirect for apa.
        if (window.location.hash.includes('loginApa')) {
            window.location.href = apiV2.externalLogin.url({ provider: 'apa-prdesk' });
        }
        const sessionResult = auth.type === 'apa'
            ? await openByKeycloak(clearSession, triggerVersionMismatch)
            : await openByAuthString(auth.authString, clearSession, triggerVersionMismatch);
        initSession(sessionResult.getOrThrow());
    };
    useEffect(() => {
        if (externalAuth !== null) {
            if (externalAuth.type === 'openid') {
                cookies.remove(EXTERNAL_AUTH_COOKIE);
            }
            void loginWithProvider(externalAuth);
        }
    }, [externalAuth]);
    useEffect(() => {
        void (async () => {
            setProviders(await fetchProviders());
        })();
    }, []);
    const handleChangeCredentials = (newCredentials) => {
        setCredentials(newCredentials);
        setAlert(null);
    };
    const handleLogin = async () => {
        const sessionResult = await openByCredentials(credentials, undefined, clearSession, triggerVersionMismatch);
        if (sessionResult.isFail()) {
            const error = sessionResult.err();
            if (error === 'forbidden') {
                setAlert('accountBlocked');
                global.logger.error('Account blocked by admin', { error });
            }
            else if (error === 'unprocessable-entity') {
                setAlert('invalidCredentials');
                setCredentials(Object.assign(Object.assign({}, credentials), { password: '' }));
            }
            else if (error === 'precondition-failed') {
                setConsentModalVisible(true);
            }
            else {
                setAlert('unexpectedError');
                global.logger.error('Login failed unexpectedly', { error });
            }
            return;
        }
        initSession(sessionResult.get());
    };
    const handleDataConsentConfirm = async () => {
        const sessionResult = await openByCredentials(credentials, true, clearSession, triggerVersionMismatch);
        initSession(sessionResult.getOrThrow());
    };
    if (providers === 'loading' || externalAuth !== null) {
        return (_jsx(Screen, { intro: true, children: _jsx(Loading, {}) }));
    }
    const externalProviders = providers.filter((provider) => provider.key !== 'Beey');
    const isBeeyLoginEnabled = providers.some((provider) => provider.key === 'Beey');
    if (!isBeeyLoginEnabled && externalProviders.length === 1 && alert === null) {
        const provider = externalProviders[0];
        window.location.href = apiV2.externalLogin.url({ provider: provider.key });
        return (_jsx(Screen, { intro: true, children: _jsx(Loading, {}) }));
    }
    return (_jsxs(IntroScreen, { children: [_jsx(Helmet, { title: "Login" }), _jsx(DataConsentModal, { open: consentModalVisible, onConfirm: handleDataConsentConfirm }), alert !== null && _jsx(LoginAlert, { code: alert }), isBeeyLoginEnabled && (_jsx(LoginForm, { credentials: credentials, onChangeCredentials: handleChangeCredentials, onLogin: handleLogin, disabled: !isLoginEnabled })), (isBeeyLoginEnabled && externalProviders.length > 0)
                && _jsx(Divider, { className: styles.orDivider, children: txt('orLabel') }), isLoginEnabled && (_jsx(AuthProviders, { providers: externalProviders })), isBeeyLoginEnabled && (_jsxs("div", { className: styles.links, children: [_jsx(Link, { to: "/login/resetpassword", children: txt('newPass') }), _jsx(Link, { to: "/signup", children: txt('signup') })] }))] }));
};
export default LoginScreen;
