import trace from 'stacktrace-js';
import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
const sendLogs = async (logs) => fetch(apiV2.log.url(), {
    method: 'POST',
    cache: 'no-cache',
    headers: {
        'Content-Type': 'application/json',
    },
    body: logs,
});
export const sendStoredLogs = async () => {
    const logsString = localStorage.getItem('log');
    if (logsString === null) {
        return;
    }
    const separatorIndex = logsString.indexOf('|');
    const logSize = Number(logsString.substring(0, separatorIndex));
    const logItems = logsString.substring(separatorIndex + 1);
    const logs = `[${logItems}]`;
    try {
        const resp = await sendLogs(logs);
        if (resp.ok) {
            localStorage.removeItem('log');
        }
        else if (resp.status === 500) {
            localStorage.removeItem('log');
            global.logger.error('Server failed to accept {logSize} log messages, status 500, log purged!!', { logSize });
        }
        else {
            global.logger.error('Unable to send {logSize} messages to server, status {statusCode}, log stored for next try', { logSize, statusCode: resp.status });
        }
    }
    catch (error) {
        global.logger.error('Unable to send logs to server.', {}, error);
    }
};
export const convertFieldsToApiFormat = (fields) => {
    const result = {};
    Object.entries(fields).forEach(([key, value]) => {
        if (value === undefined) {
            return;
        }
        if (typeof value === 'string') {
            result[key] = { string: value };
        }
        else if (typeof value === 'boolean') {
            result[key] = { boolean: value };
        }
        else if (typeof value === 'number') {
            if (Math.trunc(value) === value) {
                result[key] = { integer: value };
            }
            else {
                result[key] = { float: value };
            }
        }
    });
    return result;
};
const TEMPLATE_PARAM_REGEX = /\{(\w*)\}/g;
const renderTemplate = (template, fields) => template.replace(TEMPLATE_PARAM_REGEX, (_, param) => String(fields[param]));
export const buildLogItem = async (level, messageTemplate, fields, error) => {
    const exceptions = [];
    if (error !== undefined) {
        let frames = [];
        try {
            // some errors cause trace.fromError to crash, e.g. NetworkError in Firefox.
            frames = await trace.fromError(error);
        }
        catch (_a) {
            global.logger.warn('Failed to get stack trace for error.');
        }
        exceptions.push({
            message: error.toString(),
            stackTrace: frames.map((frame) => frame.toString()),
        });
    }
    return {
        '@timestamp': new Date().toISOString(),
        level,
        messageTemplate,
        message: renderTemplate(messageTemplate, fields),
        fields: convertFieldsToApiFormat(fields),
        exceptions,
    };
};
