import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useHistory } from 'react-router';
import { App, Button } from 'antd';
import { sendActivationEmail } from 'api/user-api';
import Screen from 'components/Screen';
import LeadPanel from 'components/LeadPanel';
import { txt } from 'libs/i18n';
import beeyLogo from 'resources/img/product-icon.png';
import styles from './style.module.less';
const ActivationScreen = ({ session }) => {
    const [wasEmailSent, setWasEmailSent] = useState(false);
    const history = useHistory();
    const { message } = App.useApp();
    const handleSendActivationEmail = async () => {
        setWasEmailSent(true);
        try {
            await sendActivationEmail(session.connection);
            void message.success(txt('activationEmailSent'));
        }
        catch (error) {
            global.logger.error('Sending activation email failed', {}, error);
            void message.error(txt('sendingEmailFailed'));
        }
    };
    const handleSignupAgain = () => {
        void session.explicitLogout();
        history.push('/signup');
    };
    return (_jsx(Screen, { intro: true, children: _jsx("div", { className: styles.activationScreen, children: _jsx(LeadPanel, { isLogoDisplayed: false, children: _jsxs("div", { className: styles.activationScreenConfirmation, children: [_jsx("img", { src: beeyLogo, className: styles.beeyLogo, alt: "Beey logo" }), _jsx("h1", { className: styles.activationTitle, children: txt('thankYouRegistration') }), _jsxs("p", { children: [_jsx("span", { children: `${txt('accountActivation')} ` }), _jsx("span", { className: styles.userEmail, children: session.login.user.email })] }), _jsxs("p", { className: styles.sendAgainText, children: [`${txt('noEmail')} `, _jsx(Button, { className: styles.sendAgainButton, type: "link", onClick: handleSendActivationEmail, children: wasEmailSent ? txt('sendAgain') : txt('sendEmail') })] }), _jsxs("p", { className: styles.sendAgainText, children: [`${txt('wrongEmail')} `, _jsx(Button, { className: styles.sendAgainButton, type: "link", onClick: handleSignupAgain, children: txt('signupAgain') })] }), _jsx("p", { className: styles.lookingForwardText, children: txt('lookingForward') })] }) }) }) }));
};
export default ActivationScreen;
