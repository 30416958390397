import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Spin, Table } from 'antd';
import { changeSubscription, fetchAvailablePlans } from 'api/team-api';
import { useSession } from 'components/Authenticated';
import { useState, useEffect } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const AvailablePlans = ({ subscriptionId }) => {
    const { session } = useSession();
    const [availablePlans, setAvailablePlans] = useState();
    useEffect(() => {
        const getAvailablePlans = async () => {
            const plans = await fetchAvailablePlans(session.connection);
            setAvailablePlans(plans);
        };
        void getAvailablePlans();
    }, []);
    const columns = [
        {
            title: 'Source system',
            dataIndex: 'sourceSystem',
        },
        {
            title: 'Name',
            dataIndex: 'displayName',
        },
        {
            title: 'Plan key',
            dataIndex: 'planKey',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '40%',
        },
        {
            title: 'Is private',
            dataIndex: 'isPrivate',
            render: (isPrivate) => (isPrivate
                ? _jsx(CheckCircleOutlined, { style: { color: 'var(--ant-color-success)' } })
                : _jsx(CloseCircleOutlined, { style: { color: 'var(--ant-color-error)' } })),
        },
        {
            title: txt('changePlan'),
            render: (plan) => (_jsx(Button, { type: "primary", onClick: () => changeSubscription(session.connection, subscriptionId, plan.id), children: txt('changePlan') })),
        },
    ];
    return (availablePlans !== undefined ? (_jsx(Table, { dataSource: availablePlans, columns: columns, className: styles.table })) : _jsx(Spin, {}));
};
export default AvailablePlans;
