import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { QuestionOutlined, PlayCircleOutlined, InsertRowBelowOutlined, BulbOutlined, BookOutlined, BugOutlined, PhoneOutlined, } from '@ant-design/icons';
import { Dropdown, Divider } from 'antd';
import { useHistory } from 'react-router';
import { useSession } from 'components/Authenticated';
import CircleButton from 'components/CircleButton';
import { txt } from 'libs/i18n';
import ShortcutList from './ShortcutList';
import headerStyles from '../style.module.less';
import styles from './style.module.less';
const HelpSection = () => {
    const { updateUserSettings, session } = useSession();
    const [isShortcutListVisible, setIsShortcutListVisible] = useState(false);
    const history = useHistory();
    const displayMspSupport = session.login.hasClaim('dataTheme:msp');
    const runTutorial = () => {
        updateUserSettings({ startBeeyTutorial: true });
        history.push('/projects');
    };
    const items = [
        {
            key: '0',
            label: (_jsxs("button", { type: "button", onClick: runTutorial, className: styles.helpButton, children: [_jsx(PlayCircleOutlined, {}), _jsx("span", { children: txt('runBeeyTutorial') })] })),
            className: styles.helpSectionItem,
        },
        {
            key: '1',
            label: (_jsxs("button", { type: "button", onClick: () => setIsShortcutListVisible(true), className: styles.helpButton, children: [_jsx(InsertRowBelowOutlined, {}), _jsx("span", { children: txt('shortcutsLabel') })] })),
            className: styles.helpSectionItem,
        },
        {
            key: '2',
            label: (_jsx("a", { href: txt('faqsLink'), target: "_blank", rel: "noreferrer", children: "FAQs" })),
            icon: _jsx(BulbOutlined, {}),
            className: styles.helpSectionItem,
        },
        {
            key: '3',
            label: (_jsx("a", { href: txt('tutorialsLink'), target: "_blank", rel: "noreferrer", children: txt('tutorials') })),
            icon: _jsx(BookOutlined, {}),
            className: styles.helpSectionItem,
        },
        {
            key: '4',
            label: (_jsx("a", { href: txt('contactLink'), target: "_blank", rel: "noreferrer", children: txt('bugReport') })),
            icon: _jsx(BugOutlined, {}),
            className: styles.helpSectionItem,
            style: { display: displayMspSupport ? 'none' : 'flex' },
        },
        {
            key: '5',
            label: (_jsxs("div", { children: [_jsx("span", { children: displayMspSupport ? 'justice@beey.cz' : 'support@beey.io' }), displayMspSupport ? (_jsxs(_Fragment, { children: [_jsx(Divider, { type: "vertical" }), _jsx(PhoneOutlined, {}), _jsx("span", { children: "+420 225 540 225" })] })) : null] })),
            icon: '@',
            className: `${styles.helpSectionItem} ${styles.noClick}`,
        },
    ];
    return (_jsxs("div", { children: [_jsx(Dropdown, { menu: { items: session.login.hasClaim('projects:transcribe') ? items : items.slice(1) }, trigger: ['click'], overlayClassName: styles.dropdown, children: _jsx(CircleButton, { tooltip: txt('help'), content: _jsx(QuestionOutlined, {}), tooltipPlacement: "bottomLeft", className: headerStyles.headerButton }) }), _jsx(ShortcutList, { visible: isShortcutListVisible, onClose: () => setIsShortcutListVisible(false) })] }));
};
export default HelpSection;
