import Quill from 'quill';
export default class Summary {
    constructor(editorController) {
        this.getEnabled = () => {
            return this.enabled;
        };
        this.setEnabled = (enabled) => {
            if (this.enabled === enabled)
                return;
            this.enabled = enabled;
        };
        this.editorController = editorController;
        this.enabled = false;
    }
    handleTextChanged() {
        if (!this.enabled)
            return;
        let shouldContinueLoop = true;
        while (shouldContinueLoop) {
            shouldContinueLoop = this.fixFirstProblem();
        }
    }
    deletePreviousSummary(summaryIndex) {
        if (!this.enabled)
            return;
        let summaryBegin = summaryIndex;
        for (let i = summaryIndex - 1; i >= 0; i -= 1) {
            if (this.editorController.getLineFormat(i).summary !== undefined) {
                summaryBegin = i;
            }
            else {
                break;
            }
        }
        if (summaryBegin === summaryIndex) {
            return;
        }
        this.editorController.deleteText(summaryBegin, summaryIndex - summaryBegin, 'user');
    }
    handleGenerateSummary(clickTarget) {
        if (!this.enabled)
            return;
        const blot = Quill.find(clickTarget);
        const summaryBlotIndex = this.editorController.getIndex(blot);
        const from = this.editorController.getNextNewLineIndex(summaryBlotIndex);
        if (from === null) {
            global.logger.warn('cannot create summary because no line end was found', { index: from });
            return;
        }
        const to = this.getNextSectionIndex(from);
        this.editorController.onGenerateSummary({ from, to });
    }
    getNextSectionIndex(from) {
        let i = from;
        while (i < this.editorController.getLength()) {
            if (this.editorController.getLineFormat(i).section === undefined) {
                i += 1;
                continue;
            }
            else {
                return i;
            }
        }
        return this.editorController.getLength() - 1;
    }
    fixFirstProblem() {
        const blockStarts = this.editorController.getBlockStarts(0, this.editorController.getLength() - 1);
        const firstLineFormat = this.editorController.getLineFormat(0);
        if (firstLineFormat.summary !== undefined) {
            this.editorController.formatLine(0, 0, 'summary', false);
        }
        for (let i = 1; i <= blockStarts.length - 1; i += 1) {
            const previousBlockStart = blockStarts[i - 1];
            const currentBlockStart = blockStarts[i];
            const nextBlockStart = blockStarts[i + 1];
            const previousFormat = this.editorController.getLineFormat(previousBlockStart);
            const currentFormat = this.editorController.getLineFormat(currentBlockStart);
            const previousText = this.editorController.getText(previousBlockStart, currentBlockStart - previousBlockStart);
            const currentText = this.editorController.getText(currentBlockStart, nextBlockStart - currentBlockStart);
            // const nextFormat = this.editorController.getLineFormat(nextBlockStart);
            if (previousFormat.section !== undefined) {
                if (currentFormat.summary === undefined) {
                    this.editorController.insertTextWithFormat(currentBlockStart, '\n', { summary: 'button', speaker: false, section: false });
                    global.logger.info('inserting empty line because summary was missing at', { previousText });
                    return true;
                }
            }
            if (currentFormat.summary !== undefined && previousFormat.summary === 'button') {
                // NOTE: only last paragraph of summary should have the button.
                this.editorController.formatLine(previousBlockStart, 0, 'summary', 'nobutton');
                return true;
            }
            if (currentFormat.summary === undefined && previousFormat.summary === 'nobutton') {
                // NOTE: The last paragraph of summary must have the button.
                this.editorController.formatLine(previousBlockStart, 0, 'summary', 'button');
                return true;
            }
            if (currentFormat.summary !== undefined
                && (previousFormat.section === undefined && previousFormat.summary === undefined)) {
                // NOTE: Summary blocks must be after section heading without any gap
                if (nextBlockStart === currentBlockStart + 1) {
                    this.editorController.deleteText(currentBlockStart, 1);
                    return true;
                }
                this.editorController.formatLine(currentBlockStart, 0, 'summary', false);
                global.logger.info('removing formatting for line', { currentText });
                return true;
            }
        }
        const lastLineFormat = this.editorController.getLineFormat(this.editorController.getLength() - 1);
        if (lastLineFormat.summary !== undefined) {
            this.editorController.insertTextWithFormat(this.editorController.getLength(), '\n', {
                summary: false,
            });
        }
        return false;
    }
}
