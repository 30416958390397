import { jsx as _jsx } from "react/jsx-runtime";
import Loading from 'components/Loading';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
const InterimEditorRedirect = () => {
    const { projId } = useParams();
    const history = useHistory();
    useEffect(() => {
        history.replace(`/edit/${projId}`);
    }, []);
    return (_jsx(Loading, {}));
};
export default InterimEditorRedirect;
