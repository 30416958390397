import { centerHorizontally, splitAtFixedPositionHorizontally, splitAtFixedPositionVertically, } from 'libs/positions';
const MINIMUM_VIDEO_WIDTH = 200;
const MINIMUM_EDITOR_WIDTH = 500;
const convertEditorWidthToPixels = (bounds, editorWidth) => {
    if (editorWidth === 'auto') {
        return bounds.width * 0.5;
    }
    if (editorWidth === 'narrow') {
        return 650;
    }
    if (editorWidth === 'medium') {
        return 840;
    }
    if (editorWidth === 'wide') {
        return 1000;
    }
    throw Error(`invalid editorWidth ${editorWidth}`);
};
const divideDocumentAndPlayer = (bounds, editorWidth, aspectRatio, isSecondaryEditor) => {
    const widthCoefficient = isSecondaryEditor ? 1.5 : 1;
    const editorWidthPixels = widthCoefficient * convertEditorWidthToPixels(bounds, editorWidth);
    if (bounds.width - editorWidthPixels < MINIMUM_VIDEO_WIDTH
        || editorWidthPixels < MINIMUM_EDITOR_WIDTH) {
        const videoHeight = Math.min(bounds.height * 0.3, bounds.width / aspectRatio);
        const [videoPosition, documentPosition] = splitAtFixedPositionVertically(bounds, videoHeight);
        return [documentPosition, videoPosition];
    }
    return splitAtFixedPositionHorizontally(bounds, editorWidthPixels);
};
const computePlayerPosition = (bounds, aspectRatio) => {
    if (bounds.width / bounds.height > aspectRatio) {
        const videoWidth = aspectRatio * bounds.height;
        return centerHorizontally(bounds, videoWidth);
    }
    const videoHeight = bounds.width / aspectRatio;
    return {
        position: 'fixed',
        height: videoHeight,
        width: bounds.width,
        top: bounds.top,
        left: bounds.left,
    };
};
const editorPositionsWithoutVideo = (bounds, editorWidth, isSecondaryEditor) => {
    const editorWidthPixels = editorWidth === 'auto'
        ? 840
        : convertEditorWidthToPixels(bounds, editorWidth);
    if (isSecondaryEditor) {
        const [secondaryDocument, document] = splitAtFixedPositionHorizontally(bounds, bounds.width / 2);
        return {
            document,
            secondaryDocument,
            player: { display: 'none' },
        };
    }
    return {
        document: centerHorizontally(bounds, editorWidthPixels),
        secondaryDocument: { display: 'none' },
        player: { display: 'none' },
    };
};
const editorPositionsWithMinimisedVideo = (bounds, editorWidth, aspectRatio, isSecondaryEditor) => {
    const { document, secondaryDocument } = editorPositionsWithoutVideo(bounds, editorWidth, isSecondaryEditor);
    const height = 200;
    const width = height * aspectRatio;
    const left = bounds.left + bounds.width - width;
    const player = {
        position: 'fixed',
        top: bounds.top,
        height,
        width,
        left,
    };
    return {
        document,
        secondaryDocument,
        player,
    };
};
const editorPositionsWithHiddenVideo = (bounds, editorWidth, isSecondaryEditor) => {
    const { document, secondaryDocument } = editorPositionsWithoutVideo(bounds, editorWidth, isSecondaryEditor);
    const height = 80;
    const width = 40;
    const left = bounds.left + bounds.width - width;
    const player = {
        position: 'fixed',
        top: bounds.top,
        height,
        width,
        left,
    };
    return {
        document,
        secondaryDocument,
        player,
    };
};
const editorPositionsWithVideo = (bounds, editorWidth, videoAspectRatio, isSecondaryEditor) => {
    const [documentPosition, playerBounds] = divideDocumentAndPlayer(bounds, editorWidth, videoAspectRatio, isSecondaryEditor);
    const player = computePlayerPosition(playerBounds, videoAspectRatio);
    if (!isSecondaryEditor) {
        return {
            document: documentPosition,
            player,
            secondaryDocument: { display: 'none' },
        };
    }
    const [secondaryDocument, document] = splitAtFixedPositionHorizontally(documentPosition, documentPosition.width / 2);
    return {
        document,
        player,
        secondaryDocument,
    };
};
export const editorPositions = (bounds, videoAspectRatio, editorWidth, playerState, isSecondaryEditorAvailable) => {
    if (bounds === null) {
        return {
            player: { display: 'none' },
            document: { display: 'none' },
            secondaryDocument: { display: 'none' },
        };
    }
    const isSecondaryEditor = isSecondaryEditorAvailable && bounds.width > 600;
    const videoDisplayStatus = playerState.video;
    if (videoDisplayStatus === 'no-video' || videoAspectRatio === null) {
        return editorPositionsWithoutVideo(bounds, editorWidth, isSecondaryEditor);
    }
    if (!videoDisplayStatus.isVisible) {
        return editorPositionsWithHiddenVideo(bounds, editorWidth, isSecondaryEditor);
    }
    if (!videoDisplayStatus.isMaximized) {
        return editorPositionsWithMinimisedVideo(bounds, editorWidth, videoAspectRatio, isSecondaryEditor);
    }
    return editorPositionsWithVideo(bounds, editorWidth, videoAspectRatio, isSecondaryEditor);
};
// TODO width selector placement makes no sense and it should have completely new UX.
export const widthSelectorPositions = (bounds) => {
    if (bounds === null) {
        return { display: 'none' };
    }
    return {
        position: 'fixed',
        top: bounds.top + 3,
        left: bounds.left + bounds.width - 39,
        height: 30,
        width: 30,
    };
};
