import { useEffect, useState, useRef } from 'react';
import { useSession } from 'components/Authenticated';
import { usePlaybackContext } from '../WithMediaPlayback';
const RIGHT_BUTTON = 0;
const CENTER_BUTTON = 1;
const LEFT_BUTTON = 2;
const TIME_STEP = 0.15;
const BUTTON_POLL_INTERVAL = 25;
const getGamepad = () => {
    if (typeof navigator.getGamepads === 'function' && navigator.getGamepads().length > 0) {
        return navigator.getGamepads()[0];
    }
    // @ts-ignore TS2339: webkitGetGamepads is needed in safari
    if (typeof navigator.webkitGetGamepads === 'function' && navigator.webkitGetGamepads().length > 0) {
        // @ts-ignore TS2339: webkitGetGamepads is needed in safari
        return navigator.webkitGetGamepads()[0];
    }
    return null;
};
const buttonPressed = (button) => {
    if (typeof button === 'object') {
        return button.pressed;
    }
    return button === 1.0;
};
const Gamepad = () => {
    const { playback, playerState } = usePlaybackContext();
    const [connected, setConnected] = useState(false);
    const { session } = useSession();
    const pollLoop = useRef(() => { });
    const centerButtonPressed = useRef(false);
    const pollIntervalId = useRef(null);
    useEffect(() => {
        if (getGamepad() !== null) {
            setConnected(true);
        }
        const connect = () => setConnected(true);
        const disconnect = () => setConnected(false);
        window.addEventListener('gamepadconnected', connect);
        window.addEventListener('gamepaddisconnected', disconnect);
        return () => {
            window.removeEventListener('gamepadconnected', connect);
            window.removeEventListener('gamepaddisconnected', disconnect);
        };
    }, []);
    const handleCenterButtonPressed = () => {
        const { pauseOnPedalRelease, isAudioDelayed, audioDelay } = session.login.user.settings;
        if (pauseOnPedalRelease) {
            if (isAudioDelayed) {
                playback.delayAudio(audioDelay);
            }
            else {
                playback.play();
            }
        }
        else if (isAudioDelayed && !playback.playing) {
            playback.delayAudio(audioDelay);
        }
        else {
            playback.togglePlay();
        }
    };
    const handleCenterButtonReleased = () => {
        const { pauseOnPedalRelease } = session.login.user.settings;
        if (pauseOnPedalRelease) {
            playback.pause();
        }
    };
    pollLoop.current = () => {
        const gamepad = getGamepad();
        if (gamepad === null) {
            return;
        }
        if (playerState.status !== 'ready') {
            return;
        }
        if (buttonPressed(gamepad.buttons[LEFT_BUTTON])) {
            playback.stepTimeBy(-TIME_STEP);
        }
        else if (buttonPressed(gamepad.buttons[RIGHT_BUTTON])) {
            playback.stepTimeBy(TIME_STEP);
        }
        if (buttonPressed(gamepad.buttons[CENTER_BUTTON])) {
            if (!centerButtonPressed.current) {
                handleCenterButtonPressed();
            }
            centerButtonPressed.current = true;
        }
        else {
            if (centerButtonPressed.current) {
                handleCenterButtonReleased();
            }
            centerButtonPressed.current = false;
        }
    };
    useEffect(() => {
        if (pollIntervalId.current !== null) {
            clearInterval(pollIntervalId.current);
            pollIntervalId.current = null;
        }
        if (connected) {
            pollIntervalId.current = window.setInterval(() => pollLoop.current(), BUTTON_POLL_INTERVAL);
            return () => {
                if (pollIntervalId.current !== null) {
                    clearInterval(pollIntervalId.current);
                }
            };
        }
        return undefined;
    }, [connected]);
    return null;
};
export default Gamepad;
