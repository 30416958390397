import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const LoginForm = ({ credentials, onChangeCredentials, onLogin, disabled, }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue(Object.assign({}, credentials));
    }, [credentials]);
    const handleValuesChange = (_, allValues) => {
        onChangeCredentials(allValues);
    };
    return (_jsxs(Form, { className: styles.form, onFinish: onLogin, initialValues: credentials, onValuesChange: handleValuesChange, form: form, children: [_jsx(Form.Item, { name: "email", className: styles.formInput, rules: [
                    {
                        required: true,
                        transform: (value) => value.trim(),
                        type: 'email',
                        message: txt('pleaseEmail'),
                    },
                ], children: _jsx(Input, { placeholder: txt('email'), autoFocus: true, size: "large" }) }), _jsx(Form.Item, { name: "password", rules: [
                    {
                        required: true,
                        message: txt('yourPassword'),
                    },
                ], className: styles.formInput, children: _jsx(Input, { type: "password", placeholder: txt('pw'), size: "large" }) }), _jsx(Button, { type: "primary", htmlType: "submit", disabled: disabled, size: "large", className: styles.formButton, children: txt('login') })] }));
};
export default LoginForm;
