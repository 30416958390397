import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { fetchVoiceSampleMedia } from 'api/speaker-api';
import { useRef, useState } from 'react';
import { useSession } from 'components/Authenticated';
import { Button, Flex, Slider } from 'antd';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { formatMediaTime } from 'libs/format-number';
import styles from './style.module.less';
const VoiceSamplePlayer = ({ sampleId }) => {
    const { session } = useSession();
    const [player, setPlayer] = useState({ status: 'not-loaded' });
    const loadedPlayer = player.status === 'playing' || player.status === 'ready-to-play';
    const audioElement = useRef(null);
    const togglePlay = () => {
        var _a, _b;
        if (player.status === 'playing') {
            void ((_a = audioElement.current) === null || _a === void 0 ? void 0 : _a.pause());
            setPlayer(Object.assign(Object.assign({}, player), { status: 'ready-to-play' }));
        }
        else if (player.status === 'ready-to-play') {
            void ((_b = audioElement.current) === null || _b === void 0 ? void 0 : _b.play());
            setPlayer(Object.assign(Object.assign({}, player), { status: 'playing' }));
        }
    };
    const handlePlayClicked = async () => {
        if (player.status === 'not-loaded') {
            setPlayer({ status: 'loading' });
            const blob = await fetchVoiceSampleMedia(session.connection, sampleId);
            const audioBlobUrl = URL.createObjectURL(blob);
            setPlayer({
                status: 'ready-to-play',
                source: audioBlobUrl,
                duration: 0,
                currentTime: 0,
            });
        }
        else {
            togglePlay();
        }
    };
    const handleMetadataLoaded = () => {
        var _a, _b, _c;
        if (player.status === 'ready-to-play') {
            void ((_a = audioElement.current) === null || _a === void 0 ? void 0 : _a.play());
            setPlayer(Object.assign(Object.assign({}, player), { status: 'playing', duration: (_c = (_b = audioElement.current) === null || _b === void 0 ? void 0 : _b.duration) !== null && _c !== void 0 ? _c : 0 }));
        }
    };
    const handleTimeUpdate = () => {
        var _a, _b;
        const currentTime = (_b = (_a = audioElement.current) === null || _a === void 0 ? void 0 : _a.currentTime) !== null && _b !== void 0 ? _b : 0;
        if (player.status === 'playing') {
            setPlayer(Object.assign(Object.assign({}, player), { currentTime }));
        }
    };
    const seekAudio = (value) => {
        if (audioElement.current !== null && loadedPlayer) {
            audioElement.current.currentTime = value;
            setPlayer(Object.assign(Object.assign({}, player), { currentTime: value }));
        }
    };
    return (_jsxs("div", { className: styles.player, children: [_jsxs(Flex, { align: "center", children: [_jsx(Button, { className: styles.playButton, loading: player.status === 'loading', onClick: handlePlayClicked, size: "small", shape: "circle", icon: player.status === 'playing' ? _jsx(PauseOutlined, {}) : _jsx(CaretRightOutlined, {}) }), loadedPlayer && (_jsxs(_Fragment, { children: [_jsx(Slider, { onChange: seekAudio, max: player.duration, value: player.currentTime, tooltip: { open: false }, className: styles.slider }), _jsx("div", { className: styles.playerTime, children: `${formatMediaTime(player.currentTime)} 
          / ${formatMediaTime(player.duration)}` })] }))] }), _jsx("audio", { loop: true, ref: audioElement, onTimeUpdate: handleTimeUpdate, onLoadedMetadata: handleMetadataLoaded, children: loadedPlayer && _jsx("source", { src: player.source, type: "audio/mp3" }) })] }));
};
export default VoiceSamplePlayer;
