import Quill from 'quill';
const BlockBlot = Quill.import('blots/block');
class UnalignedBlot extends BlockBlot {
    static create(value) {
        const node = super.create(value);
        node.className = UnalignedBlot.blotName;
        return node;
    }
    static formats(domNode) {
        return domNode.classList.contains(UnalignedBlot.blotName);
    }
    format(name, value) {
        if (name === UnalignedBlot.blotName) {
            if (value) {
                this.domNode.classList.add(UnalignedBlot.blotName);
            }
            else {
                this.domNode.classList.remove(UnalignedBlot.blotName);
            }
        }
        else {
            super.format(name, value);
        }
    }
    formats() {
        const formats = super.formats();
        return formats;
    }
    attach() {
        if (this.parent != null) {
            this.scroll = this.parent.scroll;
        }
    }
}
UnalignedBlot.blotName = 'unaligned';
export default UnalignedBlot;
