import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import IconButton from 'components/IconButton';
import { Progress, Divider, Spin, Modal, } from 'antd';
import { useState } from 'react';
import dayjs from 'dayjs';
import { txt } from 'libs/i18n';
import './style.less';
const ProgressMessage = ({ progressStatus }) => {
    const formatProgressAsTime = (transcribed) => {
        const time = dayjs.duration(1000 * transcribed).format('H:mm:ss');
        return time;
    };
    if (progressStatus === 'not-in-progress') {
        return null;
    }
    if (progressStatus.duration === 'invalid') {
        return _jsx("div", { children: formatProgressAsTime(progressStatus.transcribed) });
    }
    const percentProgress = Math.round(((progressStatus.transcribed / progressStatus.duration) * 1000) / 10);
    if (percentProgress === 100) {
        // Do not show 100%. We know its the best
        return null;
    }
    if (percentProgress === 0) {
        return _jsx(Spin, { indicator: _jsx(LoadingOutlined, {}) });
    }
    if (percentProgress > 100) {
        return _jsx("div", { children: formatProgressAsTime(progressStatus.transcribed) });
    }
    return (_jsx(Progress, { type: "circle", className: "progress-icon", percent: percentProgress, size: 33, strokeLinecap: "square" }));
};
const StatusBar = ({ progressStatus, projectErrorStatus, onStopTranscription }) => {
    const [modal, contextHolder] = Modal.useModal();
    const [modalVisible, setModalVisible] = useState(false);
    if (projectErrorStatus !== 'no-error') {
        return null;
    }
    if (progressStatus === 'not-in-progress') {
        return null;
    }
    const displayConfirmModal = () => {
        setModalVisible(true);
        void modal.confirm({
            title: txt('stopTranscriptionQuestion'),
            content: txt('confirmStopTranscription'),
            okText: txt('yesStop'),
            cancelText: txt('cancel'),
            okButtonProps: { type: 'primary', danger: true },
            onOk: () => {
                onStopTranscription();
                setModalVisible(false);
            },
            onCancel: () => {
                setModalVisible(false);
            },
        });
    };
    return (_jsxs("div", { className: "statusbar", children: [_jsxs("div", { className: "statusbar__box", children: [_jsx("div", { className: "statusbar__text", children: txt('transcriptionProgress') }), _jsx(ProgressMessage, { progressStatus: progressStatus })] }), _jsx(IconButton, { disabled: modalVisible, danger: true, tooltip: txt('stopTranscription'), onClick: displayConfirmModal, children: _jsx(CloseCircleFilled, {}) }), contextHolder, _jsx(Divider, { type: "vertical" })] }));
};
export default StatusBar;
