import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import clsx from 'clsx';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const EM_REGEX = /<em>(.*?)<\/em>/;
const ParsedSearchResult = ({ text, onAction = null, actionLabel = null }) => {
    const parsedResult = text
        // NOTE: backend does not highlight spaces, but we need them highlighted
        // for redirect to editor from multiword search phrases to work propperly.
        .replaceAll('</em> <em>', ' ')
        .split(EM_REGEX);
    return (_jsxs(_Fragment, { children: [_jsx("p", { className: styles.result, children: parsedResult.map((textPart, idx) => (_jsx("span", { className: clsx({ 'search-results__string': idx % 2 === 1 }), children: textPart }, nanoid(5)))) }), onAction !== null && actionLabel !== null && (_jsx(Tooltip, { title: txt(actionLabel), mouseEnterDelay: 0.5, mouseLeaveDelay: 0, children: _jsx(Button, { className: styles.actionButton, type: "primary", onClick: () => onAction(parsedResult), children: _jsx(ArrowRightOutlined, {}) }) }))] }));
};
export default ParsedSearchResult;
