import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Form, Popover, Tooltip, Input, } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import DropdownForm from './DropdownForm';
import './style.less';
const { Search } = Input;
const isSearchEmpty = (searchParams) => {
    const { all, projectname, transcription, speakers, notes, inAnyField, } = searchParams;
    return [all, projectname, transcription, speakers, notes, inAnyField].every((item) => item === '');
};
const FulltextSearchBar = ({ searchParams, onSearchChanged }) => {
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [form] = Form.useForm();
    const { session, updateUserSettings } = useSession();
    const { login: { user: { settings: { fulltextSearchParams } } } } = session;
    const EMPTY_FORM_VALUES = {
        all: '', projectname: '', transcription: '', speakers: '', notes: '', inAnyField: '', strict: fulltextSearchParams.strict,
    };
    useEffect(() => {
        form.setFieldsValue(searchParams === undefined
            ? EMPTY_FORM_VALUES
            : searchParams);
    }, [searchParams]);
    const handleSearch = () => {
        const searchFormValues = form.getFieldsValue();
        const isStrict = form.getFieldValue('strict');
        if (isStrict !== fulltextSearchParams.strict) {
            updateUserSettings({
                fulltextSearchParams: {
                    strict: searchFormValues.strict,
                },
            });
        }
        if (isSearchEmpty(searchFormValues)) {
            onSearchChanged();
        }
        else {
            onSearchChanged(searchFormValues);
        }
    };
    const resetFields = () => {
        onSearchChanged();
        form.resetFields();
    };
    const handleChangeAll = (e) => {
        form.setFieldsValue({ inAnyField: e.target.value });
        if (e.target.value === '') {
            void handleSearch();
        }
    };
    const handleChangeIncludesAll = (value) => {
        form.setFieldsValue({ all: value });
        if (value === '') {
            void handleSearch();
        }
    };
    const suffix = (_jsx(Popover, { trigger: ['click'], content: (_jsx(DropdownForm, { onSearch: handleSearch, onPopoverVisible: setPopoverVisible, onResetFields: resetFields, onIncludesAllChange: handleChangeIncludesAll })), open: popoverVisible, onOpenChange: setPopoverVisible, 
        // @ts-ignore - popover supports forceRender, but it is missing in types
        forceRender // without forceRender form values are undefined if popover is not open
        : true, placement: "bottomRight", arrow: false, children: _jsx(Tooltip, { title: txt('advancedSearch'), children: _jsx(CaretDownOutlined, { className: "fulltext-search__icon" }) }) }));
    return (_jsx("div", { className: "fulltext-search", children: _jsx(Form, { form: form, initialValues: EMPTY_FORM_VALUES, children: _jsx(Form.Item, { name: "all", children: _jsx(Search, { placeholder: txt('fulltextSearch'), className: "fulltext-search__input", onSearch: handleSearch, enterButton: true, onChange: handleChangeAll, suffix: suffix }) }) }) }));
};
export default FulltextSearchBar;
