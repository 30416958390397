import Delta from 'quill-delta';
import RegexEscape from 'regex-escape';
import { getAllMatchIndices } from './text-utils';
class FindAndReplace {
    constructor(_, editorController) {
        this.onSelectionChange = () => {
            this.recolorBackgroundOfCurrentPosition();
        };
        this.skipToResult = (direction) => {
            // if caret is in editor then when skipping result focus must be outside the editor
            if (this.editorController.getSelection() !== null) {
                this.editorController.blur();
            }
            // For cases when the user has already searched and places the caret
            // in editor (current index is reset to null).
            if (this.currentIndex === null) {
                this.searchAndHighlight(this.searchedValue, this.isMatchCase);
                return;
            }
            let delta = new Delta();
            // coloring previous result to the color of all results
            delta = delta.compose(this.buildColorBackgroundDelta(this.resultPositions[this.currentIndex], this.searchedValue.length, 'result'));
            // if text has been edited before skipping, indices have to be updated by a new search
            if (this.textEditedAfterSearch) {
                delta = delta.compose(this.search());
            }
            if (direction === 'next') {
                this.currentIndex += 1;
                // If last word is found, then start from begin
                if (this.currentIndex > this.resultPositions.length - 1) {
                    this.currentIndex = 0;
                }
            }
            else {
                this.currentIndex -= 1;
                // If last word is found, then start from end
                if (this.currentIndex < 0) {
                    this.currentIndex = this.resultPositions.length - 1;
                }
            }
            const currentPosition = this.resultPositions[this.currentIndex];
            delta = delta.compose(this.buildColorBackgroundDelta(currentPosition, this.searchedValue.length, 'highlighted'));
            this.editorController.scrollToIndex(currentPosition);
            this.editorController.execTextChange({ runAligner: false, requestSave: false }, () => {
                this.editorController.updateContents(delta, 'api');
                this.textEditedAfterSearch = false;
            });
        };
        this.searchAndHighlight = (searchedValue, isMatchCase) => {
            this.currentIndex = null;
            this.searchedValue = searchedValue;
            this.isMatchCase = isMatchCase;
            let delta = this.search();
            this.setCurrentIndexCloseToCaret(this.resultPositions);
            // NOTE: eslint false alarm: current index was set in function above
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (this.currentIndex !== null && this.resultPositions.length > 0) {
                delta = delta.compose(this.buildColorBackgroundDelta(this.resultPositions[this.currentIndex], searchedValue.length, 'highlighted'));
                this.editorController.scrollToIndex(this.resultPositions[this.currentIndex]);
            }
            this.editorController.execTextChange({ runAligner: false, requestSave: false }, () => {
                this.editorController.updateContents(delta, 'api');
            });
            this.textEditedAfterSearch = false;
        };
        this.replace = (searchedValue, replacingValue, isMatchCase) => {
            let delta = new Delta();
            const offset = replacingValue.length - searchedValue.length;
            // if no searching before replacing took place, caret was placed in editor
            // or input value (searchedValue) has been changed
            // then search and highlight all incl. actual occurrence
            if (this.resultPositions.length === 0
                || this.currentIndex === null
                || this.editorController.getSelection() !== null
                || this.searchedValue !== searchedValue) {
                this.searchAndHighlight(searchedValue, isMatchCase);
                return;
            }
            this.updateRemovedStyle(this.resultPositions[this.currentIndex], searchedValue.length);
            this.editorController.deleteText(this.resultPositions[this.currentIndex], searchedValue.length, 'user');
            this.editorController.insertText(this.resultPositions[this.currentIndex], replacingValue, 'user');
            this.editorController.getHistory().cutoff();
            for (let i = this.currentIndex; i < this.resultPositions.length; i += 1) {
                this.resultPositions[i] += offset;
            }
            this.textEditedAfterSearch = false;
            if (this.resultPositions.length > 1) {
                // After replacing, data have to be updated and highlighted
                this.currentIndex += 1;
                // If last word is being replaced, then start from begin
                if (this.currentIndex > this.resultPositions.length - 1) {
                    this.currentIndex = 0;
                }
                const currentPosition = this.resultPositions[this.currentIndex];
                delta = delta.compose(this.search());
                this.currentIndex = this.resultPositions.indexOf(currentPosition);
                delta = delta.compose(this.buildColorBackgroundDelta(currentPosition, searchedValue.length, 'highlighted'));
                this.editorController.scrollToIndex(currentPosition);
            }
            else {
                delta = delta.compose(this.search());
            }
            this.editorController.updateContents(delta, 'api');
            this.textEditedAfterSearch = false;
        };
        this.replaceAll = (replacingValue) => {
            const resultsCount = this.resultPositions.length;
            const offset = replacingValue.length - this.searchedValue.length;
            let totalOffset = 0;
            this.updateRemovedStyle();
            let replaceAllDelta = new Delta();
            while (this.resultPositions.length > 0) {
                const replaceThisDelta = new Delta()
                    .retain(this.resultPositions[0] + totalOffset)
                    .delete(this.searchedValue.length)
                    .insert(replacingValue);
                replaceAllDelta = replaceAllDelta.compose(replaceThisDelta);
                this.resultPositions.shift();
                totalOffset += offset;
            }
            this.editorController.updateContents(replaceAllDelta, 'user');
            void this.reset();
            return resultsCount;
        };
        this.reset = () => {
            this.resultPositions = [];
            this.currentIndex = null;
            this.updateRemovedStyle();
        };
        this.recolorBackgroundOfCurrentPosition = () => {
            if (this.currentIndex !== null && this.resultPositions.length > 0) {
                this.editorController.execTextChange({ runAligner: false, requestSave: false }, () => {
                    this.editorController.updateContents(this.buildColorBackgroundDelta(this.resultPositions[this.currentIndex], this.searchedValue.length, 'result'), 'api');
                });
                // Changes in quill text, in this case background color, sets textEditedAfterSearch to true.
                // As this change is not caused by the user, textEditedAfterSearch has to be set to false.
                this.textEditedAfterSearch = false;
                this.currentIndex = null;
            }
        };
        this.updateRemovedStyle = (position = 0, length = this.editorController.getLength()) => {
            this.editorController.execTextChange({ runAligner: false, requestSave: false }, () => {
                this.editorController.updateContents(this.removeStyle(position, length), 'api');
            });
            this.textEditedAfterSearch = false;
        };
        this.editorController = editorController;
        this.resultPositions = [];
        this.currentIndex = null;
        // Editing by user
        this.textEditedAfterSearch = false;
        this.isMatchCase = false;
        this.searchedValue = '';
    }
    handleTextChange() {
        if (this.textEditedAfterSearch === true) {
            return;
        }
        this.textEditedAfterSearch = true;
    }
    search() {
        let delta = new Delta();
        const totalText = this.editorController.getText();
        const correctedTotalText = this.isMatchCase ? totalText : totalText.toLowerCase();
        const caseSensitivity = (sensitivity) => new RegExp(RegexEscape(this.searchedValue), sensitivity);
        const correctedSearchedValue = this.isMatchCase ? caseSensitivity('g') : caseSensitivity('gi');
        delta = delta.compose(this.removeStyle());
        // Exclude speakers's blot
        this.resultPositions = getAllMatchIndices(correctedTotalText, correctedSearchedValue).filter((result) => this.editorController.getLineFormat(result).speaker === undefined);
        if (this.resultPositions.length === 0) {
            this.currentIndex = null;
            return delta;
        }
        for (let i = 0; i < this.resultPositions.length; i += 1) {
            delta = delta.compose(this.buildColorBackgroundDelta(this.resultPositions[i], this.searchedValue.length, 'result'));
        }
        return delta;
    }
    buildColorBackgroundDelta(position, length, resultType) {
        return new Delta().retain(position).retain(length, { search: resultType });
    }
    removeStyle(position = 0, length = this.editorController.getLength()) {
        return new Delta().retain(position).retain(length, { search: false });
    }
    setCurrentIndexCloseToCaret(resultPositions) {
        this.editorController.focus();
        const selectionRange = this.editorController.getSelection();
        if (selectionRange !== null) {
            const { index } = selectionRange;
            this.currentIndex = 0;
            for (let i = 0; i < resultPositions.length; i += 1) {
                if (resultPositions[i] >= index) {
                    this.currentIndex = i;
                    break;
                }
            }
        }
        this.editorController.blur();
    }
}
export default FindAndReplace;
