import { changeCapitalLetter, findClosestLetter, getClosestWordEnd } from 'libs/quill-utils';
import { txt } from 'libs/i18n';
import { INTERPUNCTIONS_REGEX, LETTER_REGEX, NBSP_SYMBOL, NONSPACE_REGEX, } from './text-utils';
const ANCHOR_ADJUST_STEP = 0.1;
const QUESTION_MARKS = '(???)';
export class KeyboardHandler {
    constructor(editorController) {
        this.handleEditorKeyDown = (event) => {
            const { keyCode } = event;
            const selection = this.editorController.forceGetQuillSelection();
            if ((keyCode === 32 // space
                || keyCode === 8 // backspace
                || keyCode === 46 // delete
                || keyCode === 13 // enter
                || (keyCode >= 37 && keyCode <= 40) // arrows
                || (keyCode >= 65 && keyCode <= 90) // a regular letter
                || (keyCode >= 48 && keyCode <= 57) // numbers
                || (keyCode >= 96 && keyCode <= 105)) // num numbers
                && (!event.ctrlKey && !event.altKey)) {
                if (this.editorController.settings.syncCaret) {
                    this.editorController.playback.pause();
                }
            }
            if ((keyCode >= 37 && keyCode <= 40) // arrows
                && event.ctrlKey
                && !event.altKey
                && !event.shiftKey) {
                if (this.editorController.settings.syncCaret) {
                    this.editorController.playback.pause();
                }
            }
            if (event.key === 'Enter' && event.shiftKey && !event.altKey) {
                if (this.editorController.settings.syncCaret) {
                    this.editorController.playback.pause();
                }
                this.editorController.handleSelectSpeaker(false);
                event.preventDefault();
            }
            else if (event.key === 'Enter' && event.altKey) {
                if (this.editorController.settings.syncCaret) {
                    this.editorController.playback.pause();
                }
                const speakerNode = this.editorController.getSpeakerNode(selection.index);
                if (speakerNode !== null) {
                    this.editorController.handleReplaceSpeaker(speakerNode, false, true);
                }
                event.preventDefault();
            }
            else if (event.key === 'Enter' && event.ctrlKey) {
                if (this.editorController.getLineFormat(selection.index).summary !== undefined) {
                    void this.editorController.onMessage('info', txt('speakerSummaryError'));
                }
                else {
                    this.editorController.speakers.addDummySpeakerOnCaret();
                }
            }
            if ((event.code === 'Digit1' || event.code === 'Numpad1')
                && event.ctrlKey === true) {
                if (event.shiftKey === true) {
                    this.insertHeadingBefore(1);
                }
                else {
                    this.editorController.insertHeading1();
                }
                event.preventDefault();
            }
            if ((event.code === 'Digit2' || event.code === 'Numpad2')
                && event.ctrlKey === true) {
                if (event.shiftKey === true) {
                    this.insertHeadingBefore(2);
                }
                else {
                    this.editorController.sections.insertSection();
                }
                event.preventDefault();
            }
            if ((event.key === '!'
                || event.key === '.'
                || event.key === '?')
                && !event.ctrlKey && !event.altKey) {
                changeCapitalLetter(this.editorController.quill, selection.index, false);
            }
            if (event.key === '.' && event.ctrlKey) {
                this.removePunctuation();
            }
            if (event.key === 'ArrowLeft' && event.ctrlKey && !event.shiftKey) {
                this.ctrlLeftJump();
                event.preventDefault();
            }
            if (event.key === 'ArrowRight' && event.ctrlKey && !event.shiftKey) {
                this.ctrlRightJump();
                event.preventDefault();
            }
            if (event.code === 'KeyG'
                && event.ctrlKey
                && !event.shiftKey) {
                this.capitalizeText('switchCase');
                event.preventDefault();
            }
            if (event.key.toLowerCase() === 'k' && event.ctrlKey && !event.shiftKey) {
                this.swapYandI();
                event.preventDefault();
            }
            if (
            // cannot use event.code because of QWERTZ
            (event.key.toLowerCase() === 'z' || event.key.toLowerCase() === 'я')
                && event.ctrlKey) {
                this.editorController.getHistory().undo();
                event.preventDefault();
            }
            if (((event.key.toLowerCase() === 'y' || event.key.toLowerCase() === 'н')
                && event.ctrlKey)
                || ((event.key.toLowerCase() === 'z' || event.key.toLowerCase() === 'я')
                    && event.ctrlKey
                    && event.shiftKey)) {
                this.editorController.getHistory().redo();
                event.preventDefault();
            }
            if (event.code === 'KeyQ' && event.ctrlKey) {
                this.editorController.timeAnchors.insertTimeAnchor(this.editorController.playback.time);
                event.preventDefault();
            }
            if (event.code === 'KeyB' && event.ctrlKey) {
                this.editorController.insertCaptionEnd();
                event.preventDefault();
            }
            if (event.key === ' ' && event.ctrlKey) {
                this.insertNbsp();
                event.preventDefault();
            }
            if (event.code === 'BracketLeft' && event.ctrlKey) {
                this.editorController.timeAnchors.forceTimestampAndAdjust(-ANCHOR_ADJUST_STEP);
                event.preventDefault();
            }
            if (event.code === 'BracketRight' && event.ctrlKey) {
                this.editorController.timeAnchors.forceTimestampAndAdjust(+ANCHOR_ADJUST_STEP);
                event.preventDefault();
            }
            if (event.key === 'i' && event.ctrlKey) {
                this.editorController.deleteText(selection.index, selection.length);
                this.editorController.insertText(selection.index, QUESTION_MARKS);
                this.editorController.setSelection(selection.index + QUESTION_MARKS.length, 0);
            }
            if (event.key === ' ') {
                this.editorController.expandableAbbreviations.handleEventKeySpace();
            }
        };
        this.changeTextToTitleCase = (wordFirstLetterIndex, wordLastLetterIndex) => {
            const text = this.editorController.getText(wordFirstLetterIndex, wordLastLetterIndex - wordFirstLetterIndex);
            const titleText = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
            this.editorController.deleteText(wordFirstLetterIndex, wordLastLetterIndex - wordFirstLetterIndex);
            this.editorController.insertText(wordFirstLetterIndex, titleText);
        };
        this.changeTextToUpperCase = (wordFirstLetterIndex, wordLastLetterIndex) => {
            const text = this.editorController
                .getText(wordFirstLetterIndex, wordLastLetterIndex - wordFirstLetterIndex)
                .toUpperCase();
            this.editorController.deleteText(wordFirstLetterIndex, wordLastLetterIndex - wordFirstLetterIndex);
            this.editorController.insertText(wordFirstLetterIndex, text);
        };
        this.changeTextToLowerCase = (wordFirstLetterIndex, wordLastLetterIndex) => {
            const text = this.editorController
                .getText(wordFirstLetterIndex, wordLastLetterIndex - wordFirstLetterIndex)
                .toLowerCase();
            this.editorController.deleteText(wordFirstLetterIndex, wordLastLetterIndex - wordFirstLetterIndex);
            this.editorController.insertText(wordFirstLetterIndex, text);
        };
        this.switchCapitalizationCase = (wordFirstLetterIndex, wordLastLetterIndex) => {
            const text = this.editorController.getText(wordFirstLetterIndex, wordLastLetterIndex - wordFirstLetterIndex);
            // NOTE: Switching procedure in cycle: lower case -> Title case -> UPPER CASE.
            if (text === text.toLowerCase()) {
                this.changeTextToTitleCase(wordFirstLetterIndex, wordLastLetterIndex);
            }
            else if (text === text.toUpperCase()) {
                this.changeTextToLowerCase(wordFirstLetterIndex, wordLastLetterIndex);
            }
            else {
                this.changeTextToUpperCase(wordFirstLetterIndex, wordLastLetterIndex);
            }
        };
        this.editorController = editorController;
    }
    capitalizeText(caseType) {
        var _a;
        // NOTE: Capitalization can be switched using a dropdown. In such a case, the focus is
        // in a dropdown and the selection is found in metadata and received using .getLastSelection()
        const selection = (_a = this.editorController.getSelection()) !== null && _a !== void 0 ? _a : this.editorController.getLastSelection();
        if (selection === null) {
            return;
        }
        const wordStart = this.editorController.getWordStart(selection.index);
        const wordFirstLetterIndex = findClosestLetter(this.editorController.quill, wordStart);
        // NOTE: When text is selected, then the selection index is always at lower index.
        const wordLastLetterIndex = getClosestWordEnd(this.editorController.quill, selection.index);
        const currentFormat = this.editorController.getLineFormat(wordFirstLetterIndex);
        if (currentFormat.speaker !== undefined) {
            return;
        }
        if (caseType === 'switchCase') {
            this.switchCapitalizationCase(wordFirstLetterIndex, wordLastLetterIndex);
        }
        else if (caseType === 'titleCase') {
            this.changeTextToTitleCase(wordFirstLetterIndex, wordLastLetterIndex);
        }
        else if (caseType === 'upperCase') {
            this.changeTextToUpperCase(wordFirstLetterIndex, wordLastLetterIndex);
        }
        else {
            this.changeTextToLowerCase(wordFirstLetterIndex, wordLastLetterIndex);
        }
        // NOTE: Place caret back, where it was before text capitalization.
        this.editorController.setSelection(selection.index, selection.length);
    }
    insertNbsp() {
        const selection = this.editorController.getSelection();
        if (selection === null)
            return;
        this.editorController.insertTextWithFormat(selection.index, NBSP_SYMBOL, {
            captionEnd: false,
            timeAnchor: false,
            speaker: false,
        });
        this.editorController.setSelection(selection.index + 1, 0);
    }
    swapYandI() {
        const selection = this.editorController.getSelection();
        if (!selection)
            return;
        let i = findClosestLetter(this.editorController.quill, selection.index - 1);
        let char = this.editorController.getText(i, 1);
        // NOTE: Search for the last letter of the active word.
        while (i <= this.editorController.getLength() && LETTER_REGEX.test(char)) {
            i += 1;
            char = this.editorController.getText(i, 1);
        }
        i -= 1;
        char = this.editorController.getText(i, 1);
        // NOTE: If interpunction is after word, change index.
        if (INTERPUNCTIONS_REGEX.test(char)) {
            while (i > 0 && INTERPUNCTIONS_REGEX.test(char)) {
                i -= 1;
                char = this.editorController.getText(i, 1);
            }
        }
        const lookup = {
            i: 'y',
            í: 'ý',
            y: 'i',
            ý: 'í',
        };
        // NOTE: If last letter is not present in the lookup table return.
        if (Object.keys(lookup).includes(char.toLowerCase()) === false)
            return;
        let swappedLetter = lookup[char.toLowerCase()];
        const isUpperCase = char === char.toUpperCase();
        swappedLetter = isUpperCase ? swappedLetter.toUpperCase() : swappedLetter;
        this.editorController.deleteText(i, 1);
        this.editorController.insertText(i, swappedLetter);
        // NOTE: Place caret back, where it was before letter was swapped.
        this.editorController.setSelection(selection.index, selection.length);
    }
    removePunctuation() {
        const selection = this.editorController.getSelection();
        if (!selection) {
            return;
        }
        const editorLength = this.editorController.getLength();
        let i = selection.index;
        if (selection.length === 0) {
            let char = this.editorController.getText(i, 1);
            while (char !== ' ' && i < editorLength) {
                i += 1;
                char = this.editorController.getText(i, 1);
            }
            const lastChar = this.editorController.getText(i - 1, 1);
            const nextChar = this.editorController.getText(i + 1, 1);
            if (nextChar !== '\n' && (lastChar === '.' || lastChar === '!' || lastChar === '?')) {
                // make the first letter of next word decapitalized
                const nextLowerChar = nextChar.toLowerCase();
                this.editorController.setSelection(i, 0);
                this.editorController.deleteText(i + 1, 1);
                this.editorController.insertText(i + 1, nextLowerChar);
                // delete the full stop/exclamation || question mark
                this.editorController.deleteText(i - 1, 1);
            }
        }
    }
    ctrlRightJump() {
        const selection = this.editorController.getSelection();
        if (!selection)
            return;
        let i = selection.index;
        let char = this.editorController.getText(i, 1);
        // skip the active whitespace
        while (i < this.editorController.getLength() && !NONSPACE_REGEX.test(char)) {
            // do not skip empty lines
            if (char === '\n') {
                this.editorController.setSelection(i + 1, 0);
                return;
            }
            i += 1;
            char = this.editorController.getText(i, 1);
        }
        // skip the next word
        while (i < this.editorController.getLength() && NONSPACE_REGEX.test(char)) {
            i += 1;
            char = this.editorController.getText(i, 1);
        }
        while (i < this.editorController.getLength()
            && this.editorController.getLineFormat(i).speaker !== undefined) {
            i += 1;
        }
        this.editorController.setSelection(i, 0);
    }
    ctrlLeftJump() {
        const selection = this.editorController.getSelection();
        if (!selection)
            return;
        let i = selection.index;
        let char = this.editorController.getText(i, 1);
        // if at paragraph start, go to previous paragraph end
        if (i > 0 && this.editorController.getText(i - 1, 1) === '\n') {
            i -= 1;
            // skip speaker, if we landed in one
            while (i > 0 && this.editorController.getLineFormat(i).speaker !== undefined) {
                i -= 1;
            }
            this.editorController.setSelection(i, 0);
            return;
        }
        // skip the space that we are in
        while (i > 0 && !NONSPACE_REGEX.test(char)) {
            i -= 1;
            char = this.editorController.getText(i, 1);
        }
        // skip the whole previous word
        while (i > 0 && NONSPACE_REGEX.test(char)) {
            i -= 1;
            char = this.editorController.getText(i, 1);
        }
        // skip the space before the word
        while (i > 0 && !NONSPACE_REGEX.test(char)) {
            i -= 1;
            char = this.editorController.getText(i, 1);
            // stop at paragraph start
            if (this.editorController.getText(i + 1, 1) === '\n') {
                this.editorController.setSelection(i + 2, 0);
                return;
            }
        }
        this.editorController.setSelection(i + 1, 0);
    }
    insertHeadingBefore(headingLevel) {
        const selection = this.editorController.forceGetQuillSelection();
        const text = this.editorController.getText(selection.index, selection.length);
        let block = this.editorController.getBlock(selection.index);
        if (block.prev && block.prev.domNode.tagName === 'H4') {
            block = block.prev;
        }
        const blockStart = this.editorController.getIndex(block);
        if (headingLevel === 1) {
            this.editorController.insertTextWithFormat(blockStart, `${text}\n`, { header: 1 });
        }
        else if (headingLevel === 2) {
            if (this.editorController.sectionNamesList !== null) {
                global.logger.error('cannot insert heading before paragraph when section name modal is active');
                return;
            }
            this.editorController.insertTextWithFormat(blockStart, `${text}\n`, { section: { tags: [], id: null } });
        }
        else {
            throw Error(`unsupported heading level ${headingLevel}`);
        }
    }
}
