import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout } from 'antd';
import { useDashboard } from 'screens/DashboardScreen/dashboard-context';
import UserControls from './UserControls';
import HelpSection from './HelpSection';
import DashMenuButton from '../DashMenuButton';
import Brand from '../Brand';
import styles from './style.module.less';
const DashboardPageHeader = ({ extraControls, children }) => {
    const { menuCollapsed, hasMobileMenu, toggleMenuCollapse } = useDashboard();
    return (_jsxs(Layout.Header, { className: styles.header, children: [hasMobileMenu ? (_jsx("div", { className: styles.menuButton, children: _jsx(DashMenuButton, { onClick: toggleMenuCollapse }) })) : null, _jsx("div", { className: styles.brand, children: menuCollapsed ? _jsx(Brand, {}) : null }), _jsxs("div", { className: styles.controls, children: [extraControls !== null && extraControls !== void 0 ? extraControls : null, _jsx(HelpSection, {}), _jsx(UserControls, {})] }), _jsx("div", { className: styles.toolbar, children: children })] }));
};
export default DashboardPageHeader;
