import Quill from 'quill';
import { getNodeProperty } from 'libs/quill-utils';
import { inlineblotOrder } from './inline-blot-order';
const Inline = Quill.import('blots/inline');
const SEARCH = 'search';
class SearchBlot extends Inline {
    static create(value) {
        const node = super.create(value);
        if (inlineblotOrder.includes(SEARCH)) {
            node.className = `${SEARCH} ${SEARCH}-${value}`;
        }
        else {
            throw new Error('Blot is not listed in inline blot order'); // if the format name is missing in blot order, it causes bugs in quill, see https://github.com/quilljs/quill/issues/2312
        }
        return node;
    }
    static formats(domNode) {
        return SearchBlot.valueFromDomNode(domNode);
    }
    static valueFromDomNode(domNode) {
        const prefix = `${SEARCH}-`;
        return getNodeProperty(domNode, prefix);
    }
    format(name, value) {
        if (name === SEARCH && value) {
            this.domNode.className = `${SEARCH} ${SEARCH}-${value}`;
        }
        else {
            super.format(name, value);
        }
    }
    formats() {
        const formats = super.formats();
        formats.search = SearchBlot.formats(this.domNode);
        return formats;
    }
    attach() {
        if (this.parent != null) {
            this.scroll = this.parent.scroll;
        }
    }
}
SearchBlot.blotName = SEARCH;
export default SearchBlot;
SearchBlot.blotName = SEARCH;
// setting a custom tag name prevents some really weird bugs in quill.
SearchBlot.tagName = SEARCH;
