import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Draggable from 'react-draggable';
import { CloseOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import IconButton from 'components/IconButton';
import styles from './style.module.less';
const DraggableModal = ({ children, title, onClose, defaultPosition, bounds, onStop, className, }) => {
    return (_jsx(Draggable, { handle: ".dragHandle", defaultPosition: defaultPosition, bounds: bounds, onStop: onStop, children: _jsxs("div", { className: clsx(styles.draggableModal, className), onScroll: (event) => event.preventDefault(), children: [_jsxs("div", { className: styles.header, children: [_jsx("div", { className: `${styles.headerTitle} dragHandle`, children: _jsx("h2", { children: title }) }), _jsx(IconButton, { className: styles.closeIcon, onClick: onClose, children: _jsx(CloseOutlined, {}) })] }), children] }) }));
};
export default DraggableModal;
