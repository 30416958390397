import { mediaIsPackaged, mediaIsStreaming } from 'api/model/project';
import ProjectStateHandler from './project-state-handler';
import MediaSource, { loadLocalSource, loadDashSource, loadPackagedSource, } from './media-source';
export default class MediaHandler extends ProjectStateHandler {
    constructor(connection, messageStream) {
        super(messageStream, new MediaSource());
        this.project = null;
        this.handleMediaIdentificationProgress = ({ data }) => {
            if (data.kind === 'DurationlessStream') {
                this.updateStatus(this.status.withoutDuration());
            }
            try {
                const videoIdentified = JSON.parse(data.rawData).streams.some((stream) => stream.codec_type === 'video');
                if (videoIdentified) {
                    this.updateStatus(this.status.withRemoteVideo());
                }
                else {
                    this.updateStatus(this.status.withoutRemoteVideo());
                }
            }
            catch (_a) {
                global.logger.info('could not detect video from media identification message');
            }
        };
        this.handleMediaFileIndexingProgress = (message) => {
            // When we have local or remota media file, we do not care for
            // audio or video availability messages
            if (this.status.isAvailable()) {
                return;
            }
            if (this.project === null) {
                global.logger.error('Project not loaded on file indexing progress. This should never happen.');
                return;
            }
            if (message.data.data === 'AudioAvailable') {
                this.dashAudioReady = true;
                const newStatus = loadDashSource(this.project);
                if (this.dashVideoReady) {
                    this.updateStatus(newStatus.withRemoteVideo());
                }
                else {
                    this.updateStatus(newStatus);
                }
            }
            else if (message.data.data === 'VideoAvailable') {
                this.dashVideoReady = true;
                if (this.dashAudioReady) {
                    this.updateStatus(this.status.withRemoteVideo());
                }
            }
        };
        this.handleMediaFilePackagingCompleted = async () => {
            if (this.status.isPackaged()) {
                return;
            }
            if (this.project === null) {
                global.logger.error('Project not loaded on packaging complete. This should never happen.');
                return;
            }
            this.updateStatus(await loadPackagedSource(this.project, this.connection));
        };
        this.connection = connection;
        this.dashAudioReady = false;
        this.dashVideoReady = false;
        this.tokenReloadInterval = window.setInterval(async () => {
            if (this.project === null)
                return;
            if (this.status.isPackaged()) {
                // NOTE: reloading the packaged source is necessary for users authenticated with
                // keycloak, because their tokens are invalidated.
                // If the token is not nearing the time when it becomes invalid, the address of
                // packaged source is not changed, so updating the status has no effect.
                this.updateStatus(await loadPackagedSource(this.project, this.connection));
            }
        }, 10000);
        this.addStreamListener('MediaIdentification', 'Progress', this.handleMediaIdentificationProgress);
        this.addStreamListener('MediaFileIndexing', 'Progress', this.handleMediaFileIndexingProgress);
        this.addStreamListener('MediaFilePackaging', 'Completed', this.handleMediaFilePackagingCompleted);
    }
    async projectReloaded(project) {
        this.project = project;
        if (this.status.isPackaged()) {
            return;
        }
        if (mediaIsPackaged(project)) {
            this.updateStatus(await loadPackagedSource(project, this.connection));
            return;
        }
        // We do not want to load DASH if DASH is already loaded or we have a local file
        if (this.status.isAvailable()) {
            return;
        }
        if (mediaIsStreaming(project)) {
            this.updateStatus(loadDashSource(this.project));
        }
    }
    useLocalSource(project, file) {
        return this.updateStatus(loadLocalSource(project, file));
    }
    destroy() {
        super.destroy();
        window.clearInterval(this.tokenReloadInterval);
    }
}
