import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Modal, Form, Button, Alert, } from 'antd';
import Loading from 'components/Loading';
import { txt } from 'libs/i18n';
import { getTranslationLocales } from 'libs/locales/translation';
import { getSummaryLang, setSummaryLang } from 'libs/client-env';
import LanguageSelect from 'components/LanguageSelect';
import styles from './style.module.less';
const SummaryModal = ({ visible, status, onClose, onSubmit, errorMessage, }) => {
    const handleSubmit = (values) => {
        setSummaryLang(values.summaryLocale.code);
        onSubmit(values);
    };
    const renderFormContent = () => {
        if (status === 'loading') {
            return (_jsxs("div", { className: styles.inProgress, children: [_jsx(Loading, { size: "sm" }), _jsx("p", { children: txt('generatingSummary') })] }));
        }
        if (status === 'failed') {
            return (_jsx(Alert, { message: (_jsxs(_Fragment, { children: [_jsx("p", { children: txt('summaryError') }), _jsxs("p", { children: [txt('problemPersists'), _jsx("a", { href: txt('contactLink'), rel: "noreferrer", target: "_blank", children: txt('contactUs') }), "."] }), _jsx("span", { children: `${txt('errorDetail')}: ` }), _jsx("span", { className: styles.errorMessage, children: errorMessage })] })), type: "error", showIcon: true }));
        }
        return (_jsxs(_Fragment, { children: [_jsx(Form.Item, { label: txt('summaryLanguage'), name: "summaryLocale", children: _jsx(LanguageSelect, { className: styles.select, locales: getTranslationLocales() }) }), _jsxs(Form.Item, { className: styles.controls, children: [_jsx(Button, { onClick: onClose, children: txt('cancel') }, "back"), _jsx(Button, { htmlType: "submit", type: "primary", children: txt('generate') })] })] }));
    };
    return (_jsx(Modal, { open: visible, onCancel: onClose, className: styles.summaryModal, title: txt('generateSummary'), footer: false, children: _jsx(Form, { onFinish: handleSubmit, initialValues: { summaryLocale: getSummaryLang() }, children: renderFormContent() }) }));
};
export default SummaryModal;
