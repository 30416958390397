import { splitAtFixedPositionVertically, } from 'libs/positions';
export const documentPositions = (bounds, isToolbarHidden) => {
    if ('display' in bounds) {
        return {
            document: { display: 'none' },
            toolbar: { display: 'none' },
        };
    }
    const [toolbar, document] = splitAtFixedPositionVertically(bounds, isToolbarHidden ? 15 : 35);
    return { document, toolbar };
};
