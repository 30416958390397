import camelcaseKeys from 'camelcase-keys';
import * as WebsocketAPI from './websocket-api';
const CHECK_INTERVAL = 15000;
const MAX_REVIVE_ATTEMPTS = 12;
const MAX_PING = 1024; // Just some number so that pings do not grow indefinetly
export const messageTypes = [
    'Started',
    'Progress',
    'Completed',
    'Failed',
    'ChainStatus',
];
export const isMessageType = (value) => (messageTypes.includes(value));
export default class LiveUpdateWebsocket {
    constructor(connection, urlParams) {
        this.lastMessageId = -1;
        this.socket = null;
        this.status = 'connecting';
        this.connectAttemptCount = 0;
        this.checkIntervalId = undefined;
        this.ping = 0;
        this.pong = 0;
        this.onReady = () => { };
        this.onMessage = () => { };
        this.onBroken = () => { };
        this.onReviveAttempt = () => { };
        this.onError = () => { };
        this.onGiveup = () => { };
        this.close = async () => new Promise((resolve, reject) => {
            if (this.socket === null) {
                reject(new Error('Cannot close unconnected socket'));
                return;
            }
            this.socket.onclose = ({ code }) => resolve(code);
            this.socket.close();
            this.cleanup();
        });
        this.connect = async () => {
            // in some unpredictable circumstances liveupdate connected twice
            // and caused bug #1082. Closing the socket before connecting a new
            // one is guaranteed to be fool-proof. We close it only if readyState
            // is not closing or closed.
            if (this.socket !== null && this.socket.readyState < 2) {
                this.socket.close();
            }
            this.connectAttemptCount += 1;
            global.logger.info('liveupdate: connect attempt', { attempt: this.connectAttemptCount });
            this.socket = await WebsocketAPI.openLiveUpdateSocket(this.connection, this.urlParams);
            this.socket.onopen = this.handleSocketOpened;
            this.socket.onerror = this.handleSocketError;
            this.socket.onmessage = this.handleSocketMessage;
            this.socket.onclose = this.handleImmediateClose;
        };
        this.reviveSocket = () => {
            void this.connect();
            this.onReviveAttempt(this.connectAttemptCount);
        };
        this.checkConnection = () => {
            if (this.status === 'reviving') {
                if (this.connectAttemptCount > MAX_REVIVE_ATTEMPTS) {
                    this.onGiveup();
                    void this.close();
                }
                else {
                    this.reviveSocket();
                }
                return;
            }
            if (this.pong !== this.ping) {
                this.status = 'reviving';
                this.onBroken();
                this.reviveSocket();
                return;
            }
            this.sendPing();
        };
        this.cancelCheckInterval = () => {
            window.clearInterval(this.checkIntervalId);
        };
        this.setupChecking = () => {
            this.ping = 0;
            this.pong = 0;
            this.cancelCheckInterval();
            this.sendPing();
            this.checkIntervalId = window.setInterval(this.checkConnection, CHECK_INTERVAL);
        };
        this.sendPing = () => {
            if (this.socket === null) {
                return;
            }
            if (this.socket.readyState !== WebSocket.OPEN) {
                this.status = 'reviving';
                this.onBroken();
                return;
            }
            this.ping = (this.ping + 1) % MAX_PING;
            this.socket.send(String(this.ping));
        };
        this.handleSocketOpened = () => {
            global.logger.info('liveupdate: waiting for handshake');
            this.setupChecking();
        };
        this.handleSocketError = (event) => {
            this.onError(event);
        };
        this.handleSocketMessage = (event) => {
            if (this.status !== 'connected') {
                global.logger.info('liveupdate: connected');
                this.status = 'connected';
                this.connectAttemptCount = 0;
                // NOTE: If a message was received, surely the socket is not closed.
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                this.socket.onclose = null;
                this.onReady();
            }
            const apiMessage = camelcaseKeys(JSON.parse(event.data), { deep: true });
            if (typeof apiMessage === 'number') {
                this.pong = Number(event.data);
                return;
            }
            const message = Object.assign(Object.assign({}, apiMessage), { projectId: String(apiMessage.projectId), source: 'socket' });
            this.lastMessageId = message.id;
            this.onMessage(message);
        };
        this.handleImmediateClose = ({ code, reason }) => {
            global.logger.info('liveupdate: immediate close', { code, reason });
            if (code === 1008) {
                // closed by server
                this.onGiveup();
                this.cleanup();
            }
        };
        this.connection = connection;
        this.urlParams = urlParams;
        void this.connect();
    }
    cleanup() {
        this.socket = null;
        this.cancelCheckInterval();
    }
}
