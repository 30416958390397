import Delta from 'quill-delta';
import * as clientEnv from 'libs/client-env';
import { findFirstNonSpace } from './text-utils';
export const getKeywordsWithTimestamps = (textList) => {
    const keywordsWithTimestampsInSec = [];
    for (let i = 0; i < textList.length; i += 1) {
        for (let j = 0; j < textList[i].length; j += 1) {
            if (textList[i][j].highlight !== null) {
                const { category, text, highlight, timestampMs, } = textList[i][j];
                if (timestampMs !== null) {
                    const keywordsWithTimestamps = {
                        category,
                        text,
                        highlight,
                        timestamp: (timestampMs) / 1000,
                    };
                    keywordsWithTimestampsInSec.push(keywordsWithTimestamps);
                }
            }
        }
    }
    return keywordsWithTimestampsInSec;
};
export const getContrastColor = (hexColor) => {
    if (hexColor === '') {
        // NOTE: Do not change the color at all. CSS styling property will be removed.
        return '';
    }
    if (hexColor === 'default' || hexColor === 'var(--keyword-bgcolor)') {
        // NOTE: Hex color has the value default for keywords without any categories.
        return 'black';
    }
    // NOTE: Based on the background color (function parameter) the text color will be calculated.
    // The text color will be set to black or white. The breaking point is calculated based on the
    // formula used in wordspotting library:
    //  https://github.com/spectreconsole/spectre.console/blob/e66d3aab2ea41afe2e31f7ae1fb5fe1e77821455/examples/Shared/Extensions/ColorExtensions.cs#L11
    const red = parseInt(hexColor.substring(1, 2), 16);
    const green = parseInt(hexColor.substring(3, 2), 16);
    const blue = parseInt(hexColor.substring(5, 2), 16);
    const luminance = red * 0.2126 + green * 0.7152 + blue * 0.0722;
    return luminance < 140 ? 'white' : 'black';
};
export default class Keywords {
    constructor(editorController) {
        this.highlightedKeyword = null;
        this.highlightAllKeywords = (keywordsHighlight) => {
            if (keywordsHighlight === null) {
                return;
            }
            if (clientEnv.getShowKeywords() === false) {
                return;
            }
            const { textMetadata } = this.editorController;
            const keywordsWithTimestampsInSec = getKeywordsWithTimestamps(keywordsHighlight.textList);
            let delta = new Delta();
            for (let i = 0; i < keywordsWithTimestampsInSec.length; i += 1) {
                const keywordText = keywordsWithTimestampsInSec[i].text.toLowerCase().trim();
                const highlightColor = keywordsWithTimestampsInSec[i].highlight;
                const { from, to } = textMetadata.getTimestampsAtTime(keywordsWithTimestampsInSec[i].timestamp);
                // NOTE: Keywords can be a phrase. In some cases, the phrase
                // can include spaces before the first character.
                const firstPhrasePart = this.editorController.getText(from, to - from);
                let wordStart = from;
                // NOTE: At the given timestamp can be a speaker. Therefore, we have to check it.
                // In such a case, the keyword is on the next new line.
                if (this.editorController.getLineFormat(from + 1).speaker !== undefined
                    && this.editorController.getNextNewLineIndex(from + 1) !== null) {
                    wordStart = this.editorController.getNextNewLineIndex(from + 1) + 1;
                }
                else {
                    wordStart = from + findFirstNonSpace(firstPhrasePart);
                }
                const textInEditor = this.editorController.getText(wordStart, keywordText.length).toLowerCase();
                if (textInEditor.includes(keywordText) && highlightColor !== null) {
                    delta = delta.compose(this.buildKeywordDelta(wordStart, keywordsWithTimestampsInSec[i].text.length, `result ${highlightColor} ${getContrastColor(highlightColor)}`));
                }
            }
            this.editorController.execTextChange({ runAligner: false, requestSave: false }, () => {
                this.editorController.updateContents(delta, 'user');
            });
        };
        this.highlightActiveKeyword = (nextKeyword) => {
            const { from: nextFrom } = this.editorController.textMetadata
                .getTimestampsAtTime(nextKeyword.timestamp);
            this.editorController.execTextChange({ runAligner: false, requestSave: false }, () => {
                if (this.highlightedKeyword !== null) {
                    const { from: previousFrom } = this.editorController.textMetadata
                        .getTimestampsAtTime(this.highlightedKeyword.timestamp);
                    this.editorController.formatText(this.getWordStartAfterSpeaker(previousFrom), this.highlightedKeyword.text.length, { keyword: `result ${this.highlightedKeyword.highlight} ${getContrastColor(this.highlightedKeyword.highlight)}` }, 'api');
                }
                this.editorController.formatText(this.getWordStartAfterSpeaker(nextFrom), nextKeyword.text.length, { keyword: `highlighted ${nextKeyword.highlight} ${getContrastColor(nextKeyword.highlight)}` }, 'api');
            });
            this.editorController.playback.seekTo(nextKeyword.timestamp);
            this.highlightedKeyword = nextKeyword;
        };
        this.toneDownKeyword = (timeStamp, keyword) => {
            const { from } = this.editorController.textMetadata.getTimestampsAtTime(timeStamp);
            this.editorController.execTextChange({ runAligner: false, requestSave: false }, () => {
                if (keyword.highlight === null)
                    return;
                this.editorController.formatText(this.getWordStartAfterSpeaker(from), keyword.text.length, { keyword: `result ${keyword.highlight} ${getContrastColor(keyword.highlight)}` }, 'api');
            });
        };
        this.getWordStartAfterSpeaker = (from) => {
            // NOTE: At the given timestamp a speaker can be placed.
            if (this.editorController.getLineFormat(from + 1).speaker !== undefined
                && this.editorController.getNextNewLineIndex(from + 1) !== null) {
                return this.editorController.getNextNewLineIndex(from + 1) + 1;
            }
            return from;
        };
        this.buildKeywordDelta = (position, length, highlightingInfo) => {
            return new Delta().retain(position).retain(length, { keyword: highlightingInfo });
        };
        this.editorController = editorController;
    }
}
