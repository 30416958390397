import { jsx as _jsx } from "react/jsx-runtime";
import { getNodeProperty } from 'libs/quill-utils';
import { formatTime } from 'libs/format-number';
import { txt } from 'libs/i18n';
import { getRectFromElement } from 'libs/positions';
import styles from './style.module.less';
const getWarningTooltip = (trigger) => {
    const warningPrefix = 'warning-details-';
    const classes = trigger.className.split(' ');
    let title = 'warning';
    const details = getNodeProperty(trigger, warningPrefix);
    if (classes.includes('warning-faster-than-optimum')) {
        title = 'subtitleFasterThanOptimum';
    }
    if (classes.includes('warning-too-fast-critical')) {
        title = 'subtitleFastCritical';
    }
    if (classes.includes('warning-duration-short')) {
        title = 'subtitleTooFast';
    }
    if (classes.includes('warning-duration-long')) {
        title = 'subtitleTooSlow';
    }
    if (classes.includes('warning-too-long')) {
        title = 'subtitleLong';
    }
    if (classes.includes('warning-section-name-missing')) {
        title = 'sectionNameChanged';
    }
    if (title === 'warning') {
        global.logger.error('cannot generate warning mesage');
    }
    return `${txt(title)} ${details !== null && details !== void 0 ? details : ''}`;
};
const getTimestampTooltip = (trigger) => {
    // anchor value must be saved as a class. If it were saved another
    // way, quill may not preserve it if the node is updated. Quill always preserves classes.
    // The class name is for example timeAnchor-83.541 for timestamp 00:01:23.541
    const prefix = 'timeAnchor-';
    let time = '';
    for (let i = 0; i < trigger.classList.length; i += 1) {
        const className = trigger.classList[i];
        if (className.startsWith(prefix)) {
            const seconds = Number(className.substring(prefix.length));
            time = formatTime(seconds, 'HH:mm:ss.SSS');
        }
    }
    return time;
};
const DocumentTooltip = ({ type, triggerElement, }) => {
    const triggerPosition = getRectFromElement(triggerElement);
    // NOTE: This prevents tooltip from reaching to the left out of screen.
    const width = Math.min(200, Math.max(50, triggerPosition.left + triggerPosition.width - 20));
    const createTooltipMesage = () => {
        if (type === 'timeAnchor') {
            return getTimestampTooltip(triggerElement);
        }
        if (type === 'warning') {
            return getWarningTooltip(triggerElement);
        }
        return txt('unaligned');
    };
    return (_jsx("div", { className: styles.tooltip, style: {
            top: triggerPosition.top,
            left: triggerPosition.left + triggerPosition.width,
            width,
        }, children: createTooltipMesage() }));
};
export default DocumentTooltip;
