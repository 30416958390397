const parseEntry = (previousFormat, entry) => {
    try {
        const [name, value] = entry.split(':');
        if (name === 'align') {
            if (!['left', 'center', 'right'].includes(value)) {
                throw new Error(`invalid value for align: ${value}`);
            }
            return Object.assign(Object.assign({}, previousFormat), { align: value });
        }
        if (name === 'line') {
            if (!value.endsWith('%')) {
                throw new Error('line value without percent is not supported');
            }
            const line = Number(value.substring(0, value.length - 1));
            return Object.assign(Object.assign({}, previousFormat), { line });
        }
        throw new Error(`unsupported format name: ${name}`);
    }
    catch (error) {
        logger.error(`invalid entry: ${entry}`, {}, error);
        return previousFormat;
    }
};
export const parseVttFormat = (vttFormat) => {
    const entries = vttFormat.split(' ');
    return entries.reduce(parseEntry, {
        align: null,
        line: null,
    });
};
export const serializeToVttFormat = (format) => {
    const entries = [];
    if (format.align !== null) {
        entries.push(`align:${format.align}`);
    }
    if (format.line !== null) {
        entries.push(`line:${format.line}%`);
    }
    return entries.join(' ');
};
