import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import beeyLogo from 'resources/img/product-logo.svg';
import { useSession } from 'components/Authenticated';
import styles from './style.module.less';
const Brand = () => {
    const { session: { login } } = useSession();
    const chooseLogo = () => {
        if (login.hasClaim('dataTheme:apa')) {
            return (_jsxs("div", { className: styles.apaLogo, children: [_jsx("span", { className: styles.apaLogoBold, children: "SPEECH-TO-TEXT" }), _jsxs("div", { className: styles.apaLogoDetail, children: [_jsx("span", { className: styles.apaLogoBold, children: "PR-DESK" }), _jsx("span", { className: styles.apaLogoText, children: "by APA-Comm" })] })] }));
        }
        return _jsx("img", { className: styles.brand, alt: "Beey logo", src: beeyLogo });
    };
    return (_jsx("a", { href: "/projects", children: chooseLogo() }));
};
export default Brand;
