import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input, Button, Form, Tooltip, Checkbox, } from 'antd';
import { QuestionCircleOutlined, } from '@ant-design/icons';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
const DropdownForm = ({ onSearch, onResetFields, onIncludesAllChange, onPopoverVisible, }) => {
    const inputNames = ['projectname', 'transcription', 'speakers', 'notes'];
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onPopoverVisible(false);
            onSearch();
        }
    };
    const handleSearchButton = () => {
        onPopoverVisible(false);
        onSearch();
    };
    const handleResetButton = () => {
        onPopoverVisible(false);
        onResetFields();
    };
    const tooltipContent = (text1, text2) => (_jsxs("div", { className: styles.dropdownFormTooltip, children: [_jsx("p", { children: txt(text1) }), _jsx("p", { children: txt(text2) })] }));
    return (_jsxs("div", { className: styles.dropdownForm, children: [_jsx(Form.Item, { label: txt('inAnyField'), name: "inAnyField", className: styles.formInput, children: _jsx(Input, { size: "small", onChange: (e) => onIncludesAllChange(e.target.value), onKeyDown: handleKeyDown, allowClear: true, bordered: false }) }), _jsxs("div", { className: styles.advancedSearchHeader, children: [txt('advancedSearch'), _jsx(Tooltip, { title: tooltipContent('fulltextSearchInfo1', 'fulltextSearchInfo2'), trigger: "click", overlayInnerStyle: { textAlign: 'left' }, children: _jsx(QuestionCircleOutlined, { className: styles.infoIcon }) })] }), _jsx("div", { className: styles.advancedSearch, children: inputNames.map((name) => (_jsx(Form.Item, { className: styles.formInput, label: txt(name), name: name, children: _jsx(Input, { size: "small", allowClear: true, bordered: false, onChange: (e) => {
                            if (e.target.value === '') {
                                onSearch();
                            }
                        }, onKeyDown: handleKeyDown }) }, name))) }), _jsxs("div", { className: styles.checkbox, children: [_jsx(Form.Item, { valuePropName: "checked", name: "strict", children: _jsx(Checkbox, { children: txt('strictSearch') }) }), ' ', _jsx("span", { children: _jsx(Tooltip, { title: tooltipContent('strictSearchInfo1', 'strictSearchInfo2'), trigger: "click", overlayInnerStyle: { textAlign: 'left' }, children: _jsx(QuestionCircleOutlined, { className: styles.infoIcon }) }) })] }), _jsxs("div", { className: styles.controls, children: [_jsx(Button, { onClick: handleResetButton, children: txt('resetSearch') }), _jsx(Button, { type: "primary", onClick: handleSearchButton, children: txt('searchFulltext') })] })] }));
};
export default DropdownForm;
