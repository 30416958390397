import * as ProjectAPI from 'api/project-api';
import { mediaHasVideo } from 'api/model/project';
class MediaSource {
    constructor(url = null, type = 'none', remoteHasVideo = false, hasDuration = true) {
        this.url = url;
        this.type = type;
        this.remoteHasVideo = remoteHasVideo;
        this.hasDuration = hasDuration;
    }
    isAvailable() {
        return this.type !== 'none';
    }
    isPackaged() {
        return this.type === 'remote-packaged';
    }
    isDash() {
        return this.type === 'remote-dash';
    }
    withRemoteVideo() {
        return new MediaSource(this.url, this.type, true, this.hasDuration);
    }
    withoutRemoteVideo() {
        return new MediaSource(this.url, this.type, false, this.hasDuration);
    }
    withoutDuration() {
        return new MediaSource(this.url, this.type, this.remoteHasVideo, false);
    }
}
MediaSource.NONE = new MediaSource();
export default MediaSource;
export const canPlayMedia = (file) => {
    const videoElement = document.createElement('video');
    return videoElement.canPlayType(file.type) !== '';
};
export const loadLocalSource = (project, file) => (canPlayMedia(file)
    ? new MediaSource(URL.createObjectURL(file), 'local', file.type.startsWith('video'))
    : MediaSource.NONE);
export const loadDashSource = (project) => new MediaSource(ProjectAPI.buildMediaManifestUrl(project), 'remote-dash', mediaHasVideo(project.mediaInfo));
export const loadPackagedSource = async (project, connection) => new MediaSource(await ProjectAPI.buildMediaFileUrl(project, connection), 'remote-packaged', mediaHasVideo(project.mediaInfo));
