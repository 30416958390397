import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { DeleteOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import IconButton from 'components/IconButton';
import { Progress, Spin } from 'antd';
import { downloadBlobAsFile } from 'screens/DashboardScreen/pages/EditorPage/ProjectMenuPanel/exports';
import { downloadModifiedVideo, fetchJobsOnProject, removeJob } from 'api/model/services/services-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import styles from './style.module.less';
import JobState from './JobsState';
const TranscodeJobsInfo = ({ workerType, project, visible, form, initialValues, isLoading, }) => {
    const { session } = useSession();
    const { connection } = session;
    const [jobs, setJobs] = useState({
        list: 'loading',
        downloadingIds: {},
    });
    const { id, name } = project;
    const getJobsOnProject = async () => {
        const jobsList = await fetchJobsOnProject(connection, id);
        const jobsIds = {};
        jobsList.forEach((job) => {
            jobsIds[job.id] = false;
        });
        const jobsListWithName = jobsList.map((job) => (Object.assign(Object.assign({}, job), { name: `${name}.mp4`, id: job.id.toString() })));
        // previous state callback is used because of the interval of fetching jobs in useEffect
        setJobs((prevJobs) => {
            const prevDownloadingIds = Object.assign({}, prevJobs.downloadingIds);
            Object.keys(prevJobs.downloadingIds).forEach((key) => {
                if (!prevDownloadingIds[key]) {
                    delete prevDownloadingIds[key];
                }
            });
            return ({
                list: jobsListWithName,
                downloadingIds: (Object.assign(Object.assign({}, jobsIds), prevDownloadingIds)),
            });
        });
    };
    useEffect(() => {
        if (isLoading) {
            setJobs(Object.assign(Object.assign({}, jobs), { list: 'loading' }));
        }
        else {
            void getJobsOnProject();
        }
    }, [isLoading]);
    useEffect(() => {
        if (visible) {
            void getJobsOnProject();
            form.setFieldsValue(initialValues);
            const interval = setInterval(() => {
                void getJobsOnProject();
            }, 5000);
            return () => clearInterval(interval);
        }
        return () => { };
    }, [visible]);
    const updateJobDownloadState = (isJobDownloading, jobId) => {
        setJobs(Object.assign(Object.assign({}, jobs), { downloadingIds: Object.assign(Object.assign({}, jobs.downloadingIds), { [jobId]: isJobDownloading }) }));
    };
    const downloadVideo = async (jobId, jobName) => {
        updateJobDownloadState(true, jobId);
        const blob = await downloadModifiedVideo(connection, jobId);
        updateJobDownloadState(false, jobId);
        downloadBlobAsFile(jobName, blob);
    };
    const filterJobs = () => {
        if (jobs.list !== 'loading') {
            // NOTE: This is necessary for backward compatibility and can be removed in the future
            if (workerType === 'cut-length') {
                return jobs.list.filter((job) => job.workerType === 'cut-length' || job.workerType === 'cut-video');
            }
            return jobs.list.filter((job) => job.workerType === 'burn-subs');
        }
        return [];
    };
    // NOTE: This is necessary for backward compatibility and can be removed in the future
    const worker = workerType === 'cut-length' ? ['cut-video', 'cut-length'] : ['burn-subs'];
    const cutAndBurnJobsList = filterJobs();
    if (jobs.list === 'loading' || jobs.list.length > 0) {
        return (_jsxs(_Fragment, { children: [cutAndBurnJobsList.length === 0 ? (_jsx("p", { className: styles.empty, children: txt('noFilesToDownload') })) : null, jobs.list === 'loading' ? _jsx(Spin, { className: styles.spin }) : (jobs.list.map((job) => {
                    if (worker.includes(job.workerType)) {
                        return (_jsxs("div", { className: styles.jobInfo, children: [_jsx("span", { className: styles.jobName, children: job.workerName }), job.percentage < 1 || (job.percentage > 0 && job.state === 'FAILED')
                                    ? _jsx(JobState, { state: job.state })
                                    : _jsx(Progress, { type: "circle", percent: job.percentage, className: styles.jobsProgress }), jobs.downloadingIds[job.id] ? (_jsx("span", { className: styles.icon, children: _jsx(LoadingOutlined, { className: styles.iconLoading }) })) : (_jsx("span", { className: styles.icon, children: _jsx(IconButton, { onClick: () => downloadVideo(job.id, `${job.workerName}.mp4`), disabled: job.state !== 'SUCCESS' || Object.values(jobs.downloadingIds).includes(true), children: _jsx(DownloadOutlined, {}) }) })), _jsx(IconButton, { onClick: () => removeJob(connection, job.id), children: _jsx(DeleteOutlined, {}) })] }, job.id));
                    }
                    return null;
                }))] }));
    }
    return _jsx("p", { className: styles.empty, children: txt('noFilesToDownload') });
};
export default TranscodeJobsInfo;
