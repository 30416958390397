import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Alert, Card, Button, Popconfirm, Tooltip, Form, Input, Upload, message, } from 'antd';
import IconButton from 'components/IconButton';
import { EditOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import DashboardPage from 'screens/DashboardScreen/components/DashboardPage';
import ResponsiveTable, { useTableView, empiricalScrollHeightAdjust, } from 'components/ResponsiveTable';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import AddEditModal from 'components/AddEditModal';
import { AbbreviationIcon } from 'components/CustomIcons';
import { addAbbreviation, deleteAbbreviation, editAbbreviation, getAllAbbreviations, importCsv, } from 'api/expandable-abbreviations-api';
import { usePapaParse } from 'react-papaparse';
import AddButton from 'components/AddButton';
import styles from './styles.module.less';
const TAGS_TABLE_ID = 'abbreviations-table'; // Must be unique across all tables in Beey!!
const SCROLL_HEIGHT_ADJUST = empiricalScrollHeightAdjust(230);
const emptyEntry = () => ({ abbreviation: '', text: '', id: 0 });
const AbbreviationsPage = () => {
    const { session } = useSession();
    const [abbreviations, setAbbreviations] = useState('loading');
    const [abbreviationModal, setAbbreviationModal] = useState({ isVisible: false, action: 'add', initialValues: emptyEntry() });
    const { readString } = usePapaParse();
    const [messageApi, contextHolder] = message.useMessage();
    const tableView = useTableView(TAGS_TABLE_ID);
    const fetchAbbreviations = async () => {
        try {
            const fetchedAbbreviations = await getAllAbbreviations(session.connection);
            setAbbreviations(fetchedAbbreviations);
        }
        catch (error) {
            global.logger.error('Failed to get list of abbreviations from database', {}, error);
            void messageApi.error(txt('failedToFetchAbbreviations'));
        }
    };
    const handleAddAbbreviation = async (record) => {
        try {
            await addAbbreviation(session.connection, record);
            void messageApi.success(txt('abbreviationSaved'), 2);
            void fetchAbbreviations();
        }
        catch (error) {
            global.logger.error('Failed to save abbreviation to the database', {}, error);
            void messageApi.error(txt('failedToSaveAbbreviation'));
        }
    };
    const handleEditAbbreviation = async (record) => {
        try {
            await editAbbreviation(session.connection, record);
            void messageApi.success(txt('abbreviationUpdated'), 2);
            void fetchAbbreviations();
        }
        catch (error) {
            global.logger.error('Failed to update abbreviation in the database', {}, error);
            void messageApi.error(txt('failedToUpdateAbbreviation'));
        }
    };
    const handleDeleteAbbreviation = async (record) => {
        try {
            await deleteAbbreviation(session.connection, record.id);
            void messageApi.success(txt('abbreviationDeleted'), 2);
            void fetchAbbreviations();
        }
        catch (error) {
            global.logger.error('Failed to delete abbreviation from database', {}, error);
            void messageApi.error(txt('failedToDeleteAbbreviation'));
        }
    };
    const handleOpenEditModal = (record) => {
        setAbbreviationModal({ action: 'edit', isVisible: true, initialValues: record });
    };
    const handleOpenAddModal = () => {
        setAbbreviationModal({ action: 'add', isVisible: true, initialValues: emptyEntry() });
    };
    const handleBeforeUploadCsv = (file) => {
        // NOTE: we check if the file is csv by extension and not by file type, because
        // the type is inconsistent across browsers.
        const isCsv = file.name.endsWith('.csv');
        if (!isCsv) {
            void messageApi.error(`${file.name} is not a .csv file`);
        }
        return isCsv || Upload.LIST_IGNORE;
    };
    const handleImportCsv = async ({ file }) => {
        const importedAbbreviationList = file.originFileObj;
        if (importedAbbreviationList === undefined) {
            return;
        }
        readString(await importedAbbreviationList.text(), {
            worker: true,
            delimiter: ';',
            complete: async (abbreviationsFromCsv) => {
                if (abbreviations === 'loading') {
                    throw Error('cannot import abbreviations while abbreviations are loading');
                }
                const importedAbbreviations = abbreviationsFromCsv.data
                    // NOTE: The csv may contain empty row at the end.
                    .filter((abbreviation) => abbreviation.length === 2)
                    .filter((abbreviation) => !abbreviations.some((item) => item.abbreviation.trim() === abbreviation[0].trim()))
                    .map((abbreviation) => {
                    return {
                        abbreviation: abbreviation[0],
                        text: abbreviation[1],
                    };
                });
                try {
                    await importCsv(session.connection, importedAbbreviations);
                    void messageApi.success(txt('abbreviationImported'), 2);
                    void fetchAbbreviations();
                }
                catch (error) {
                    global.logger.error('Failed to import abbreviations from csv', {}, error);
                    void messageApi.error(txt('abbreviationImportFail'));
                }
            },
        });
    };
    const modalTitle = (_jsxs(_Fragment, { children: [_jsx(AbbreviationIcon, { className: styles.abbreviationIcon }), abbreviationModal.action === 'add' ? txt('addAbbreviation') : txt('editAbbreviation')] }));
    const columns = [
        {
            title: txt('textAbbreviation'),
            key: 'id',
            dataIndex: 'abbreviation',
            className: 'name-cell',
        },
        {
            title: txt('replacedWith'),
            key: 'id',
            dataIndex: 'text',
            className: 'name-cell',
        },
        {
            title: '',
            key: 'edit',
            width: '40px',
            render: (record) => {
                return (_jsx(IconButton, { onClick: () => handleOpenEditModal(record), children: _jsx(EditOutlined, {}) }));
            },
        },
        {
            title: '',
            key: 'delete',
            width: '40px',
            render: (record) => {
                return (_jsx(Popconfirm, { title: txt('deletePermanently'), okText: txt('delete'), okType: "primary", okButtonProps: { danger: true }, onConfirm: () => handleDeleteAbbreviation(record), cancelText: txt('cancel'), children: _jsx(Tooltip, { title: txt('delete'), children: _jsx(IconButton, { danger: true, children: _jsx(DeleteOutlined, {}) }) }) }));
            },
        },
    ];
    return (_jsx(DashboardPage, { title: txt('abbreviations'), children: ({ contentHeight }) => (_jsx("section", { className: "abbreviations-page", children: _jsxs(Card, { bordered: false, children: [_jsxs("div", { className: styles.header, children: [_jsx(AddButton, { title: txt('addAbbreviation'), onClick: () => handleOpenAddModal() }), _jsx(Alert, { showIcon: true, message: txt('abbreviationDescription'), type: "info", className: styles.description }), _jsx(Upload, { accept: "text/csv", beforeUpload: handleBeforeUploadCsv, onChange: handleImportCsv, showUploadList: false, customRequest: () => undefined, children: _jsx("div", { className: styles.importButtonWithTooltip, children: _jsx(Tooltip, { title: txt('importFromCsvDetail'), placement: "left", children: _jsxs(Button, { className: styles.importButton, size: "small", children: [_jsx(UploadOutlined, {}), _jsx("span", { className: styles.importButtonText, children: txt('importFromCsv') })] }) }) }) })] }), _jsx(ResponsiveTable, { className: "abbreviations-table", bordered: false, tableView: tableView, onViewChange: fetchAbbreviations, dataSource: abbreviations !== 'loading' ? abbreviations : [], recordCount: abbreviations !== 'loading'
                            ? abbreviations.length : 0, columns: columns, loading: abbreviations === 'loading', rowKey: (abbreviation) => abbreviation.id, pageSizeOptions: ['10', '20', '50', '100'], scroll: { y: contentHeight - SCROLL_HEIGHT_ADJUST } }), _jsxs(AddEditModal, { action: abbreviationModal.action, visible: abbreviationModal.isVisible, title: modalTitle, initialValues: abbreviationModal.initialValues, onCancel: () => setAbbreviationModal(Object.assign(Object.assign({}, abbreviationModal), { isVisible: false })), onAdd: async (values) => handleAddAbbreviation(values), onEdit: async (values) => handleEditAbbreviation(values), children: [_jsx(Form.Item, { label: txt('textAbbreviation'), name: "abbreviation", rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: txt('noAbbreviation'),
                                    },
                                    {
                                        validator: async (_, value) => {
                                            const abbreviationList = abbreviations === 'loading' ? [] : abbreviations;
                                            const words = value.trim().split(/\s+/);
                                            if (words.length >= 2) {
                                                return Promise.reject(txt('onlyOneWordAbbreviation'));
                                            }
                                            const duplicitAbbreviation = abbreviationList.find((item) => item.abbreviation.trim() === value.trim()
                                                && item.id !== abbreviationModal.initialValues.id);
                                            if (duplicitAbbreviation !== undefined) {
                                                return Promise.reject(txt('duplicitAbbreviation'));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ], children: _jsx(Input, { autoFocus: true }) }), _jsx(Form.Item, { label: txt('replacedWith'), name: "text", rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: txt('noReplacement'),
                                        min: 1,
                                    },
                                ], children: _jsx(Input, {}) }), _jsx(Form.Item, { name: "id", className: styles.hiddenInput, children: _jsx(Input, { type: "hidden" }) })] }), contextHolder] }) })) }));
};
export default AbbreviationsPage;
