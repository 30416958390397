import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Redirect } from 'react-router-dom';
import BrowserInfo from '../IntroScreen/BrowserInfo';
import styles from './style.module.less';
const LeadPanel = ({ children = null, redirectURL = null, isLogoDisplayed = true, state, }) => {
    if (redirectURL !== null) {
        return _jsx(Redirect, { push: true, to: { pathname: redirectURL, state } });
    }
    return (_jsxs("div", { className: styles.leadPanel, children: [_jsx("div", { className: styles.leadPanelHead, children: isLogoDisplayed ? (_jsx("div", { className: styles.productLogo })) : null }), _jsx(BrowserInfo, {}), _jsx("div", { className: styles.leadPanelBody, children: children })] }));
};
export default LeadPanel;
