import { json } from '@newtontechnologies/beey-api-js-client/receivers';
import { apiV2 } from '@newtontechnologies/beey-api-js-client/endpoints';
export const fetchManagementGroups = async (connection) => connection.authFetch()
    .url(apiV2.admin.managementGroups.url())
    .receive(json())
    .fetch();
export const fetchManagementGroup = async (connection, groupId) => connection.authFetch()
    .url(apiV2.admin.managementGroups.groupId(groupId).url())
    .receive(json())
    .fetch();
export const addManagementGroup = async (connection, GroupName, Notes, ExternalId) => connection.authFetch()
    .url(apiV2.admin.managementGroups.url())
    .postJson({
    GroupName,
    Notes,
    ExternalId,
})
    .receive(json())
    .fetch();
export const updateManagementGroup = async (connection, Id, GroupName, Notes, ExternalId) => connection.authFetch()
    .url(apiV2.admin.managementGroups.groupId(Id).url())
    .putJson({
    GroupName,
    Notes,
    ExternalId,
})
    .receive(json()
    .catchHttpError(() => 'unexpected'))
    .fetchStrict();
export const addManagementGroupCredit = async (connection, groupId, credit) => connection.authFetch()
    .url(apiV2.admin.managementGroups.groupId(groupId).credit.add.url())
    .putJson({ credit })
    .send();
export const removeManagementGroupCredit = async (connection, groupId, credit) => connection.authFetch()
    .url(apiV2.admin.managementGroups.groupId(groupId).credit.subtract.url())
    .putJson({ credit })
    .send();
export const fetchManagementGroupCredit = async (connection, groupId) => connection.authFetch()
    .url(apiV2.admin.managementGroups.groupId(groupId).credit.url())
    .receive(json())
    .fetch();
