import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchCurrentUserTranscriptionReport } from 'api/user-api';
import { useSession } from 'components/Authenticated';
import ResponsiveTable, { useTableView } from 'components/ResponsiveTable';
import { txt } from 'libs/i18n';
import { FundOutlined } from '@ant-design/icons';
import IconButton from 'components/IconButton';
import ProjectsOverview from '../ProjectsOverview/index';
import styles from './style.module.less';
const USER_TRANSCRIBED_TABLE_ID = 'user-trans-table'; // Must be unique across all tables in Beey!!
export const formatDuration = (floatMinutes) => {
    const hours = String(Math.floor(floatMinutes / 60));
    const minutesLeft = floatMinutes % 60;
    const minutes = String(Math.floor(minutesLeft));
    const seconds = String(Math.floor((minutesLeft % 1) * 60));
    return (`${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`);
};
const TranscribedOverview = () => {
    const { session } = useSession();
    const [modalVisible, setModalVisible] = useState(false);
    const [month, setMonth] = useState('');
    const tableView = useTableView(USER_TRANSCRIBED_TABLE_ID, { pageSize: 10 });
    const { currentPage, pageSize } = tableView.getViewParams();
    const { status: transcriptionReportStatus, data: transcriptionReport } = useQuery(['transcriptionReport', pageSize * (currentPage - 1), pageSize], ({ queryKey }) => fetchCurrentUserTranscriptionReport(session.connection, {
        skip: Number(queryKey[1]),
        count: Number(queryKey[2]),
    }));
    const minutesPerMonth = transcriptionReportStatus === 'success' ? transcriptionReport.list
        .map((item, index) => ({
        key: index,
        month: `${item.month}/${item.year}`,
        minutes: formatDuration(item.minutes),
    })) : [];
    const handleOpenMonthTranscripions = (monthNumber) => {
        setModalVisible(true);
        setMonth(monthNumber);
    };
    const columns = [
        {
            title: txt('month'),
            dataIndex: 'month',
            align: 'center',
            render: (text) => (_jsx(IconButton, { onClick: () => handleOpenMonthTranscripions(text), children: text })),
        },
        {
            title: txt('chargedMinutes'),
            dataIndex: 'minutes',
            align: 'center',
        },
    ];
    const getTotalDuration = () => {
        if (transcriptionReportStatus === 'success') {
            const hours = Math.floor(transcriptionReport.totalAggregated / 60);
            const minutes = Math.floor(transcriptionReport.totalAggregated % 60);
            return `${hours} h ${minutes} min`;
        }
        return '';
    };
    return (_jsxs("div", { className: styles.transcribedOverview, children: [_jsxs("h3", { className: styles.monthlyOverview, children: [_jsx(FundOutlined, { style: { marginRight: '5px', fontSize: '20px' } }), txt('monthlyOverviewOfTeam')] }), _jsx(ResponsiveTable, { className: styles.table, tableView: tableView, bordered: true, columns: columns, dataSource: transcriptionReportStatus === 'success' ? minutesPerMonth : [], rowKey: (min) => min.key, recordCount: transcriptionReportStatus === 'success' ? transcriptionReport.totalCount : 0, loading: transcriptionReportStatus === 'loading', 
                // Fix with next refactoring
                // eslint-disable-next-line react/no-unstable-nested-components
                footer: () => (_jsx("div", { className: styles.transcribedTotal, children: _jsx("p", { children: `${txt('myTranscriptionTotal')} ${getTotalDuration()}` }) })), pageSizeOptions: ['10', '20', '50', '100'], paginationDisplaySize: "small" }), _jsx(ProjectsOverview, { modalVisible: modalVisible, month: month, onModalClosed: () => setModalVisible(false) })] }));
};
export default TranscribedOverview;
