import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { txt } from 'libs/i18n';
import { useSession } from 'components/Authenticated';
import { mediaHasVideo } from 'api/model/project';
import { createCutMediaJob, startJob, } from 'api/model/services/services-api';
import { buildMediaFileUrl } from 'api/project-api';
import TimerangeSelectionForm, { formatTimeToISOString, getTimeInSeconds } from 'components/TimerangeSelectionForm';
import { Form, Modal, Divider, message, } from 'antd';
import TranscodeJobsInfo from 'components/TranscodeJobsInfo';
import { downloadUrl } from '../exports';
import styles from './style.module.less';
const CutMediaModal = ({ visible, onCancel, project, selectedTextTimestamps, }) => {
    const { session } = useSession();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const { id, name, mediaInfo } = project;
    const durationInSeconds = getTimeInSeconds(project.length);
    const selectedRangeEnd = selectedTextTimestamps === null
        || selectedTextTimestamps.end === Infinity ? durationInSeconds : selectedTextTimestamps.end;
    const { connection } = session;
    const initialValues = {
        begin: selectedTextTimestamps === null
            ? '00:00:00.000'
            : formatTimeToISOString(selectedTextTimestamps.begin),
        end: selectedTextTimestamps === null
            // some projects return duration without milliseconds
            ? formatTimeToISOString(durationInSeconds)
            : formatTimeToISOString(selectedRangeEnd),
    };
    const mediaBegin = Form.useWatch('begin', form);
    const mediaEnd = Form.useWatch('end', form);
    const isUncutMedia = mediaBegin === '00:00:00.000' && mediaEnd === formatTimeToISOString(durationInSeconds);
    const handleFullLengthSelected = () => {
        form.setFieldsValue({
            begin: '00:00:00.000',
            end: formatTimeToISOString(durationInSeconds),
        });
    };
    const downloadUncutMedia = async () => {
        const url = await buildMediaFileUrl(project, session.connection);
        void message.success(txt('downloadStarted'));
        downloadUrl(url, `${project.name}.mp4`);
    };
    const startCuttingJob = async () => {
        setIsLoading(true);
        const selectedBegin = form.getFieldValue('begin');
        const selectedEnd = form.getFieldValue('end');
        const jobId = await createCutMediaJob(connection, selectedBegin, selectedEnd, id, `${name}_${selectedBegin}_${selectedEnd}`);
        setIsLoading(false);
        void startJob(connection, jobId);
    };
    const buttonText = () => {
        const mediaType = mediaHasVideo(mediaInfo) ? 'Video' : 'Audio';
        const action = isUncutMedia ? 'download' : 'cut';
        return txt(`${action}${mediaType}`);
    };
    return (_jsxs(Modal, { open: visible, onCancel: onCancel, destroyOnClose: true, footer: false, children: [_jsx("h3", { children: txt('dlMedia') }), _jsx("div", { children: _jsxs(Form, { form: form, onFinish: isUncutMedia ? downloadUncutMedia : startCuttingJob, children: [_jsx("h4", { className: styles.heading, children: mediaHasVideo(mediaInfo) ? txt('downloadVideo') : txt('downloadAudio') }), _jsx("p", { children: txt('canSelectText') }), _jsx(TimerangeSelectionForm, { alertText: isUncutMedia ? txt('fullLengthSelected') : txt('cutTimeInfo'), buttonText: buttonText(), onFullLengthSelected: handleFullLengthSelected, wholeLength: isUncutMedia, durationInSeconds: durationInSeconds })] }) }), _jsx(Divider, { className: styles.divider }), _jsx("h4", { className: styles.heading, children: mediaHasVideo(mediaInfo) ? txt('cutVideos') : txt('cutAudios') }), _jsx(TranscodeJobsInfo, { workerType: "cut-length", project: project, visible: visible, form: form, initialValues: initialValues, isLoading: isLoading })] }));
};
export default CutMediaModal;
